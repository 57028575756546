import { useState, useEffect } from 'react';
import useAxios from '../../../../../../../hooks/useAxios';
import { useLocation } from 'react-router-dom';
import { Table } from '../../../../../../../components/exports';
import { useTranslation } from 'react-i18next';

function LgsBuyOrder_PaymentTerm({ parentData }) {
	const { t } = useTranslation();

	//#region state initiation
	const [tableData, setTableData] = useState([]);

	const [tableFooter, setTableFooter] = useState([]);
	const { isLoading, error, sendRequest: axiosPaymentTermList } = useAxios();

	//#endregion

	//#region variable initiation

	const guid = useLocation().pathname.split('/').pop();

	const headTitleList = [
		'#',
		t('lgsBuyOrderPayment:page2.paymentTerms.paymentDateBasis'),
		t('lgsBuyOrderPayment:page2.paymentTerms.paymentDays'),
		t('lgsBuyOrderPayment:page2.paymentTerms.paymentDate'),
		t('lgsBuyOrderPayment:page2.paymentTerms.paymentAmount'),
		t('lgsBuyOrderPayment:page2.paymentTerms.paymentPercentage'),
		t('lgsBuyOrderPayment:page2.paymentTerms.annotation'),
	];

	const bodyTitleList = [
		'displayOrder',
		'baseOn',
		'days',
		'payDate',
		'amount',
		'percentage',
		'note',
	];

	const tableHeadStyles = [
		{
			width: '48px',
			textAlign: 'center',
		},
		{
			width: '200px',
			textAlign: 'center',
		},
		{
			width: '200px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'center',
		},
		{
			width: '160px',
			textAlign: 'center',
		},
		{
			textAlign: 'center',
		},
		{
			width: '304px',
			textAlign: 'center',
		},
	];

	const tableBodyStyles = [
		{
			width: '48px',
			textAlign: 'center',
		},
		{
			width: '200px',
			textAlign: 'left',
		},
		{
			width: '200px',
			textAlign: 'left',
		},
		{
			width: '120px',
			textAlign: 'left',
		},
		{
			width: '160px',
			textAlign: 'right',
		},
		{
			textAlign: 'right',
		},
		{
			width: '304px',
			textAlign: 'left',
		},
	];

	const baseOnObj = {
		0: '約定付款日',
		1: '採購日',
		2: '交貨日',
		3: '收貨日',
		4: '驗收日',
	};

	//#endregion

	useEffect(() => {
		const { buyOrderPaymentTerm } = parentData;
		console.log(buyOrderPaymentTerm);

		const { buyOrderPaymentTermList, totalAmount, totalPercentage } =
			buyOrderPaymentTerm;

		// buyOrderPaymentTerm.buyOrderPaymentTermList?.forEach((el) => {
		//     el.baseOn = baseOnObj[el.baseOn];
		// });
		setTableData(buyOrderPaymentTermList);
		setTableFooter([
			'',
			'',
			'',
			t('lgsBuyOrderPayment:page2.totalAmount'),
			totalAmount,
			totalPercentage,
			'',
		]);
	}, []);

	if (error) {
		console.log('error !', error);
	}

	console.log('tableData', tableData);
	console.log('tableFooter', tableFooter);

	return (
		<>
			{tableData && (
				<Table
					guidName="paymentTermGuid"
					headTitleList={headTitleList}
					bodyTitleList={bodyTitleList}
					tableHeadStyles={tableHeadStyles}
					tableBodyStyles={tableBodyStyles}
					tableData={tableData}
					tableFooter={tableFooter}
				></Table>
			)}
		</>
	);
}

export default LgsBuyOrder_PaymentTerm;
