import { useState, useEffect, useLayoutEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import FunctionActionButtonBar from '../../../components/FunctionActionButtonBar/FunctionActionButtonBar';
import { PageTitleBar } from '../LgsBuyOrder/exports';
import { BtnNormal2 } from '../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import FormControlInput from '../../../components/Inputs/FormControlInput';
import InputWithDialog from '../../../components/Inputs/InputWithDialog';
import TextareaElement from '../../../components/Inputs/TextareaElement';
import dayjs from 'dayjs';
import NumberingRuleDialog from './NumberingRuleDialog';
import StatusBar from './StatusBar';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useParams,
    useNavigate,
} from 'react-router-dom';
import {
    convertFromNumberingRuleToRuleForUser,
    getRulePreview,
    periodFormatDict,
} from './NumberingRule';
import { ReactComponent as EditIcon } from '../../../assets/images/icn_Edit.svg';
import { ReactComponent as ActivateIcon } from '../../../assets/images/Activate-icon.svg';
import { SearchBtnSmall } from '../../../components/Buttons/SearchBtnSmall';
import { DropdownMenuArea } from './DropdownMenuArea';
import { ReactComponent as LogIcon } from '../../../assets/images/icn_Log.svg';
import showAlert from '../../../utils/swal';
import { DialogConfirm } from './DialogConfirm';
import { SubmitConfirm } from './SubmitConfirm';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Icn_OpenModal } from '../../../assets/images/icn_OpenModal.svg';

// #region styled-components
const LabelNormal = styled.label`
    font-size: 12px;
    color: var(--grey4);
    margin-bottom: 6px;
`;
const InputElement = styled.input`
    font-size: 14px;
    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 8px 8px;
    height: 32px;
    &:focus-visible {
        outline: 0;
    }
`;

const DialogBtn = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid var(--grey2);
    border-radius: 0 4px 4px 0;
    border-left: 0;
    padding-left: 16px;
    padding-right: 12px;
    cursor: pointer;
    height: 32px;
`; // dialog按鈕
const Wrapper = styled.div`
    min-height: 720px;

    border-radius: 0 0 0.75rem 0.75rem;
`;
const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
`;

const Left = styled.div`
    display: flex;
`;

const Center = styled.div``;

const Right = styled.div`
    display: flex;
`;
const Textarea = styled.div`
    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 8px;
    min-height: 88px;
    resize: none;
    &:focus-visible {
        outline: 0;
    }
    background-color: #efefef4d;
    /* width: 600px;
    height: 160px; */
`;
//#endregion

function LgsBuyOrderNoRule_Create() {
    const { t } = useTranslation(['common', 'lgsBuyOrderNoRule']);
    const [page, setPage] = useState(0);
    const [view, setView] = useState('create');
    const pageTitle = t('lgsBuyOrderNoRule:pageTitle');
    const permission = localStorage.getItem('Permission_LgsBuyOrderNoRule');
    const [modalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();
    const noRuleGuid = useParams().guid;

    // numbering rule 的參數
    const [data, setData] = useState({
        // numbering rule 組成參數
        name: '',
        prefix: '',
        period: '',
        separator: '',
        suffix: '',
        length: '',
        startNo: '001',
        incremental: '1',
        length: 3,
    });

    // 真正的 numbering rule
    const [noRule, setNoRule] = useState('3');
    // 給使用者看的 numbering rule
    const [ruleForUser, setRuleForUser] = useState('000');
    // numbering rule 的預期結果示範
    const [preview, setPreview] = useState([]);

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false);
    const [isInActivateDialogOpen, setIsInActivateDialogOpen] = useState(false);
    const [isSubmitConfirmDialogOpen, setIsSubmitConfirmDialogOpen] =
        useState(false);

    const [err, setErr] = useState(false);

    const handleDialogConfirm = (
        dialogNoRule,
        dialogData,
        dialogStartNo,
        dialogIncrement
    ) => {
        console.log(dialogNoRule, dialogData, dialogStartNo, dialogIncrement);
        setData({
            ...data,
            ...dialogData,
            startNo: dialogStartNo,
            incremental: dialogIncrement,
        });

        setNoRule(dialogNoRule);
    };

    const handleSubmit = (status) => {
        const { name, noRuleGuid, startNo, incremental, rowStamp } = data;

        const updateContent = t('log.create', {
            value: name,
        });

        console.log(updateContent);

        axios
            .post(`api/Logistics/LgsBuyOrderNoRule/insert`, {
                name,
                noRule,
                noRuleGuid,
                startNo,
                incremental,
                status,
                rowStamp,
                updateContent,
            })
            .then((res) => navigate(-1));
    };

    const checkInputs = () => {
        if (data?.name) {
            setErr(false);
            return setIsSubmitConfirmDialogOpen(true);
        } else {
            setErr(true);
        }
    };

    // 計算 numbering rule(UI) & 產生 preview
    useEffect(() => {
        // 把 numbering rule(db) 拆解
        const {
            prefix,
            suffix,
            timeSeparator,
            format,
            content,
            period,
            length,
        } = convertFromNumberingRuleToRuleForUser(noRule, data.startNo);

        // 把拆解後的參數, 更新到 data 裡
        setData({
            ...data,
            prefix: prefix,
            suffix: suffix,
            timeSeparator: timeSeparator,
            period: period,
            length: length,
            format: format,
        });

        const noRuleForUser = `${prefix}${format}${timeSeparator}${content}${suffix}`;
        setRuleForUser(noRuleForUser);

        const rule = {
            prefix,
            timeSeparator,
            format: periodFormatDict[period],
            startNo: data.startNo,
            incremental: data.incremental,
            suffix,
        };

        // 產生 preview
        const preview = getRulePreview(rule);
        setPreview(preview);
    }, [noRule, data.startNo, data.incremental]);

    // console.log(data);
    return (
        <>
            <div id="detailsView">
                <PageTitleBar
                    pageTitle={pageTitle}
                    previousPageButton={true}
                    backTo={-1}
                />
                {/* DialogConfirm element */}
                <SubmitConfirm
                    t={t}
                    isOpen={isSubmitConfirmDialogOpen}
                    setIsOpen={setIsSubmitConfirmDialogOpen}
                    handleClickInactive={() => handleSubmit('0')}
                    handleClickActive={() => handleSubmit('1')}
                    message={t('dialog.saveConfirm')}
                />
                <Container>
                    <Left></Left>
                    <Center></Center>
                    <Right>
                        <BtnNormal2
                            className="btn-primary mediumBtn"
                            handleClick={() => checkInputs()}
                        >
                            {t('button.saveButton')}
                        </BtnNormal2>
                        <BtnNormal2
                            className="btn-outline-primary mediumBtn"
                            handleClick={() => navigate(-1)}
                        >
                            {t('button.cancelButton')}
                        </BtnNormal2>
                    </Right>
                </Container>
                <div className="position-relative">
                    <Wrapper className="bg-whit d-flex flex-column p-4 position-relative bg-white">
                        <div className="col-12">
                            <div className="row"></div>
                            <div className="col-lg-4 px-4 pr-xl-6">
                                <FormControlInput
                                    id="name"
                                    labelText={t('lgsBuyOrderNoRule:name')}
                                    value={data.name}
                                    onChange={(e) => {
                                        setData({
                                            ...data,
                                            name: e.target.value,
                                        });
                                    }}
                                    disabled={false}
                                    disabledCssType={0}
                                    isErr={err}
                                    errMsg={t('errMsg.mustFill')}
                                />
                                {/* <InputWithDialog
                                    id="noRule"
                                    labelText={t('lgsBuyOrderNoRule:noRule')}
                                    value={ruleForUser}
                                    onChange={(e) => {}}
                                    disabled={false}
                                    disabledCssType={0}
                                    handleDialogShow={() => {
                                        setModalOpen(true);
                                    }}
                                /> */}
                                <div
                                    className={`wrapper d-flex flex-column mb-3`}
                                >
                                    <LabelNormal>
                                        {t('lgsBuyOrderNoRule:noRule')}
                                    </LabelNormal>
                                    <div
                                        className="input-group vendorGuidInput"
                                        id="vendorGuidInput"
                                    >
                                        <InputElement
                                            className="form-control border-right-0 item-view-noRule border-grey2"
                                            placeholder=""
                                            value={ruleForUser}
                                            onChange={() => {}}
                                            type="text"
                                            id="noRule"
                                            name="noRule"
                                            style={{
                                                cursor: 'pointer',
                                                pointerEvents: 'none',
                                                borderRight: 0,
                                            }}
                                        />

                                        <DialogBtn
                                            style={{
                                                backgroundColor: '#fff',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                setModalOpen(true);
                                            }}
                                        >
                                            <Icn_OpenModal />
                                        </DialogBtn>
                                    </div>
                                </div>
                                <FormControlInput
                                    id="startNo"
                                    labelText={t('lgsBuyOrderNoRule:startNo')}
                                    value={data?.startNo}
                                    onChange={(e) => {
                                        setData({
                                            ...data,
                                            startNo: e.target.value,
                                        });
                                    }}
                                    disabled={false}
                                    disabledCssType={0}
                                />
                                <FormControlInput
                                    id="incremental"
                                    labelText={t(
                                        'lgsBuyOrderNoRule:incremental'
                                    )}
                                    value={data.incremental}
                                    onChange={(e) => {
                                        setData({
                                            ...data,
                                            incremental: e.target.value,
                                        });
                                    }}
                                    disabled={false}
                                    disabledCssType={0}
                                />
                                <LabelNormal>
                                    {t('lgsBuyOrderNoRule:preview')}
                                </LabelNormal>
                                <Textarea disabled>
                                    <div className="mb-1">{preview[0]}</div>
                                    <div className="mb-1">{preview[1]}</div>
                                    <div className="mb-1">{preview[2]}</div>
                                </Textarea>
                            </div>
                            <div className="col-lg-4 px-4 pr-xl-5"></div>
                            <div className="col-lg-4 px-4 pr-xl-6"></div>
                        </div>
                    </Wrapper>
                </div>
            </div>
            <NumberingRuleDialog
                modalOpen={modalOpen}
                handleClose={() => {
                    setModalOpen(false);
                }}
                data={{
                    ...data,
                    prefix: data.prefix?.replace(new RegExp('"', 'g'), ''),
                    suffix: data.suffix?.replace(new RegExp('"', 'g'), ''),
                }}
                setData={setData}
                noRule={noRule}
                preview={preview}
                setPreview={setPreview}
                handleSubmit={handleDialogConfirm}
                t={t}
            />
        </>
    );
}

export default LgsBuyOrderNoRule_Create;
