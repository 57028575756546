import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// #region styled-components
const IndexTableContainer = styled.div``;

const IndexTable = styled.table`
	margin-bottom: 0;
	color: var(--grey4);
	background-color: #fff;
`;

const THead = styled.thead`
	height: 40px;
`;

const TR = styled.tr`
	height: 40px;
	&:hover {
		cursor: ${(hoverEffect) =>
			hoverEffect === 'true' ? 'pointer' : 'default'};
		background-color: ${(hoverEffect) =>
			hoverEffect === 'true' ? 'var(--grey0)' : 'var(--bs-table-bg)'};
	}
`;

const TH = styled.th`
	vertical-align: middle;
	border: 1px solid var(--grey1) !important;
	&:first-of-type {
		border-left: none !important;
	}

	&:last-of-type {
		border-right: none !important;
	}
`;

const TBody = styled.tbody``;

const TD = styled.td`
	cursor: auto;
	border: 1px solid var(--grey1) !important;
	&:first-of-type {
		border-left: none !important;
	}

	&:last-of-type {
		border-right: none !important;
	}

	background-color: ${(prop) =>
		prop.active ? '#fbfcfd !important' : '#fff'};

	position: relative;
	padding: 7px 8px !important;
	max-height: 40px;
`;

const Input = styled.input`
	padding-inline: 8px;
	border: none;
	border-radius: 4px;

	/* 去除 type="number" 上下按鈕 */
	::-webkit-outer-spin-button,
	::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	::placeholder {
		color: var(--grey3);
	}

	&:disabled {
		border: none;
		background-color: transparent;
	}
`;

const TFoot = styled.tfoot`
	border: none;
`;

const FootTR = styled.tr`
	border-top: 2px solid var(--grey3) !important;
`;

const FootTD = styled.td`
	border: none !important;
`;

//#endregion

function StandardCostTable(props) {
	//#region state initiation
	const { t } = useTranslation(['common', 'lgsBuyOrderGoodsInvoice']); // i18n

	const {
		tab4Data,
		parentData,
		headTitleList,
		bodyTitleList,
		tableHeadStyles,
		tableBodyStyles,
		tableBodyData,
		inputProperties,
	} = props;
	const { decimalPlaceTl } = parentData.page1.backEndData.buyOrder;

	console.log('tableBodyData', tableBodyData);
	//#endregion

	//#region function

	// 型態轉換
	const handleBuyCategoryName = (buyCategoryCode) => {
		switch (buyCategoryCode) {
			case 'M':
				return '物料';
			case 'E':
				return '費用';
			case 'A':
				return '資產';
		}
	};

	// 採購內容串接
	const handleBuyContent = (data) => {
		const {
			buyCategoryCode,
			materialNo,
			materialName,
			assetsName,
			assetsNo,
			expenseClassCode,
			expenseClassName,
			sectorCode,
			sectorName,
		} = data;

		switch (buyCategoryCode) {
			case 'M':
				return `${materialNo} : ${materialName}`;
			case 'E':
				return `${expenseClassCode} ${expenseClassName} : ${sectorCode} ${sectorName}`;
			case 'A':
				return `${assetsNo} : ${assetsName}`;
		}
	};

	// 計算採購金額
	const handleTotalAmount = () => {
		let totalAmount = 0;
		tab4Data.buyOrderItemList.forEach((el) => {
			totalAmount += el.quantityReceive * el.netPrice;
		});
		return totalAmount.toFixed(decimalPlaceTl);
	};

	// 計算標準成本總價
	const handleStandardBuyPrice = () => {
		let totalAmount = 0;
		tab4Data.buyOrderItemList.forEach((el) => {
			totalAmount += Number(el.standardBuyPrice);
		});
		return totalAmount.toFixed(decimalPlaceTl);
	};

	// 計算差異金額
	const handleDiffBuyPrice = () => {
		let totalAmount = 0;
		tab4Data.buyOrderItemList.forEach((el) => {
			totalAmount += Number(el.diffBuyPrice);
		});
		return totalAmount.toFixed(decimalPlaceTl);
	};
	//#endregion

	return (
		<div className="position-relative">
			<IndexTableContainer className="bg-white d-flex flex-column position-relative">
				<IndexTable className="table">
					<THead className="table-head">
						<TR>
							{headTitleList?.map((el) => (
								<TH
									key={`tableHead${headTitleList?.indexOf(
										el
									)}`}
									style={
										tableHeadStyles[
											headTitleList?.indexOf(el)
										]
									}
								>
									{el}
								</TH>
							))}
						</TR>
					</THead>
					<TBody className="">
						{tableBodyData?.map((data, rowIndex) => (
							<TR key={`standardCostTableRow${rowIndex}`}>
								{bodyTitleList?.map((el, inputIndex) => {
									if (
										[
											'displayOrder',
											'buyCategoryCode',
											'buyContent',
											'uomName',
										].includes(el)
									)
										return (
											<TD
												style={
													tableBodyStyles[
														bodyTitleList?.indexOf(
															el
														)
													]
												}
												className={`border`}
												active={data.active}
												key={`${bodyTitleList?.indexOf(
													el
												)}`}
											>
												<div className="px-2">
													{el === 'buyCategoryCode'
														? handleBuyCategoryName(
																data[el]
														  )
														: data[el]}
													{el === 'buyContent' &&
														handleBuyContent(data)}
												</div>
											</TD>
										);

									if (el === 'quantityReceive') {
										return (
											<TD
												style={
													tableBodyStyles[
														bodyTitleList?.indexOf(
															el
														)
													]
												}
												className={`border`}
												active={data.active}
												key={`${bodyTitleList?.indexOf(
													el
												)}`}
											>
												<Input
													style={{
														minWidth: '24px',
														width: '100%',
														outline: 'none',
													}}
													className={`${'text-end'}`}
													value={data[el] || ''}
													active={data.active}
													type={
														inputProperties[
															inputIndex
														]?.type
													}
													onChange={() => {}}
												/>
											</TD>
										);
									}

									if (el === 'netPrice') {
										return (
											<TD
												style={
													tableBodyStyles[
														bodyTitleList?.indexOf(
															el
														)
													]
												}
												className={`border`}
												active={data.active}
												key={`${bodyTitleList?.indexOf(
													el
												)}`}
											>
												<Input
													style={{
														minWidth: '24px',
														width: '100%',
														outline: 'none',
													}}
													className={`${'text-end'}`}
													value={
														data[el].toFixed(
															decimalPlaceTl
														) || ''
													}
													disabled
													type={
														inputProperties[
															inputIndex
														]?.type
													}
													onChange={() => {}}
												/>
											</TD>
										);
									}

									if (el === 'buyPrice') {
										return (
											<TD
												style={
													tableBodyStyles[
														bodyTitleList?.indexOf(
															el
														)
													]
												}
												className={`border`}
												active={data.active}
												key={`${bodyTitleList?.indexOf(
													el
												)}`}
											>
												<Input
													style={{
														minWidth: '24px',
														width: '100%',
														outline: 'none',
													}}
													className={`${'text-end'}`}
													value={
														Number(
															data.buyPrice
														).toFixed(
															decimalPlaceTl
														) || ''
													}
													disabled
													type={
														inputProperties[
															inputIndex
														]?.type
													}
													onChange={() => {}}
												/>
											</TD>
										);
									}

									return (
										<TD
											style={
												tableBodyStyles[
													bodyTitleList?.indexOf(el)
												]
											}
											className={`border`}
											active={data.active}
											key={`${bodyTitleList?.indexOf(
												el
											)}`}
										>
											<Input
												style={{
													minWidth: '24px',
													width: '100%',
													outline: 'none',
												}}
												className={`text-end`}
												value={data[el] || ''}
												active={data.active}
												type={
													inputProperties[inputIndex]
														?.type
												}
												onChange={() => {}}
												disabled
											/>
										</TD>
									);
								})}
							</TR>
						))}
					</TBody>

					<TFoot>
						<FootTR
							id="total"
							key="total"
							hoverEffect={false}
							style={{ fontWeight: 'bold' }}
						>
							<FootTD className="p-2 text-end"></FootTD>
							<FootTD className="p-2 text-end"></FootTD>
							<FootTD className="p-2 text-end"></FootTD>
							<FootTD className="p-2 text-end"></FootTD>
							<FootTD className="p-2 text-end"></FootTD>
							<FootTD className="px-3 py-2 text-end">
								{t('lgsBuyOrderGoodsInvoice:total')}
							</FootTD>
							<FootTD className="px-3 py-2 text-end">
								{handleTotalAmount()}
							</FootTD>
							<FootTD className="p-2 text-end"></FootTD>
							<FootTD className="px-3 py-2 text-end">
								{handleStandardBuyPrice()}
							</FootTD>
							<FootTD className="p-2 text-end"></FootTD>
							<FootTD className="px-3 py-2 text-end">
								{handleDiffBuyPrice()}
							</FootTD>
							<FootTD className="p-2 text-end"></FootTD>
						</FootTR>
					</TFoot>
				</IndexTable>
			</IndexTableContainer>
		</div>
	);
}

export default StandardCostTable;
