import React from 'react';
import styled from 'styled-components';
import { LabelNormal } from './shared/LabelNormal';
import { disabledStyle } from './shared/DisabledStyle';
import { ErrMsg } from './shared/ErrMsg';

// 使用範例
/* 
<Input
	wrapperClass="mb-3" // 外層class，可用來推下面margin
	labelText="年齡" // label文字
	type="number" // input型態
	id="age" // input id&name ; label htmlFor
	placeholder="請填寫年齡"
	disabled={false}
	disabledCssType={0} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
	register={register} // react-hook-form
	validation={{
		// 驗證邏輯
		required: 'must be required',
		min: { value: 0, message: '>0' },
		max: { value: 100, message: '<100' },
	}}
	isErr={errors.age} // 是否顯示錯誤訊息
	errMsg={errors.age?.message} // 錯誤訊息
	onChange={(e) => {
		setValue('age', e.target.value);
		const ageValue = getValues('age');
		// console.log(ageValue);
	}}
/>
*/

const InputElement = styled.input`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}

	${disabledStyle}
`;

function Input({
	labelText,
	elementStyle,
	type,
	id,
	value,
	defaultValue,
	placeholder,
	onChange,
	isErr,
	errMsg,
	wrapperClass,
	disabled,
	disabledCssType,
	register,
	validation,
}) {
	return (
		<div className={`wrapper d-flex flex-column ${wrapperClass}`}>
			<LabelNormal htmlFor={id}>{labelText}</LabelNormal>
			<InputElement
				style={elementStyle}
				{...register(id, validation)}
				type={type}
				id={id}
				value={value}
				defaultValue={defaultValue}
				placeholder={placeholder}
				onChange={onChange}
				disabled={disabled}
				disabledCssType={disabledCssType}
			/>
			<ErrMsg
				className={`text-danger mt-1 ${isErr ? 'd-block' : 'd-none'}`}
			>
				{errMsg}
			</ErrMsg>
		</div>
	);
}

export default Input;
