import styled from 'styled-components';
import { ReactComponent as Icn_SidebarEnt } from '../../assets/images/icn_SidebarEnt.svg';
import { ReactComponent as Icn_SidebarGla } from '../../assets/images/icn_SidebarGla.svg';
import { ReactComponent as Icn_SidebarLgs } from '../../assets/images/icn_SidebarLgs.svg';
import { ReactComponent as Icn_SidebarPro } from '../../assets/images/icn_SidebarPro.svg';
import { ReactComponent as Icn_chevronLeft } from '../../assets/images/icn_chevron-left.svg';
import { ReactComponent as Icn_chevronRight } from '../../assets/images/icn_chevron-right.svg';
import { ReactComponent as SynerterLogo } from '../../assets/images/synerterLogo.svg';
import { ReactComponent as SynerterLogoSmall } from '../../assets/images/synerterLogoSmall.svg';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// #region styled-components
const SidebarContainer = styled.div`
	width: ${(prop) => (prop.isSidebarWrap ? '56px' : '200px')};
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	z-index: 999;
	background: var(--primary);
	color: #ffffff;
`;

const SidebarHeader = styled.div`
	padding: 1rem 0.5rem;
	text-align: center;
	cursor: pointer;
`;

const SidebarHeaderShrinkBtn = styled.div`
	cursor: pointer;
	&:hover {
		background-color: var(--primaryhover);
	}
`;

const SidebarCollapseBtn = styled.div`
	padding: 9px 20px;
	line-height: 22px;
	display: block;
`;

const SidebarListContainer = styled.div``;

const sidebarBtn = styled.div`
	display: flex;
	padding: 9.5px 20px;
	list-style: none;
	align-items: center;
	font-size: 0.875rem;
	cursor: pointer;
	&:hover {
		background-color: var(--primaryhover);

		svg {
			opacity: 1;
		}
	}

	height: ${(prop) => prop.isSidebarWrap && '40px'};
`;

const GmnSidebar = styled(sidebarBtn)`
	&:hover svg {
		path:last-child {
			fill: var(--warning);
		}
	}
`;

const EntSidebar = styled(sidebarBtn)`
	&:hover svg {
		path:last-child {
			fill: var(--warning);
		}
	}
`;
const GlaSidebar = styled(sidebarBtn)`
	&:hover svg {
		path:first-child {
			fill: var(--warning);
		}
	}
`;
const LgsSidebar = styled(sidebarBtn)`
	&:hover svg {
		#Rectangle_3550 {
			fill: var(--warning);
		}
	}
`;
const ProSidebar = styled(sidebarBtn)`
	&:hover svg {
		path:nth-of-type(2) {
			fill: var(--warning);
		}
	}
`;

const Layer2 = styled.div`
	position: absolute;
	top: 56px;
	left: ${(prop) => (prop.isSidebarWrap ? '56px' : '200px')};
	min-height: 500px;
	padding: 24px;
	z-index: 10000;
	background-color: var(--primarylllt);
	cursor: auto;
`;

const Layer2Head = styled.div`
	display: flex;
	align-items: center;
	padding: 8px 16px 0 16px;
`;

const Hr = styled.hr`
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.4);
`;

const SubjectIcon = styled.div`
	svg {
		width: 24px;
		height: 24px;

		//ent, gla
		path:first-child {
			fill: var(--warning);
		}

		path:last-child {
			fill: var(--primary) !important;
		}

		//lgs
		#Rectangle_3551,
		#Rectangle_3552 {
			fill: var(--primary) !important;
		}

		//pro
		#Path_1553,
		#Rectangle_3555 {
			fill: var(--primary) !important;
		}
	}
`;

const SubjectText = styled.div`
	font-weight: bold;
	font-size: 20px;
	color: var(--primary);
	margin-left: 8px;
`;

const Layer2Body = styled.div`
	padding: 16px;
	color: var(--secondary);
	display: flex;
	flex-wrap: nowrap;
`;

const InnerSubject = styled.div`
	width: 248px;
`;

const InnerSubjectTitle = styled.div`
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 24px;
`;

const InnerSubjectContent = styled.div``;

const InnerSubjectItems = styled.div`
	width: 248px;
`;

const InnerSubjectSecondTitle = styled.div`
	margin-bottom: 16px;
`;

const InnerSubjectItem = styled.div`
	margin-bottom: 16px;
`;

const InnerSubjectText = styled.span`
	font-size: 14px;
	cursor: ${(prop) => (prop.ispermission ? 'pointer' : 'auto')};
	color: ${(prop) => (prop.ispermission ? 'var(--grey5)' : 'var(--grey3)')};
`;

const StyledLink = styled(Link)`
	color: ${(prop) =>
		prop.ispermission === 'true' ? 'var(--grey5)' : 'var(--grey3)'};
	pointer-events: ${(prop) =>
		prop.ispermission === 'true' ? 'auto' : 'none'};
	text-decoration: none;
`;
//#endregion

const Sidebar = ({ isSidebarWrap, setSidebarWrap }) => {
	const { t } = useTranslation(['common', 'shdSidebar']);

	const mvcLocalhost = 'https://localhost:7014/'; // publishReset 前改為下方
	// const mvcLocalhost = '/';

	const [userPermission, setUserPermission] = useState([]);
	const [layer2Show, setLayer2Show] = useState('');
	const [cookies, setCookie] = useCookies();
	// console.log(cookies);

	const getUserPermission = async () => {
		await axios
			.get(`api/Auth/UserPermission/SideBar`)
			.then((res) => {
				setUserPermission(res.data.authData);
				console.log(res.data);
			})
			.catch((err) => console.log(err));
	};

	useEffect(() => {
		getUserPermission();
	}, []);

	// console.log(userPermission);

	return (
		<SidebarContainer isSidebarWrap={isSidebarWrap}>
			<SidebarHeader onClick={() => (window.location.href = '/')}>
				{isSidebarWrap ? <SynerterLogoSmall /> : <SynerterLogo />}
			</SidebarHeader>
			<SidebarHeaderShrinkBtn
				onClick={() => setSidebarWrap(!isSidebarWrap)}
			>
				<SidebarCollapseBtn>
					{isSidebarWrap ? <Icn_chevronRight /> : <Icn_chevronLeft />}
				</SidebarCollapseBtn>
			</SidebarHeaderShrinkBtn>

			{cookies._group_guid && (
				<SidebarListContainer>
					<GmnSidebar
						isSidebarWrap={isSidebarWrap}
						onMouseEnter={() => setLayer2Show('0')}
						onMouseLeave={() => setLayer2Show('')}
					>
						<Icn_SidebarEnt className={!isSidebarWrap && 'me-3'} />
						{!isSidebarWrap && t('shdSidebar:groupManagement')}
						{layer2Show === '0' && (
							<Layer2
								isSidebarWrap={isSidebarWrap}
								className="layer2"
							>
								<Layer2Head>
									<SubjectIcon>
										<Icn_SidebarEnt />
									</SubjectIcon>
									<SubjectText>
										{t('shdSidebar:groupManagement')}
									</SubjectText>
								</Layer2Head>
								<Hr />
								<Layer2Body>
									<InnerSubject>
										<InnerSubjectTitle>
											{t('shdSidebar:group')}
										</InnerSubjectTitle>
										<InnerSubjectContent>
											<InnerSubjectItems>
												<InnerSubjectItem>
													<InnerSubjectText
														ispermission={userPermission.includes(
															'EntGroupHierarchy'
														)}
														onClick={() => {
															userPermission.includes(
																'EntGroupHierarchy'
															) &&
																(window.location.href = `${mvcLocalhost}Enterprise/EntGroupHierarchy`);
														}}
													>
														{t(
															'shdSidebar:entGroupHierarchy'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														ispermission={userPermission.includes(
															'EntGroup'
														)}
														onClick={() => {
															userPermission.includes(
																'EntGroup'
															) &&
																(window.location.href = `${mvcLocalhost}Enterprise/EntGroup/Details/${cookies['_group_guid']}`);
														}}
													>
														{t(
															'shdSidebar:entGroup'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														ispermission={userPermission.includes(
															'EntCompany'
														)}
														onClick={() => {
															userPermission.includes(
																'EntCompany'
															) &&
																(window.location.href = `${mvcLocalhost}Enterprise/EntCompany`);
														}}
													>
														{t(
															'shdSidebar:entCompany'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														ispermission={userPermission.includes(
															'EntCompany'
														)}
														onClick={() => {
															userPermission.includes(
																'EntCompany'
															) &&
																(window.location.href = `${mvcLocalhost}Enterprise/EntCompany/Create`);
														}}
													>
														{t(
															'shdSidebar:createNewCompany'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
											</InnerSubjectItems>
										</InnerSubjectContent>
									</InnerSubject>
									<InnerSubject>
										<InnerSubjectTitle>
											{t('shdSidebar:groupUser')}
										</InnerSubjectTitle>
										<InnerSubjectContent>
											<InnerSubjectItems>
												<InnerSubjectItem>
													<InnerSubjectText
														ispermission={userPermission.includes(
															'GmnAuthorization'
														)}
														onClick={() => {
															userPermission.includes(
																'GmnAuthorization'
															) &&
																(window.location.href = `${mvcLocalhost}User/GmnAuthorization`);
														}}
													>
														{t(
															'shdSidebar:permissionSetting'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>

												<InnerSubjectItem>
													<InnerSubjectText
														ispermission={userPermission.includes(
															'GmnAuthorization'
														)}
														onClick={() => {
															userPermission.includes(
																'GmnAuthorization'
															) &&
																(window.location.href = `${mvcLocalhost}User/Home/Development`);
														}}
													>
														{t(
															'shdSidebar:permissionAssignment'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														ispermission={userPermission.includes(
															'GmnGroupUser'
														)}
														onClick={() => {
															userPermission.includes(
																'GmnGroupUser'
															) &&
																(window.location.href = `${mvcLocalhost}User/GmnGroupUser`);
														}}
													>
														{t(
															'shdSidebar:userManagement'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
											</InnerSubjectItems>
										</InnerSubjectContent>
									</InnerSubject>
								</Layer2Body>
							</Layer2>
						)}
					</GmnSidebar>
				</SidebarListContainer>
			)}

			{cookies._company_guid && (
				<SidebarListContainer>
					<EntSidebar
						isSidebarWrap={isSidebarWrap}
						onMouseEnter={() => setLayer2Show('0')}
						onMouseLeave={() => setLayer2Show('')}
					>
						<Icn_SidebarEnt className={!isSidebarWrap && 'me-3'} />
						{!isSidebarWrap &&
							t('shdSidebar:organizationalStructure')}
						{layer2Show === '0' && (
							<Layer2
								isSidebarWrap={isSidebarWrap}
								className="layer2"
							>
								<Layer2Head>
									<SubjectIcon>
										<Icn_SidebarEnt />
									</SubjectIcon>
									<SubjectText>
										{t(
											'shdSidebar:organizationalStructure'
										)}
									</SubjectText>
								</Layer2Head>
								<Hr />
								<Layer2Body>
									<InnerSubject>
										<InnerSubjectTitle>
											{t('shdSidebar:customer')}
										</InnerSubjectTitle>
										<InnerSubjectContent>
											<InnerSubjectItems>
												<InnerSubjectItem>
													<InnerSubjectText
														ispermission={userPermission.includes(
															'EntCompanyUser'
														)}
														onClick={() => {
															userPermission.includes(
																'EntCompanyUser'
															) &&
																(window.location.href = `${mvcLocalhost}Enterprise/EntCustomer`);
														}}
													>
														{t(
															'shdSidebar:customerInfo'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														ispermission={userPermission.includes(
															'EntCustomerClass'
														)}
														onClick={() => {
															userPermission.includes(
																'EntCustomerClass'
															) &&
																(window.location.href = `${mvcLocalhost}Enterprise/EntCustomerClass`);
														}}
													>
														{t(
															'shdSidebar:customerClass'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														ispermission={userPermission.includes(
															'EntCustomerNoRule'
														)}
														onClick={() => {
															userPermission.includes(
																'EntCustomerNoRule'
															) &&
																(window.location.href = `${mvcLocalhost}Enterprise/EntCustomerNoRule`);
														}}
													>
														{t(
															'shdSidebar:customerNoRule'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
											</InnerSubjectItems>
										</InnerSubjectContent>
									</InnerSubject>
									<InnerSubject>
										<InnerSubjectTitle>
											{t('shdSidebar:vendorGuid')}
										</InnerSubjectTitle>
										<InnerSubjectContent>
											<InnerSubjectItems>
												<InnerSubjectItem>
													<InnerSubjectText
														ispermission={userPermission.includes(
															'EntVendor'
														)}
														onClick={() => {
															userPermission.includes(
																'EntVendor'
															) &&
																(window.location.href = `${mvcLocalhost}Enterprise/EntVendor`);
														}}
													>
														{t('shdSidebar:vendor')}
													</InnerSubjectText>
												</InnerSubjectItem>

												<InnerSubjectItem>
													<InnerSubjectText
														ispermission={userPermission.includes(
															'EntVendorClass'
														)}
														onClick={() => {
															userPermission.includes(
																'EntVendorClass'
															) &&
																(window.location.href = `${mvcLocalhost}Enterprise/EntVendorClass`);
														}}
													>
														{t(
															'shdSidebar:vendorClass'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														ispermission={userPermission.includes(
															'EntVendorNoRule'
														)}
														onClick={() => {
															userPermission.includes(
																'EntVendorNoRule'
															) &&
																(window.location.href = `${mvcLocalhost}Enterprise/EntVendorNoRule`);
														}}
													>
														{t(
															'shdSidebar:vendorNoRule'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
											</InnerSubjectItems>
										</InnerSubjectContent>
									</InnerSubject>
									<InnerSubject>
										<InnerSubjectTitle>
											{t(
												'shdSidebar:employeePermissionSetting'
											)}
										</InnerSubjectTitle>
										<InnerSubjectContent>
											<InnerSubjectItems>
												<InnerSubjectItem>
													<InnerSubjectText
														ispermission={userPermission.includes(
															'ErpEmployee'
														)}
														onClick={() => {
															userPermission.includes(
																'ErpEmployee'
															) &&
																(window.location.href = `${mvcLocalhost}Enterprise/EntEmployee`);
														}}
													>
														{t(
															'shdSidebar:entEmployee'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														ispermission={userPermission.includes(
															'EntEmployeeClass'
														)}
														onClick={() => {
															userPermission.includes(
																'EntEmployeeClass'
															) &&
																(window.location.href = `${mvcLocalhost}Enterprise/EntEmployeeClass`);
														}}
													>
														{t(
															'shdSidebar:employeeClass'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														ispermission={userPermission.includes(
															'EntEmployeeNoRule'
														)}
														onClick={() => {
															userPermission.includes(
																'EntEmployeeNoRule'
															) &&
																(window.location.href = `${mvcLocalhost}Enterprise/EntEmployeeNoRule`);
														}}
													>
														{t(
															'shdSidebar:employeeNoRule'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														ispermission={userPermission.includes(
															'EntCompanyUser'
														)}
														onClick={() => {
															userPermission.includes(
																'EntCompanyUser'
															) &&
																(window.location.href = `${mvcLocalhost}User/EntCompanyUser`);
														}}
													>
														{t(
															'shdSidebar:userManagement'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														ispermission={userPermission.includes(
															'ErpFunctionAuthorization'
														)}
														onClick={() => {
															userPermission.includes(
																'ErpFunctionAuthorization'
															) &&
																(window.location.href = `${mvcLocalhost}User/EntAuthorization`);
														}}
													>
														{t(
															'shdSidebar:permissionSetting'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														ispermission={userPermission.includes(
															'ErpFunctionAuthorization'
														)}
														onClick={() => {
															userPermission.includes(
																'ErpFunctionAuthorization'
															) &&
																(window.location.href = `${mvcLocalhost}User/Home/Development`);
														}}
													>
														{t(
															'shdSidebar:permissionAssignment'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
											</InnerSubjectItems>
										</InnerSubjectContent>
									</InnerSubject>
									<InnerSubject>
										<InnerSubjectTitle>
											{t('shdSidebar:companySetting')}
										</InnerSubjectTitle>
										<InnerSubjectContent>
											<InnerSubjectItems>
												<InnerSubjectItem>
													<InnerSubjectText
														ispermission={userPermission.includes(
															'ErpCompany'
														)}
														onClick={() => {
															window.location.href = `${mvcLocalhost}Enterprise/EntCompany/Details/${cookies['_company_guid']}`;
														}}
													>
														{t(
															'shdSidebar:entCompany'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														ispermission={userPermission.includes(
															'EntSector'
														)}
														onClick={() => {
															userPermission.includes(
																'EntSector'
															) &&
																(window.location.href = `${mvcLocalhost}Enterprise/EntSector`);
														}}
													>
														{t(
															'shdSidebar:entSector'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														ispermission={userPermission.includes(
															'EntSectorClass'
														)}
														onClick={() => {
															userPermission.includes(
																'EntSectorClass'
															) &&
																(window.location.href = `${mvcLocalhost}Enterprise/EntSectorClass`);
														}}
													>
														{t(
															'shdSidebar:sectorClass'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														ispermission={userPermission.includes(
															'EntCurrency'
														)}
														onClick={() => {
															userPermission.includes(
																'EntCurrency'
															) &&
																(window.location.href = `${mvcLocalhost}Enterprise/EntCurrency`);
														}}
													>
														{t(
															'shdSidebar:currency'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														ispermission={userPermission.includes(
															'EntUOM'
														)}
														onClick={() => {
															userPermission.includes(
																'EntUOM'
															) &&
																(window.location.href = `${mvcLocalhost}Enterprise/EntUom`);
														}}
													>
														{t(
															'shdSidebar:uomguid'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
											</InnerSubjectItems>
										</InnerSubjectContent>
									</InnerSubject>
								</Layer2Body>
							</Layer2>
						)}
					</EntSidebar>
					<GlaSidebar
						isSidebarWrap={isSidebarWrap}
						onMouseEnter={() => setLayer2Show('1')}
						onMouseLeave={() => setLayer2Show('')}
					>
						<Icn_SidebarGla className={!isSidebarWrap && 'me-3'} />
						{!isSidebarWrap && t('shdSidebar:generalLedger')}

						{layer2Show === '1' && (
							<Layer2
								isSidebarWrap={isSidebarWrap}
								className="layer2"
							>
								<Layer2Head>
									<SubjectIcon>
										<Icn_SidebarGla />
									</SubjectIcon>
									<SubjectText>
										{t('shdSidebar:generalLedger')}
									</SubjectText>
								</Layer2Head>
								<Hr />
								<Layer2Body>
									<InnerSubject>
										<InnerSubjectTitle>
											{t('shdSidebar:accountingPosting')}
										</InnerSubjectTitle>
										<InnerSubjectContent>
											<InnerSubjectItems>
												<InnerSubjectItem>
													<InnerSubjectText
														ispermission={userPermission.includes(
															'GlaAccountDocument'
														)}
														onClick={() => {
															userPermission.includes(
																'GlaAccountDocument'
															) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaAccountDocument/Create`);
														}}
													>
														{t(
															'shdSidebar:accountingVoucherEntry'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														ispermission={userPermission.includes(
															'GlaAccountDocument'
														)}
														onClick={() => {
															userPermission.includes(
																'GlaAccountDocument'
															) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaAccountDocument`);
														}}
													>
														{t(
															'shdSidebar:accountDocument'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
											</InnerSubjectItems>
										</InnerSubjectContent>
									</InnerSubject>
									<InnerSubject>
										<InnerSubjectTitle>
											{t(
												'shdSidebar:financialStatements'
											)}
										</InnerSubjectTitle>
										<InnerSubjectContent>
											<InnerSubjectItems>
												<InnerSubjectSecondTitle>
													{t(
														'shdSidebar:accountDocument'
													)}
												</InnerSubjectSecondTitle>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'GlaAccountDocument'
														)}
														onClick={() => {
															userPermission.includes(
																'GlaAccountDocument'
															) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaAccountDocumentList`);
														}}
													>
														{t(
															'shdSidebar:listOfAccountingDocuments'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'GlaJournal'
														)}
														onClick={() => {
															userPermission.includes(
																'GlaJournal'
															) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaLedgersJournal`);
														}}
													>
														{t(
															'shdSidebar:journal'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>

												<InnerSubjectSecondTitle>
													{t(
														'shdSidebar:accountReports'
													)}
												</InnerSubjectSecondTitle>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'GlaLedgersGeneral'
														)}
														onClick={() => {
															userPermission.includes(
																'GlaLedgersGeneral'
															) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaLedgersGeneral`);
														}}
													>
														{t(
															'shdSidebar:generalLedger2'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'GlaLedgersOpen'
														)}
														onClick={() => {
															userPermission.includes(
																'GlaLedgersOpen'
															) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaLedgersOpen`);
														}}
													>
														{t(
															'shdSidebar:detailedLedger'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'GlaAccountBalance'
														)}
														onClick={() => {
															userPermission.includes(
																'GlaAccountBalance'
															) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaLedgersBalance`);
														}}
													>
														{t(
															'shdSidebar:accountBalance'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'GlaAccountBalance'
														)}
														onClick={() => {
															userPermission.includes(
																'GlaAccountBalance'
															) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaLedgersBalanceTable`);
														}}
													>
														{t(
															'shdSidebar:accountBalanceOverview'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>

												<InnerSubjectSecondTitle>
													{t(
														'shdSidebar:financialReports'
													)}
												</InnerSubjectSecondTitle>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'GlaTrialBalance'
														)}
														onClick={() => {
															userPermission.includes(
																'GlaTrialBalance'
															) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaTrialBalance`);
														}}
													>
														{t(
															'shdSidebar:trialBalance'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'GlaIncomeStatement'
														)}
														onClick={() => {
															userPermission.includes(
																'GlaIncomeStatement'
															) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaIncomeStatement`);
														}}
													>
														{t(
															'shdSidebar:incomeStatement'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'GlaBalanceSheet'
														)}
														onClick={() => {
															userPermission.includes(
																'GlaBalanceSheet'
															) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaBalanceSheet`);
														}}
													>
														{t(
															'shdSidebar:balanceSheet'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'GlaReportFormat'
														)}
														onClick={() => {
															userPermission.includes(
																'GlaReportFormat'
															) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaReportFormat`);
														}}
													>
														{t(
															'shdSidebar:reportFormat'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
											</InnerSubjectItems>
										</InnerSubjectContent>
									</InnerSubject>
									<InnerSubject>
										<InnerSubjectTitle>
											{t(
												'shdSidebar:otherAccountManagement'
											)}
										</InnerSubjectTitle>
										<InnerSubjectContent>
											<InnerSubjectItems>
												<InnerSubjectSecondTitle>
													{t(
														'shdSidebar:assetManagement'
													)}
												</InnerSubjectSecondTitle>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'GlaAssets'
														)}
														onClick={() => {
															userPermission.includes(
																'GlaAssets'
															) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaAssets`);
														}}
													>
														{t(
															'shdSidebar:assetMaster'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'GlaAssetsPosting'
														)}
														onClick={() => {
															userPermission.includes(
																'GlaAssetsPosting'
															) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaAssetsPosting`);
														}}
													>
														{t(
															'shdSidebar:assetPosting'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'GlaAssetsDepreciationRun'
														)}
														onClick={() => {
															userPermission.includes(
																'GlaAssetsDepreciationRun'
															) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaAssetsDepreciationRun`);
														}}
													>
														{t(
															'shdSidebar:assetDepreciationBooking'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'GlaAssetsNoRule'
														)}
														onClick={() => {
															userPermission.includes(
																'GlaAssetsNoRule'
															) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaAssetsNoRule`);
														}}
													>
														{t(
															'shdSidebar:assetNumberRule'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'GlaAssetsClass'
														)}
														onClick={() => {
															userPermission.includes(
																'GlaAssetsClass'
															) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaAssetsClass`);
														}}
													>
														{t(
															'shdSidebar:assetClassification'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'GlaAssetsLegacyConversion'
														)}
														onClick={() => {
															userPermission.includes(
																'GlaAssetsLegacyConversion'
															) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaAssetsLegacyConversion`);
														}}
													>
														{t(
															'shdSidebar:importExistingAssets'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>

												<InnerSubjectSecondTitle>
													{t(
														'shdSidebar:expenseClassCode'
													)}
												</InnerSubjectSecondTitle>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'GlaExpenseClass'
														)}
														onClick={() => {
															userPermission.includes(
																'GlaExpenseClass'
															) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaExpenseClass`);
														}}
													>
														{t(
															'shdSidebar:expenseClassCode'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>

												<InnerSubjectSecondTitle>
													{t(
														'shdSidebar:revenueCategory'
													)}
												</InnerSubjectSecondTitle>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'GlaRevenueClass'
														)}
														onClick={() => {
															userPermission.includes(
																'GlaRevenueClass'
															) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaRevenueClass`);
														}}
													>
														{t(
															'shdSidebar:revenueCategory'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>

												<InnerSubjectSecondTitle>
													{t(
														'shdSidebar:fundManagement'
													)}
												</InnerSubjectSecondTitle>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'GlaBankingAccount'
														)}
														onClick={() => {
															userPermission.includes(
																'GlaBankingAccount'
															) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaBankingAccount`);
														}}
													>
														{t(
															'shdSidebar:fundAccount'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'GlaBankingClass'
														)}
														onClick={() => {
															userPermission.includes(
																'GlaBankingClass'
															) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaBankingClass`);
														}}
													>
														{t(
															'shdSidebar:fundCategory'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
											</InnerSubjectItems>
										</InnerSubjectContent>
									</InnerSubject>
									<InnerSubject>
										<InnerSubjectTitle>
											{t(
												'shdSidebar:generalPostingSettings'
											)}
										</InnerSubjectTitle>
										<InnerSubjectContent>
											<InnerSubjectItems>
												<InnerSubjectSecondTitle>
													{t('shdSidebar:account')}
												</InnerSubjectSecondTitle>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'GlaAccount'
														)}
														onClick={() => {
															userPermission.includes(
																'GlaAccount'
															) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaAccount`);
														}}
													>
														{t(
															'shdSidebar:account'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>

												<InnerSubjectSecondTitle>
													{t(
														'shdSidebar:accountDocument'
													)}
												</InnerSubjectSecondTitle>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'GlaAccountDocument'
														)}
														onClick={() => {
															userPermission.includes(
																'GlaAccountDocument'
															) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaAccountDocument`);
														}}
													>
														{t(
															'shdSidebar:accountDocument'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>

												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'GlaAccountingDocumentType'
														)}
														onClick={() => {
															userPermission.includes(
																'GlaAccountingDocumentType'
															) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaAccountDocumentType`);
														}}
													>
														{t(
															'shdSidebar:accountDocumentType'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'GlaAccountDocumentNoRule'
														)}
														onClick={() => {
															userPermission.includes(
																'GlaAccountDocumentNoRule'
															) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaAccountDocumentNoRule`);
														}}
													>
														{t(
															'shdSidebar:accountingCertificateNumberRule'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'GlaInvoiceFormatType'
														)}
														onClick={() => {
															userPermission.includes(
																'GlaInvoiceFormatType'
															) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaInvoiceFormatType`);
														}}
													>
														{t(
															'shdSidebar:invoiceType'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'GlaInvoiceTaxRateType'
														)}
														onClick={() => {
															userPermission.includes(
																'GlaInvoiceTaxRateType'
															) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaInvoiceTaxRateType`);
														}}
													>
														{t(
															'shdSidebar:invoiceTaxType'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'GlaAppendixClass'
														)}
														onClick={() => {
															userPermission.includes(
																'GlaAppendixClass'
															) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaAppendixClass`);
														}}
													>
														{t(
															'shdSidebar:attachmentCategory'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>

												<InnerSubjectSecondTitle>
													{t(
														'shdSidebar:accountingBasicSettings'
													)}
												</InnerSubjectSecondTitle>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'GlaClosingPeriod'
														)}
														onClick={() => {
															userPermission.includes(
																'GlaClosingPeriod'
															) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaPostingPeriod`);
														}}
													>
														{t(
															'shdSidebar:accountingOpenPeriod'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'GlaExchangeTable'
														)}
														onClick={() => {
															userPermission.includes(
																'GlaExchangeTable'
															) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaExchangeTable/Details`);
														}}
													>
														{t(
															'shdSidebar:exchangeTable'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'GlaCarryForward'
														)}
														onClick={() => {
															userPermission.includes(
																'GlaCarryForward'
															) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaCarryForward`);
														}}
													>
														{t(
															'shdSidebar:accountingYearEndTransfer'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'GlaCompanyAccountingSeting'
														)}
														onClick={() => {
															userPermission.includes(
																'GlaCompanyAccountingSeting'
															) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaCompanySetting/Details/${cookies['_company_guid']}`);
														}}
													>
														{t(
															'shdSidebar:companyBasicSetting'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'GlaTransaction'
														)}
														onClick={() => {
															userPermission.includes(
																'GlaTransaction'
															) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaTransaction/Details/${cookies['_company_guid']}`);
														}}
													>
														{t(
															'shdSidebar:transaction'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={
															cookies._company_setting_status ===
															'0'
																? userPermission.includes(
																		'GlaCompanyAccountingSeting'
																  )
																: false
														}
														onClick={() => {
															cookies._company_setting_status ===
																'0' &&
																userPermission.includes(
																	'GlaCompanyAccountingSeting'
																) &&
																(window.location.href = `${mvcLocalhost}GeneralAccount/GlaCompanySetting/Activate`);
														}}
													>
														{t(
															'shdSidebar:enableGeneralLedger'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
											</InnerSubjectItems>
										</InnerSubjectContent>
									</InnerSubject>
								</Layer2Body>
							</Layer2>
						)}
					</GlaSidebar>
					<LgsSidebar
						isSidebarWrap={isSidebarWrap}
						onMouseEnter={() => setLayer2Show('2')}
						onMouseLeave={() => setLayer2Show('')}
					>
						<Icn_SidebarLgs className={!isSidebarWrap && 'me-3'} />
						{!isSidebarWrap && t('shdSidebar:inventory')}

						{layer2Show === '2' && (
							<Layer2
								isSidebarWrap={isSidebarWrap}
								className="layer2"
							>
								<Layer2Head>
									<SubjectIcon>
										<Icn_SidebarLgs />
									</SubjectIcon>
									<SubjectText>
										{t('shdSidebar:inventoryManagement')}
									</SubjectText>
								</Layer2Head>
								<Hr />
								<Layer2Body>
									<InnerSubject>
										<InnerSubjectTitle>
											{t(
												'shdSidebar:purchaseTransactions'
											)}
										</InnerSubjectTitle>
										<InnerSubjectContent>
											<InnerSubjectItems>
												<InnerSubjectSecondTitle>
													{t(
														'shdSidebar:purchaseOrders'
													)}
												</InnerSubjectSecondTitle>
												<InnerSubjectItem>
													<StyledLink
														className="ps-2"
														to="/Logistics/LgsBuyOrder"
														ispermission={userPermission
															.includes(
																'LgsBuyOrder'
															)
															.toString()}
														onClick={() =>
															setLayer2Show('')
														}
													>
														{t(
															'shdSidebar:purchaseOrders'
														)}
													</StyledLink>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<StyledLink
														className="ps-2"
														to="/Logistics/LgsBuyOrderType"
														ispermission={userPermission
															.includes(
																'LgsBuyOrderType'
															)
															.toString()}
														onClick={() =>
															setLayer2Show('')
														}
													>
														{t(
															'shdSidebar:purchaseOrderTypes'
														)}
													</StyledLink>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<StyledLink
														className="ps-2"
														to="/Logistics/LgsBuyOrderNoRule"
														ispermission={userPermission
															.includes(
																'LgsBuyOrderNoRule'
															)
															.toString()}
														onClick={() =>
															setLayer2Show('')
														}
													>
														{t(
															'shdSidebar:purchaseOrderNumberRules'
														)}
													</StyledLink>
												</InnerSubjectItem>
												<InnerSubjectSecondTitle>
													{t(
														'shdSidebar:purchaseOrderReceiptAndInvoice'
													)}
												</InnerSubjectSecondTitle>

												<InnerSubjectItem>
													<StyledLink
														className="ps-2"
														to="/Logistics/LgsBuyOrderGoodsInvoice"
														ispermission={userPermission
															.includes(
																'LgsBuyOrderGoodsInvoice'
															)
															.toString()}
														onClick={() =>
															setLayer2Show('')
														}
													>
														{t(
															'shdSidebar:purchaseOrderReceiptAndInvoice'
														)}
													</StyledLink>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<StyledLink
														className="ps-2"
														to="/Logistics/LgsBuyOrderGoodsReturn"
														ispermission={userPermission
															.includes(
																'LgsBuyOrderGoodsReturn'
															)
															.toString()}
														onClick={() =>
															setLayer2Show('')
														}
													>
														{t(
															'shdSidebar:purchaseOrderReturn'
														)}
													</StyledLink>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<StyledLink
														className="ps-2"
														to="/Logistics/LgsBuyOrderInvoice"
														ispermission={userPermission
															.includes(
																'LgsBuyOrderInvoice'
															)
															.toString()}
														onClick={() =>
															setLayer2Show('')
														}
													>
														{t(
															'shdSidebar:purchaseOrderInvoicing'
														)}
													</StyledLink>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<StyledLink
														className="ps-2"
														to="/Logistics/LgsBuyOrderInvoiceReturn"
														ispermission={userPermission
															.includes(
																'LgsBuyOrderInvoiceReturn'
															)
															.toString()}
														onClick={() =>
															setLayer2Show('')
														}
													>
														{t(
															'shdSidebar:purchaseOrderInvoiceReturn'
														)}
													</StyledLink>
												</InnerSubjectItem>
												<InnerSubjectSecondTitle>
													{t(
														'shdSidebar:purchaseOrderInspection'
													)}
												</InnerSubjectSecondTitle>

												<InnerSubjectItem>
													<InnerSubjectItem>
														<StyledLink
															className="ps-2"
															to="/Logistics/LgsBuyOrderGoodsInspect"
															ispermission={userPermission
																.includes(
																	'LgsBuyOrderGoodsInspect'
																)
																.toString()}
															onClick={() =>
																setLayer2Show(
																	''
																)
															}
														>
															{t(
																'shdSidebar:purchaseOrderInspection'
															)}
														</StyledLink>
													</InnerSubjectItem>
												</InnerSubjectItem>

												<InnerSubjectSecondTitle>
													{t(
														'shdSidebar:purchasePaymentAndSettlement'
													)}
												</InnerSubjectSecondTitle>
												<InnerSubjectItem>
													<StyledLink
														className="ps-2"
														to="/Logistics/LgsBuyOrderPayment"
														ispermission={userPermission
															.includes(
																'LgsBuyOrderPayment'
															)
															.toString()}
														onClick={() =>
															setLayer2Show('')
														}
													>
														{t(
															'shdSidebar:purchaseOrderPayment'
														)}
													</StyledLink>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<StyledLink
														className="ps-2"
														to="/Logistics/LgsBuyOrderPaymentReturn"
														ispermission={userPermission
															.includes(
																'LgsBuyOrderPaymentReturn'
															)
															.toString()}
														onClick={() =>
															setLayer2Show('')
														}
													>
														{t(
															'shdSidebar:purchasePaymentRefund'
														)}
													</StyledLink>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<StyledLink
														className="ps-2"
														to="/Logistics/LgsBuyOrderPaymentClose"
														ispermission={userPermission
															.includes(
																'LgsBuyOrderPaymentClose'
															)
															.toString()}
														onClick={() =>
															setLayer2Show('')
														}
													>
														{t(
															'shdSidebar:purchaseOrderPaymentSettlement'
														)}
													</StyledLink>
												</InnerSubjectItem>
											</InnerSubjectItems>
										</InnerSubjectContent>
									</InnerSubject>
									<InnerSubject>
										<InnerSubjectTitle>
											{t('shdSidebar:salesTransaction')}
										</InnerSubjectTitle>
										<InnerSubjectContent>
											<InnerSubjectItems>
												<InnerSubjectSecondTitle>
													{t('shdSidebar:salesOrder')}
												</InnerSubjectSecondTitle>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'XXX'
														)}
														onClick={() => {
															userPermission.includes(
																'XXX'
															) &&
																(window.location.href = `/User/Home/Development`);
														}}
													>
														{t(
															'shdSidebar:addSalesOrder'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'XXX'
														)}
														onClick={() => {
															userPermission.includes(
																'XXX'
															) &&
																(window.location.href = `/User/Home/Development`);
														}}
													>
														{t(
															'shdSidebar:modifySalesOrder'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'XXX'
														)}
														onClick={() => {
															userPermission.includes(
																'XXX'
															) &&
																(window.location.href = `/User/Home/Development`);
														}}
													>
														{t(
															'shdSidebar:salesOrderType'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'XXX'
														)}
														onClick={() => {
															userPermission.includes(
																'XXX'
															) &&
																(window.location.href = `/User/Home/Development`);
														}}
													>
														{t(
															'shdSidebar:salesOrderNumberRule'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectSecondTitle>
													{t(
														'shdSidebar:salesDeliveryAndInvoicing'
													)}
												</InnerSubjectSecondTitle>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'XXX'
														)}
														onClick={() => {
															userPermission.includes(
																'XXX'
															) &&
																(window.location.href = `/User/Home/Development`);
														}}
													>
														{t(
															'shdSidebar:salesDelivery'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'XXX'
														)}
														onClick={() => {
															userPermission.includes(
																'XXX'
															) &&
																(window.location.href = `/User/Home/Development`);
														}}
													>
														{t(
															'shdSidebar:salesReturn'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'XXX'
														)}
														onClick={() => {
															userPermission.includes(
																'XXX'
															) &&
																(window.location.href = `/User/Home/Development`);
														}}
													>
														{t(
															'shdSidebar:salesSupplementInvoice'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'XXX'
														)}
														onClick={() => {
															userPermission.includes(
																'XXX'
															) &&
																(window.location.href = `/User/Home/Development`);
														}}
													>
														{t(
															'shdSidebar:salesReturnInvoice'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectSecondTitle>
													{t(
														'shdSidebar:salesReceiptAndSettlement'
													)}
												</InnerSubjectSecondTitle>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'XXX'
														)}
														onClick={() => {
															userPermission.includes(
																'XXX'
															) &&
																(window.location.href = `/User/Home/Development`);
														}}
													>
														{t(
															'shdSidebar:salesReceipt'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'XXX'
														)}
														onClick={() => {
															userPermission.includes(
																'XXX'
															) &&
																(window.location.href = `/User/Home/Development`);
														}}
													>
														{t(
															'shdSidebar:salesReceiptReturn'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'XXX'
														)}
														onClick={() => {
															userPermission.includes(
																'XXX'
															) &&
																(window.location.href = `/User/Home/Development`);
														}}
													>
														{t(
															'shdSidebar:salesDifferenceSettlement'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
											</InnerSubjectItems>
										</InnerSubjectContent>
									</InnerSubject>
									<InnerSubject>
										<InnerSubjectTitle>
											{t(
												'shdSidebar:inventorySalesReport'
											)}
										</InnerSubjectTitle>
										<InnerSubjectContent>
											<InnerSubjectItems>
												<InnerSubjectSecondTitle>
													{t(
														'shdSidebar:purchaseReport'
													)}
												</InnerSubjectSecondTitle>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'XXX'
														)}
														onClick={() => {
															userPermission.includes(
																'XXX'
															) &&
																(window.location.href = `/User/Home/Development`);
														}}
													>
														{t(
															'shdSidebar:purchaseOrderList'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'XXX'
														)}
														onClick={() => {
															userPermission.includes(
																'XXX'
															) &&
																(window.location.href = `/User/Home/Development`);
														}}
													>
														{t(
															'shdSidebar:purchaseReceiptList'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'XXX'
														)}
														onClick={() => {
															userPermission.includes(
																'XXX'
															) &&
																(window.location.href = `/User/Home/Development`);
														}}
													>
														{t(
															'shdSidebar:purchaseInspectionList'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'XXX'
														)}
														onClick={() => {
															userPermission.includes(
																'XXX'
															) &&
																(window.location.href = `/User/Home/Development`);
														}}
													>
														{t(
															'shdSidebar:purchasePaymentList'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>

												<InnerSubjectSecondTitle>
													{t(
														'shdSidebar:salesReport'
													)}
												</InnerSubjectSecondTitle>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'XXX'
														)}
														onClick={() => {
															userPermission.includes(
																'XXX'
															) &&
																(window.location.href = `/User/Home/Development`);
														}}
													>
														{t(
															'shdSidebar:salesOrderList'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'XXX'
														)}
														onClick={() => {
															userPermission.includes(
																'XXX'
															) &&
																(window.location.href = `/User/Home/Development`);
														}}
													>
														{t(
															'shdSidebar:salesShipmentList'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'XXX'
														)}
														onClick={() => {
															userPermission.includes(
																'XXX'
															) &&
																(window.location.href = `/User/Home/Development`);
														}}
													>
														{t(
															'shdSidebar:salesReceiptList'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>

												<InnerSubjectSecondTitle>
													{t(
														'shdSidebar:inventoryReport'
													)}
												</InnerSubjectSecondTitle>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'LgsMaterialMonthlyQuantity'
														)}
														onClick={() => {
															userPermission.includes(
																'LgsMaterialMonthlyQuantity'
															) &&
																(window.location.href = `/Logistics/LgsMaterialMonthlyQuantity`);
														}}
													>
														{t(
															'shdSidebar:monthlyQuantityOfMaterials'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'lgsMaterialMonthlyStandardCost'
														)}
														onClick={() => {
															userPermission.includes(
																'lgsMaterialMonthlyStandardCost'
															) &&
																(window.location.href = `/Logistics/lgsMaterialMonthlyStandardCost`);
														}}
													>
														{t(
															'shdSidebar:totalStandardCostOfMonthlyMaterials'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
											</InnerSubjectItems>
										</InnerSubjectContent>
									</InnerSubject>
									<InnerSubject>
										<InnerSubjectTitle>
											{t(
												'shdSidebar:inventoryPurchasingAndSalesSettings'
											)}
										</InnerSubjectTitle>
										<InnerSubjectContent>
											<InnerSubjectItems>
												<InnerSubjectSecondTitle>
													{t(
														'shdSidebar:basicMasterData'
													)}
												</InnerSubjectSecondTitle>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'LgsPlant'
														)}
														onClick={() => {
															userPermission.includes(
																'LgsPlant'
															) &&
																(window.location.href = `${mvcLocalhost}Logistics/LgsPlant`);
														}}
													>
														{t(
															'shdSidebar:factoryMasterData'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'LgsMaterialMaster'
														)}
														onClick={() => {
															userPermission.includes(
																'LgsMaterialMaster'
															) &&
																(window.location.href = `${mvcLocalhost}Logistics/LgsMaterialMaster`);
														}}
													>
														{t(
															'shdSidebar:materialMasterData'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'LgsMaterialMasterPlant'
														)}
														onClick={() => {
															userPermission.includes(
																'LgsMaterialMasterPlant'
															) &&
																(window.location.href = `${mvcLocalhost}Logistics/LgsMaterialMasterPlant`);
														}}
													>
														{t(
															'shdSidebar:materialPlantSettings'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'LgsMaterialMasterVendor'
														)}
														onClick={() => {
															userPermission.includes(
																'LgsMaterialMasterVendor'
															) &&
																(window.location.href = `${mvcLocalhost}Logistics/LgsMaterialMasterVendor`);
														}}
													>
														{t(
															'shdSidebar:materialSupplier'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'LgsMaterialCube'
														)}
														onClick={() => {
															userPermission.includes(
																'LgsMaterialCube'
															) &&
																(window.location.href = `${mvcLocalhost}Logistics/LgsMaterialCube`);
														}}
													>
														{t(
															'shdSidebar:materialLocation'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'LgsMaterialClass'
														)}
														onClick={() => {
															userPermission.includes(
																'LgsMaterialClass'
															) &&
																(window.location.href = `${mvcLocalhost}Logistics/LgsMaterialClass`);
														}}
													>
														{t(
															'shdSidebar:materialCategory'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'XXX'
														)}
														onClick={() => {
															userPermission.includes(
																'XXX'
															) &&
																(window.location.href = `}/User/Home/Development`);
														}}
													>
														{t(
															'shdSidebar:materialUsageCategory'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>

												<InnerSubjectSecondTitle>
													{t(
														'shdSidebar:standardCostOfMaterial'
													)}
												</InnerSubjectSecondTitle>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'LgsFutureStandardPrice'
														)}
														onClick={() => {
															userPermission.includes(
																'LgsFutureStandardPrice'
															) &&
																(window.location.href = `${mvcLocalhost}Logistics/LgsFutureStandardPrice`);
														}}
													>
														{t(
															'shdSidebar:futureStandardCostSetting'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'LgsCurrentStandardPrice'
														)}
														onClick={() => {
															userPermission.includes(
																'LgsCurrentStandardPrice'
															) &&
																(window.location.href = `${mvcLocalhost}Logistics/LgsCurrentStandardPrice`);
														}}
													>
														{t(
															'shdSidebar:monthlyStandardCostChange'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>

												<InnerSubjectSecondTitle>
													{t(
														'shdSidebar:materialVoucher'
													)}
												</InnerSubjectSecondTitle>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'LgsMaterialDocumentType'
														)}
														onClick={() => {
															userPermission.includes(
																'LgsMaterialDocumentType'
															) &&
																(window.location.href = `${mvcLocalhost}Logistics/LgsMaterialDocumentType`);
														}}
													>
														{t(
															'shdSidebar:materialVoucherType'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'LgsMaterialDocumentNoRule'
														)}
														onClick={() => {
															userPermission.includes(
																'LgsMaterialDocumentNoRule'
															) &&
																(window.location.href = `${mvcLocalhost}Logistics/LgsMaterialDocumentNoRule`);
														}}
													>
														{t(
															'shdSidebar:materialVoucherNumberRule'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>

												<InnerSubjectSecondTitle>
													{t(
														'shdSidebar:otherSettings'
													)}
												</InnerSubjectSecondTitle>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'XXX'
														)}
														onClick={() => {
															userPermission.includes(
																'XXX'
															) &&
																(window.location.href = `/User/Home/Development`);
														}}
													>
														{t(
															'shdSidebar:inventoryPurchaseSalesSettings'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
												<InnerSubjectItem>
													<InnerSubjectText
														className="ps-2"
														ispermission={userPermission.includes(
															'LgsTransaction'
														)}
														onClick={() => {
															userPermission.includes(
																'LgsTransaction'
															) &&
																(window.location.href = `${mvcLocalhost}Logistics/LgsTransaction`);
														}}
													>
														{t(
															'shdSidebar:inventoryPurchaseSalesTransactionSettings'
														)}
													</InnerSubjectText>
												</InnerSubjectItem>
											</InnerSubjectItems>
										</InnerSubjectContent>
									</InnerSubject>
								</Layer2Body>
							</Layer2>
						)}
					</LgsSidebar>
					{/* <ProSidebar
					isSidebarWrap={isSidebarWrap}
					onMouseEnter={() => setLayer2Show('3')}
					onMouseLeave={() => setLayer2Show('')}
				>
					<Icn_SidebarPro className={!isSidebarWrap && 'me-3'} />
					{!isSidebarWrap && '生產管理'}

					{layer2Show === '3' && (
						<Layer2
							isSidebarWrap={isSidebarWrap}
							className="layer2"
						>
							<Layer2Head>
								<SubjectIcon>
									<Icn_SidebarPro />
								</SubjectIcon>
								<SubjectText>生產管理</SubjectText>
							</Layer2Head>
							<Hr />
							<Layer2Body>
								<InnerSubject>
									<InnerSubjectTitle>集團</InnerSubjectTitle>
									<InnerSubjectContent>
										<InnerSubjectItems>
											<InnerSubjectItem>
												<InnerSubjectText
													ispermission={userPermission.includes(
														'EntGroupHierarchy'
													)}
													onClick={() => {
														userPermission.includes(
															'EntGroupHierarchy'
														) &&
															(window.location.href = `${mvcLocalhost}Enterprise/EntGroupHierarchy`);
													}}
												>
													集團層級分類
												</InnerSubjectText>
											</InnerSubjectItem>
											<InnerSubjectItem>
												<InnerSubjectText
													ispermission={userPermission.includes(
														'EntGroup'
													)}
													onClick={() => {
														userPermission.includes(
															'EntGroup'
														) &&
															(window.location.href = `${mvcLocalhost}Enterprise/EntGroup/Details/${cookies['Auth.COOKIES_CONTROLGROUPGUID']}`);
													}}
												>
													集團基本資料
												</InnerSubjectText>
											</InnerSubjectItem>
											<InnerSubjectItem>
												<InnerSubjectText
													ispermission={userPermission.includes(
														'ErpCompany'
													)}
													onClick={() => {
														userPermission.includes(
															'ErpCompany'
														) &&
															(window.location.href = `${mvcLocalhost}Enterprise/EntCompany`);
													}}
												>
													公司基本資料
												</InnerSubjectText>
											</InnerSubjectItem>
											<InnerSubjectItem>
												<InnerSubjectText
													ispermission={userPermission.includes(
														'EntCompany'
													)}
													onClick={() => {
														userPermission.includes(
															'EntCompany'
														) &&
															(window.location.href = `${mvcLocalhost}Enterprise/EntCompany/Create`);
													}}
												>
													建立新公司
												</InnerSubjectText>
											</InnerSubjectItem>
										</InnerSubjectItems>
									</InnerSubjectContent>
								</InnerSubject>
								<InnerSubject>
									<InnerSubjectTitle>
										集團使用者
									</InnerSubjectTitle>
									<InnerSubjectContent>
										<InnerSubjectItems>
											<InnerSubjectItem>
												<InnerSubjectText
													ispermission={userPermission.includes(
														'GmnAuthorization'
													)}
													onClick={() => {
														userPermission.includes(
															'GmnAuthorization'
														) &&
															(window.location.href = `/User/GmnAuthorization`);
													}}
												>
													權限設定
												</InnerSubjectText>
											</InnerSubjectItem>
											<InnerSubjectItem>
												<InnerSubjectText
													ispermission={userPermission.includes(
														'XXX'
													)}
													onClick={() => {
														userPermission.includes(
															'XXX'
														) &&
															(window.location.href = `/User/Home/Development`);
													}}
												>
													權限指派
												</InnerSubjectText>
											</InnerSubjectItem>
											<InnerSubjectItem>
												<InnerSubjectText
													ispermission={userPermission.includes(
														'GmnGroupUser'
													)}
													onClick={() => {
														userPermission.includes(
															'GmnGroupUser'
														) &&
															(window.location.href = `/User/GmnGroupUser`);
													}}
												>
													使用者管理
												</InnerSubjectText>
											</InnerSubjectItem>
										</InnerSubjectItems>
									</InnerSubjectContent>
								</InnerSubject>
							</Layer2Body>
						</Layer2>
					)}
				</ProSidebar> */}
				</SidebarListContainer>
			)}
		</SidebarContainer>
	);
};

export default Sidebar;
