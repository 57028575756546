import { useState, useEffect, useCallback } from 'react';
import PageTitleBar from '../../../components/PageTitleBar/PageTitleBar';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';

import Page0 from './Pages/Page0/Page0';
import Page1 from './Pages/Page1/Page1';
import Page2 from './Pages/Page2/Page2';
import Page3 from './Pages/Page3/Page3';
import { DialogConfirm } from './Pages/DialogConfirm';

// Author: YL
function LgsBuyOrderPayment() {
	const emptyPaymentData = {
		isEverEnterPage: {
			page0: false,
			page1: false,
			page2: false,
		},
		buyOrder: {},
		payment: {},
	};

	const { t, i18n } = useTranslation(['lgsBuyOrderPayment', 'common']);
	const [parentData, setParentData] = useState(emptyPaymentData);
	const [cookies, setCookie] = useCookies();
	const CurrencyCode = cookies._company_currency_code;
	const CurrencyName = cookies._company_currency_name;
	const DecimalPlaceTl = cookies._company_currency_decimalplace_tl;

	const [page, setPage] = useState(0);
	const pageTitle = t('lgsBuyOrderPayment:pageTitle');

	//#region page1
	const [copyBuyOrderData, setCopyBuyOrderData] = useState({});
	const [dialogBtnKeyName, setDialogBtnKeyName] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	const [modalData, setModalData] = useState([]);
	const [warningMessage, setWarningMessage] = useState('');

	const processSteps = [
		t('lgsBuyOrderPayment:steps.step0'),
		t('lgsBuyOrderPayment:steps.step1'),
		t('lgsBuyOrderPayment:steps.step2'),
	];

	//#endregion

	const handlePrevPage = () => {
		setPage(page - 1);
	};

	const handleNextPage = () => {
		setPage(page + 1);
	};

	/**
	 * DialogConfirm 返回btn確認dialog
	 **/
	const [dialogConfirmData, setDialogConfirmData] = useState({
		btnName: '',
		isOpen: false,
		goTo: '',
		title: '',
	});
	const [isConfirmBtnClicked, setIsConfirmBtnClicked] = useState(false);

	// DialogConfirm Modal open
	const handleDialogConfirm = () => {
		const newData = {
			btnName: 'goToRoot',
			isOpen: true,
			goTo: 'prePage',
			title: t('lgsBuyOrderPayment:steps.confirmLeavePaymentPage'),
		};
		setDialogConfirmData(newData);
	};

	return (
		<>
			<div id="detailsView">
				<PageTitleBar
					pageTitle={pageTitle}
					previousPageButton={true}
					goTo={-1}
					isDialogConfirm={true}
					handleDialogConfirm={handleDialogConfirm}
				/>

				{/* DialogConfirm element */}
				<DialogConfirm
					dialogConfirmData={dialogConfirmData}
					setDialogConfirmData={setDialogConfirmData}
					page={page}
					setPage={setPage}
					setIsConfirmBtnClicked={setIsConfirmBtnClicked}
				/>

				<div>
					{page === 0 && (
						<Page0
							parentData={parentData}
							setParentData={setParentData}
							page={page}
							setPage={setPage}
							processSteps={processSteps}
							handleNextPage={handleNextPage}
							emptyPaymentData={emptyPaymentData}
						/>
					)}
					{page === 1 && (
						<Page1
							dialogConfirmData={dialogConfirmData}
							setDialogConfirmData={setDialogConfirmData}
							parentData={parentData}
							setParentData={setParentData}
							page={page}
							setPage={setPage}
							processSteps={processSteps}
							handlePrevPage={handlePrevPage}
							handleNextPage={handleNextPage}
							copyBuyOrderData={copyBuyOrderData}
							setCopyBuyOrderData={setCopyBuyOrderData}
							dialogBtnKeyName={dialogBtnKeyName}
							setDialogBtnKeyName={setDialogBtnKeyName}
							isOpen={isOpen}
							setIsOpen={setIsOpen}
							modalData={modalData}
							setModalData={setModalData}
							warningMessage={warningMessage}
							setWarningMessage={setWarningMessage}
							CurrencyCode={CurrencyCode}
							CurrencyName={CurrencyName}
							DecimalPlaceTl={DecimalPlaceTl}
							t={t}
						/>
					)}
					{page === 2 && (
						<Page2
							parentData={parentData}
							setParentData={setParentData}
							page={page}
							setPage={setPage}
							processSteps={processSteps}
							handlePrevPage={handlePrevPage}
							handleNextPage={handleNextPage}
							copyBuyOrderData={copyBuyOrderData}
							setCopyBuyOrderData={setCopyBuyOrderData}
							dialogConfirmData={dialogConfirmData}
							setDialogConfirmData={setDialogConfirmData}
							dialogBtnKeyName={dialogBtnKeyName}
							setDialogBtnKeyName={setDialogBtnKeyName}
							isOpen={isOpen}
							setIsOpen={setIsOpen}
							modalData={modalData}
							setModalData={setModalData}
							warningMessage={warningMessage}
							setWarningMessage={setWarningMessage}
							CurrencyCode={CurrencyCode}
							CurrencyName={CurrencyName}
							DecimalPlaceTl={DecimalPlaceTl}
						/>
					)}
					{page === 3 && (
						<Page3
							parentData={parentData}
							setParentData={setParentData}
							page={page}
							setPage={setPage}
							processSteps={processSteps}
							handlePrevPage={handlePrevPage}
							handleNextPage={handleNextPage}
							copyBuyOrderData={copyBuyOrderData}
							setCopyBuyOrderData={setCopyBuyOrderData}
							dialogConfirmData={dialogConfirmData}
							setDialogConfirmData={setDialogConfirmData}
							dialogBtnKeyName={dialogBtnKeyName}
							setDialogBtnKeyName={setDialogBtnKeyName}
							isOpen={isOpen}
							setIsOpen={setIsOpen}
							modalData={modalData}
							setModalData={setModalData}
							warningMessage={warningMessage}
							setWarningMessage={setWarningMessage}
							emptyPaymentData={emptyPaymentData}
						/>
					)}
				</div>
			</div>
		</>
	);
}

export default LgsBuyOrderPayment;
