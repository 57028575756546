import styled from 'styled-components';
import { useCookies } from 'react-cookie';
import AccountDocumentTable from './AccountDocumentTable';
import { useTranslation } from 'react-i18next';

// #region styled-components
const TabTitle = styled.h1`
	font-size: 28px;
`;

const LabelNormal = styled.label`
	font-size: 12px;
	color: var(--grey4);
	margin-bottom: 6px;
`;

const InputElement = styled.input`
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
	&:disabled {
		background-color: transparent;
		border-width: 0 0 1px 0;
		border-bottom: 1px solid var(--grey1);
	}
`;

//#endregion

function AccountDocumentTab({ parentData, tabData, DecimalPlaceTl }) {
	//#region state initiation
	const { t } = useTranslation(['common', 'lgsBuyOrderGoodsInvoice']); // i18n
	const [cookies] = useCookies();
	//#endregion

	//#region variable initiation

	// table header 的名稱
	const headTitleList = [
		t('common:accountingVoucher.accountingSubject'),
		t('common:accountingVoucher.summary'),
		t('common:accountingVoucher.debitAmount'),
		t('common:accountingVoucher.creditAmount'),
	];

	// table body 的資料名稱
	const bodyTitleList = ['accountName', 'note', 'amountDr', 'amountCr'];

	// 不同 input 的 type
	const inputProperties = [
		{ type: 'text' },
		{ type: 'text' },
		{ type: 'number' },
		{ type: 'number' },
	];

	// table header 的 style
	const tableHeadStyles = [
		{
			width: '448px',
			textAlign: 'left',
		},
		{
			width: '312px',
			textAlign: 'left',
		},
		{
			width: '200px',
			textAlign: 'left',
		},
		{
			width: '200px',
			textAlign: 'left',
		},
	];

	// table body 的 style
	const tableBodyStyles = [
		{
			width: '448px',
			textAlign: 'left',
		},
		{
			width: '312px',
			textAlign: 'left',
		},
		{
			width: '200px',
			textAlign: 'right',
		},
		{
			width: '200px',
			textAlign: 'right',
		},
	];

	//#endregion

	return (
		<>
			<TabTitle className="fw-bold text-grey5 text-center mt-4">
				{t('common:accountingVoucher.accountingVoucher')}
			</TabTitle>

			<div className="row bg-white rounded-xxl px-6 pt-6 mx-0 mb-0">
				<div className="row">
					<div className="col-12">
						<div className="row">
							<div className="col-lg-3 px-4 pr-xl-6">
								<div className="d-flex flex-column">
									<LabelNormal htmlFor={`documentTypeName`}>
										{t(
											'common:accountingVoucher.voucherType'
										)}
									</LabelNormal>
									<InputElement
										type="text"
										id={`documentTypeName`}
										className="mb-3"
										disabled
										defaultValue={
											tabData.accountDocumentName
										}
									/>
								</div>
							</div>
							<div className="col-lg-3 px-4 px-xl-5">
								<div className="d-flex flex-column">
									<LabelNormal htmlFor={`postingDate`}>
										{t(
											'common:accountingVoucher.postingDate'
										)}
									</LabelNormal>
									<InputElement
										type="date"
										id={`postingDate`}
										className="mb-3"
										disabled
										defaultValue={
											parentData.payment.postingDate
										}
									/>
								</div>
							</div>
							<div className="col-lg-3 px-4 pl-xl-6">
								<div className="d-flex flex-column">
									<LabelNormal htmlFor={`postingPeriod`}>
										{t(
											'common:accountingVoucher.postingPeriod'
										)}
									</LabelNormal>
									<InputElement
										type="text"
										id={`postingPeriod`}
										className="mb-3"
										disabled
										defaultValue={
											parentData.payment.postingDate
										}
									/>
								</div>
							</div>
							<div className="col-lg-3 px-4 pl-xl-6">
								<div className="d-flex flex-column">
									<LabelNormal htmlFor={`personnelName`}>
										{t(
											'common:accountingVoucher.handlingPersonnel'
										)}
									</LabelNormal>
									<InputElement
										type="text"
										id={`personnelName`}
										className="mb-3"
										disabled
										defaultValue={cookies['_user_name']}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="position-relative">
				<div className="bg-white rounded-xxl d-flex flex-column px-4 pt-3 pb-6 position-relative">
					<AccountDocumentTable
						tabData={tabData}
						parentData={parentData}
						headTitleList={headTitleList}
						bodyTitleList={bodyTitleList}
						tableHeadStyles={tableHeadStyles}
						tableBodyStyles={tableBodyStyles}
						inputProperties={inputProperties}
						DecimalPlaceTl={DecimalPlaceTl}
					/>
				</div>
			</div>
		</>
	);
}

export default AccountDocumentTab;
