import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { BtnNormal2 } from '../../../../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import { ReactComponent as CreateIcon } from '../../../../../../assets/images/icn_Create.svg';
import { useTranslation } from 'react-i18next';

// #region styled-components
const IndexTableContainer = styled.div``;

const IndexTable = styled.table`
	margin-bottom: 0;
	color: var(--grey4);
	background-color: #fff;
`;

const THead = styled.thead`
	height: 40px;
`;

const TR = styled.tr`
	height: 40px;
	&:hover {
		cursor: ${(hoverEffect) =>
			hoverEffect === 'true' ? 'pointer' : 'default'};
		background-color: ${(hoverEffect) =>
			hoverEffect === 'true' ? 'var(--grey0)' : 'var(--bs-table-bg)'};
	}
	td:last-child {
		display: none !important;
	}

	&:hover {
		td:last-child {
			display: block !important;
		}
	}
`;

const TH = styled.th`
	vertical-align: middle;
	border: 1px solid var(--grey1) !important;
	&:first-of-type {
		border-left: none !important;
	}

	&:last-of-type {
		border-right: none !important;
	}
`;

const TBody = styled.tbody``;

const TD = styled.td`
	cursor: auto;
	border: 1px solid var(--grey1) !important;
	&:first-of-type {
		border-left: none !important;
	}

	&:last-of-type {
		border-left: none !important;
		border-right: none !important;
	}

	background-color: ${(prop) =>
		prop.active ? '#fbfcfd !important' : '#fff'};

	position: relative;
	padding: 0;
`;

const TFoot = styled.tfoot`
	border: none;
`;

const FootTR = styled.tr`
	border-top: 2px solid var(--grey3) !important;
`;

const FootTD = styled.td`
	border: none !important;
`;

const Container = styled.div`
	display: flex;
	justify-content: start;
	margin: 16px 0;
`;

const Input = styled.input`
	border: ${(prop) => (prop.active ? '1px solid #e3e8ee' : 'none')};
	border-radius: 4px;
`;

const Menu = styled.div`
	position: absolute;
	top: 0px;
	right: 0px;
	will-change: transform;
	padding: 0;
	margin-top: 8px;
	min-width: 64px;
	background-color: #ffffff;
	box-shadow: 2px 4px 12px -1px rgb(61 61 61 / 51%);
	border-radius: 0.25rem;
	color: var(--danger);

	&:hover,
	&:focus {
		color: var(--danger);
	}
`;

const Button = styled.div`
	//active-submit dropdownItem d-flex align-items-center pe-2 tex-left px-3 py-2
	display: flex;
	cursor: pointer;
	align-items: center;
	padding: 8px 16px;
	text-align: left;
	min-width: 64px;
	height: 32px;
	justify-content: center;
	svg {
		margin-right: 8px;
	}
`;
//#endregion

function LgsBuyOrder_PaymentTermTable(props) {
	//#region state initiation
	const {
		headTitleList,
		bodyTitleList,
		tableHeadStyles,
		tableBodyStyles,
		tableBodyData,
		setTableBodyData,
		totals,
		handleSplit,
		inputProperties,
		decimalPlace,
		toFloatList,
		deletedTermList,
		setDeleteTermList,
	} = props;
	// console.log(props);

	const { t } = useTranslation();
	const [totalAmount, setTotalAmount] = useState();
	const [totalPercentage, setTotalPercentage] = useState();

	//#endregion

	//#region function

	// make a new row
	const handleAddPaymentTerm = () => {
		const newData = {};
		bodyTitleList.map((columnName) => {
			newData[columnName] = '';
			newData.action = 'insert';
			columnName === 'displayOrder' &&
				(newData[columnName] = tableBodyData.length + 1);
		});

		setTableBodyData([...tableBodyData, newData]);
	};

	const handleEditTable = (e, index) => {
		const newList = [...tableBodyData];
		newList.forEach((el) => delete el?.active);
		newList[index].active = true;

		setTableBodyData(newList);
	};

	// input改變時
	const handleInputChange = (value, rowIndex, inputIndex, toFloat) => {
		// console.log(value);
		const newData = [...tableBodyData];

		if (toFloat) {
			// 如果此input有decimalPlace限制
			const regex = new RegExp(`^(\\d+(\\.\\d{0,${decimalPlace}})?|)$`); // 只允許數字，並且小數點後最多decimalPlace位
			if (regex.test(value))
				newData[rowIndex][bodyTitleList[inputIndex]] = value;
			setTableBodyData(newData);
		} else {
			newData[rowIndex][bodyTitleList[inputIndex]] = value;
			setTableBodyData(newData);
		}
	};

	const handleDeletePaymentTerm = (e, data) => {
		e.stopPropagation();
		const oldList = [...tableBodyData];

		// 只有在 EDIT 裡才會有被刪除的付款條件
		if (oldList[data.displayOrder - 1].action === 'update') {
			oldList[data.displayOrder - 1].action = 'delete';
			deletedTermList &&
				setDeleteTermList([
					...deletedTermList,
					oldList[data.displayOrder - 1],
				]);
		}

		// remove the item from the array
		oldList.splice(data.displayOrder - 1, 1);
		// update the displayOrder property of the remaining items
		const newList = oldList.map((item, index) => ({
			...item,
			displayOrder: index + 1,
		}));

		setTableBodyData(newList);
		// console.log(newList);
	};

	//#endregion

	useEffect(() => {
		setTotalAmount(totals.totalAmount);
		setTotalPercentage(totals.totalPercentage);
	}, [totals]);

	return (
		<div className="position-relative">
			<IndexTableContainer className="firstRowMinHeight bg-white d-flex flex-column position-relative">
				<Container>
					<BtnNormal2
						className="btn-primary smallBtn"
						keyName="advance-search"
						handleClick={handleAddPaymentTerm}
						word={t('button.createButton')}
						// icon={}
					>
						<CreateIcon className="me-2" />
					</BtnNormal2>
					<BtnNormal2
						className="btn-primary smallBtn"
						keyName="advance-search"
						handleClick={handleSplit}
						word={t('lgsBuyOrder:proportionSplit')}
						// icon={}
					></BtnNormal2>
				</Container>
				<IndexTable className="table">
					<THead className="table-head">
						<TR>
							{headTitleList?.map((el) => (
								<TH
									key={`tableHead${headTitleList?.indexOf(
										el
									)}`}
									style={
										tableHeadStyles[
											headTitleList?.indexOf(el)
										]
									}
								>
									{el}
								</TH>
							))}
						</TR>
					</THead>
					<TBody className="">
						{tableBodyData?.map((data, rowIndex) => (
							<TR
								key={`paymentTermTableRow${rowIndex}`}
								onClick={(e) => handleEditTable(e, rowIndex)}
							>
								{bodyTitleList?.map((el, inputIndex) => {
									if (el === 'displayOrder')
										return (
											<TD
												style={
													tableBodyStyles[
														bodyTitleList?.indexOf(
															el
														)
													]
												}
												className={`p-2 border`}
												active={data.active}
												key={`${bodyTitleList?.indexOf(
													el
												)}`}
											>
												<div>{data[el]}</div>
											</TD>
										);

									return (
										<TD
											style={
												tableBodyStyles[
													bodyTitleList?.indexOf(el)
												]
											}
											className={`p-2 border`}
											active={data.active}
											key={`${bodyTitleList?.indexOf(
												el
											)}`}
										>
											<Input
												value={data[el] || ''}
												active={data.active}
												type={
													inputProperties[inputIndex]
														?.type
												}
												min={
													inputProperties[inputIndex]
														?.min
												}
												onChange={(e) => {
													handleInputChange(
														e.target.value,
														rowIndex,
														inputIndex,
														toFloatList[inputIndex]
													);
												}}
												onBlur={
													inputProperties[inputIndex]
														?.onBlur
												}
												style={{
													minWidth: '24px',
													width: '100%',
													outline: 'none',
												}}
											/>
										</TD>
									);
								})}
								<TD
									style={{
										padding: '0',
										border: 'none !important',
									}}
								>
									<Menu style={{ zIndex: '2' }}>
										<Button
											onClick={(e) => {
												handleDeletePaymentTerm(
													e,
													data
												);
											}}
										>
											<span>
												{t('button.deleteButton')}
											</span>
										</Button>
									</Menu>
								</TD>
							</TR>
						))}
					</TBody>
					<TFoot>
						<FootTR
							id="totalAmount"
							key="totalAmount"
							hoverEffect={false}
							style={{ fontWeight: 'bold' }}
						>
							<FootTD className="p-2 text-right"></FootTD>
							<FootTD className="p-2 text-right"></FootTD>
							<FootTD className="p-2 text-right"></FootTD>
							<FootTD className="p-2 text-right">
								{t('lgsBuyOrder:totalAmount')}
							</FootTD>
							<FootTD className="p-2 text-right">
								{totalAmount
									? totalAmount.toFixed(decimalPlace)
									: 0}
							</FootTD>
							<FootTD className="p-2 text-right">
								{totalPercentage
									? totalPercentage.toFixed(2)
									: 0}
							</FootTD>
							<FootTD className="p-2 text-right"></FootTD>
						</FootTR>
					</TFoot>
				</IndexTable>
			</IndexTableContainer>
		</div>
	);
}

export default LgsBuyOrder_PaymentTermTable;
