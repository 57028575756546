import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ReactComponent as CreateIcon } from '../../../../../assets/images/icn_Create.svg';
import { ReactComponent as Icn_Cancel } from '../../../../../assets/images/icn_Cancel.svg';
import { ReactComponent as Icn_Search2 } from '../../../../../assets/images/icn_Search2.svg';
import { ReactComponent as Icn_InputYellowCancel } from '../../../../../assets/images/icn_InputYellowCancel.svg';
import { BtnNormal2 } from '../../../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import useAxios from '../../../../../hooks/useAxios';
import useSortableData from '../../../../../hooks/useSortableData';
import { useTranslation } from 'react-i18next';

// #region styled-components
const ModalThead = styled.thead`
	padding: 0 0.75rem;
	cursor: pointer !important;
`;
const ModalTh = styled.th`
	padding: 0 0.75rem;
	cursor: pointer !important;
	border: none !important;
`;
const ModalLabel = styled.label`
	padding: 0 0.75rem;
	cursor: pointer !important;
`;
const ModalInput = styled.input`
	padding: 0 0.75rem;
	cursor: pointer !important;
`;

const SearchModalInput = styled.input`
	font-size: 14px;
	width: 240px;
	height: 32px;
	padding: 0 0.75rem;
	cursor: pointer !important;
	text-transform: uppercase;
`;

const ModalSearchBtn = styled.button`
	top: 1px;
	right: 74px;
	height: 30px;
	border-radius: 0 0.25rem 0.25rem 0;
`;
// #endregion

export const DialogDouble = ({
	dialogDataFromBE,
	btnWord,
	btnKeyName,
	btnWord2,
	newBuyOrderData,
	setNewBuyOrderData,
	cardKeyNameList,
}) => {
	//#region state initialization
	const { t } = useTranslation();

	// state
	const [modal, setModal] = useState(false);
	const [modalData, setModalData] = useState([]);

	const [modal2, setModal2] = useState(false);
	const [modal2Data, setModal2Data] = useState([]);

	const [oneCheck, setOneCheck] = useState('');
	const [cardName, setCardName] = useState('');
	const [expenseClassGuid, setExpenseClassGuid] = useState('');

	// ref
	const searchModalInput = useRef('');
	const searchModal2Input = useRef('');

	// axios
	const { sendRequest: axiosSectorData } = useAxios();

	// base function
	const toggle = () => setModal(!modal);
	const toggle2 = () => setModal2(!modal2);

	//#endregion

	//#region clickCreateModal(createBtn)

	const clickCreateModal = (e) => {
		e.preventDefault();

		setModalData(dialogDataFromBE.expenseClassList);

		toggle();
	};

	//#endregion

	//#region Modal搜尋

	// 搜尋
	const searchModalBtn = () => {
		//取得搜尋文字
		let searchModalInputText = searchModalInput.current
			? searchModalInput.current.value.toUpperCase()
			: searchModal2Input.current.value.toUpperCase();

		let modalTrList = document.querySelectorAll('.modalTr');

		if (searchModalInputText !== '') {
			//隱藏全部欄位
			modalTrList.forEach((item) => {
				item.classList.add('d-none');
			});

			// 搜尋每個Tr中的每個Td欄位
			modalTrList.forEach((item) => {
				// console.log(item.children);

				[...item.children].forEach((td) => {
					if (
						td.textContent
							.toUpperCase()
							.includes(searchModalInputText)
					) {
						td.parentElement.classList.remove('d-none');
					}
				});
			});

			//顯示取消搜尋btn
			document
				.querySelector('.cancelSearchModalBtn')
				.classList.remove('d-none');
		}
	};

	// 取消搜尋
	const cancelSearchModalBtn = (i) => {
		// 顯示全部欄位
		document.querySelectorAll('.modalTr').forEach((item) => {
			item.classList.remove('d-none');
		});

		// 清空搜尋欄位
		if (searchModalInput.current) searchModalInput.current.value = '';
		if (searchModal2Input.current) searchModal2Input.current.value = '';

		// 隱藏取消搜尋btn
		document.querySelector('.cancelSearchModalBtn').classList.add('d-none');
	};

	//#endregion

	//#region Modal clickTableRow(只能選一個)

	function clickTableRow(e, guid) {
		// console.log(guid);
		setOneCheck(guid);

		//點選tr 使checkbox被選取
		let majorCheck =
			e.target.parentElement.children[0].querySelector('input');

		//避免input無法直接點擊
		if (e.nodeName != 'INPUT' && e.nodeName != 'LABEL') {
			majorCheck.checked = !majorCheck.checked;
		}
	}

	//#endregion

	//#region Modal儲存

	// console.log(modalData);
	function modalSave() {
		// 選取哪些checkbox
		const handleSelectedData = () => {
			const selectedId = [];

			document
				.querySelectorAll('.modal-content [type=checkbox]')
				.forEach((el) => {
					el.checked && selectedId.push(el.id);
				});

			selectedId.forEach((id) => {
				let item = modalData.find((el) => {
					return el[`expenseClassGuid`] === id;
				});

				setCardName(item.expenseClassCode + ' ' + item['name01']);
				setExpenseClassGuid(item.expenseClassGuid);
			});

			const url = `api/Logistics/LgsBuyOrder/getsector?ExpenseClassGuid=${selectedId[0]}`;
			axiosSectorData({ url }, (res) => {
				console.log(res);
				setModal2Data(res.sectorList);
			});

			toggle(); //關
			toggle2(); //開
		};

		oneCheck.length !== 0 && handleSelectedData();
	}

	//#endregion

	//#region Modal2儲存

	// console.log(modal2Data);
	function modal2Save() {
		// 選取哪些checkbox
		const handleSelectedData = () => {
			const selectedId = [];
			const selectedList = [];

			document
				.querySelectorAll('.modal-content [type=checkbox]')
				.forEach((el) => {
					el.checked && selectedId.push(el.id);
				});
			// console.log(selectedId);

			selectedId.forEach((id) => {
				let item = modal2Data.find((el) => el[`sectorGuid`] == id);

				selectedList.push(item);
			});
			// console.log(selectedList);

			return selectedList;
		};

		handleSelectedData();

		// 新增卡片
		const handleAddCard = () => {
			let newArray = [...newBuyOrderData];
			let selectedList = handleSelectedData();
			console.log(selectedList);

			if (selectedList.length) {
				selectedList.forEach((item, index) => {
					const newCardData = {};
					cardKeyNameList.map((columnName) => {
						newCardData[columnName] = '';

						// 更新或新增欄位

						// 1
						columnName === 'displayOrder' &&
							(newCardData[columnName] =
								newBuyOrderData.length + 1 + index);

						// 2
						if (columnName === 'buyCategoryCode') {
							newCardData[columnName] = 'E';
						}

						// 3
						if (columnName === 'cardName') {
							newCardData[columnName] = cardName;
						}

						if (columnName === 'name') {
							newCardData[columnName] = cardName;
						}

						// 4
						if (columnName === 'cardChildName') {
							newCardData[columnName] =
								item.sectorCode + ' ' + item.sectorName;
						}
						if (columnName === 'cardChildName2') {
							newCardData[columnName] =
								item.accountNo + ' ' + item.accountName;
						}

						// 5
						// columnName === 'image' &&
						// 	(newCardData[columnName] = item.image);

						// 6 第一筆選擇的[交貨日期]，作為後面尚未輸入的筆數的預設值。
						columnName === 'deliverDate' &&
							(newCardData[columnName] =
								newArray[0]?.deliverDate || '');

						// 7.
						newCardData['accountGuid'] = item.accountGuid;
						newCardData['sectorClassGuid'] = item.sectorClassGuid;
						newCardData['sectorGuid'] = item.sectorGuid;
						newCardData['expenseClassGuid'] = expenseClassGuid;

						// 8 修改頁面，新增action屬性
						newCardData.action = 'insert';
					});

					console.log(newCardData);
					newArray.push(newCardData);
				});

				console.log(newArray);
				setNewBuyOrderData(newArray);

				setOneCheck('');

				toggle2();
			}
		};

		handleAddCard();
	}

	//#endregion

	//#region Modal取消

	const modalCancel = () => {
		toggle(); //關
	};

	//#endregion

	//#region Modal2取消

	const modal2Cancel = () => {
		setModal2Data([]);
		setOneCheck('');

		toggle2(); //關

		toggle(); //開
	};

	//#endregion

	//#region Modal1 排序

	let headTitleList1 = [
		t('lgsBuyOrder:dialogCol.code'),
		t('lgsBuyOrder:dialogCol.name'),
	];
	let bodyTitleList1 = ['expenseClassCode', 'name01'];

	const [orderBy1, setOrderBy1] = useState('asc');
	const [UISort1, setUISort1] = useState('expenseClassCode');
	const {
		items: items1,
		requestSort: requestSort1,
		sortConfig: sortConfig1,
	} = useSortableData(modalData, bodyTitleList1[0], 'ascending');

	const handleSortChange1 = (newTarget) => {
		const oldTarget = UISort1;
		setUISort1(newTarget);

		if (oldTarget === newTarget) {
			setOrderBy1(orderBy1 === 'desc' ? 'asc' : 'desc');
		} else {
			setOrderBy1('asc');
		}

		setModalData(items1);
	};

	//#endregion

	//#region Modal2 排序

	let headTitleList2 = [
		t('lgsBuyOrder:dialogCol.code'),
		t('lgsBuyOrder:dialogCol.name'),
		t('lgsBuyOrder:dialogCol.category'),
	];
	let bodyTitleList2 = ['sectorCode', 'sectorName', 'sectorClassName'];

	const [orderBy2, setOrderBy2] = useState('asc');
	const [UISort2, setUISort2] = useState('sectorCode');
	const {
		items: items2,
		requestSort: requestSort2,
		sortConfig: sortConfig2,
	} = useSortableData(modal2Data, bodyTitleList2[0], 'ascending');

	const handleSortChange2 = (newTarget) => {
		const oldTarget = UISort2;
		setUISort2(newTarget);

		if (oldTarget === newTarget) {
			setOrderBy2(orderBy2 === 'desc' ? 'asc' : 'desc');
		} else {
			setOrderBy2('asc');
		}

		setModal2Data(items2);
	};

	//#endregion

	return (
		<div>
			{/* 開關button */}
			<BtnNormal2
				className="btn-primary d-inline-block me-2 smallBtn"
				keyName={btnKeyName}
				word={btnWord}
				handleClick={(e) => clickCreateModal(e)}
			>
				<CreateIcon className="me-2" />
			</BtnNormal2>

			{/* Modal */}
			<Modal
				isOpen={modal}
				toggle={toggle}
				centered={true}
				style={{
					maxWidth: '824px',
					border: 'none',
				}}
			>
				<ModalHeader className="position-relative pt-4 pb-2 ps-4">
					<p
						style={{ fontSize: '20px', color: 'var(--primary)' }}
						className="fw-bold mb-1"
					>
						{btnWord}
					</p>
					<button
						style={{ top: '12px', right: '12px' }}
						type="button"
						className="close position-absolute btn"
						onClick={toggle}
					>
						<Icn_Cancel />
					</button>
				</ModalHeader>
				<ModalBody className="px-6 pt-6 pb-0">
					<form asp-action="Index" method="get">
						<div className="modal-body p-0">
							<div className="wrap mx-2">
								<div className="row">
									<div className="position-relative col-12 col-md-5 p-0 mb-3">
										<SearchModalInput
											type="text"
											name="searchModalInput"
											ref={searchModalInput}
											className="form-control searchModalInput"
											placeholder={t(
												'lgsBuyOrder:dialogCol.quickSearch'
											)}
										/>
										<ModalSearchBtn
											type="button"
											className="searchModalBtn position-absolute border-0 btn btn-primaryllt px-3 d-flex align-items-center"
											onClick={() => searchModalBtn()}
										>
											<Icn_Search2 />
										</ModalSearchBtn>
										<a
											className="cancelSearchModalBtn position-absolute d-none"
											style={{
												top: '4px',
												right: '48px',
												cursor: 'pointer',
											}}
											onClick={() =>
												cancelSearchModalBtn()
											}
										>
											<Icn_InputYellowCancel />
										</a>
									</div>
								</div>

								<div
									style={{ height: '440px' }}
									className="row"
								>
									<div
										style={{
											height: '440px',
											overflowY: 'scroll',
										}}
										className="col-12 px-0 border border-primaryllt rounded-xl"
									>
										<table className="table tableModal">
											<ModalThead
												className="table-head position-sticky"
												style={{ top: '0' }}
												items={items1}
												requestSort={requestSort1}
												sortConfig={sortConfig1}
											>
												<tr>
													<ModalTh className="p-0">
														<ModalLabel className="m-0 w-100 text-center">
															<ModalInput
																className="totalCheckBtn d-none"
																type="checkbox"
															/>
															<span></span>
														</ModalLabel>
													</ModalTh>
													{headTitleList1?.map(
														(el, index) => {
															return (
																<th
																	key={
																		bodyTitleList1[
																			index
																		] +
																		index
																	}
																	onClick={() => {
																		requestSort1(
																			bodyTitleList1[
																				headTitleList1.indexOf(
																					el
																				)
																			]
																		);
																		handleSortChange1(
																			bodyTitleList1[
																				headTitleList1.indexOf(
																					el
																				)
																			]
																		);
																	}}
																>
																	{el}
																	{bodyTitleList1[
																		headTitleList1.indexOf(
																			el
																		)
																	] ===
																		UISort1 &&
																		(orderBy1 ===
																		'desc' ? (
																			<span className="ml-1">
																				▼
																			</span>
																		) : (
																			<span className="ml-1">
																				▲
																			</span>
																		))}
																</th>
															);
														}
													)}
												</tr>
											</ModalThead>
											<tbody>
												{items1.map((item) => {
													return (
														<tr
															key={
																item[
																	`${btnKeyName}Guid`
																]
															}
															className="modalTr list-item table-item tableData"
															onClick={(e) =>
																clickTableRow(
																	e,
																	item[
																		`${btnKeyName}Guid`
																	]
																)
															}
														>
															<td className="text-center p-0">
																<label className="m-0 w-100 px-2">
																	<input
																		type="checkbox"
																		id={
																			item[
																				`${btnKeyName}Guid`
																			]
																		}
																		value={
																			item[
																				`${btnKeyName}Guid`
																			]
																		}
																		className="mt-2"
																		checked={
																			oneCheck ===
																			item[
																				`${btnKeyName}Guid`
																			]
																		}
																		onChange={() => {}}
																	/>
																</label>
															</td>
															<td className="text-start">
																{btnKeyName ===
																'expenseClass'
																	? item[
																			`${btnKeyName}Code`
																	  ]
																	: item[
																			`${btnKeyName}No`
																	  ]}
															</td>
															<td className="text-start">
																{item[`name01`]}
															</td>
															{btnKeyName !==
																'expenseClass' && (
																<td className="text-start">
																	{
																		item[
																			`${btnKeyName}ClassName`
																		]
																	}
																</td>
															)}
														</tr>
													);
												})}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
						<hr className="border-grey1 mt-5 mb-0" />
						<div className="modal-footer justify-content-center border-0 pt-4 pb-5">
							<button
								type="button"
								className="btn btn-primary mediumBtn mx-1"
								onClick={modalSave}
							>
								{t('button.confirmButton')}
							</button>
							<button
								type="button"
								className="btn btn-outline-primarylllt mediumBtn mx-1"
								onClick={modalCancel}
							>
								{t('button.cancelButton')}
							</button>
						</div>
					</form>
				</ModalBody>
			</Modal>

			{/* Modal2 */}
			<Modal
				isOpen={modal2}
				toggle={toggle2}
				centered={true}
				style={{
					maxWidth: '824px',
					border: 'none',
				}}
			>
				<ModalHeader className="position-relative pt-4 pb-2 ps-4">
					<p
						style={{ fontSize: '20px', color: 'var(--primary)' }}
						className="fw-bold mb-1"
					>
						{btnWord2}
					</p>
					<button
						style={{ top: '12px', right: '12px' }}
						type="button"
						className="close position-absolute btn"
						onClick={toggle2}
					>
						<Icn_Cancel />
					</button>
				</ModalHeader>
				<ModalBody className="px-6 pt-6 pb-0">
					<form asp-action="Index" method="get">
						<div className="modal-body p-0">
							<div className="wrap mx-2">
								<div className="row">
									<div className="position-relative col-12 col-md-5 p-0 mb-3">
										<SearchModalInput
											type="text"
											name="searchModal2Input"
											ref={searchModal2Input}
											className="form-control searchModal2Input"
											placeholder={t(
												'lgsBuyOrder:dialogCol.quickSearch'
											)}
										/>
										<ModalSearchBtn
											type="button"
											className="searchModal2Btn position-absolute border-0 btn btn-primaryllt px-3 d-flex align-items-center"
											onClick={() => searchModalBtn()}
										>
											<Icn_Search2 />
										</ModalSearchBtn>
										<a
											className="cancelSearchModalBtn position-absolute d-none"
											style={{
												top: '4px',
												right: '48px',
												cursor: 'pointer',
											}}
											onClick={() =>
												cancelSearchModalBtn()
											}
										>
											<Icn_InputYellowCancel />
										</a>
									</div>
								</div>

								<div
									style={{ height: '440px' }}
									className="row"
								>
									<div
										style={{
											height: '440px',
											overflowY: 'scroll',
										}}
										className="col-12 px-0 border border-primaryllt rounded-xl"
									>
										<table className="table tableModal">
											<ModalThead
												className="table-head position-sticky"
												style={{ top: '0' }}
												items={items2}
												requestSort={requestSort2}
												sortConfig={sortConfig2}
											>
												<tr>
													<ModalTh className="p-0">
														<ModalLabel className="m-0 w-100 text-center">
															<ModalInput
																className="totalCheckBtn d-none"
																type="checkbox"
															/>
															<span></span>
														</ModalLabel>
													</ModalTh>
													{headTitleList2?.map(
														(el, index) => {
															return (
																<th
																	key={
																		bodyTitleList2[
																			index
																		] +
																		index
																	}
																	onClick={() => {
																		requestSort2(
																			bodyTitleList2[
																				headTitleList2.indexOf(
																					el
																				)
																			]
																		);
																		handleSortChange2(
																			bodyTitleList2[
																				headTitleList2.indexOf(
																					el
																				)
																			]
																		);
																	}}
																>
																	{el}
																	{bodyTitleList2[
																		headTitleList2.indexOf(
																			el
																		)
																	] ===
																		UISort2 &&
																		(orderBy2 ===
																		'desc' ? (
																			<span className="ml-1">
																				▼
																			</span>
																		) : (
																			<span className="ml-1">
																				▲
																			</span>
																		))}
																</th>
															);
														}
													)}
												</tr>
											</ModalThead>
											<tbody>
												{items2.map((item) => {
													return (
														<tr
															key={
																item[
																	`sectorGuid`
																]
															}
															className="modalTr list-item table-item tableData"
															onClick={(e) =>
																clickTableRow(
																	e,
																	item[
																		`sectorGuid`
																	]
																)
															}
														>
															<td className="text-center p-0">
																<label className="m-0 w-100 px-2">
																	<input
																		type="checkbox"
																		id={
																			item[
																				`sectorGuid`
																			]
																		}
																		value={
																			item[
																				`sectorGuid`
																			]
																		}
																		className="mt-2"
																	/>
																</label>
															</td>
															<td className="text-start">
																{
																	item[
																		`sectorCode`
																	]
																}
															</td>
															<td className="text-start">
																{
																	item[
																		`sectorName`
																	]
																}
															</td>
															<td className="text-start">
																{
																	item[
																		`sectorClassName`
																	]
																}
															</td>
														</tr>
													);
												})}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
						<hr className="border-grey1 mt-5 mb-0" />
						<div className="modal-footer justify-content-center border-0 pt-4 pb-5">
							<button
								type="button"
								className="btn btn-primary mediumBtn mx-1"
								onClick={modal2Save}
							>
								{t('button.confirmButton')}
							</button>
							<button
								type="button"
								className="btn btn-outline-primarylllt mediumBtn mx-1"
								onClick={modal2Cancel}
							>
								{t('button.cancelButton')}
							</button>
						</div>
					</form>
				</ModalBody>
			</Modal>
		</div>
	);
};
