import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

export default function Pagination(props) {
    // console.log(props);
    const {
        paginationData,
        setPaginationData,
        fetchData,
        sortBy,
        setSortBy,
        advancedSearchDataList,
        orderBy,
    } = props;
    let { numberOfData, pageNumber, totalPage, pageSize } = paginationData;

    const { t, i18n } = useTranslation();

    const handleChangePage = (action) => {
        if (action === 'first') {
            pageNumber = 1;
        } else if (action === 'previous') {
            pageNumber = pageNumber - 1;
        } else if (action === 'next') {
            pageNumber = pageNumber + 1;
        } else if (action === 'last') {
            pageNumber = totalPage;
        }

        setPaginationData({
            ...paginationData,
            pageNumber: pageNumber,
        });

        console.log({
            sortBy,
        });

        fetchData(
            advancedSearchDataList.length ? advancedSearchDataList : null,
            pageSize,
            sortBy,
            pageNumber
        );
        // (advancedSearchDataList = null),
        //     (pageSize = 2),
        //     (sort = sortBy),
        //     (pageNumber = 1);
    };

    // console.log(paginationData);

    return (
        <>
            <div className="row my-3">
                <div className="col-md-3 ps-7">
                    <span style={{ cursor: 'default', lineHeight: '32px' }}>
                        {t('common:pagination.numberOfData')}: {numberOfData}
                    </span>
                </div>
                <div className="col-md-6">
                    <div className="pagination-container">
                        <ul className="pagination d-flex justify-content-between">
                            {pageNumber !== 1 ? (
                                <li
                                    role="button"
                                    className="disabled PagedList-skipToFirst"
                                    onClick={() => handleChangePage('first')}
                                >
                                    <a>|&lt;</a>
                                </li>
                            ) : (
                                <li role="button" className="disabled">
                                    <a rel=""></a>
                                </li>
                            )}
                            {pageNumber !== 1 ? (
                                <li
                                    role="button"
                                    className="disabled PagedList-skipToPrevious"
                                    onClick={() => handleChangePage('previous')}
                                >
                                    <a rel="prev">&lt;</a>
                                </li>
                            ) : (
                                <li role="button" className="disabled">
                                    <a rel=""></a>
                                </li>
                            )}
                            <li className="active">
                                <span>{pageNumber}</span>
                            </li>
                            {pageNumber !== totalPage ? (
                                <li
                                    role="button"
                                    className="disabled PagedList-skipToNext"
                                    onClick={() => handleChangePage('next')}
                                >
                                    <a rel="next">&gt;</a>
                                </li>
                            ) : (
                                <li role="button" className="disabled">
                                    <a rel=""></a>
                                </li>
                            )}
                            {pageNumber !== totalPage ? (
                                <li
                                    role="button"
                                    className="disabled PagedList-skipToLast"
                                    onClick={() => handleChangePage('last')}
                                >
                                    <a>&gt;|</a>
                                </li>
                            ) : (
                                <li role="button" className="disabled">
                                    <a rel=""></a>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
                <div className="col-md-3 pe-7 d-flex justify-content-end">
                    <span
                        style={{
                            cursor: 'pointer',
                            lineHeight: '32px',
                            background: '#F8F9FB',
                            border: '1px solid #ffffff',
                            padding: '0.375rem 0.75rem',
                            fontSize: '0.875rem',
                            lineHeight: '1.5',
                            borderRadius: '0.25rem',
                        }}
                    >
                        {t('common:pagination.totalPage')}
                        {pageNumber} / {totalPage}
                    </span>
                </div>
            </div>
        </>
    );
}
