import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    padding: 0px;

    // min-width: 88px;
    // svg {
    // 	margin-right: 8px;
    // }

    /// disabled
    background-color: ${(prop) =>
        prop.isDisabled ? 'var(--grey2) !important' : ''};
    border-color: ${(prop) =>
        prop.isDisabled ? 'var(--grey2) !important' : ''};
    color: ${(prop) => (prop.isDisabled ? 'var(--grey3) !important' : '')};
    cursor: ${(prop) =>
        prop.isDisabled ? 'auto !important' : 'pointer !important'};
`;

export const BtnNormal2 = (props) => {
    // 	// console.log(props);
    const {
        className,
        keyName,
        handleClick,
        handleMouseEnter,
        handleMouseLeave,
        word,
        disabled,
        specialDisabled, // 當按鈕不能是disabled，但又需要有其樣式
        children,
    } = props;

    return (
        <Button
            className={`btn ${className}`}
            key={keyName}
            onClick={(e) => handleClick(e)}
            onMouseEnter={(e) => handleMouseEnter && handleMouseEnter(e)}
            onMouseLeave={(e) => handleMouseLeave && handleMouseLeave(e)}
            disabled={disabled}
            isDisabled={disabled || specialDisabled}
        >
            {children}
            <span>{word}</span>
        </Button>
    );
};
