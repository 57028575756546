import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState, memo } from 'react';

import InvoiceModal from './InvoiceModal2';
import { ReactComponent as Icn_Check } from '../../../../../../assets/images/icn_Check-r.svg';
import { useTranslation } from 'react-i18next';
// #region styled-components
const IndexTableContainer = styled.div``;

const IndexTable = styled.table`
	margin-bottom: 0;
	color: var(--grey4);
	background-color: #fff;
`;

const THead = styled.thead`
	height: 40px;
`;

const TR = styled.tr`
	height: 40px;
	&:hover {
		cursor: ${(hoverEffect) =>
			hoverEffect === 'true' ? 'pointer' : 'default'};
		background-color: ${(hoverEffect) =>
			hoverEffect === 'true' ? 'var(--grey0)' : 'var(--bs-table-bg)'};
	}
`;

const TH = styled.th`
	vertical-align: middle;
	border: 1px solid var(--grey1) !important;
	&:first-of-type {
		border-left: none !important;
	}

	&:last-of-type {
		border-right: none !important;
	}
`;

const TBody = styled.tbody``;

const TFoot = styled.tfoot`
	border: none;
`;

const TD = styled.td`
	cursor: auto;
	border: 1px solid var(--grey1) !important;
	&:first-of-type {
		border-left: none !important;
	}

	&:last-of-type {
		border-right: none !important;
	}
`;

const FootTR = styled.tr`
	border-top: 2px solid var(--grey3) !important;
`;

const FootTD = styled.td`
	border: none !important;
`;
//#endregion

function InvoiceHistoryTable(props) {
	const { t } = useTranslation();
	console.log(props);
	const {
		guidName,
		sortBy,
		headTitleList,
		bodyTitleList,
		tableData,
		totalNetAmount,
		totalTaxAmount,
		totalTaxableAmount,
		tableHeadStyles,
		tableBodyStyles,
		register,
		errors,
		getValues,
		setValue,
		detailsInfo,
	} = props;
	const [orderBy, setOrderBy] = useState('desc');
	const [UISort, setUISort] = useState(sortBy);

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const preferredOrder = (tableData, bodyTitleList) => {
		let newObject = {};
		for (let i = 0; i < bodyTitleList.length; i++) {
			if (tableData.hasOwnProperty(bodyTitleList[i])) {
				newObject[bodyTitleList[i]] = tableData[bodyTitleList[i]];
			}
		}
		return newObject;
	};

	preferredOrder(tableData, bodyTitleList);

	return (
		<div className="position-relative">
			<IndexTableContainer className="bg-white d-flex flex-column p-4 position-relative">
				<IndexTable className="table">
					<THead className="table-head">
						<TR>
							{headTitleList?.map((el) => {
								return (
									<TH
										key={`tableHead${headTitleList.indexOf(
											el
										)}`}
										style={
											tableHeadStyles[
												headTitleList.indexOf(el)
											]
										}
										className={`p-2 border`}
									>
										{el}
										{bodyTitleList[
											headTitleList.indexOf(el)
										] === UISort &&
											(orderBy === 'desc' ? (
												<span className="ml-1">▼</span>
											) : (
												<span className="ml-1">▲</span>
											))}
									</TH>
								);
							})}
						</TR>
					</THead>
					<TBody className="">
						{tableData?.map((data) => {
							return (
								<TR
									id={data[guidName]}
									key={data[guidName]}
									// className="cursor-pointer"
									onClick={() => {
										console.log(data);
										handleShow();
									}}
								>
									{bodyTitleList.map((el) => {
										if (
											el === 'invoiceReturn' ||
											el === 'governmentInvoice'
										) {
											return data[el] ? (
												<TD
													style={
														tableBodyStyles[
															bodyTitleList.indexOf(
																el
															)
														]
													}
													className="p-2 border cursor-pointer"
													key={`${el}${data[guidName]}`}
												>
													<Icn_Check />
												</TD>
											) : (
												<TD
													style={
														tableBodyStyles[
															bodyTitleList.indexOf(
																el
															)
														]
													}
													className="p-2 border cursor-pointer"
													key={`${el}${data[guidName]}`}
												></TD>
											);
										}
										return (
											<TD
												style={
													tableBodyStyles[
														bodyTitleList.indexOf(
															el
														)
													]
												}
												className="p-2 border cursor-pointer"
												key={`${el}${data[guidName]}`}
											>
												{data[el]}
											</TD>
										);
									})}
								</TR>
							);
						})}
					</TBody>
					{tableData.length ? (
						<TFoot>
							<FootTR
								id="totalAmount"
								key="totalAmount"
								style={{ fontWeight: 'bold' }}
							>
								<FootTD
									className="text-left p-2 
                                        text-center"
								></FootTD>
								<FootTD
									className="text-left p-2 
                                            text-center"
								></FootTD>
								<FootTD
									className="text-left p-2 
                                            text-center"
								>
									{t('lgsBuyOrder:totalAmount')}
								</FootTD>
								<FootTD
									className="text-left p-2 
                                            text-center"
								></FootTD>
								<FootTD
									className="text-left p-2 
                                            text-center"
								></FootTD>
								<FootTD
									className="text-left p-2 
                                            text-center"
								></FootTD>
								<FootTD
									className="text-left p-2 
                                            text-center"
								></FootTD>
								<FootTD
									className="text-left p-2 
                                            text-center"
								>
									{totalTaxableAmount}
								</FootTD>
								<FootTD
									className="text-left p-2 
                                            text-center"
								>
									{totalNetAmount}
								</FootTD>
								<FootTD
									className="text-left p-2 
                                            text-center"
								>
									{totalTaxAmount}
								</FootTD>
							</FootTR>
						</TFoot>
					) : (
						<TFoot></TFoot>
					)}
				</IndexTable>
				{/* {!tableData.length && (
                    <>
                        <div
                            style={{
                                textAlign: 'center',
                                verticalAlign: 'middle',
                                fontWeight: 'bold',
                                marginTop: '16px',
                            }}
                        >
                            (無資料)
                        </div>
                    </>
                )} */}
			</IndexTableContainer>
			<InvoiceModal
				show={show}
				handleClose={handleClose}
				register={register}
				errors={errors}
				setValue={setValue}
				getValues={getValues}
				detailsInfo={detailsInfo}
			/>
		</div>
	);
}

export default InvoiceHistoryTable;
