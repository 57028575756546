import React from 'react';
import styled from 'styled-components';
import { useCookies } from 'react-cookie';
import ThreeColLayout from '../../../../../components/ThreeColLayout/ThreeColLayout';
import { useTranslation } from 'react-i18next';

//#region styled-components
const TopTable = styled.table`
	font-size: 12px;
	color: #616e83;
	background-color: #f7f8fc;
	width: 100% !important;
	height: 128px !important;
	border: 1px solid var(--grey1) !important;
	border-collapse: separate;
	border-spacing: 0;
	border-radius: 10px;
`;

const TBody = styled.tbody``;

const TR = styled.tr`
	height: 32px;
	border: 2px solid var(--grey1) !important;
`;
const TH1 = styled.th`
	padding: 0px;
	height: 32px;
	width: 23% !important;
	text-align: center !important;
`;
const TH = styled.th`
	padding: 0px;
	height: 32px;
	width: 38.5% !important;
	text-align: center !important;
	font-weight: bold !important;
`;
const TD1 = styled.td`
	padding: 0px;
	height: 32px;
	width: 23% !important;
	border: 1px solid var(--grey1) !important;
	text-align: center !important;
`;
const TD = styled.td`
	padding-right: 8px;
	height: 32px;
	width: 38.5% !important;
	border: 1px solid var(--grey1) !important;

	text-align: right !important;
`;

const LabelNormal = styled.label`
	font-size: 12px;
	color: var(--grey4);
	margin-bottom: 6px;
`;

const InputElement = styled.input`
	background-color: #eff2f5;
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
`;

//#endregion

const TopInfo = ({ topInfoData, buyOrderData }) => {
	const { t } = useTranslation();
	const [cookies] = useCookies();

	const {
		exchangeRate,
		foreignCurrency,
		vendorName,
		orderTypeName,
		orderDate,
		currencyShow,
		inputTaxName,
		inputTaxRate,
		decimalPlaceTl,
	} = buyOrderData.page0;

	// 本幣的showName
	const localCurrencyName =
		cookies._company_currency_code + ' ' + cookies._company_currency_name;

	function LeftCol1() {
		return (
			<>
				<div className="d-flex flex-column">
					<LabelNormal htmlFor={`vendor`}>
						{t('lgsBuyOrder:vendor')}
					</LabelNormal>
					<InputElement
						type="text"
						id={`vendor`}
						className="mb-3"
						disabled
						value={vendorName}
					/>
				</div>

				<div className="row">
					<div className="col-lg-7">
						<div className="d-flex flex-column">
							<LabelNormal htmlFor={`orderTypeName`}>
								{t('lgsBuyOrder:orderType')}
							</LabelNormal>
							<InputElement
								type="text"
								id={`orderTypeName`}
								className="mb-3"
								disabled
								value={orderTypeName}
							/>
						</div>
					</div>

					<div className="col-lg-5 ps-0">
						<div className="d-flex flex-column">
							<LabelNormal htmlFor={`orderDate`}>
								{t('lgsBuyOrder:orderDate')}
							</LabelNormal>
							<InputElement
								type="date"
								id={`orderDate`}
								className="mb-3"
								disabled
								value={orderDate}
							/>
						</div>
					</div>
				</div>
			</>
		);
	}

	function CenterCol1() {
		return (
			<>
				<div className="row">
					<div className={foreignCurrency ? 'col-lg-8' : 'col-lg-12'}>
						<div className="d-flex flex-column">
							<LabelNormal htmlFor={`currencyShow`}>
								{t('lgsBuyOrder:currency')}
							</LabelNormal>
							<InputElement
								type="text"
								id={`currencyShow`}
								className="mb-3"
								disabled
								value={currencyShow}
							/>
						</div>
					</div>
					{foreignCurrency && (
						<div className="col-lg-4">
							<div className="d-flex flex-column">
								<LabelNormal htmlFor={`exchangeRate`}>
									{t('lgsBuyOrder:exchangeRate')}
								</LabelNormal>
								<InputElement
									style={{
										backgroundColor: '#EFF2F5',
									}}
									type="text"
									id={`exchangeRate`}
									className="mb-3"
									disabled
									value={exchangeRate}
								/>
							</div>
						</div>
					)}
				</div>
				<div className="row">
					<div className="col-lg-8">
						<div className="d-flex flex-column">
							<LabelNormal htmlFor={`inputTax`}>
								{t('lgsBuyOrder:invoiceTaxCategory')}
							</LabelNormal>
							<InputElement
								type="text"
								id={`inputTax`}
								className="mb-3"
								disabled
								value={inputTaxName}
							/>
						</div>
					</div>

					<div className="col-lg-4">
						<div className="d-flex flex-column">
							<LabelNormal htmlFor={`inputTaxRate`}>
								{t('lgsBuyOrder:invoiceTaxRate')}
							</LabelNormal>
							<InputElement
								style={{}}
								type="text"
								id={`inputTaxRate`}
								className="mb-3"
								disabled
								value={inputTaxRate}
							/>
						</div>
					</div>
				</div>
			</>
		);
	}

	function RightCol1() {
		// 小數點位數限制
		const localDecimalPlaceTl = parseInt(
			cookies._company_currency_decimalplace_tl
		);
		const foreignDecimalPlaceTl = parseInt(decimalPlaceTl);

		return (
			<>
				<TopTable>
					<TBody>
						<TR>
							<TH1></TH1>
							{foreignCurrency && <TH>{currencyShow}</TH>}
							<TH>{localCurrencyName}</TH>
						</TR>
						<TR>
							<TD1>{t('lgsBuyOrder:netTotalAmount')}</TD1>
							{foreignCurrency && (
								<TD>
									{topInfoData.foreignNetAmount?.toFixed(
										foreignDecimalPlaceTl
									)}
								</TD>
							)}
							<TD>
								{topInfoData.netAmount?.toFixed(
									localDecimalPlaceTl
								)}
							</TD>
						</TR>
						<TR>
							<TD1>{t('lgsBuyOrder:taxAmount2')}</TD1>
							{foreignCurrency && (
								<TD>
									{topInfoData.foreignTaxAmount?.toFixed(
										foreignDecimalPlaceTl
									)}
								</TD>
							)}
							<TD>
								{topInfoData.taxAmount?.toFixed(
									localDecimalPlaceTl
								)}
							</TD>
						</TR>
						<TR>
							<TD1>{t('lgsBuyOrder:taxableTotalAmount')}</TD1>
							{foreignCurrency && (
								<TD>
									{topInfoData.foreignGrossAmount?.toFixed(
										foreignDecimalPlaceTl
									)}
								</TD>
							)}
							<TD>
								{topInfoData.grossAmount?.toFixed(
									localDecimalPlaceTl
								)}
							</TD>
						</TR>
					</TBody>
				</TopTable>
			</>
		);
	}

	return (
		<ThreeColLayout
			LeftCol={<LeftCol1 />}
			CenterCol={<CenterCol1 />}
			RightCol={<RightCol1 />}
		/>
	);
};

export default TopInfo;
