import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DetailAndGoodsTable from './DetailAndGoodsTable';
import useAxios from '../../../../../../hooks/useAxios';
import { useTranslation } from 'react-i18next';

function LgsBuyOrder_DetailAndGoods() {
	//#region state initialization
	const { t } = useTranslation();

	// state
	const [fetchedData, setFetchedData] = useState([]);

	// axios
	const { sendRequest: axiosDetailAndGoodsList } = useAxios();

	// variable
	const guidName = 'buyOrderItemGuid';
	const sortBy = 'displayOrder';
	const buyOrderGuid = useLocation().pathname.split('/').pop();

	// table title list
	const headTitleList = [
		'#',
		t('lgsBuyOrder:type'),
		t('lgsBuyOrder:purchaseContent'),
		t('lgsBuyOrder:purchaseQuantity'),
		t('lgsBuyOrder:unit'),
		t('lgsBuyOrder:inspection'),
		t('lgsBuyOrder:unitPriceExcludingTax'),
		t('lgsBuyOrder:priceDifferencePercentage'),
		t('lgsBuyOrder:amountExcludingTax'),
		t('lgsBuyOrder:deliveryDate'),
		t('lgsBuyOrder:annotation'),
	];

	const bodyTitleList = [
		'displayOrder',
		'buyCategoryCode',
		'procurementContent',
		'quantityBuy',
		'uomcode',
		'inspection',
		'netPrice',
		'spreadPercent',
		'netAmount',
		'deliverDate',
		'note',
	];

	const childHeadTitleList = [
		'#',
		t('lgsBuyOrder:date'),
		t('lgsBuyOrder:receivedQuantity'),
		t('lgsBuyOrder:unreceivedQuantity'),
		t('lgsBuyOrder:materialLocation'),
		t('lgsBuyOrder:receiptNumber'),
		t('lgsBuyOrder:annotation'),
		t('lgsBuyOrder:batchNumber'),
	];

	//#endregion

	//#region useEffect

	useEffect(() => {
		const url = `api/Logistics/LgsBuyOrder/buyOrderItem?guid=${buyOrderGuid}`;
		axiosDetailAndGoodsList({ url }, (res) => {
			console.log(res);
			setFetchedData(res.data);
		});
	}, []);

	//#endregion

	return (
		<div id="indexView">
			{fetchedData && (
				<DetailAndGoodsTable
					guidName={guidName}
					headTitleList={headTitleList}
					bodyTitleList={bodyTitleList}
					childHeadTitleList={childHeadTitleList}
					indexData={fetchedData}
					sortBy={sortBy}
				/>
			)}
		</div>
	);
}

export default LgsBuyOrder_DetailAndGoods;
