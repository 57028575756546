import { useState, useEffect, useRef } from 'react';
import useAxios from '../../../../../../hooks/useAxios';
//import AppendixTable from './AppendixTable';
import styled from 'styled-components';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { ReactComponent as PictureSmallIcon } from '../../../../../../assets/images/pictureSmallIcon.svg';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
// TODO: change to useAxios
import axios from 'axios';

// #region styled-components
const TabContainer = styled.div``;

const buttonStyles = {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '32px',
  alignItems: 'center',
  border: '1px solid #1278E8',
  marginBottom: 8,
  borderRadius: '10px',
};

const colStyle = {
  margin: 10,
  marginTop: 32,
  marginLeft: 34,
  marginBottom: 48,
  padding: 20,
  border: '1px solid #EFF2F5',
  borderRadius: '10px',
};
const col2Style = {
  margin: 10,
  marginRight: 34,
  marginTop: 32,
  border: '0px solid #EFF2F5',
  display: 'flex',
};

const inner = {
  width: '224px',
  height: '152px',
  backgroundColor: '#EFF2F5',
  backgroundImage: `url("../../../../../assets/1.jpg")`,
  borderTop: '1px solid blue',
  borderLeft: '1px solid blue',
  borderRight: '1px solid blue',
  borderRadius: '10px 10px 0px 0px',
  textAlign: 'center',
};

const inner2 = {
  width: '224px',
  height: '48px',
  borderBottom: '1px solid blue',
  borderLeft: '1px solid blue',
  borderRight: '1px solid blue',
  borderRadius: '0px 0px 10px 10px',
  textAlign: 'center',
};

const inputA = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '12px',
};

const AppendixCard = styled.div`
  height: 200px;
  width: 224px;
  margin-right: 16px;
  margin-bottom: 16px;
  border-radius: 8px;
  border: 1px solid #eff2f5;
  border: ${(props) =>
    props.activeCard ? '1px solid var(--primary)' : '1px solid #eff2f5'};
  overflow: hidden;
  cursor: pointer;
  position: relative;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
`;

//#endregion
function LgsBuyOrder_Appendix() {
  const [activeTab, setActiveTab] = useState('');
  const [appendixInfo, setAppendixInfo] = useState([]);
  const [appendixData, setAppendixData] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [file, setFile] = useState(null);
  const [appendixClass, setAppendixClass] = useState([]);
  const [attachment, setAttachment] = useState([]);
  const fileInputRef = useRef(null);
  const [activeCard, setActiveCard] = useState('');
  //const newAppendixClass = [...appendixClasses];

  const handleSelectTab = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  const convertDataFormat = (data) => {
    console.log('data', data);
    const originalAppendixInfo = [];
    const originalAppendixData = [];
    const originalFileList = [];

    data.map((el) => {
      console.log('el', el);
      originalAppendixInfo.push({
        //action: 'update',
        attachmentGuid: el.attachmentGuid,
        appendixClass: el.appendixClass,
        appendixName: el.appendixName,
        appendixType: el.appendixUrl.split('.').pop(), // 這邊是抓 .後面的字串(判斷檔案類型)
      });

      originalAppendixData.push({
        appendixData: el.appendixUrl,
      });
      console.log(el.appendixUrl.split('.').pop());

      // 因為 fileList 要和 appendixInfo 保持一致性, 所以一開始會根據 api 回傳的 data list 把 fileList 加入空字串作為填充
      originalFileList.push('');
    });

    return { originalAppendixInfo, originalAppendixData, originalFileList };

    /// appendixInfo items format
    // appendixClass: '257a1d8f-5ba8-ed11-a17f-00155d070709';
    // appendixName: '工廠App_20211126';
    // appendixType: 'docx';

    /// appendixData items format
    // appendixData: 'blob:https://localhost:44370/49e6f7cf-95de-4501-b9aa-a301cc95b09d';
  };

  // const infoObject = {
  //   appendixClass: appendixClassGuid,
  //   appendixName: fileName,
  //   appendixType: fileType,
  // };

  //API 拿資料

  const guid = useLocation().pathname.split('/').pop();
  const getTableData = () => {
    axios
      .get(`api/Logistics/LgsBuyOrder/buyOrderAttach?guid=${guid}`)
      .then((res) => {
        console.log(res.data);
        setAppendixClass(res.data.appendixClass);
        setAttachment(res.data.attachment);
        setActiveTab(res.data.appendixClass[0].appendixClassGuid); //預設 tab

        const { originalAppendixInfo, originalAppendixData, originalFileList } =
          convertDataFormat(res.data.attachment); // res.attachment
        console.log(res.data.attachment);
        originalAppendixInfo.map((el) => (el.action = 'update'));
        setAppendixInfo(originalAppendixInfo);
        setAppendixData(originalAppendixData);
        setFileList(originalFileList);
      });
  };
  function handleClick(fileInputRef) {
    fileInputRef.current.click();
  }
  useEffect(() => {
    getTableData();
  }, []);

  const [appendixClassGuid, setAppendixClassGuid] = useState('');

  // console.log(appendixClassGuid);
  //判斷檔案型態，給予不同 UI 樣式

  const handleCardImage = (info, data) => {
    const { appendixClass, appendixName, appendixType } = info;
    //const { appendixData } = data;

    const fileType = appendixType.toUpperCase();
    if (['PNG', 'JPG', 'JPGE', 'GIF'].some((value) => fileType === value)) {
      return <img className="h-100 w-100" src={appendixData} />;
    } else if (['DOC', 'DOCX'].some((value) => fileType === value)) {
      return (
        <h6 className="m-0 px-2 py-2 bg-primary rounded-lg text-white">
          {fileType}
        </h6>
      );
    } else if (['PDF', 'PPT', 'PPTX'].some((value) => fileType === value)) {
      return (
        <h6 className="m-0 px-2 py-2 bg-danger rounded-lg text-white">
          {fileType}
        </h6>
      );
    } else if (['XLS', 'XLSX'].some((value) => fileType === value)) {
      return (
        <h6 className="m-0 px-2 py-2 bg-success rounded-lg text-white">
          {fileType}
        </h6>
      );
    } else {
      return (
        <h6 className="m-0 px-2 py-2 bg-secondary rounded-lg text-white">
          {fileType}
        </h6>
      );
    }
  };
  //console.log(fileType);

  const handleDoubleClickCard = (e, el, index) => {
    if (e.target.tagName.toLowerCase() !== 'input') {
      const fileName = el.appendixName;
      const file = appendixData[index].appendixData;

      const link = document.createElement('a');
      link.href = file;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  //console.log(el.appendixName);

  return (
    <TabContainer activeKey={activeTab}>
      <Tab.Container id="" activeKey={activeTab} onSelect={handleSelectTab}>
        <Row>
          <Col lg={2} style={colStyle}>
            <Nav variant="pills" className="flex-column">
              {appendixClass.map((el) => {
                //                console.log(el);
                //console.log(el.name01);

                return (
                  <Nav.Item key={el.appendixClassGuid}>
                    <Nav.Link
                      eventKey={el.appendixClassGuid}
                      style={buttonStyles}
                      onClick={() => setAppendixClassGuid(el.appendixClassGuid)}
                    >
                      {el.name01}
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
            </Nav>
          </Col>

          <Col style={col2Style}>
            <Tabs activeKey={activeTab}>
              {appendixClass.map((data) => {
                //      console.log(data);
                return (
                  <Tab.Pane
                    key={data.appendixClassGuid}
                    eventKey={data.appendixClassGuid}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      <>
                        {attachment.map((el, index2) => {
                          //console.log(el.appendixName); //這邊的 el 是 附件類別裡的 檔案資料
                          //console.log(data); //這邊的 data 是 附件類別
                          return (
                            el.appendixClass === data.appendixClassGuid && (
                              <AppendixCard
                                key={el.appendixName + index2}
                                id={el.appendixName}
                                onDoubleClick={(e) =>
                                  handleDoubleClickCard(e, el, index2)
                                }
                                activeCard={
                                  el.appendixClass + index2 === activeCard
                                }
                              >
                                {el.appendixClass + index2 === activeCard && (
                                  <span
                                    style={{
                                      position: 'absolute',
                                      top: '12px',
                                      right: '12px',
                                    }}
                                  >
                                    <svg
                                      id="Group_6556"
                                      dataname="Group 6556"
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                    >
                                      <defs>
                                        <clipPath id="clip-path-times1811DDDDDDD">
                                          <rect
                                            id="Rectangle_3429"
                                            dataname="Rectangle 3429"
                                            width="16"
                                            height="16"
                                            fill="#ea4132"
                                          ></rect>
                                        </clipPath>
                                      </defs>
                                      <g
                                        id="Group_6556-2"
                                        dataname="Group 6556"
                                        clipPath="url(#clip-pathtimes1811DDDDDDD)"
                                      >
                                        <path
                                          id="Path_1484"
                                          dataname="Path 1484"
                                          d="M6.785,12.011h0a5.226,5.226,0,1,1,5.226-5.226,5.226,5.226,0,0,1-5.226,5.226"
                                          transform="translate(1.215 1.216)"
                                          fill="#fff"
                                        ></path>
                                        <path
                                          id="Path_1485"
                                          dataname="Path 1485"
                                          d="M8,16a7.995,7.995,0,1,1,5.657-2.34A8,8,0,0,1,8,16M8,9.126H8L10.868,12,12,10.87,9.124,8,12,5.128,10.868,4,8,6.872,5.127,4,4,5.128,6.869,8,4,10.87,5.127,12Z"
                                          transform="translate(0.001 0.001)"
                                          fill="#ea4132"
                                        ></path>
                                      </g>
                                    </svg>
                                  </span>
                                )}
                                <div
                                  className="text-center d-flex align-items-center justify-content-center"
                                  style={{
                                    height: '152px',
                                    width: '224px',
                                  }}
                                >
                                  <img
                                    className="h-100 w-100"
                                    src={el.appendixUrl}
                                  />
                                  {/* {console.log(el)} */}
                                  {/* {handleCardImage(el, appendixData[index2])} */}
                                </div>

                                <div
                                  className="text-center bg-white"
                                  style={{
                                    height: '48px',
                                    width: '224px',
                                    padding: '8px 16px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    position: 'relative',
                                  }}
                                >
                                  <PictureSmallIcon />
                                  <div
                                    style={{
                                      width: '124px',
                                      height: '30px',
                                      margin: '0 auto',
                                      borderRadius: '4px',
                                      border:
                                        el.appendixClass + index2 === activeCard
                                          ? '1px solid var(--primary)'
                                          : 'none',
                                    }}
                                  >
                                    <input
                                      style={{
                                        width: '120px',
                                        position: 'absolute',
                                        top: '50%',
                                        // left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        outline: 'none',
                                        border: 'none',
                                        textAlign: 'center',
                                      }}
                                      value={el.appendixName}
                                      onChange={() => {}}
                                      autoFocus={
                                        el.appendixClass + index2 === activeCard
                                      }
                                    />
                                  </div>
                                </div>
                              </AppendixCard>
                            )
                          );
                        })}
                      </>
                    </div>
                  </Tab.Pane>
                );
              })}
            </Tabs>
          </Col>
        </Row>
      </Tab.Container>
    </TabContainer>
  );
}

export default LgsBuyOrder_Appendix;
