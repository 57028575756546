import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Icn_CheckAndCircle } from '../../../../../assets/images/icn_CheckAndCircle.svg';
import { StepByStepBar } from '../../../../../components/StepByStepBar/StepByStepBar';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// #region styled-components

// 換上下頁元件
const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div`
	display: flex;
`;

const Right = styled.div`
	display: flex;
`;

// dialog
const CardWrapper = styled.div`
	width: 384px;
	height: 408px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

const CardTitle = styled.h1`
	font-size: 20px;
	font-weight: 900;
	margin-bottom: 16px;
	text-align: center;
`;
const CardText = styled.h2`
	font-size: 14px;
	text-align: center;
`;

const ButtonWrapper = styled.div``;

const ButtonLeft = styled.button`
	width: 136px;
	height: 32px;
	margin-top: 88px;
`;
const ButtonRight = styled.button`
	width: 136px;
	height: 32px;
	margin-top: 88px;
`;

//#endregion

function Page4(props) {
	//#region state initiation
	const { t } = useTranslation(['common', 'lgsBuyOrderInvoice']); // i18n

	const { parentData, setParentData, setPage, processSteps } = props;

	const navigate = useNavigate();
	console.log(parentData);
	//#endregion

	const handleCreateNewBuyOrder = () => {
		// 清空parent Data
		setParentData({
			isEverEnterPage: {
				page0: false,
				page1: false,
				page2: false,
			},
			page0: {},
			page1: {},
			page2: {},
			page3: {},
		});
		setPage(0);
	};

	return (
		<>
			<Container>
				<Left></Left>
				<Center className="ps-8">
					<StepByStepBar steps={processSteps} activePage={3} />
				</Center>
				<Right></Right>
			</Container>

			<CardWrapper className="d-flex flex-column border-grey3 rounded-xxl bg-white mx-auto mt-5">
				<Icn_CheckAndCircle className="mx-auto my-6" />
				{parentData.page0.buyOrderSelectedList.map((el) => {
					return (
						<CardTitle>
							{t('lgsBuyOrderInvoice:orderNo') + ' ' + el.orderNo}
						</CardTitle>
					);
				})}
				<CardText>
					{t(
						'lgsBuyOrderInvoice:purchaseOrderInvoiceReceivedCompleted'
					)}
					！
				</CardText>
				<ButtonWrapper className="d-flex justify-content-center">
					<ButtonLeft
						className="btn btn-outline-primary mx-2"
						onClick={() => {
							navigate('/');
						}}
					>
						{t('lgsBuyOrderInvoice:goToMainScreen')}
					</ButtonLeft>

					<ButtonRight
						className="btn btn-primary mx-2"
						onClick={() => {
							handleCreateNewBuyOrder();
						}}
					>
						{t('lgsBuyOrderInvoice:continueAdding')}
					</ButtonRight>
				</ButtonWrapper>
			</CardWrapper>
		</>
	);
}

export default Page4;
