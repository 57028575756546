import { CreateBtnNormal } from './components/Buttons/CreateBtnNormal';
import { DeleteBtnSmall } from './components/Buttons/DeleteBtnSmall';
import { SearchBtnSmall } from './components/Buttons/SearchBtnSmall';
import { CopyCreateBtn } from './components/Buttons/CopyCreateBtn';
import { EditBtnNormal } from './components/Buttons/EditBtnNormal';
import { DropdownMenuArea } from './components/DropdownMenuArea/DropdownMenuArea';
import i18n from 'i18next';

// check if variable is a object
export function isObject(objValue) {
    return (
        objValue &&
        typeof objValue === 'object' &&
        objValue.constructor === Object
    );
}

//TODO: 之後可能會不要
export const functionButtonsTable = (name, view) => {
    const table = {
        create: <CreateBtnNormal name={name} view={view} key={name} />,
        log: <DropdownMenuArea name={name} />,
        delete: <DeleteBtnSmall />,
        search: <SearchBtnSmall />,
        copy: <CopyCreateBtn />,
        edit: <EditBtnNormal />,
    };

    return table[name];
};

export const generateEditLog = (pageName, t, updated, original) => {
    // const t = i18n.getFixedT('en');
    // console.log(updated, original, t);

    let updateContent = '';

    Object.keys(original).forEach((key) => {
        // console.log(key);
        const originalVal = original[key];
        const updatedVal = updated[key];
        if (originalVal === updatedVal) {
            return;
        }

        const keyName = t(`${pageName}.${key}`);

        console.log(keyName);

        const content = t('log.edit', {
            key: `[${keyName}]`,
            originalVal: originalVal,
            updatedVal: updatedVal,
        });

        return (updateContent += `${content} `);
    });

    console.log(updateContent);
    return updateContent;
};
