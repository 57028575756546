import { useState } from 'react';
import PageTitleBar from '../../../components/PageTitleBar/PageTitleBar';
import Page0 from './Pages/Page0/Page0';
import Page1 from './Pages/Page1/Page1';
import Page2 from './Pages/Page2/Page2';
import Page3 from './Pages/Page3/Page3';
import Page4 from './Pages/Page4/Page4';
import { DialogConfirm } from './Pages/DialogConfirm';
import { useTranslation } from 'react-i18next';

function LgsBuyOrderInvoice() {
	//#region state initialization
	const { t } = useTranslation(['common', 'lgsBuyOrderInvoice']); // i18n

	// state
	const [parentData, setParentData] = useState({
		isEverEnterPage: {
			page0: false,
			page1: false,
			page2: false,
		},
		page0: {},
		page1: {},
		page2: {},
		page3: {},
	});
	const [page, setPage] = useState(0);

	const pageTitle = t('lgsBuyOrderInvoice:buyOrderInvoice');
	const processSteps = [
		t('lgsBuyOrderInvoice:selectOrder'),
		t('lgsBuyOrderInvoice:invoiceDetails'),
		t('lgsBuyOrderInvoice:invoiceSplit'),
		t('lgsBuyOrderInvoice:confirmation'),
	];

	//#endregion

	//#region handlePage
	const handlePrevPage = () => {
		setPage(page - 1);
	};

	const handleNextPage = () => {
		setPage(page + 1);
	};
	//#endregion

	//#region DialogConfirm

	//  返回btn確認dialog
	const [dialogConfirmData, setDialogConfirmData] = useState({
		btnName: '',
		isOpen: false,
		goTo: '',
		title: '',
	});
	const [isConfirmBtnClicked, setIsConfirmBtnClicked] = useState(false);

	// DialogConfirm Modal open
	const handleDialogConfirm = () => {
		const newData = {
			btnName: 'goToRoot',
			isOpen: true,
			goTo: 'toRoot',
			title:
				t('lgsBuyOrderInvoice:confirmLeavePurchaseOrderInvoicePage') +
				'?',
		};
		setDialogConfirmData(newData);
	};
	console.log(dialogConfirmData);

	//#endregion

	return (
		<>
			<div id="detailsView">
				<PageTitleBar
					pageTitle={pageTitle}
					previousPageButton={true}
					goTo={-1}
					isDialogConfirm={true}
					handleDialogConfirm={handleDialogConfirm}
				/>

				{/* DialogConfirm element */}
				<DialogConfirm
					dialogConfirmData={dialogConfirmData}
					setDialogConfirmData={setDialogConfirmData}
					page={page}
					setPage={setPage}
					setIsConfirmBtnClicked={setIsConfirmBtnClicked}
				/>

				<div>
					{page === 0 && (
						<Page0
							parentData={parentData}
							setParentData={setParentData}
							page={page}
							processSteps={processSteps}
							handleNextPage={handleNextPage}
						/>
					)}
					{page === 1 && (
						<Page1
							setDialogConfirmData={setDialogConfirmData}
							parentData={parentData}
							setParentData={setParentData}
							page={page}
							processSteps={processSteps}
							handleNextPage={handleNextPage}
						/>
					)}
					{page === 2 && (
						<Page2
							parentData={parentData}
							setParentData={setParentData}
							page={page}
							processSteps={processSteps}
							handleNextPage={handleNextPage}
							handlePrevPage={handlePrevPage}
						/>
					)}
					{page === 3 && (
						<Page3
							parentData={parentData}
							page={page}
							processSteps={processSteps}
							handlePrevPage={handlePrevPage}
							handleNextPage={handleNextPage}
						/>
					)}
					{page === 4 && (
						<Page4
							parentData={parentData}
							setParentData={setParentData}
							page={page}
							setPage={setPage}
							processSteps={processSteps}
							handlePrevPage={handlePrevPage}
						/>
					)}
				</div>
			</div>
		</>
	);
}

export default LgsBuyOrderInvoice;
