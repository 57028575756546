import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import useAxios from './useAxios';
import { functionButtonsTable } from '../utils';

// TODO:  如果確定不會用這 hook, 就刪除
function usePermissionButton(view, permissionListObject) {
    const [rrrr, setRrrr] = useState(null);
    const AAA = () => {};

    const handleButtonList = async () => {
        const result = {};
        console.log(view, permissionListObject);
        const url = api`/Logistics/LgsBuyOrder/GetList?sort=orderDate&pagesize=2`;
        const indexInfoPromise = await AAA(url);
        const permission = indexInfoPromise?.permission;
        console.log(permissionListObject);

        const leftResult = [];
        const centerResult = [];
        const rightResult = [];

        Object.entries(permissionListObject.left).forEach((buttonArr) => {
            if (permission.includes(buttonArr[0])) {
                const button = functionButtonsTable([buttonArr[1]], view);
                leftResult.push(button);
            }
        });

        Object.entries(permissionListObject.center).forEach((buttonArr) => {
            if (permission.includes(buttonArr[0])) {
                const button = functionButtonsTable([buttonArr[1]], view);
                centerResult.push(button);
            }
        });

        Object.entries(permissionListObject.right).forEach((buttonArr) => {
            if (permission.includes(buttonArr[0])) {
                const button = functionButtonsTable([buttonArr[1]], view);
                rightResult.push(button);
            }
        });

        result.left = leftResult;
        result.center = centerResult;
        result.right = rightResult;

        console.log('result', result);
        setRrrr(result);
    };

    useEffect(() => {
        handleButtonList();
    }, []);

    return rrrr;
}

export default usePermissionButton;
