import { Route, Routes } from 'react-router-dom';
import { Layout } from './components/exports';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { createContext } from 'react';

import {
	LgsBuyOrderNoRule_Index,
	LgsBuyOrderNoRule_Create,
	LgsBuyOrderNoRule_Details,
	LgsBuyOrder_Index,
	LgsBuyOrder_Details,
	LgsBuyOrderType_Index,
	LgsBuyOrderType_Create,
	LgsBuyOrderType_Details,
	LgsBuyOrderType_Edit,
	LgsBuyOrderType_Log,
	LgsBuyOrder_Create,
	LgsBuyOrder_Edit,
	LgsBuyOrderNoRule_Edit,
	LgsBuyOrderNoRule_Log,
	LgsBuyOrderPayment,
	LgsBuyOrderPaymentReturn,
	LgsBuyOrderPaymentClose,
} from './pages/Logistics/exports';
import LgsBuyOrderGoodsInvoice from './pages/Logistics/LgsBuyOrderGoodsInvoice/LgsBuyOrderGoodsInvoice';
import LgsBuyOrderGoodsInspect from './pages/Logistics/LgsBuyOrderGoodsInspect/LgsBuyOrderGoodsInspect';
import LgsBuyOrderGoodsReturn from './pages/Logistics/LgsBuyOrderGoodsReturn/LgsBuyOrderGoodsReturn';
import LgsBuyOrderInvoice from './pages/Logistics/LgsBuyOrderInvoice/LgsBuyOrderInvoice';
import LgsBuyOrderInvoiceReturn from './pages/Logistics/LgsBuyOrderInvoiceReturn/LgsBuyOrderInvoiceReturn';

export const Context = createContext();

function App() {
	const [cookies, setCookie] = useCookies();
	const lang = cookies['_system_culture']; // IdentityServer.Cookie // /AuthIdentityServer/Auth/Login

	const isLogin = cookies['_system_identityserver'];
	// const isLogin = cookies['IdentityServer.Cookie'];

	// console.log(isLogin);

	const { i18n } = useTranslation(['common']);

	// console.log(isLogin);
	// console.log(lang);
	// 组件内部使用hook
	// const [t, i18n] = useTranslation();

	// 在事件方法中使用i18n.changeLanguage()方法
	const toggleI18n = () => {
		let locale = 'zh';

		if (lang === 'c=zh-CN|uic=zh-CN') {
			locale = 'zh-CN';
		} else if (lang === 'c=zh-TW|uic=zh-TW') {
			locale = 'zh';
		} else if (lang === 'c=en|uic=en') {
			locale = 'en';
		}

		i18n.changeLanguage(locale);
	};

	// alert(isLogin);
	if (!isLogin) {
		location.href = './AuthIdentityServer/Auth/Login';
	}
	// publishReset
	//if (!isLogin) {
	//    location.href = 'AuthIdentityServer/Auth/Login';
	//}

	useEffect(() => {
		toggleI18n();
	}, [lang]);

	return (
		<Context.Provider value={{ lang: lang }}>
			<Layout lang={lang}>
				<Routes>
					{/* // LgsBuyOrderNoRule */}
					<Route path={`/Logistics/LgsBuyOrderNoRule`}>
						<Route path="" element={<LgsBuyOrderNoRule_Index />} />
						<Route
							path="Create"
							element={<LgsBuyOrderNoRule_Create />}
						/>
						<Route
							path="Log/:logGuid"
							element={<LgsBuyOrderNoRule_Log />}
						/>
						<Route path="Log" element={<LgsBuyOrderNoRule_Log />} />
						<Route
							path="Details/:noRuleGuid"
							element={<LgsBuyOrderNoRule_Details />}
						/>
						<Route
							path="Edit/:guid"
							element={<LgsBuyOrderNoRule_Edit />}
						/>
					</Route>

					{/* // LgsBuyOrder */}
					<Route path={`/Logistics/LgsBuyOrder`}>
						<Route path="" element={<LgsBuyOrder_Index />} />
						<Route
							path="Details/:buyOrderGuid"
							element={<LgsBuyOrder_Details />}
						/>
						<Route path="Create" element={<LgsBuyOrder_Create />} />

						<Route
							path="Edit/:guid"
							element={<LgsBuyOrder_Edit />}
						/>
					</Route>

					{/* // LgsBuyOrderType */}
					<Route path={`/Logistics/LgsBuyOrderType`}>
						<Route path="" element={<LgsBuyOrderType_Index />} />
						<Route
							path="Create"
							element={<LgsBuyOrderType_Create />}
						/>

						<Route
							path="Details/:typeGuid"
							element={<LgsBuyOrderType_Details />}
						/>
						<Route
							path="Edit/:guid"
							element={<LgsBuyOrderType_Edit />}
						/>
						<Route
							path="Log/:logGuid"
							element={<LgsBuyOrderType_Log />}
						/>
						<Route path="Log" element={<LgsBuyOrderType_Log />} />
					</Route>

					{/* 採購訂單收貨與收發票 LgsBuyOrderGoodsInvoice */}
					<Route path={`/Logistics/lgsBuyOrderGoodsInvoice`}>
						<Route path="" element={<LgsBuyOrderGoodsInvoice />} />
					</Route>

					{/* // LgsBuyOrderGoodsInspect */}
					<Route path={`/Logistics/LgsBuyOrderGoodsInspect`}>
						<Route path="" element={<LgsBuyOrderGoodsInspect />} />
					</Route>

					{/* 採購訂單付款 lgsBuyOrderPayment */}
					<Route path={`/Logistics/lgsBuyOrderPayment`}>
						<Route path="" element={<LgsBuyOrderPayment />} />
					</Route>
					{/* 採購付款退回 lgsBuyOrderPaymentReturn */}
					<Route path={`/Logistics/lgsBuyOrderPaymentReturn`}>
						<Route path="" element={<LgsBuyOrderPaymentReturn />} />
					</Route>
					{/* 採購訂單付款結清 lgsBuyOrderPaymentClose */}
					<Route path={`/Logistics/lgsBuyOrderPaymentClose`}>
						<Route path="" element={<LgsBuyOrderPaymentClose />} />
					</Route>

					{/* 採購訂單退貨 LgsBuyOrderGoodsReturn */}
					<Route path={`/Logistics/LgsBuyOrderGoodsReturn`}>
						<Route path="" element={<LgsBuyOrderGoodsReturn />} />
					</Route>

					{/* 採購訂單收發票 lgsBuyOrderInvoice */}
					<Route path={`/Logistics/lgsBuyOrderInvoice`}>
						<Route path="" element={<LgsBuyOrderInvoice />} />
					</Route>

					{/* 採購訂單退發票 lgsBuyOrderInvoiceReturn */}
					<Route path={`/Logistics/lgsBuyOrderInvoiceReturn`}>
						<Route path="" element={<LgsBuyOrderInvoiceReturn />} />
					</Route>
				</Routes>
			</Layout>
		</Context.Provider>
	);
}

export default App;
