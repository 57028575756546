import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import useSortableData from '../../../../../../hooks/useSortableData';
import Modal from '../../../../../../components/Modals/IndexTableDisplayModal';
import { useTranslation } from 'react-i18next';

// #region styled-components
const IndexTableContainer = styled.div``;

const IndexTable = styled.table`
	margin-bottom: 0;
	color: var(--grey4);
	background-color: #fff;
`;

const THead = styled.thead`
	height: 40px;
`;

const TR = styled.tr`
	height: 40px;
	&:hover {
		cursor: ${(hoverEffect) =>
			hoverEffect === 'true' ? 'pointer' : 'default'};
		background-color: ${(hoverEffect) =>
			hoverEffect === 'true' ? 'var(--grey0)' : 'var(--bs-table-bg)'};
	}
`;

const TH = styled.th`
	vertical-align: middle;
	border: 1px solid var(--grey1) !important;
	&:first-of-type {
		border-left: none !important;
	}

	&:last-of-type {
		border-right: none !important;
	}
`;

const TBody = styled.tbody``;

const TFoot = styled.tfoot`
	border: none;
`;

const TD = styled.td`
	cursor: auto;
	border: 1px solid var(--grey1) !important;
	&:first-of-type {
		border-left: none !important;
	}

	&:last-of-type {
		border-right: none !important;
	}
`;

const FakeTD = styled.td`
	cursor: auto;
	border: none !important;
`;

const FootTR = styled.tr`
	border-top: 2px solid var(--grey3) !important;
`;

const FootTD = styled.td`
	border: none !important;
`;

const TypeTH = styled.th`
	color: ${(prop) => prop.color};
	border-bottom: 1px solid ${(prop) => prop.color} !important;
	border-top: 0px;
	border-right: 0px;
	border-left: 0px;
`;
//#endregion

function LgsBuyOrder_HistoryOverviewTable(props) {
	// console.log(props);
	const {
		guidName,
		sortBy,
		tableHoverEffect,
		headTitleList,
		bodyTitleList,
		purchaseOrderStatus,
		indexData,
		property,
		orderDate,
		foreignGoodsReceiveAmount,
		foreignInvoiceGrossAmount,
		foreignInvoiceTaxAmount,
		foreignPayableAmount,
		goodsReceiveAmount,
		invoiceGrossAmount,
		invoiceTaxAmount,
		payableAmount,
		totalForeignGoodsReceiveAmount,
		totalForeignInvoiceGrossAmount,
		totalForeignInvoiceTaxAmount,
		totalForeignPayableAmount,
		totalGoodsReceiveAmount,
		totalInvoiceGrossAmount,
		totalInvoiceTaxAmount,
		totalPayableAmount,
		foreignCurrency,
		tableHeadStyles,
		tableBodyStyles,
	} = props;

	const { t } = useTranslation();
	const [orderBy, setOrderBy] = useState('desc');
	const [UISort, setUISort] = useState(sortBy);
	const { items, requestSort, sortConfig } = useSortableData(indexData);
	const navigate = useNavigate();
	const location = useLocation();

	const propertyObj = {
		0: t('lgsBuyOrder:purchaseOrder'),
		1: t('lgsBuyOrder:purchaseReceipt'),
		2: t('lgsBuyOrder:purchaseReturn'),
		3: t('lgsBuyOrder:purchaseReceiptInvoice'),
		4: t('lgsBuyOrder:purchaseReturnInvoice'),
		5: t('lgsBuyOrder:purchasePayment'),
		6: t('lgsBuyOrder:purchasePaymentReturn'),
		7: t('lgsBuyOrder:purchasePaymentSettle'),
	};

	const getClassNamesFor = (name) => {
		if (!sortConfig) {
			return;
		}
		return sortConfig.key === name ? sortConfig.direction : undefined;
	};

	const handleSortChange = (newTarget) => {
		const oldTarget = UISort;
		setUISort(newTarget);

		if (oldTarget === newTarget)
			return setOrderBy(orderBy === 'desc' ? 'asc' : 'desc');

		return setOrderBy('asc');
	};

	const preferredOrder = (indexData, bodyTitleList) => {
		let newObject = {};
		for (let i = 0; i < bodyTitleList.length; i++) {
			if (indexData.hasOwnProperty(bodyTitleList[i])) {
				newObject[bodyTitleList[i]] = indexData[bodyTitleList[i]];
			}
		}
		return newObject;
	};

	preferredOrder(indexData, bodyTitleList);

	return (
		<div className="position-relative">
			<IndexTableContainer className="firstRowMinHeight bg-white d-flex flex-column p-4 position-relative justify-content-betwen">
				{/* <div className="d-flex">
                    {headTitleList?.map((el) => {
                        return (
                            <div
                                className="text-danger border-danger border-bottom"
                                style={
                                    tableHeadStyles[headTitleList.indexOf(el)]
                                }
                            >
                                收發票
                            </div>
                        );
                    })}
                </div> */}
				<IndexTable className="table">
					<THead
						className="table-head"
						items={items}
						requestSort={requestSort}
						sortConfig={sortConfig}
						style={{ backgroundColor: 'white' }}
					>
						<TR className="border-0">
							{foreignCurrency ? (
								<React.Fragment>
									<th
										className={`border-0 px-2 text-center`}
									></th>
									<th
										className={`border-0 px-2 text-center`}
									></th>
									<th
										className={`border-0 px-2 text-center`}
									></th>
									<TypeTH
										className={`px-2 text-center`}
										color={'var(--primary)'}
									>
										{t('lgsBuyOrder:receiveGoods')}
									</TypeTH>
									<TypeTH
										className={`px-2 text-center`}
										colSpan={2}
										color={'#31A952'}
									>
										{t('lgsBuyOrder:receiveInvoice')}
									</TypeTH>
									<TypeTH
										className={`px-2 text-center`}
										colSpan={2}
										color={'#FFA51D'}
									>
										{t('lgsBuyOrder:makePayment')}
									</TypeTH>
								</React.Fragment>
							) : (
								<React.Fragment>
									<th
										className={`border-0 px-2 text-center`}
									></th>
									<th
										className={`border-0 px-2 text-center`}
									></th>
									<th
										className={`border-0 px-2 text-center`}
									></th>
									<TypeTH
										className={`px-2 text-center`}
										color={'var(--primary)'}
									>
										{t('lgsBuyOrder:receiveGoods')}
									</TypeTH>
									<TypeTH
										className={`px-2 text-center`}
										colSpan={2}
										color={'#31A952'}
									>
										{t('lgsBuyOrder:receiveInvoice')}
									</TypeTH>
									<TypeTH
										className={`px-2 text-center`}
										color={'#FFA51D'}
									>
										{t('lgsBuyOrder:makePayment')}
									</TypeTH>
								</React.Fragment>
							)}
						</TR>
					</THead>
					<THead
						className="table-head"
						items={items}
						requestSort={requestSort}
						sortConfig={sortConfig}
					>
						<TR className="">
							{headTitleList?.map((el) => {
								return (
									<TH
										key={`tableHead${headTitleList.indexOf(
											el
										)}`}
										style={
											tableHeadStyles[
												headTitleList.indexOf(el)
											]
										}
										className={`
                                        px-2
                                        border 
                                        text-center ${getClassNamesFor(
											bodyTitleList[
												headTitleList.indexOf(el)
											]
										)}`}
										onClick={() => {
											requestSort(
												bodyTitleList[
													headTitleList.indexOf(el)
												]
											);
											handleSortChange(
												bodyTitleList[
													headTitleList.indexOf(el)
												]
											);
										}}
									>
										{el}
										{bodyTitleList[
											headTitleList.indexOf(el)
										] === UISort &&
											(orderBy === 'desc' ? (
												<span className="ml-1">▼</span>
											) : (
												<span className="ml-1">▲</span>
											))}
									</TH>
								);
							})}
						</TR>
					</THead>
					<TBody>
						{/* 假 TR => 用作 padding 用途 */}
						<tr
							className="border-0"
							id="fakeTr1"
							key="fakeTr1"
							style={{ height: '8px' }}
						>
							{bodyTitleList.map((el) => {
								return (
									<FakeTD
										style={
											tableBodyStyles[
												bodyTitleList.indexOf(el)
											]
										}
										className="p-0 border-0"
										key={`${
											el[guidName]
										}_${bodyTitleList.indexOf(el)}`}
									></FakeTD>
								);
							})}
						</tr>
						<TR
							className=""
							id="purchaseOrderStatus"
							key="purchaseOrderStatus"
							hoverEffect={tableHoverEffect}
							style={{ backgroundColor: '#EEF4FD' }}
						>
							{bodyTitleList.map((el) => {
								console.log(el);
								console.log(bodyTitleList);
								if (el === 'property') {
									const propertyString = propertyObj[0];
									return (
										<TD
											style={
												tableBodyStyles[
													bodyTitleList.indexOf(el)
												]
											}
											className="p-2 border"
											key={`${
												el[guidName]
											}_${bodyTitleList.indexOf(el)}`}
										>
											<div>{propertyString}</div>
										</TD>
									);
								}
								return (
									<TD
										style={
											tableBodyStyles[
												bodyTitleList.indexOf(el)
											]
										}
										className={`p-2 border 
                                        ${props[el] < 0 && 'text-danger'}`}
										key={`${
											el[guidName]
										}_${bodyTitleList.indexOf(el)}`}
									>
										<div>{props[el]}</div>
									</TD>
								);
							})}
						</TR>

						{/* 假 TR => 用作 padding 用途 */}
						<tr
							className="border-0"
							id="fakeTr2"
							key="fakeTr2"
							style={{ height: '8px' }}
						>
							{bodyTitleList.map((el) => {
								return (
									<FakeTD
										style={
											tableBodyStyles[
												bodyTitleList.indexOf(el)
											]
										}
										className="p-0 border-0"
										key={`${
											el[guidName]
										}_${bodyTitleList.indexOf(el)}`}
									></FakeTD>
								);
							})}
						</tr>
						{items?.map((data) => {
							return (
								<TR
									id={data[guidName]}
									key={data[guidName]}
									hoverEffect={tableHoverEffect}
								>
									{bodyTitleList.map((el) => {
										if (el === 'property') {
											const propertyString =
												propertyObj[data[el]];
											return (
												<TD
													style={
														tableBodyStyles[
															bodyTitleList.indexOf(
																el
															)
														]
													}
													className={`p-2 border `}
													key={`${el}${data[guidName]}`}
												>
													{propertyString}
												</TD>
											);
										}
										return (
											<TD
												style={
													tableBodyStyles[
														bodyTitleList.indexOf(
															el
														)
													]
												}
												className={`p-2 border ${
													data[el] < 0 &&
													'text-danger'
												}`}
												key={`${el}${data[guidName]}`}
											>
												{data[el]}
											</TD>
										);
									})}
								</TR>
							);
						})}
					</TBody>
					<TFoot>
						{foreignCurrency ? (
							<FootTR
								id="totalAmount"
								key="totalAmount"
								hoverEffect={tableHoverEffect}
								style={{ fontWeight: 'bold' }}
							>
								<FootTD className="p-2 text-right"></FootTD>
								<FootTD className="p-2 text-right"></FootTD>
								<FootTD className="p-2 text-right fw-bold">
									{t('lgsBuyOrder:balance')}
								</FootTD>
								<FootTD
									className={`p-2 text-right fw-bold ${
										totalForeignGoodsReceiveAmount < 0 &&
										'text-danger'
									}`}
								>
									{totalForeignGoodsReceiveAmount}
								</FootTD>
								<FootTD
									className={`p-2 text-right fw-bold ${
										totalForeignInvoiceTaxAmount < 0 &&
										'text-danger'
									}`}
								>
									{totalForeignInvoiceTaxAmount}
								</FootTD>
								<FootTD
									className={`p-2 text-right fw-bold ${
										totalForeignInvoiceGrossAmount < 0 &&
										'text-danger'
									}`}
								>
									{totalForeignInvoiceGrossAmount}
								</FootTD>
								<FootTD
									className={`p-2 text-right fw-bold ${
										totalForeignPayableAmount < 0 &&
										'text-danger'
									}`}
								>
									{totalForeignPayableAmount}
								</FootTD>
								<FootTD
									className={`p-2 text-right fw-bold ${
										totalPayableAmount < 0 && 'text-danger'
									}`}
								>
									{totalPayableAmount}
								</FootTD>
							</FootTR>
						) : (
							<FootTR
								id="totalAmount"
								key="totalAmount"
								hoverEffect={tableHoverEffect}
								style={{ fontWeight: 'bold' }}
							>
								<FootTD className="p-2 text-right"></FootTD>
								<FootTD className="p-2 text-right"></FootTD>
								<FootTD className="p-2 text-right fw-bold">
									{t('lgsBuyOrder:balance')}
								</FootTD>
								<FootTD
									className={`p-2 text-right fw-bold ${
										totalGoodsReceiveAmount < 0 &&
										'text-danger'
									}`}
								>
									{totalGoodsReceiveAmount}
								</FootTD>
								<FootTD
									className={`p-2 text-right fw-bold ${
										totalInvoiceTaxAmount < 0 &&
										'text-danger'
									}`}
								>
									{totalInvoiceTaxAmount}
								</FootTD>
								<FootTD
									className={`p-2 text-right fw-bold ${
										totalInvoiceGrossAmount < 0 &&
										'text-danger'
									}`}
								>
									{totalInvoiceGrossAmount}
								</FootTD>
								<FootTD
									className={`p-2 text-right fw-bold ${
										totalPayableAmount < 0 && 'text-danger'
									}`}
								>
									{totalPayableAmount}
								</FootTD>
							</FootTR>
						)}
					</TFoot>
				</IndexTable>
			</IndexTableContainer>
			<div>{<Modal isHovered={false} />}</div>
		</div>
	);
}

export default LgsBuyOrder_HistoryOverviewTable;
