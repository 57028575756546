import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useCookies } from 'react-cookie';
import { ReactComponent as Icn_InputYellowCancel } from '../../../../../assets/images/icn_InputYellowCancel.svg';
import { ReactComponent as Icn_InputDialog } from '../../../../../assets/images/icn_inputDialog.svg';
import ThreeColLayout from '../../../../../components/ThreeColLayout/ThreeColLayout';
import { DialogCol } from '../DialogCol';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { DialogWarning } from '../DialogWarning';

//#region styled-components

const SelectElement = styled.select`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
`;

const TopTable = styled.table`
	font-size: 12px;
	color: #616e83;
	background-color: #f7f8fc;
	width: 100% !important;
	height: 128px !important;
	border: 1px solid var(--grey1) !important;
	border-collapse: separate;
	border-spacing: 0;
	border-radius: 10px;
`;

const TBody = styled.tbody``;

const TR = styled.tr`
	height: 32px;
	border: 2px solid var(--grey1) !important;
`;
const TH1 = styled.th`
	padding: 0px;
	height: 32px;
	width: 23% !important;
	text-align: center !important;
`;
const TH = styled.th`
	padding: 0px;
	height: 32px;
	width: 38.5% !important;
	text-align: center !important;
`;
const TD1 = styled.td`
	padding: 0px;
	height: 32px;
	width: 23% !important;
	border: 1px solid var(--grey1) !important;
	text-align: center !important;
`;
const TD = styled.td`
	padding-right: 8px;
	height: 32px;
	width: 38.5% !important;
	border: 1px solid var(--grey1) !important;

	text-align: right !important;
`;

const LabelNormal = styled.label`
	font-size: 12px;
	color: var(--grey4);
	margin-bottom: 6px;
`;

const InputElement = styled.input`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
	&:disabled {
		background-color: #eff2f5;
	}
`;

const InputElement2 = styled.input`
	border: 1px solid var(--grey2);
	border-right: none;
	border-radius: 4px;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
	&:disabled {
		background-color: #eff2f5;
	}
`;

const TextareaElement = styled.textarea`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 8px;
	min-height: 88px;
	resize: none;
	&:focus-visible {
		outline: 0;
	}
`;

const DialogWrapper = styled.div`
	position: relative;
	align-items: stretch;
	width: 100%;
	--bs-gutter-x: none;
`;

const InputDialogElementData = styled.input`
	display: none;
`; // input實際資料欄位

const InputDialogElementView = styled.input`
	flex: 1 1 auto;
	border: 1px solid var(--grey2);
	border-radius: 4px 0 0 4px;
	border-right: 0;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
	pointer-events: none;
	background-color: transparent;
`; // input顯示欄位

const CancelInputBtn = styled.span`
	display: inline-block;
	position: absolute;
	height: 100%;
	top: 3px;
	right: 32px;
	width: 24px;
	text-align: center;
	display: ${(props) => (props.isValueExist ? 'inline' : 'none')};
	cursor: pointer;
`; // 取消按鈕

const DialogBtn = styled.div`
	width: 40px;
	display: flex;
	align-items: center;
	border: 1px solid var(--grey2);
	border-radius: 0 4px 4px 0;
	border-left: 0;
	padding-left: 16px;
	padding-right: 8px;
	cursor: pointer;
`; // dialog按鈕

//#endregion

const BottomInfo = ({
	pageData,
	setPageData,
	CurrencyCode,
	CurrencyName,
	DecimalPlaceTl,
	t,
}) => {
	const { employeeList, bankingAccountList, buyOrder } = pageData;

	const handleEmployeeChange = (val) => {
		const { fullname } = employeeList.find((el) => el.employeeGuid === val);

		console.log(fullname);

		setPageData({
			...pageData,
			payment: {
				...pageData.payment,
				employeeGuid: val,
				employeeName: fullname,
			},
		});
	};

	const handleBankingAccountChange = (val) => {
		const { accountNo, name01, bankingAccountName } =
			bankingAccountList.find((el) => el.bankAccountGuid === val);

		setPageData({
			...pageData,

			payment: {
				...pageData.payment,
				bankAccountGuid: val,
				bankingAccountName: bankingAccountName,
				accountName: name01,
				accountNo: accountNo,
			},
		});
	};

	const handlePostingDateChange = (val) => {
		setPageData({
			...pageData,
			payment: { ...pageData.payment, postingDate: val },
		});
	};

	const handleGrossAmountChange = (val) => {
		setPageData({
			...pageData,
			payment: { ...pageData.payment, grossAmount: val },
		});
	};

	const handleForeignGrossAmountChange = (val) => {
		setPageData({
			...pageData,
			payment: {
				...pageData.payment,
				foreignGrossAmount: val,
				grossAmount: (val * pageData?.payment?.exchageRate).toFixed(
					DecimalPlaceTl
				),
				grossAmountShouldPay: (
					val * pageData?.buyOrder?.exchageRate
				).toFixed(DecimalPlaceTl),
				exchangeDifferenceAmount: (
					val * pageData?.payment?.exchageRate -
					val * pageData?.buyOrder?.exchageRate
				).toFixed(DecimalPlaceTl),
			},
		});
	};

	const handleNoteChange = (val) => {
		setPageData({
			...pageData,
			payment: { ...pageData.payment, note: val },
		});
	};

	const handleExChangeRateChange = (val) => {
		setPageData({
			...pageData,
			payment: {
				...pageData.payment,

				exchageRate: val,

				grossAmount: (
					val * pageData?.payment?.foreignGrossAmount
				).toFixed(DecimalPlaceTl),

				grossAmountShouldPay: (
					pageData?.buyOrder?.exchageRate *
					pageData?.payment?.foreignGrossAmount
				).toFixed(DecimalPlaceTl),

				exchangeDifferenceAmount: (
					val * pageData?.payment?.foreignGrossAmount -
					pageData?.buyOrder?.exchageRate *
						pageData?.payment?.foreignGrossAmount
				).toFixed(DecimalPlaceTl),
			},
		});
	};

	/**
	 * DialogWarning 返回btn確認dialog
	 **/
	const [isWarningOpen, setIsWarningOpen] = useState(false);
	const [warningMessage, setWarningMessage] = useState('');

	// DialogWarning Modal open
	const handleDialogWarning = () => {
		setIsWarningOpen(true);
	};

	// 提醒若無任何資金帳戶
	useEffect(() => {
		console.log(Array.isArray(bankingAccountList));
		console.log(bankingAccountList?.length);
		if (
			Array.isArray(bankingAccountList) &&
			bankingAccountList?.length === 0
		) {
			setWarningMessage('目前無任何資金帳戶，請先新增');
			handleDialogWarning();
		}
	}, [bankingAccountList]);

	useEffect(() => {
		pageData?.payment?.bankAccountGuid &&
			handleBankingAccountChange(pageData?.payment?.bankAccountGuid);
	}, [pageData.payment?.bankAccountGuid]);

	console.log(pageData);
	return (
		<>
			{/* DialogWarning element */}
			<DialogWarning
				isWarningOpen={isWarningOpen}
				setIsWarningOpen={setIsWarningOpen}
				warningMessage={warningMessage}
			/>

			<div className="row">
				<div className="col-12">
					<div className="row">
						<div className="col-lg-4 px-4 pr-xl-6">
							<div className="wrapper d-flex flex-column mb-3">
								<LabelNormal>
									{t(
										'lgsBuyOrderPayment:page1.bottom.postingDate'
									)}
								</LabelNormal>
								<InputElement
									type="date"
									id="postingDate"
									value={pageData?.payment?.postingDate || ''}
									onChange={(e) =>
										handlePostingDateChange(e.target.value)
									}
								/>
								<span className="text-danger ms-1 mt-1">
									{pageData?.payment?.postingDateWarning}
								</span>
							</div>
							<div className="wrapper d-flex flex-column mb-3">
								<LabelNormal>
									{t('lgsBuyOrderPayment:page1.bottom.staff')}
								</LabelNormal>
								<SelectElement
									id="staff"
									value={
										pageData?.payment?.employeeGuid || ''
									}
									onChange={(e) =>
										handleEmployeeChange(e.target.value)
									}
								>
									{employeeList?.map((el) => (
										<option
											key={el.employeeGuid}
											id={el.employeeGuid}
											value={el.employeeGuid}
										>
											{el.fullname}
										</option>
									))}
								</SelectElement>
								<span className="text-danger ms-1 mt-1">
									{pageData?.payment?.staffWarning}
								</span>
							</div>
							<div className="wrapper d-flex flex-column mb-3">
								<LabelNormal>
									{t(
										'lgsBuyOrderPayment:page1.bottom.bankingAccount'
									)}
								</LabelNormal>
								<SelectElement
									id="bankingAccount"
									value={
										pageData?.payment?.bankAccountGuid || ''
									}
									onChange={(e) =>
										handleBankingAccountChange(
											e.target.value
										)
									}
								>
									{bankingAccountList?.map((el) => (
										<option
											key={el.bankAccountGuid}
											id={el.bankAccountGuid}
											value={el.bankAccountGuid}
										>
											{el.bankingAccountName}
										</option>
									))}
								</SelectElement>
								<span className="text-danger ms-1 mt-1">
									{pageData?.payment?.bankAccountGuidWarning}
								</span>
							</div>
							<div className="wrapper d-flex flex-column mb-3">
								<LabelNormal>
									{t(
										'lgsBuyOrderPayment:page1.bottom.account'
									)}
								</LabelNormal>
								<div className="input-group">
									<InputElement2
										className="form-control border-grey2"
										placeholder=""
										style={{
											backgroundColor: 'var(--grey1)',
										}}
										disabled={true}
										value={`${
											pageData?.payment?.accountNo
												? pageData.payment.accountNo +
												  pageData.payment.accountName
												: ''
										}`}
										onChange={() => {}}
										type="text"
										id="account"
										name="account"
									/>

									<DialogBtn
										style={{
											backgroundColor: '#EFF2F5',
											cursor: 'auto',
										}}
									>
										<Icn_InputDialog />
									</DialogBtn>
								</div>
								<span className="text-danger ms-1 mt-1">
									{/* {data.vendorNameWarning} */}
								</span>
							</div>
						</div>
						<div className="col-lg-4 px-4 px-xl-5">
							<div className="row">
								<div className="wrapper d-flex flex-column mb-3 col">
									<LabelNormal>
										{t(
											'lgsBuyOrderPayment:page1.bottom.postingCurrency'
										)}
									</LabelNormal>
									<div className="input-group">
										<InputElement2
											className="form-control border-grey2"
											placeholder=""
											style={{
												backgroundColor: 'var(--grey1)',
											}}
											disabled={true}
											value={
												buyOrder?.currencyCode
													? `${buyOrder?.currencyCode} ${buyOrder?.currencyName}`
													: ''
											}
											onChange={() => {}}
											type="text"
											id="postingCurrency"
											name="postingCurrency"
										/>

										<DialogBtn
											style={{
												backgroundColor: '#EFF2F5',
												cursor: 'auto',
											}}
										>
											<Icn_InputDialog />
										</DialogBtn>
									</div>
									<span className="text-danger ms-1 mt-1">
										{/* {data.vendorNameWarning} */}
									</span>
								</div>
								{buyOrder.foreignCurrency ? (
									<div className="d-flex flex-column col-5 ps-0 mb-3">
										<LabelNormal>
											{t(
												'lgsBuyOrderPayment:page1.bottom.paymentExchangeRate'
											)}
										</LabelNormal>
										<InputElement
											type="number"
											id="exchageRate"
											value={
												pageData?.payment
													?.exchageRate || ''
											}
											min="1"
											onChange={(e) =>
												handleExChangeRateChange(
													e.target.value
												)
											}
										/>
										<span className="text-danger ms-1 mt-1">
											{
												pageData?.payment
													?.exchageRateWarning
											}
										</span>
									</div>
								) : null}
							</div>
							{buyOrder.foreignCurrency ? (
								<div className="d-flex flex-column mb-3">
									<LabelNormal>
										{t(
											'lgsBuyOrderPayment:page1.bottom.grossAmount'
										)}
									</LabelNormal>
									<InputElement
										type="number"
										id="foreignGrossAmount
                                        "
										value={
											pageData?.payment
												?.foreignGrossAmount || ''
										}
										min="1"
										onChange={(e) =>
											handleForeignGrossAmountChange(
												e.target.value
											)
										}
									/>
									<span className="text-danger ms-1 mt-1">
										{
											pageData?.payment
												?.foreignGrossAmountWarning
										}
									</span>
								</div>
							) : (
								<div className="d-flex flex-column mb-3">
									<LabelNormal>
										{t(
											'lgsBuyOrderPayment:page1.bottom.grossAmount'
										)}
									</LabelNormal>
									<InputElement
										type="number"
										id="grossAmount"
										className=""
										value={
											pageData?.payment?.grossAmount || ''
										}
										min="1"
										onChange={(e) =>
											handleGrossAmountChange(
												e.target.value
											)
										}
									/>
									<span className="text-danger ms-1 mt-1">
										{pageData?.payment?.grossAmountWarning}
									</span>
								</div>
							)}
							{buyOrder.foreignCurrency ? (
								<>
									<div className="d-flex flex-column">
										<LabelNormal>
											{t(
												'lgsBuyOrderPayment:page1.bottom.localCurrencyPaymentAmount'
											)}
										</LabelNormal>
										<InputElement
											type="number"
											id="grossAmountActualPay"
											className="mb-3"
											value={
												pageData?.payment
													?.grossAmount || ''
											}
											min="0"
											onChange={(e) =>
												handleGrossAmountChange(
													e.target.value
												)
											}
											disabled
										/>
									</div>
									<div className="d-flex flex-column">
										<LabelNormal>
											{t(
												'lgsBuyOrderPayment:page1.bottom.localCurrencyPayableAmount'
											)}
										</LabelNormal>
										<InputElement
											type="number"
											id="grossAmountShouldPay"
											className="mb-3"
											value={
												pageData?.payment
													?.grossAmountShouldPay || ''
											}
											min="1"
											onChange={(e) =>
												handleGrossAmountChange(
													e.target.value
												)
											}
											disabled
										/>
									</div>
									<div className="d-flex flex-column">
										<LabelNormal>
											{t(
												'lgsBuyOrderPayment:page1.bottom.exchangeRateDifferenceAmount'
											)}
										</LabelNormal>
										<InputElement
											type="number"
											id="exchangeDifferenceAmount"
											className="mb-3"
											value={
												pageData?.payment
													?.exchangeDifferenceAmount ||
												0
											}
											min="1"
											onChange={(e) =>
												handleGrossAmountChange(
													e.target.value
												)
											}
											disabled
										/>
									</div>
								</>
							) : null}
						</div>
						<div className="col-lg-4 px-4 pl-xl-6">
							<div className="wrapper d-flex flex-column mb-3">
								<LabelNormal>
									{t('lgsBuyOrderPayment:page1.bottom.note')}
								</LabelNormal>
								<TextareaElement
									id="note"
									rows="4"
									cols="30"
									value={pageData?.payment?.note || ''}
									onChange={(e) =>
										handleNoteChange(e.target.value)
									}
									disabled={false}
									disabledCssType={0}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default BottomInfo;
