import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import useAxios from '../../../../hooks/useAxios';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	useLocation,
	useParams,
	useNavigate,
} from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
	PageTitleBar,
	TabPage,
	LgsBuyOrder_TopCommonInfo,
	LgsBuyOrder_BaseInfo,
	LgsBuyOrder_DetailAndGoods,
	LgsBuyOrder_PaymentTerm,
	LgsBuyOrder_GoodsHistory,
	LgsBuyOrder_InvoiceHistory,
	LgsBuyOrder_PaymentHistory,
	LgsBuyOrder_HistoryOverview,
	LgsBuyOrder_Appendix,
} from '../exports';

import { CopyCreateBtn } from '../../../../components/Buttons/CopyCreateBtn';
import { EditBtnNormal } from '../../../../components/Buttons/EditBtnNormal';

import StatusBar from '../../../../components/StatusBar/StatusBar';
import { useTranslation } from 'react-i18next';

// #region styled-components
const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div`
	display: flex;
`;

const Right = styled.div`
	display: flex;
`;

//#endregion

function LgsBuyOrder_Details() {
	const { t } = useTranslation();

	// react-hook-form
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		getValues,
	} = useForm({
		// 何時驗證
		mode: 'onSubmit',
		// submit後，何時驗證
		reValidateMode: 'onBlur',
		// 初始值定義
		//defaultValues: {
		//    note: 'coolMan',
		//    dateFormate: '6',
		//},
	});

	let { buyOrderGuid } = useParams();
	const location = useLocation();
	const navigate = useNavigate();

	const { isLoading, error, sendRequest: axiosDetailData } = useAxios();
	const {
		isCopyBuyOrderLoading,
		copyBuyOrderError,
		sendRequest: axiosCopyBuyOrder,
	} = useAxios();

	if (error) {
		console.log('error !', error);
	}

	const [view, setView] = useState('details');
	const [detailsInfo, setDetailsInfo] = useState([]);

	const pageTitle = `${t('lgsBuyOrder:purchaseOrder')} ${
		detailsInfo.orderNo ? detailsInfo.orderNo : ''
	}`;

	const handleCopyBuyOrder = () => {
		// console.log(buyOrderGuid);
		// console.log(data);
		// let data;
		// const url = `api/Logistics/LgsBuyOrder/copyBuyOrder?guid=${buyOrderGuid}`;
		// axiosCopyBuyOrder({ url }, (res) => {
		//     data = res;
		// });
		// console.log(data);
		// if (data) {
		//     useNavigate('/create', {
		//         state: {
		//             copyBuyOrderData: data,
		//         },
		//     });
		// }

		axios(`api/Logistics/LgsBuyOrder/copyBuyOrder?guid=${buyOrderGuid}`)
			.then((response) => {
				// console.log(response.data);
				navigate('/Logistics/LgsBuyOrder/Create', {
					state: {
						data: response.data,
					},
				});
			})
			.catch((error) => console.log(error));
	};

	useEffect(() => {
		const url = `api/Logistics/LgsBuyOrder/buyOrder?guid=${buyOrderGuid}`;
		axiosDetailData({ url }, (res) => {
			setDetailsInfo(res.data);
		});
	}, []);

	// 分頁title
	const tabNavList = [
		t('lgsBuyOrder:basicInformation'),
		t('lgsBuyOrder:detailsAndReceipt'),
		t('lgsBuyOrder:paymentTerms'),
		t('lgsBuyOrder:attachments'),
		t('lgsBuyOrder:overviewHistory'),
		t('lgsBuyOrder:receiptHistory'),
		t('lgsBuyOrder:invoiceHistory'),
		t('lgsBuyOrder:paymentHistory'),
	];

	// 分頁內容 component
	const tabContent = [
		<LgsBuyOrder_BaseInfo
			register={register}
			errors={errors}
			setValue={setValue}
			getValues={getValues}
			detailsInfo={detailsInfo}
		/>,
		<LgsBuyOrder_DetailAndGoods
			register={register}
			errors={errors}
			setValue={setValue}
			getValues={getValues}
			detailsInfo={detailsInfo}
		/>,
		<LgsBuyOrder_PaymentTerm
			register={register}
			errors={errors}
			setValue={setValue}
			getValues={getValues}
			detailsInfo={detailsInfo}
		/>,
		<LgsBuyOrder_Appendix
			register={register}
			errors={errors}
			setValue={setValue}
			getValues={getValues}
			detailsInfo={detailsInfo}
		/>,
		<LgsBuyOrder_HistoryOverview
			register={register}
			errors={errors}
			setValue={setValue}
			getValues={getValues}
			detailsInfo={detailsInfo}
		/>,
		<LgsBuyOrder_GoodsHistory
			register={register}
			errors={errors}
			setValue={setValue}
			getValues={getValues}
			detailsInfo={detailsInfo}
		/>,
		<LgsBuyOrder_InvoiceHistory
			register={register}
			errors={errors}
			setValue={setValue}
			getValues={getValues}
			detailsInfo={detailsInfo}
		/>,
		<LgsBuyOrder_PaymentHistory
			register={register}
			errors={errors}
			setValue={setValue}
			getValues={getValues}
			detailsInfo={detailsInfo}
		/>,
	];

	return (
		<div id="detailsView">
			<PageTitleBar
				pageTitle={pageTitle}
				previousPageButton={true}
				backTo={-1}
				isDialogConfirm={false}
			/>
			<Container>
				<Left>
					<EditBtnNormal
						permission={[]}
						buyOrderGuid={buyOrderGuid}
					/>
					<CopyCreateBtn handleClick={handleCopyBuyOrder} />
				</Left>
				<Center></Center>
				<Right></Right>
			</Container>
			<form>
				{detailsInfo && (
					<>
						<StatusBar status={detailsInfo?.status} />
						<LgsBuyOrder_TopCommonInfo
							register={register}
							errors={errors}
							setValue={setValue}
							getValues={getValues}
							detailsInfo={detailsInfo}
						/>
						<TabPage
							tabNavList={tabNavList}
							tabContent={tabContent}
							defaultPage={0}
						/>
					</>
				)}
			</form>
		</div>
	);
}

export default LgsBuyOrder_Details;
