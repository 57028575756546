import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// #region styled-components
const IndexTableContainer = styled.div``;

const IndexTable = styled.table`
	margin-bottom: 0;
	color: var(--grey4);
	background-color: #fff;
`;

const THead = styled.thead`
	height: 40px;
`;

const TR = styled.tr`
	height: 40px;
	&:hover {
		cursor: ${(hoverEffect) =>
			hoverEffect === 'true' ? 'pointer' : 'default'};
		background-color: ${(hoverEffect) =>
			hoverEffect === 'true' ? 'var(--grey0)' : 'var(--bs-table-bg)'};
	}
`;

const TH = styled.th`
	vertical-align: middle;
	border: 1px solid var(--grey1) !important;
	&:first-of-type {
		border-left: none !important;
	}

	&:last-of-type {
		border-right: none !important;
	}
`;

const TBody = styled.tbody``;

const TD = styled.td`
	cursor: auto;
	border: 1px solid var(--grey1) !important;
	&:first-of-type {
		border-left: none !important;
	}

	&:last-of-type {
		border-right: none !important;
	}

	background-color: ${(prop) =>
		prop.active ? '#fbfcfd !important' : '#fff'};

	position: relative;
	padding: 7px 8px !important;
	max-height: 40px;
`;

const TFoot = styled.tfoot`
	border: none;
`;

const FootTR = styled.tr`
	border-top: 2px solid var(--grey3) !important;
`;

const FootTD = styled.td`
	border: none !important;
`;

const Input = styled.input`
	padding-inline: 8px;
	border: ${(prop) => (prop.active ? '1px solid #e3e8ee' : 'none')};
	border-radius: 4px;

	/* 去除 type="number" 上下按鈕 */
	::-webkit-outer-spin-button,
	::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	::placeholder {
		color: var(--grey3);
	}

	&:disabled {
		border: none;
		background-color: transparent;
	}
`;

//#endregion

function InvoiceSplitTable(props) {
	//#region state initiation

	const { t } = useTranslation(['common', 'lgsBuyOrderInvoice']); // i18n

	const {
		pageData,
		setPageData,
		parentData,
		headTitleList,
		bodyTitleList,
		tableHeadStyles,
		tableBodyStyles,
		tableBodyData,
		setTableBodyData,
		inputProperties,
		isMultiSelected,
		calAllInvoice,
	} = props;
	// console.log(props);

	console.log('tableBodyData', tableBodyData);

	const decimalPlaceTl =
		parentData.page0.buyOrderSelectedList[0].decimalPlaceTL;

	//#endregion

	//#region function

	// console.log('pageData', pageData);

	// input改變時
	const handleInputChange = (value, rowIndex, inputIndex, toFloat) => {
		// console.log(value);
		const newData = [...tableBodyData];

		if (toFloat) {
			// 如果此input有decimalPlace限制
			// const regex = new RegExp(`^(\\d+(\\.\\d{0,${decimalPlace}})?|)$`); // 只允許數字，並且小數點後最多decimalPlace位
			// if (regex.test(value))
			// 	newData[rowIndex][bodyTitleList[inputIndex]] = value;
			// setTableBodyData(newData);
		} else {
			newData[rowIndex][bodyTitleList[inputIndex]] = value;
			setTableBodyData(newData);

			const newPageData = { ...pageData };
			newPageData.invoiceSplitList = [...newData];
			setPageData({ ...newPageData });
		}
	};

	const handleInputBlur = (value, rowIndex, inputIndex, inputName) => {
		const newData = [...tableBodyData];

		// 計算總額 newnew
		calAllInvoice();
		newData[rowIndex]['totalCollectedInvoices'] =
			Number(value).toFixed(decimalPlaceTl);
		setTableBodyData(newData);

		const newPageData = { ...pageData };
		newPageData.invoiceSplitList = [...newData];
		console.log(newPageData);
		console.log(pageData);
		// setPageData({ ...newPageData });

		// old
		if (inputName === 'quantityReceive') {
			const quantityUnreceived = newData[rowIndex]['quantityUnreceived'];

			// 收貨數量 不大於 未收數量
			if (Number(value) > Number(quantityUnreceived)) {
				newData[rowIndex]['quantityReceive'] = quantityUnreceived;
				setTableBodyData(newData);
			} else if (Number(value) < 0) {
				// 未收數量不為負數
				newData[rowIndex]['quantityReceive'] = 0;
				setTableBodyData(newData);
			}

			const newPageData = { ...pageData };
			newPageData.buyOrderItemList = [...newData];
			setPageData({ ...newPageData });
		}

		// 手動批次編號，判斷是否滿碼
		if (inputName === 'batchNo' && newData[rowIndex].batchNoAuto === '0') {
			const { batchNumberingFull, batchNumberingLength } =
				newData[rowIndex];
			const inputValueLength = value.length;

			// console.log(
			// 	batchNumberingFull,
			// 	batchNumberingLength,
			// 	inputValueLength
			// );

			if (
				batchNumberingFull &&
				inputValueLength !== batchNumberingLength
			) {
				newData[rowIndex][inputName] = '';
			} else {
				newData[rowIndex][`${inputName}Warning`] = '';
			}

			setTableBodyData(newData);

			const newPageData = { ...pageData };
			newPageData.buyOrderItemList = [...newData];
			setPageData({ ...newPageData });
		}
	};

	//#endregion

	return (
		<div className="position-relative">
			<h6 className="fw-bold fontSize75Rem text-end mt-2 mb-3 ms-auto me-3">
				{t('lgsBuyOrderInvoice:currency')}：
				{parentData.page0.buyOrderSelectedList[0].currencyCode +
					' ' +
					parentData.page0.buyOrderSelectedList[0].currencyName}
			</h6>

			<IndexTableContainer className="bg-white d-flex flex-column position-relative">
				<IndexTable className="table">
					<THead className="table-head">
						<TR>
							{headTitleList?.map((el) => (
								<TH
									key={`tableHead${headTitleList?.indexOf(
										el
									)}`}
									style={
										tableHeadStyles[
											headTitleList?.indexOf(el)
										]
									}
								>
									{el}
								</TH>
							))}
						</TR>
					</THead>
					<TBody className="">
						{tableBodyData?.map((data, rowIndex) => (
							<TR key={`InvoiceSplitTableRow${rowIndex}`}>
								{bodyTitleList?.map((el, inputIndex) => {
									// 不可編輯欄位(不為input)
									if (
										[
											'displayOrder',
											'vendorCode',
											'vendor',
											'orderDate',
											'orderNo',
											'invoiceNo',
											'purchaseTaxableTotal',
											'totalUncollectedInvoices',
										].includes(el)
									)
										return (
											<TD
												style={
													tableBodyStyles[
														bodyTitleList?.indexOf(
															el
														)
													]
												}
												className={`border`}
												active={data.active}
												key={`${bodyTitleList?.indexOf(
													el
												)}`}
											>
												<div className="px-2">
													{data[el]}
												</div>
											</TD>
										);

									// 收發票總額
									if (el === 'totalCollectedInvoices') {
										return (
											<TD
												style={
													tableBodyStyles[
														bodyTitleList?.indexOf(
															el
														)
													]
												}
												className={`border`}
												active={true}
												key={`${bodyTitleList?.indexOf(
													el
												)}`}
											>
												<Input
													style={{
														minWidth: '24px',
														width: '100%',
														outline: 'none',
													}}
													className={`${'text-end'}`}
													value={data[el] || ''}
													active={true}
													disabled={!isMultiSelected}
													type={
														inputProperties[
															inputIndex
														]?.type
													}
													onChange={(e) => {
														handleInputChange(
															e.target.value,
															rowIndex,
															inputIndex
														);
													}}
													onBlur={(e) =>
														handleInputBlur(
															e.target.value,
															rowIndex,
															inputIndex,
															el
														)
													}
												/>
											</TD>
										);
									}

									return (
										<TD
											style={
												tableBodyStyles[
													bodyTitleList?.indexOf(el)
												]
											}
											className={`border`}
											active={data.active}
											key={`${bodyTitleList?.indexOf(
												el
											)}`}
										>
											<Input
												style={{
													minWidth: '24px',
													width: '100%',
													outline: 'none',
												}}
												className={``}
												value={data[el] || ''}
												active={data.active}
												type={
													inputProperties[inputIndex]
														?.type
												}
												onChange={(e) => {
													handleInputChange(
														e.target.value,
														rowIndex,
														inputIndex
													);
												}}
											/>
										</TD>
									);
								})}
							</TR>
						))}
					</TBody>

					<TFoot>
						<FootTR
							id="total"
							key="total"
							hoverEffect={false}
							style={{ fontWeight: 'bold' }}
						>
							<FootTD className="p-2 text-end"></FootTD>
							<FootTD className="p-2 text-end"></FootTD>
							<FootTD className="p-2 text-end">
								{t('lgsBuyOrderInvoice:total')}
							</FootTD>
							<FootTD className="p-2 text-end"></FootTD>
							<FootTD className="p-2 text-end"></FootTD>
							<FootTD className="p-2 text-end"></FootTD>
							<FootTD className="p-2 text-end"></FootTD>
							<FootTD className="p-2 text-end"></FootTD>
							<FootTD className="py-2 px-3 text-end">
								{pageData.allAmount
									? pageData.allAmount.allCollectedInvoices
									: 0}
							</FootTD>
						</FootTR>
					</TFoot>
				</IndexTable>
			</IndexTableContainer>
		</div>
	);
}

export default InvoiceSplitTable;
