import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Icn_Cancel } from '../../../assets/images/icn_Cancel.svg';

// #region styled-components
const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    width: 100%;
    height: 100%;
`;

const ModalDialog = styled.div`
    max-width: 440px !important;
    min-height: calc(100% - 10.5rem);
`;

// #endregion

export const SubmitConfirm = ({
    t,
    isOpen,
    setIsOpen,
    handleClickInactive,
    handleClickActive,
    message,
}) => {
    const navigator = useNavigate();

    /**
     * Modal cancel close
     **/
    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <>
            {isOpen && (
                <ModalWrapper onClick={handleClose}>
                    <ModalDialog
                        className="modal-dialog d-flex align-items-center"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div
                            className="modal-content border-0"
                            style={{
                                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                                borderRadius: '12px',
                            }}
                        >
                            <div className="modal-header border-0 px-4 pt-5 pb-4">
                                <h5 className="w-100 text-center text-primary fw-bold fontSize1Rem mb-0">
                                    {message}
                                </h5>
                                <button
                                    style={{ top: '12px', right: '12px' }}
                                    type="button"
                                    className="btn close position-absolute "
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={handleClose}
                                >
                                    <Icn_Cancel />
                                </button>
                            </div>
                            <div className="modal-footer justify-content-center border-0 py-4">
                                <button
                                    type="button"
                                    className="btn btn-outline-primarylllt mediumBtn mx-1"
                                    onClick={handleClose}
                                >
                                    {t('button.cancelButton')}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary mediumBtn mx-1"
                                    style={{
                                        margin: '0 20px',
                                        width: '120px',
                                    }}
                                    onClick={handleClickInactive}
                                >
                                    {t('button.dialogSaveButton')}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-warning mediumBtn mx-1"
                                    style={{
                                        margin: '0 20px',
                                        width: '120px',
                                    }}
                                    onClick={handleClickActive}
                                >
                                    {t('button.dialogActiveSaveButton')}
                                </button>
                            </div>
                        </div>
                    </ModalDialog>
                </ModalWrapper>
            )}
        </>
    );
};
