import { useState, useEffect, useCallback } from 'react';
import { buyOrderAdvancedSearchData as searchContentList } from '../../../../../../utils/advancedSearchData';
import styled from 'styled-components';
import useAxios from '../../../../../../hooks/useAxios';
import Table from '../../../../../../components/Table/Table';
import PageCopyPartial from './PageCopyPartial';
import AdvancedSearchModal from '../../../../../../components/Modals/AdvancedSearchModal';
import AdvancedSearchResultTag from '../../../../../../components/Modals/AdvancedSearchResultTag';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

// #region styled-components
const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div``;

const Right = styled.div`
	display: flex;
`;

//#endregion

function PageCopyTable({
	setBuyOrderGuid,
	table,
	singleChecked,
	setSingleChecked,
	advancedSearchShow,
	setAdvancedSearchShow,
}) {
	const { t } = useTranslation();
	const [fetchedData, setFetchedData] = useState([]);
	const [displayColumns, setDisplayColumns] = useState([]);
	const [paginationData, setPaginationData] = useState({});
	const view = 'index';
	const sortBy = 'orderDate';
	const guidName = 'buyOrderGuid';
	const pageTitle = t('lgsBuyOrder:purchaseOrder');
	const { isLoading, error, sendRequest: axiosIndexData } = useAxios();

	if (error) {
		console.log('error !', error);
	}

	const headTitleList = [
		'#',
		t('lgsBuyOrder:vendorCode'),
		t('lgsBuyOrder:vendor'),
		t('lgsBuyOrder:orderDate'),
		t('lgsBuyOrder:orderNo'),
		t('lgsBuyOrder:currencyCode'),
		t('lgsBuyOrder:displayGrossAmount'),
		t('lgsBuyOrder:displayNetAmount'),
		t('lgsBuyOrder:displayTaxAmount'),
		t('lgsBuyOrder:grossAmount'),
		t('lgsBuyOrder:netAmount'),
		t('lgsBuyOrder:taxAmount'),
	];

	const bodyTitleList = [
		'displayOrder',
		'vendorCode',
		'vendor',
		'orderDate',
		'orderNo',
		'currencyCode',
		'displayNetAmount',
		'displayTaxAmount',
		'displayGrossAmount',
		'grossAmount',
		'netAmount',
		'taxAmount',
	];

	const tableStyles = [
		{
			minWidth: '54px',
			textAlign: 'center',
		},
		{
			minWidth: '160px',
			textAlign: 'left',
		},
		{
			minWidth: '240px',
			textAlign: 'left',
		},
		{
			minWidth: '120px',
			textAlign: 'left',
		},
		{
			minWidth: '160px',
			textAlign: 'left',
		},
		{
			minWidth: '72px',
			textAlign: 'left',
		},
		{
			minWidth: '120px',
			textAlign: 'center',
		},
		{
			minWidth: '120px',
			textAlign: 'center',
		},
		{
			minWidth: '112px',
			textAlign: 'center',
		},
		{
			minWidth: '160px',
			textAlign: 'right',
		},
		{
			minWidth: '160px',
			textAlign: 'right',
		},
		{
			minWidth: '160px',
			textAlign: 'right',
		},
	];

	const columnMustShow = ['displayOrder', 'orderDate', 'orderNo'];

	// 進階搜尋 一進去的第一筆的模板
	const advancedSearchDataTemp = {
		searchLogic: '',
		column: searchContentList[0].column,
		searchOperator: 'contains',
		searchValue: '',
		searchContent: '',
		inputWarningContent: '',
	};

	// 進階搜尋實際資料
	const [advancedSearchDataList, setAdvancedSearchDataList] = useState([]);
	// 進階搜尋畫面資料
	const [displayList, setDisplayList] = useState([]);

	// Advanced Search

	//todo: default sort col => orderDate

	const fetchData = (
		advancedSearchDataList = null,
		pageSize = 20,
		sort = sortBy,
		pageNumber = 1
	) => {
		// const pageString = page ? `&page=${page}` : '';
		const url = `api/Logistics/LgsBuyOrder/GetList`;

		// 如果只有一個查詢條件, 並不需要 AND/OR, 所以要把 searchLogic 清空
		if (advancedSearchDataList && advancedSearchDataList.length === 1) {
			advancedSearchDataList[0].searchLogic = '';
		}

		axiosIndexData(
			{
				url,
				method: 'post',
				data: {
					sort,
					pageSize,
					page: pageNumber,
					advancedSearchDataList,
				},
			},
			(res) => {
				console.log(res);
				const { indexData, permission, numberOfData, totalPage } = res;

				setPaginationData({
					...paginationData,
					numberOfData: numberOfData,
					totalPage: totalPage,
					pageNumber: pageNumber,
				});
				setFetchedData({
					indexData,
					permission,
					paginationData,
				});

				function isArrayFn(obj) {
					// 包成函式
					if (typeof Array.isArray === 'function') {
						return Array.isArray(obj); // 如果瀏覽器支援就用 isArray() 方法
					} else {
						// 否則就使用 toString 方法
						return (
							Object.prototype.toString.call(obj) ===
							'[object Array]'
						);
					}
				}

				if (isArrayFn(advancedSearchDataList)) {
					// 因為後端給的 select list 名稱不一定 和 後端在做進階查詢時的欄位名稱一樣
					// 所以要將進階查詢時的欄位(searchFilter) 轉換為 tableColumn
					advancedSearchDataList.map((el) => {
						searchContentList.map((item) => {
							// console.log(item);
							if (el.searchFilter === item.tableColumn) {
								el.searchFilter = item.searchFromDataBase;
							}
						});
					});

					setDisplayList(advancedSearchDataList);
					setAdvancedSearchDataList([...advancedSearchDataList]);
				}

				setAdvancedSearchShow(false);
				localStorage.setItem('Permission_LgsBuyOrder', permission);
			}
		);
	};

	const getLocalStorageData = useCallback(() => {
		const gettingData = async () => {
			const data = await (JSON.parse(
				localStorage.getItem(`${guidName}DisplayColumns`)
			) || bodyTitleList);

			setDisplayColumns(data);

			return data;
		};

		return gettingData();
	}, []);

	const handleOpenAdvancedSearch = () => {
		console.log('handleOpenAdvancedSearch');
	};

	const deleteAllFilters = () => {
		setDisplayList([]);
		setAdvancedSearchDataList([]);
		fetchData();
	};

	const deleteFilter = (targetIndex) => {
		console.log('deleteFilter index', targetIndex);
		console.log(advancedSearchDataList[targetIndex]);
		console.log(displayList[targetIndex]);

		const newAdvancedSearchDataList = advancedSearchDataList.filter(
			(_, index) => index !== targetIndex
		);
		const newDisplayList = displayList.filter(
			(_, index) => index !== targetIndex
		);
		setDisplayList(newDisplayList);
		setAdvancedSearchDataList(newAdvancedSearchDataList);
		fetchData(
			newAdvancedSearchDataList.length ? newAdvancedSearchDataList : null
		);
	};

	useEffect(() => {
		// fetchIndexInfo();
		getLocalStorageData();
		fetchData();

		return () => {
			// cleanup
		};
	}, [getLocalStorageData]);

	// console.log(advancedSearchDataList);

	return (
		<>
			<AdvancedSearchResultTag
				advancedSearchDataList={advancedSearchDataList}
				deleteAllFilters={deleteAllFilters}
				deleteFilter={deleteFilter}
			/>
			<div id="indexView">
				<div className="position-relative firstRowMinHeight bg-white d-flex flex-column p-4 position-relative overflow-auto">
					{fetchedData?.indexData && (
						<PageCopyPartial
							guidName={guidName}
							headTitleList={headTitleList}
							bodyTitleList={bodyTitleList}
							tableData={fetchedData?.indexData}
							indexData={fetchedData?.indexData}
							sortBy={sortBy}
							displayColumns={displayColumns}
							setDisplayColumns={setDisplayColumns}
							paginationData={paginationData}
							setPaginationData={setPaginationData}
							fetchData={fetchData}
							fetchedData={fetchedData}
							tableStyles={tableStyles}
							columnMustShow={columnMustShow}
							rowClick={true}
							advancedSearchDataList={advancedSearchDataList}
							clickSelection={1}
							setBuyOrderGuid={setBuyOrderGuid}
							table={table}
							singleChecked={singleChecked}
							setSingleChecked={setSingleChecked}
						/>
					)}
				</div>
			</div>

			{advancedSearchShow && (
				<AdvancedSearchModal
					isOpen={advancedSearchShow}
					handleClose={() => setAdvancedSearchShow(false)}
					headTitleList={headTitleList}
					bodyTitleList={bodyTitleList}
					advancedSearchDataList={
						advancedSearchDataList.length > 0
							? advancedSearchDataList
							: [{ ...advancedSearchDataTemp }]
					}
					setAdvancedSearchDataList={setAdvancedSearchDataList}
					advancedSearchDataTemp={advancedSearchDataTemp}
					fetchData={fetchData}
					sortBy={sortBy}
					setFetchedData={setFetchedData}
					displayList={
						displayList.length > 0
							? displayList
							: [{ ...advancedSearchDataTemp }]
					}
					setDisplayList={setDisplayList}
					searchContentList={searchContentList}
					// getFilterList={getFilterList}
				/>
			)}
		</>
	);
}

export default PageCopyTable;
