import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// #region styled-components
const IndexTableContainer = styled.div``;

const IndexTable = styled.table`
    margin-bottom: 0;
    color: var(--grey4);
    background-color: #fff;
`;

const THead = styled.thead`
    height: 40px;
`;

const TR = styled.tr`
    height: 40px;
    &:hover {
        cursor: ${(hoverEffect) =>
            hoverEffect === 'true' ? 'pointer' : 'default'};
        background-color: ${(hoverEffect) =>
            hoverEffect === 'true' ? 'var(--grey0)' : 'var(--bs-table-bg)'};
    }
`;

const TH = styled.th`
    vertical-align: middle;
    border: 1px solid var(--grey1) !important;
`;

const TBody = styled.tbody``;

const TD = styled.td`
    cursor: auto;
    border: 1px solid var(--grey1) !important;

    background-color: ${(prop) =>
        prop.active ? '#fbfcfd !important' : '#fff'};

    position: relative;
    padding: 7px 8px !important;
    max-height: 40px;
`;

const Input = styled.input`
    padding-inline: 8px;
    border: none;
    border-radius: 4px;

    /* 去除 type="number" 上下按鈕 */
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    ::placeholder {
        color: var(--grey3);
    }

    &:disabled {
        border: none;
        background-color: transparent;
    }
`;

const TFoot = styled.tfoot``;

const FootTR = styled.tr``;

const FootTD = styled.td`
    border: 1px solid var(--grey1) !important;
`;

//#endregion

function AccountDocumentTable(props) {
    //#region state initiation
    const { t } = useTranslation(['common', 'lgsBuyOrderGoodsInvoice']); // i18n

    const {
        tab3Data,
        parentData,
        headTitleList,
        bodyTitleList,
        tableHeadStyles,
        tableBodyStyles,
        inputProperties,
        DecimalPlaceTl,
    } = props;
    // const { DecimalPlaceTl } = parentData.page1.backEndData.buyOrder;

    console.log(tab3Data);
    //#endregion

    // 計算借方與貸方金額
    const handleTotalAmount = (DC) => {
        let totalAmount = 0;
        tab3Data.accountList?.forEach((el) => {
            console.log(el);
            if (DC === 'Dr') {
                console.log(Number(el.amountDr));
                totalAmount += el.amountDr ? Number(el.amountDr) : 0;
            } else if (DC === 'Cr') {
                console.log(Number(el.amountCr));
                totalAmount += el.amountCr ? Number(el.amountCr) : 0;
            }
        });

        return Number(totalAmount).toFixed(DecimalPlaceTl);
    };

    return (
        <div className="position-relative">
            <IndexTableContainer className="bg-white d-flex flex-column position-relative">
                <IndexTable className="table">
                    <THead className="table-head">
                        <TR>
                            {headTitleList?.map((el) => (
                                <TH
                                    key={`tableHead${headTitleList?.indexOf(
                                        el
                                    )}`}
                                    style={
                                        tableHeadStyles[
                                            headTitleList?.indexOf(el)
                                        ]
                                    }
                                >
                                    {el}
                                </TH>
                            ))}
                        </TR>
                    </THead>
                    <TBody className="">
                        {tab3Data.accountList?.map((data, rowIndex) => (
                            <TR key={`accountDocumentTableRow${rowIndex}`}>
                                {bodyTitleList?.map((el, inputIndex) => {
                                    console.log(el, data[el]);
                                    if (el === 'accountName') {
                                        return (
                                            <TD
                                                style={
                                                    tableBodyStyles[
                                                        bodyTitleList?.indexOf(
                                                            el
                                                        )
                                                    ]
                                                }
                                                className={`border`}
                                                active={data.active}
                                                key={`${bodyTitleList?.indexOf(
                                                    el
                                                )}`}
                                            >
                                                <Input
                                                    style={{
                                                        minWidth: '24px',
                                                        width: '100%',
                                                        outline: 'none',
                                                    }}
                                                    className={`${'text-start'}`}
                                                    value={
                                                        `${
                                                            data.accountNo +
                                                            ' ' +
                                                            data.name01
                                                        }` || ''
                                                    }
                                                    type={
                                                        inputProperties[
                                                            inputIndex
                                                        ]?.type
                                                    }
                                                    onChange={() => {}}
                                                />
                                            </TD>
                                        );
                                    }

                                    return (
                                        <TD
                                            style={
                                                tableBodyStyles[
                                                    bodyTitleList?.indexOf(el)
                                                ]
                                            }
                                            className="border"
                                            active={data.active}
                                            key={`${bodyTitleList?.indexOf(
                                                el
                                            )}`}
                                        >
                                            <Input
                                                style={{
                                                    minWidth: '24px',
                                                    width: '100%',
                                                    outline: 'none',
                                                }}
                                                className={
                                                    el === 'note'
                                                        ? 'text-start'
                                                        : `text-end`
                                                }
                                                value={data[el] || ''}
                                                active={data.active}
                                                type={
                                                    inputProperties[inputIndex]
                                                        ?.type
                                                }
                                                onChange={() => {}}
                                                disabled
                                            />
                                        </TD>
                                    );
                                })}
                            </TR>
                        ))}
                    </TBody>

                    <TFoot>
                        <FootTR id="total" key="total" hoverEffect={false}>
                            <FootTD className="px-3 py-2 text-end" colSpan={2}>
                                {t('common:accountingVoucher.total')}
                            </FootTD>
                            <FootTD className="px-3 py-2 text-end">
                                {handleTotalAmount('Dr')}
                            </FootTD>
                            <FootTD className="px-3 py-2 text-end">
                                {handleTotalAmount('Cr')}
                            </FootTD>
                        </FootTR>
                    </TFoot>
                </IndexTable>
            </IndexTableContainer>
        </div>
    );
}

export default AccountDocumentTable;
