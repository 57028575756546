import { useState, useEffect } from 'react';
import useAxios from '../../../../../../hooks/useAxios';
import { useLocation } from 'react-router-dom';
import { Table } from '../../../../../../components/exports';
import { useTranslation } from 'react-i18next';

function LgsBuyOrder_PaymentTerm() {
	const { t } = useTranslation();

	//#region state initiation
	const [tableData, setTableData] = useState([]);

	const [tableFooter, setTableFooter] = useState([]);
	const { isLoading, error, sendRequest: axiosPaymentTermList } = useAxios();

	//#endregion

	//#region variable initiation

	const guid = useLocation().pathname.split('/').pop();

	const headTitleList = [
		'#',
		t('lgsBuyOrder:paymentDateBasis'),
		t('lgsBuyOrder:paymentDateDays'),
		t('lgsBuyOrder:paymentDate'),
		t('lgsBuyOrder:paymentAmount'),
		t('lgsBuyOrder:paymentPercentage'),
		t('lgsBuyOrder:annotation'),
	];

	const bodyTitleList = [
		'displayOrder',
		'baseOn',
		'days',
		'payDate',
		'amount',
		'percentage',
		'note',
	];

	const tableHeadStyles = [
		{
			width: '48px',
			textAlign: 'center',
		},
		{
			width: '200px',
			textAlign: 'center',
		},
		{
			width: '200px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'center',
		},
		{
			width: '160px',
			textAlign: 'center',
		},
		{
			textAlign: 'center',
		},
		{
			width: '304px',
			textAlign: 'center',
		},
	];

	const tableBodyStyles = [
		{
			width: '48px',
			textAlign: 'center',
		},
		{
			width: '200px',
			textAlign: 'left',
		},
		{
			width: '200px',
			textAlign: 'left',
		},
		{
			width: '120px',
			textAlign: 'left',
		},
		{
			width: '160px',
			textAlign: 'right',
		},
		{
			textAlign: 'right',
		},
		{
			width: '304px',
			textAlign: 'left',
		},
	];

	const baseOnObj = {
		0: t('lgsBuyOrder:duePaymentDate'),
		1: t('lgsBuyOrder:purchaseDate'),
		2: t('lgsBuyOrder:deliveryDate2'),
		3: t('lgsBuyOrder:receiptDate'),
		4: t('lgsBuyOrder:inspectionDate'),
	};

	//#endregion

	useEffect(() => {
		axiosPaymentTermList(
			{
				url: `api/Logistics/LgsBuyOrder/buyOrderPaymentTerm?guid=${guid}`,
			},
			(res) => {
				res.data.buyOrderPaymentTermList?.forEach((el) => {
					el.baseOn = baseOnObj[el.baseOn];
				});

				setTableData(res?.data.buyOrderPaymentTermList);
				setTableFooter([
					'',
					'',
					'',
					t('lgsBuyOrder:totalAmount'),
					res?.data.totalAmount,
					res?.data.totalPercentage,
					'',
				]);
			}
		);
	}, []);

	if (error) {
		console.log('error !', error);
	}

	return (
		<>
			{tableData && (
				<Table
					guidName="paymentTermGuid"
					headTitleList={headTitleList}
					bodyTitleList={bodyTitleList}
					tableHeadStyles={tableHeadStyles}
					tableBodyStyles={tableBodyStyles}
					tableData={tableData}
					tableFooter={tableFooter}
				></Table>
			)}
		</>
	);
}

export default LgsBuyOrder_PaymentTerm;
