import { useState, useEffect } from 'react';
import { Table } from '../../../../../../components/exports';
import { ReactComponent as Icn_check } from '../../../../../../assets/images/icn_Check-r.svg';
import { useTranslation } from 'react-i18next';
function LgsBuyOrderPage4Tab_BuyOrderDetails({ buyOrderData }) {
	const { t } = useTranslation();
	const [tableData, setTableData] = useState([]);
	const [tableFooter, setTableFooter] = useState([]);

	const headTitleList = [
		'#',
		t('lgsBuyOrder:type'),
		t('lgsBuyOrder:purchaseContent'),
		t('lgsBuyOrder:purchaseQuantity'),
		t('lgsBuyOrder:unit'),
		t('lgsBuyOrder:inspection'),
		t('lgsBuyOrder:unitPriceExcludingTax'),
		t('lgsBuyOrder:priceDifferencePercentage'),
		t('lgsBuyOrder:amountExcludingTax'),
		t('lgsBuyOrder:deliveryDate'),
		t('lgsBuyOrder:annotation'),
	];

	const bodyTitleList = [
		'displayOrder',
		'buyCategoryCode',
		'buyOrderContent',
		'quantityBuy',
		'uomName01',
		'inspection',
		'netPrice',
		'diffPrice',
		'netAmount',
		'deliverDate',
		'note',
	];

	const tableHeadStyles = [
		{
			width: '56px',
			textAlign: 'center',
		},
		{
			width: '80px',
			textAlign: 'center',
		},
		{
			width: '240px',
			textAlign: 'center',
		},
		{
			width: '112px',
			textAlign: 'center',
		},
		{
			width: '80px',
			textAlign: 'center',
		},
		{
			width: '60px',
			textAlign: 'center',
		},
		{
			width: '112px',
			textAlign: 'center',
		},
		{
			width: '80px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'center',
		},
		{
			width: '104px',
			textAlign: 'center',
		},
		{
			width: '160px',
			textAlign: 'center',
		},
	];

	const tableBodyStyles = [
		{
			width: '56px',
			textAlign: 'center',
		},
		{
			width: '80px',
			textAlign: 'left',
		},
		{
			width: '240px',
			textAlign: 'left',
		},
		{
			width: '112px',
			textAlign: 'right',
		},
		{
			width: '80px',
			textAlign: 'left',
		},
		{
			width: '60px',
			textAlign: 'center',
		},
		{
			width: '112px',
			textAlign: 'right',
		},
		{
			width: '80px',
			textAlign: 'right',
		},
		{
			width: '120px',
			textAlign: 'right',
		},
		{
			width: '104px',
			textAlign: 'left',
		},
		{
			width: '160px',
			textAlign: 'left',
		},
	];

	// console.log('buyOrderData', buyOrderData);

	let buyOrderDetails = buyOrderData.page1.map(
		({
			displayOrder,
			buyCategoryCode,
			quantityBuy,
			uomName01,
			inspection,
			netPrice,
			diffPrice,
			netAmount,
			deliverDate,
			note,
			name,
		}) => ({
			displayOrder,
			buyCategoryCode:
				buyCategoryCode === 'M'
					? t('lgsBuyOrder:material')
					: buyCategoryCode === 'E'
					? t('lgsBuyOrder:expense')
					: buyCategoryCode === 'A'
					? t('lgsBuyOrder:asset')
					: '?',
			buyOrderContent: name,
			quantityBuy,
			uomName01,
			inspection: inspection !== '0' ? <Icn_check /> : '',
			netPrice,
			diffPrice,
			netAmount,
			deliverDate,
			note,
		})
	);

	useEffect(() => {
		setTableData(buyOrderDetails);
		setTableFooter([
			'',
			'',
			t('lgsBuyOrder:totalAmount'),
			'',
			'',
			'',
			'',
			'',
			buyOrderData.page0.foreignCurrency
				? buyOrderData.topInfoData.foreignNetAmount
				: buyOrderData.topInfoData.netAmount,
			'',
			'',
		]);
	}, []);
	console.log('buyOrderDetails', buyOrderDetails);

	return (
		<>
			{tableData && (
				<Table
					guidName="buyCategoryCode"
					headTitleList={headTitleList}
					bodyTitleList={bodyTitleList}
					tableHeadStyles={tableHeadStyles}
					tableBodyStyles={tableBodyStyles}
					tableData={tableData}
					tableFooter={tableFooter}
				></Table>
			)}
		</>
	);
}

export default LgsBuyOrderPage4Tab_BuyOrderDetails;
