import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BtnNormal2 } from '../../../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import { ReactComponent as CopyPage } from '../../../../../assets/images/copyPage.svg';
import Page0Table from './Page0Table';
import { StepByStepBar } from '../../../../../components/StepByStepBar/StepByStepBar';
import { useTranslation } from 'react-i18next';

// #region styled-components

const Wrapper = styled.div`
    min-height: 720px;
`;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
`;

const Left = styled.div`
    display: flex;
`;

const Center = styled.div`
    display: flex;
`;

const Right = styled.div`
    display: flex;
`;

//#endregion

function Page0(props) {
    //#region state initiation
    const { t, i18n } = useTranslation();
    const {
        parentData,
        setParentData,
        page,
        setPage,
        processSteps,
        handleNextPage,
        emptyPaymentData,
    } = props;
    const [buyOrderGuid, setBuyOrderGuid] = useState('');
    const [advancedSearchShow, setAdvancedSearchShow] = useState(false);
    const [buttonName, setButtonName] = useState('');

    //#endregion

    const handlePaymentNext = () => {
        const newParentData = { ...parentData };
        newParentData.buyOrderGuid = buyOrderGuid;
        setParentData(newParentData);
        handleNextPage();
    };

    // 每次回到page0清空parentData
    useEffect(() => {
        setParentData(emptyPaymentData);
    }, []);

    useEffect(() => {
        setButtonName(t('common:button.advancedSearch'));
    }, [t]);

    return (
        <>
            {/* 上下步驟 */}
            <Container>
                <Left>
                    <BtnNormal2
                        className="btn-primary d-inline-block me-2 mediumSmallBtn"
                        keyName="advancedSearchCancel"
                        word={buttonName}
                        handleClick={() => {
                            setAdvancedSearchShow(true);
                            setButtonName(t('common:button.re-search'));
                        }}
                    >
                        <CopyPage className="me-2" />
                    </BtnNormal2>
                </Left>
                <Center>
                    <StepByStepBar steps={processSteps} activePage={page} />
                </Center>
                <Right>
                    {page < processSteps.length - 1 ? (
                        <BtnNormal2
                            className="btn-primary d-inline-block me-2 mediumBtn"
                            keyName="advancedSearchCancel"
                            word={t('common:button.nextStep')}
                            handleClick={() => {
                                handlePaymentNext(buyOrderGuid);
                            }}
                            disabled={!buyOrderGuid}
                        />
                    ) : (
                        <BtnNormal2 className="d-none" />
                    )}
                </Right>
            </Container>

            <div className="position-relative">
                <Wrapper className="bg-white rounded-xxl d-flex flex-column p-4 position-relative">
                    <Page0Table
                        table="page0Table"
                        singleChecked={buyOrderGuid}
                        setSingleChecked={setBuyOrderGuid}
                        advancedSearchShow={advancedSearchShow}
                        setAdvancedSearchShow={setAdvancedSearchShow}
                    />
                </Wrapper>
            </div>
        </>
    );
}

export default Page0;
