import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { PrevBtnSmall } from '../../../../../../components/Buttons/PrevBtnSmall';
import { NextBtnSmall } from '../../../../../../components/Buttons/NextBtnSmall';
import { StepByStepBar } from '../../../../../../components/StepByStepBar/StepByStepBar';
import { SaveSubmitBtn } from '../../../../../../components/Buttons/SaveSubmitBtn';
import { CancelSubmitBtn } from '../../../../../../components/Buttons/CancelSubmitBtn';
import TopInfo from '../TopInfo';
import { TabPage } from '../../../exports';
import LgsBuyOrderPage4Tab_BuyOrderDetails from './BuyOrderDetails';
import LgsBuyOrderPage4Tab_PaymentTerm from './PaymentTerm';
import useAxios from '../../../../../../hooks/useAxios';
import { useTranslation } from 'react-i18next';

// #region styled-components

// 換上下頁元件
const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div`
	display: flex;
`;

const Right = styled.div`
	display: flex;
`;

// 表格
const Wrapper = styled.div``;

//#endregion

function Page4(props) {
	const { t } = useTranslation();
	const {
		buyOrderData,
		setBuyOrderData,
		page,
		processSteps,
		handlePrevPage,
		handleNextPage,
	} = props;

	// 分頁title
	const tabNavList = [
		t('lgsBuyOrder:purchaseDetails'),
		t('lgsBuyOrder:paymentTerms'),
	];

	// 分頁內容 component
	const tabContent = [
		<LgsBuyOrderPage4Tab_BuyOrderDetails buyOrderData={buyOrderData} />,
		<LgsBuyOrderPage4Tab_PaymentTerm buyOrderData={buyOrderData} />,
	];

	// page1此頁面資料管控
	const [newBuyOrderData, setNewBuyOrderData] = useState([]);
	console.log(newBuyOrderData);

	console.log('parentData', buyOrderData);

	// 整理資料
	const organizeData = () => {
		const oldData = { ...buyOrderData };

		// 整理資料
		const updateBuyOrderItemList = oldData.page1.map(
			({
				action,
				buyOrderItemGuid,
				displayOrder,
				buyCategoryCode,
				materialGuid,
				assetsGuid,
				accountGuid,
				expenseClassGuid,
				sectorGuid,
				sectorClassGuid,
				deliverDate,
				uomCode,
				quantityBuy,
				netPrice,
				netAmount,
				note,
				rowStamp,
			}) => ({
				action,
				buyOrderItemGuid,
				displayOrder,
				buyCategoryCode,
				materialGuid,
				assetGuid: assetsGuid,
				accountGuid,
				expenseClassGuid,
				sectorGuid,
				sectorClassGuid,
				deliverDate,
				uomCode,
				quantityBuy,
				netPrice,
				netAmount,
				note,
				rowStamp,
			})
		);

		console.log(updateBuyOrderItemList);

		let newPaymentTermList = oldData.page2.paymentTermList.concat(
			oldData.page2.deletedTermList
		);
		const updateBuyOrderPaymentTermList = newPaymentTermList.map(
			({
				action,
				paymentTermGuid,
				displayOrder,
				baseOn,
				payDate,
				days,
				amount,
				foreignAmount,
				percentage,
				note,
				rowStamp,
			}) => ({
				action,
				paymentTermGuid,
				displayOrder,
				baseOn,
				payDate,
				days,
				amount,
				foreignAmount,
				percentage,
				note,
				rowStamp,
			})
		);

		const updateBuyOrderAttachmentList = oldData.page3.appendixInfo.map(
			({
				action,
				attachmentGuid,
				displayOrder,
				appendixClass,
				appendixName,
				rowStamp,
			}) => ({
				action,
				attachmentGuid,
				displayOrder,
				appendixClass,
				appendixName,
				rowStamp,
			})
		);

		const updateAppendixDataList = [...oldData.page3.fileList];

		let newData = {
			updateBuyOrder: {
				BuyOrderGuid: buyOrderData.page0.buyOrderGuid,
				NetAmount: buyOrderData.topInfoData.netAmount,
				TaxAmount: buyOrderData.topInfoData.taxAmount,
				GrossAmount: buyOrderData.topInfoData.grossAmount,
				ForeignNetAmount: buyOrderData.topInfoData.foreignNetAmount,
				ForeignTaxAmount: buyOrderData.topInfoData.foreignTaxAmount,
				ForeignGrossAmount: buyOrderData.topInfoData.foreignGrossAmount,
				VendorSales: buyOrderData.page0.vendorSales,
				VendorSalesPhone: buyOrderData.page0.vendorSalesPhone,
				ShipToPlantGuid: buyOrderData.page0.shipToPlantGuid,
				ShipToSectorGuid: buyOrderData.page0.shipToSectorGuid,
				ShipToStaffGuid: buyOrderData.page0.shipToStaffGuid,
				Note: buyOrderData.page0.note,
				RowStamp: buyOrderData.page0.rowStamp,
			},
			updateBuyOrderItemList: updateBuyOrderItemList,
			updateBuyOrderPaymentTermList: updateBuyOrderPaymentTermList,
			updateBuyOrderAttachmentList: updateBuyOrderAttachmentList,
			updateAppendixDataList: updateAppendixDataList,
		};

		// 將資料形式改為formData回傳給後端
		const formData = new FormData();

		formData.append(
			'updateBuyOrder',
			JSON.stringify(newData.updateBuyOrder)
		);
		formData.append(
			'updateBuyOrderItemList',
			JSON.stringify(newData.updateBuyOrderItemList)
		);
		formData.append(
			'updateBuyOrderPaymentTermList',
			JSON.stringify(newData.updateBuyOrderPaymentTermList)
		);
		formData.append(
			'updateBuyOrderAttachmentList',
			JSON.stringify(newData.updateBuyOrderAttachmentList)
		);

		// 新增附件-以同樣的keyName一筆筆append
		newData.updateAppendixDataList.forEach((el) => {
			formData.append('updateAppendixDataList', el);
		});

		console.log(newData.updateAppendixDataList);
		console.log([...formData.entries()]);

		return formData;
	};

	// 儲存
	const { isLoading, error, sendRequest: axiosSaveData } = useAxios();

	const handleSubmit = () => {
		const newData = organizeData();

		const headers = {
			'Content-Type': 'multipart/form-data',
		};
		const url = `api/Logistics/LgsBuyOrder/updateBuyOrder`;
		const method = 'PUT';
		const data = newData;

		axiosSaveData({ headers, url, method, data }, (res) => {
			console.log(res);

			const newParentData = { ...buyOrderData };
			newParentData.page4.orderNo = res;
			setBuyOrderData(newParentData);

			res && handleNextPage();
		});
		console.log(buyOrderData);

		console.log(error);
	};

	return (
		<>
			<Container>
				<Left>
					{page !== 0 && (
						<PrevBtnSmall
							handleClick={() => {
								handlePrevPage();
							}}
						/>
					)}
				</Left>
				<Center className="ps-14">
					<StepByStepBar steps={processSteps} activePage={page} />
				</Center>
				<Right>
					{page < processSteps.length - 1 ? (
						<NextBtnSmall
							handleClick={() => {
								handleNextPage();
							}}
						/>
					) : (
						<>
							<SaveSubmitBtn handleSubmit={handleSubmit} />
							<CancelSubmitBtn
								className={'btn-outline-primary'}
							/>
						</>
					)}
				</Right>
			</Container>

			<Wrapper className="row bg-white rounded-xxl px-6 pt-4 pb-4 mx-0 mb-3">
				<div className="col-12 px-0 ">
					<TopInfo
						topInfoData={buyOrderData.topInfoData}
						buyOrderData={buyOrderData}
					/>
				</div>
			</Wrapper>

			<TabPage
				wrapperStyle={{ minHeight: '530px' }}
				liColWidth={'col-2'}
				tabNavList={tabNavList}
				tabContent={tabContent}
				defaultPage={0}
			/>
		</>
	);
}

export default Page4;
