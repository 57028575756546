import { useState, useEffect } from 'react';
import styled from 'styled-components';
import useAxios from '../../../../../hooks/useAxios';
import { StepByStepBar } from '../../../../../components/StepByStepBar/StepByStepBar';
import { NextBtnSmall } from '../../../../../components/Buttons/NextBtnSmall';
import TopInfo from './TopInfo';
import PaymentTermTable from './PaymentTermTable';
//import PaymentTermTable from '../../Details/TabContent/PaymentTerm/PaymentTermTable';
import GoodsInspectTable from './GoodsInspectTable';
import { DialogWarning } from '../DialogWarning';
import { BtnNormal } from '../../../../../components/Buttons/BtnNormal';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

// #region styled-components

const IndexTableContainer = styled.div``;

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div`
	display: flex;
`;

const Right = styled.div`
	display: flex;
`;

//#endregion

function Page1(props) {
	//#region state initiation
	const { t } = useTranslation();

	// props
	const {
		setDialogConfirmData,
		parentData,
		setParentData,
		buyOrderData,
		page,
		processSteps,
		handleNextPage,
	} = props;
	// console.log(props);

	// state
	const [pageData, setPageData] = useState({
		goodsItemInfo: {
			inspectDate: '',
			postingDate: '',
			employeeGuid: '',
			employeeName: '',
			thisQuantityInspect: '',
			note: '',
		},
		goodsInspectList: [],
	});

	const [tableBodyData, setTableBodyData] = useState([]);
	const [backEndData, setBackEndData] = useState({});
	const [dialogBtnKeyName, setDialogBtnKeyName] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	const [modalData, setModalData] = useState([]);
	const [warningMessage, setWarningMessage] = useState('');
	const [totals, setTotals] = useState({
		totalAmount: 0,
	});

	//console.log('pageData', pageData);
	//console.log('BackEndData', backEndData);

	// 每一筆檢驗資料

	//#endregion

	//#region variable initiation

	// table header 的名稱
	const headTitleList = [
		'#',
		t('lgsBuyOrderGoodsInspect:page1.material'),
		t('lgsBuyOrderGoodsInspect:page1.originalMaterialLocation'),
		t('lgsBuyOrderGoodsInspect:page1.inspectionQuantity'),
		t('lgsBuyOrderGoodsInspect:page1.qualityTypeDecision'),
		t('lgsBuyOrderGoodsInspect:page1.acceptedMaterialLocation'),
		t('lgsBuyOrderGoodsInspect:page1.batchNumber'),
		t('lgsBuyOrderGoodsInspect:page1.detailAnnotation'),
		'',
	];

	// table body 的資料名稱
	const bodyTitleList = [
		'displayOrder',
		'materialName',
		'cubeName',
		'thisQuantityInspect',
		'qualityCode',
		'materialCube',
		'batchNo',
		'tableBodyNote',
		'serialList',
	];

	// 不同 input 的 type
	const inputProperties = [
		{ type: 'number' },
		{ type: 'text' },
		{ type: 'text' },
		{
			type: 'number',
			min: 1,
			//待處理....，離開"檢驗數量"欄位時，計算總和並比對和填入
			// onBlur: () =>
			// 	handleCalculateAmount('changingAmount', tableBodyData),
		},
		{},
		{},
		{ type: 'text' },
		{ type: 'text' },
		{ type: 'text' },
	];

	// table header 的 style
	const tableHeadStyles = [
		{
			width: '56px',
			textAlign: 'center',
		},
		{
			width: '200px',
			textAlign: 'center',
		},
		{
			width: '200px',
			textAlign: 'center',
		},
		{
			width: '136px',
			textAlign: 'center',
		},
		{
			width: '136px',
			textAlign: 'center',
		},
		{
			width: '136px',
			textAlign: 'center',
		},
		{
			width: '144px',
			textAlign: 'center',
		},
		{
			minWidth: '250px',
			textAlign: 'center',
		},
		{
			width: '100px',
			textAlign: 'center',
		},
	];

	// table body 的 style
	const tableBodyStyles = [
		{
			width: '56px',
			textAlign: 'center',
		},
		{
			width: '200px',
			textAlign: 'left',
		},
		{
			width: '200px',
			textAlign: 'left',
		},
		{
			width: '136px',
			textAlign: 'left',
		},
		{
			width: '136px',
			textAlign: 'left',
		},
		{
			width: '136px',
			textAlign: 'left',
		},
		{
			width: '144px',
			textAlign: 'left',
		},
		{
			minWidth: '240px',
			textAlign: 'left',
		},
		{
			width: '100px',
			textAlign: 'left',
		},
	];

	//#endregion

	//#region function

	// const handleCalculateAmount = (isChanging, list) => {
	// 	const copyList = [];
	// 	let newTotalAmount = 0;
	// 	console.log('215list', list);

	// 	list.map((el, index) => {
	// 		// console.log('213', el);
	// 	});
	// 	const totalQuantity = list
	// 		.map((el) => Number(el.quantityInspect))
	// 		.reduce(
	// 			(accumulator, currentValue) => accumulator + currentValue,
	// 			0
	// 		);
	// 	console.log('totalQuantity', totalQuantity);

	// 	setPageData((prevPageData) => {
	// 		const updatedPageData = {
	// 			...prevPageData,
	// 			goodsItemInfo: {
	// 				...prevPageData.goodsItemInfo,
	// 				quantityInspect: totalQuantity,
	// 			},
	// 		};

	// 		return updatedPageData;
	// 	});

	// 	useEffect(() => {
	// 		//console.log('pageData', pageData);
	// 		// 执行其他逻辑
	// 		// ...
	// 	}, [pageData]);

	// 	// setPageData({
	// 	// 	...pageData.goodsItemInfo,
	// 	// 	quantityInspect: totalQuantity,
	// 	// });

	// 	// console.log('pageData', pageData);
	// 	// setTableBodyData(copyList);
	// };

	// 暫存改被修改的資料

	//console.log(pageData);
	const handleTemporaryStore = () => {
		const newParentData = { ...parentData };
		const newPageData = { ...pageData };

		newParentData.page1 = newPageData;
		newParentData.page1.goodsInspectList = tableBodyData;

		// 標記是否曾拿取過資料
		newParentData.isEverEnterPage.page1 = true;

		setParentData(newParentData);
	};

	// console.log('parentData', parentData);

	// 回到page0 清空暫存資料
	const handleBackPage0 = () => {
		const newData = {
			btnName: 'goToPage0',
			isOpen: true,
			goTo: 'preStep',
			title: t('lgsBuyOrderGoodsInspect:page1.confirmReselectReceipt'),
		};
		setDialogConfirmData(newData);
	};

	// 往下一頁之前的檢查
	const handleSubmit = () => {
		let falseCount = 0;

		// 1.pageData
		// 1-1.檢驗日期必填
		let newPageData = { ...pageData };
		// console.log(pageData);
		// console.log(tableBodyData);

		if (pageData.goodsItemInfo.inspectDate === '') {
			newPageData.goodsItemInfo.inspectDateWarning = t(
				'lgsBuyOrderGoodsInspect:page1.fillData'
			);
			setPageData({ ...newPageData });
			falseCount++;
		}

		// 1-2.檢驗人員必填
		if (pageData.goodsItemInfo.employeeName === '') {
			newPageData.goodsItemInfo.employeeNameWarning = t(
				'lgsBuyOrderGoodsInspect:page1.fillData'
			);
			setPageData({ ...newPageData });
			falseCount++;
		}

		// 2.tableBodyData
		if (tableBodyData.length) {
			let newTableBodyData = [...tableBodyData];

			// 2-1.檢驗數量至少填一欄 (alert)
			let isQuantityReceiveLeastOne = tableBodyData.every((el) => {
				// console.log('322', el);
				// console.log(el['thisQuantityInspect'] !== '');
				return (
					el['thisQuantityInspect'] &&
					el['thisQuantityInspect'] !== '0' &&
					el['thisQuantityInspect'] !== ''
				);
			});
			!isQuantityReceiveLeastOne && falseCount++;
			// console.log(falseCount);

			if (!isQuantityReceiveLeastOne) {
				setWarningMessage(
					t(
						'lgsBuyOrderGoodsInspect:page1.atLeastOneInspectionQuantityRequired'
					) + '！'
				);
				handleDialogWarning();
			}

			// 2-2.判定品質型態和物料倉位必填 (錯誤訊息存tableBodyData)

			let isRequiredAllFill = tableBodyData.every((el) => {
				return el['qualityCode'] && el['materialCube'] !== '';
			});

			!isRequiredAllFill && falseCount++;

			newTableBodyData.forEach((el) => {
				if (el.thisQuantityInspect === '') {
					el.thisQuantityInspectWarning = t(
						'lgsBuyOrderGoodsInspect:page1.fillData'
					);
					falseCount++;
				}

				if (el.qualityCode === '') {
					el.qualityCodeWarning = t(
						'lgsBuyOrderGoodsInspect:page1.fillData'
					);
					falseCount++;
				}

				if (!el.materialCube) {
					el.materialCubeWarning = t(
						'lgsBuyOrderGoodsInspect:page1.fillData'
					);
				}
			});
			setTableBodyData([...newTableBodyData]);
		}

		// 驗證通過

		if (('falseCount', falseCount === 0)) {
			handleTemporaryStore();
			handleNextPage();
		}

		// if (pageData.goodsItemInfo.inspectDate === '') {
		// 	newPageData.goodsItemInfo.inspectDateWarning = 'test';
		// 	// newPageData.goodsItemInfo.inspectDateWarning = t(
		// 	// 	'lgsBuyOrderGoodsInvoice:fillData'
		// 	// );
		// 	setPageData({ ...newPageData });

		// 	falseCount++;
		// 	console.log('314', newPageData.goodsItemInfo.inspectDateWarning);
		// 	console.log(falseCount);
		// }

		// 如果 (沒有收貨檢驗) -> 不用檢查
		// if (tableBodyData.length) {
		// 	//
		// 	console.log(tableBodyData.length);
		// 	// Step 1:
		// 	// 檢查所有必填欄位是否都有資料
		// 	let allValuesAreFilledIn = tableBodyData.some((el) => {
		// 		//console.log('el', el);
		// 		/// 必填欄位
		// 		// 收貨數量
		// 		const keysToCheck = ['quantityInspect'];

		// 		// 所有欄位都有資料 -> true, otherwis檢驗人員e -> false
		// 		const valueIsFilledIn = keysToCheck.every((key) => {
		// 			return el[key] && el[key] !== '0';
		// 		});

		// 		return valueIsFilledIn;
		// 	});

		// 	// console.log(allValuesAreFilledIn);

		// 	// Step 2:
		// 	// if (所有必填欄位都有資料)
		// 	//檢查付款金額總額與本幣應稅總額/外幣應稅總額是否相同
		// 	if (allValuesAreFilledIn) {
		// 		isValid = true;
		// 	}

		// 	// 不是所有必填欄位都有資料 -> alert提醒
		// 	if (!allValuesAreFilledIn) {
		// 		setWarningMessage('需輸入檢驗數量！');
		// 		handleDialogWarning();
		// 	}
		// } else {
		// 	isValid = true;
		// }

		// if (isValid) {
		// 	handleTemporaryStore();
		// 	handleNextPage();
		// }
	};

	// // 檢驗數量的檢查
	// const quantityInspectCheck = () => {
	// 	let isValid = false;
	// };

	// axios
	const { sendRequest: axiosDataPage1 } = useAxios();

	// console.log('copyBuyOrderData', copyBuyOrderData);

	// //useEffect(() => {
	// 	// 3. 回到此頁時，從parent回復資料
	// 	// 有資料則回復資料
	// 	Object.keys(parentData.page1).length !== 0 &&
	// 		setNewParentData(parentData.page1);
	// }, []);

	//#endregion

	// const handleSubmit = () => {
	// 	const newData = organizeData();

	// 	const headers = {
	// 		'Content-Type': 'multipart/form-data',
	// 	};
	// 	const url = `api/Logistics/LgsBuyOrder/insertBuyOrder`;
	// 	const method = 'POST';
	// 	const data = newData;

	// 	axiosSaveData({ headers, url, method, data }, (res) => {
	// 		console.log(res);

	// 		const newParentData = { ...buyOrderData };
	// 		newParentData.page4.orderNo = res;
	// 		setBuyOrderData(newParentData);

	// 		res && handleNextPage();
	// 	});
	// 	console.log(buyOrderData);

	// 	console.log(error);
	// };
	// const { sendRequest: axiosData } = useAxios();

	// useEffect(() => {
	// 	const url = `api/Logistics/LgsBuyOrderGoodsInspect/checkSerial`;
	// 	const method = 'POST';
	// 	axiosData({ url, method }, (res) => {
	// 		console.log('res', res);
	// 	});
	// });

	useEffect(() => {
		const isEverEnterPage = parentData.isEverEnterPage.page1;

		if (!isEverEnterPage) {
			// 1.axios get BE data
			//第一次進來，先從後端拿資料
			const url = `api/Logistics/LgsBuyOrderGoodsInspect/getPage2?guid=${parentData.page0.goodsItemGuid}`;
			const method = 'POST';

			axiosDataPage1({ url, method }, async (res) => {
				console.log('res', res);

				await setBackEndData(res);

				const newPageData = {
					...pageData,

					goodsItemInfo: {
						...pageData.goodsItemInfo,
						inspectDate: dayjs().format('YYYY-MM-DD'),
						...res.buyOrderGoodsItem, // 後端來的topInfo不可編輯資料
						thisQuantityInspect: '',
					},
					backEndData: { ...res },
				};
				await setPageData(newPageData);
				//console.log(pageData);

				//預設 tableBodyData 欄位
				let data = {
					displayOrder: '1',
					materialGuid: res.buyOrderGoodsItem.materialGuid,
					materialName: res.buyOrderGoodsItem.materialName,
					cubeName: res.buyOrderGoodsItem.cubeName,
					thisQuantityInspect: '', //本次驗收數量
					qualityCode: '',
					qualityCode: '',
					qualityName: '',
					materialCube: '',
					batchNo: res.buyOrderGoodsItem.batchNo,
					tableBodyNote: '',
					serialList: [],
					serialString: '',
				};

				const newTableBD = [...tableBodyData, data];
				setTableBodyData(newTableBD);
			});
		} else {
			setBackEndData(parentData.page1.backEndData);
			setTableBodyData(parentData.page1.goodsInspectList); //回到此頁重新拿table資料
			setPageData(parentData.page1);
		}
		// console.log(parentData);
	}, []);

	//console.log('tableBodyData', tableBodyData);
	/**
	 * DialogWarning 返回btn確認dialog
	 **/
	const [isWarningOpen, setIsWarningOpen] = useState(false);

	// DialogWarning Modal open
	const handleDialogWarning = () => {
		setIsWarningOpen(true);
	};

	return (
		<>
			{/* DialogWarning element */}
			<DialogWarning
				isWarningOpen={isWarningOpen}
				setIsWarningOpen={setIsWarningOpen}
				warningMessage={warningMessage}
			/>

			<Container className="">
				<Left className="">
					{page !== 0 && (
						<BtnNormal
							className={`layout-title-searchBtn layout-title-item btn btn-outline-primary justify-content-center align-items-center`}
							key={'PrevBtn'}
							onClick={() => {
								handleBackPage0();
							}}
						>
							{t('button.prevStep')}
						</BtnNormal>
					)}
				</Left>
				<Center className="">
					<StepByStepBar steps={processSteps} activePage={page} />
				</Center>
				<Right className="">
					{page < processSteps.length - 1 ? (
						<BtnNormal
							className={`layout-title-searchBtn layout-title-item btn btn-primary justify-content-center align-items-center`}
							key={'PrevBtn'}
							onClick={(e) => {
								e.preventDefault();
								handleSubmit(page);
							}}
						>
							{t('button.nextStep')}
						</BtnNormal>
					) : (
						<NextBtnSmall className="d-none" />
					)}
				</Right>
			</Container>

			<div className="row bg-white rounded-xxl px-6 py-4 mx-0 mb-3">
				<TopInfo
					pageData={pageData}
					setPageData={setPageData}
					topInfoData={buyOrderData?.topInfoData}
					buyOrderData={buyOrderData}
					dialogBtnKeyName={dialogBtnKeyName}
					setDialogBtnKeyName={setDialogBtnKeyName}
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					modalData={modalData}
					setModalData={setModalData}
					backEndData={backEndData}
				/>
			</div>

			<div className="position-relative">
				<IndexTableContainer className="bg-white d-flex flex-column p-4 position-relative">
					<GoodsInspectTable
						pageData={pageData}
						setPageData={setPageData}
						headTitleList={headTitleList}
						bodyTitleList={bodyTitleList}
						backEndData={backEndData}
						tableHeadStyles={tableHeadStyles}
						tableBodyStyles={tableBodyStyles}
						tableBodyData={tableBodyData}
						setTableBodyData={setTableBodyData}
						inputProperties={inputProperties}
						dialogBtnKeyName={dialogBtnKeyName}
						setDialogBtnKeyName={setDialogBtnKeyName}
						isOpen={isOpen}
						setIsOpen={setIsOpen}
						modalData={modalData}
						setModalData={setModalData}

						// totals={totals}
						//handleSplit={handleSplit}
						//decimalPlace={decimalPlace}
						//toFloatList={toFloatList}
						//deletedTermList={deletedTermList}
						//setDeleteTermList={setDeleteTermList}
					/>
				</IndexTableContainer>
			</div>
		</>
	);
}

export default Page1;
