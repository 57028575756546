import { useState, useEffect } from 'react';
import TopInfo from './TopInfo';
import GoodsItemTable from './GoodsItemTable';
import { useTranslation } from 'react-i18next';

function GoodsItemTab({ parentData }) {
	//#region state initiation
	const { t } = useTranslation(['common', 'lgsBuyOrderGoodsReturn']); // i18n

	const [tableBodyData, setTableBodyData] = useState([]);
	console.log('tableBodyData', tableBodyData);

	//#endregion

	//#region variable initiation

	// table header 的名稱
	const headTitleList = [
		'#',
		t('lgsBuyOrderGoodsReturn:buyCategoryCode'),
		t('lgsBuyOrderGoodsReturn:buyContent'),
		t('lgsBuyOrderGoodsReturn:quantityReturn'),
		t('lgsBuyOrderGoodsReturn:uomName'),
		t('lgsBuyOrderGoodsReturn:materialCube'),
		t('lgsBuyOrderGoodsReturn:qualityCategory'),
		t('lgsBuyOrderGoodsReturn:batchNo'),
		t('lgsBuyOrderGoodsReturn:note'),
	];

	// table body 的資料名稱
	const bodyTitleList = [
		'displayOrder',
		'buyCategoryCode',
		'buyContent',
		'quantityReturn',
		'uomName',
		'materialCube',
		'qualityCategory',
		'batchNo',
		'note',
	];

	// 不同 input 的 type
	const inputProperties = [
		{},
		{},
		{},
		{ type: 'number' },
		{ type: 'text' },
		{ type: 'text' },
		{ type: 'text' },
		{ type: 'text' },
		{ type: 'text' },
	];

	// table header 的 style
	const tableHeadStyles = [
		{
			width: '56px',
			textAlign: 'center',
		},
		{
			width: '72px',
			textAlign: 'center',
		},
		{
			width: '240px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'center',
		},
		{
			width: '80px',
			textAlign: 'center',
		},
		{
			width: '160px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'center',
		},
		{
			width: '144px',
			textAlign: 'center',
		},
		{
			width: '200px',
			textAlign: 'center',
		},
	];

	// table body 的 style
	const tableBodyStyles = [
		{
			width: '56px',
			textAlign: 'center',
		},
		{
			width: '72px',
			textAlign: 'left',
		},
		{
			width: '240px',
			textAlign: 'left',
		},
		{
			width: '120px',
			textAlign: 'right',
		},
		{
			width: '80px',
			textAlign: 'left',
		},
		{
			width: '160px',
			textAlign: 'left',
		},
		{
			width: '120px',
			textAlign: 'left',
		},
		{
			width: '144px',
			textAlign: 'left',
		},
		{
			width: '200px',
			textAlign: 'left',
		},
	];

	//#endregion

	useEffect(() => {
		// 只顯示有退貨數量的
		const newGoodsItemList = parentData.page1.buyOrderGoodsItemList.filter(
			(el) => {
				return el.quantityReturn && el.quantityReturn !== '0';
			}
		);

		setTableBodyData(newGoodsItemList);
	}, []);

	return (
		<>
			<h1
				style={{ fontSize: '28px' }}
				className="fw-bold text-grey5 text-center mt-4"
			>
				{t('lgsBuyOrderGoodsReturn:receivingReturningOrder')}
			</h1>
			<div className="row bg-white rounded-xxl px-6 pt-6 mx-0 mb-0">
				<TopInfo parentData={parentData} />
			</div>
			<div className="position-relative">
				<div className="bg-white rounded-xxl d-flex flex-column px-2 py-3 position-relative">
					<GoodsItemTable
						parentData={parentData}
						headTitleList={headTitleList}
						bodyTitleList={bodyTitleList}
						tableHeadStyles={tableHeadStyles}
						tableBodyStyles={tableBodyStyles}
						tableBodyData={tableBodyData}
						inputProperties={inputProperties}
					/>
				</div>
			</div>
		</>
	);
}

export default GoodsItemTab;
