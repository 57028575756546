import { useState, useEffect } from 'react';
import styled from 'styled-components';
import useAxios from '../../../../../hooks/useAxios';
import { StepByStepBar } from '../../../../../components/StepByStepBar/StepByStepBar';
import { NextBtnSmall } from '../../../../../components/Buttons/NextBtnSmall';
import TopInfo from './TopInfo';
import GoodsTable from './GoodsTable';
import { DialogWarning } from '../DialogWarning';
import { BtnNormal } from '../../../../../components/Buttons/BtnNormal';
import { useTranslation } from 'react-i18next';

// #region styled-components

const IndexTableContainer = styled.div``;

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div`
	display: flex;
`;

const Right = styled.div`
	display: flex;
`;

//#endregion

function Page1(props) {
	//#region state initiation

	// i18n
	const { t } = useTranslation(['common', 'lgsBuyOrderGoodsReturn']);

	// props
	const {
		setDialogConfirmData,
		parentData,
		setParentData,
		page,
		processSteps,
		handleNextPage,
	} = props;
	// console.log(props);

	// state
	const [pageData, setPageData] = useState({
		goodsItemInfo: {
			postingDate: '',
			employeeGuid: '',
			employeeName: '',
			note: '',
		},
		buyOrderGoodsItemList: [],
	});
	const [tableBodyData, setTableBodyData] = useState([]);
	const [backEndData, setBackEndData] = useState({});
	const [dialogBtnKeyName, setDialogBtnKeyName] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	const [modalData, setModalData] = useState([]);
	const [warningMessage, setWarningMessage] = useState('');

	console.log('tableBodyData', tableBodyData);
	console.log('pageData', pageData);
	console.log('BackEndData', backEndData);

	// axios
	const { sendRequest: axiosDataPage1 } = useAxios();

	//#endregion

	//#region variable initiation

	// table header 的名稱
	const headTitleList = [
		'#',
		t('lgsBuyOrderGoodsReturn:buyCategoryCode'),
		t('lgsBuyOrderGoodsReturn:buyContent'),
		t('lgsBuyOrderGoodsReturn:uomName'),
		t('lgsBuyOrderGoodsReturn:quantityReceive'),
		t('lgsBuyOrderGoodsReturn:quantityReturn'),
		t('lgsBuyOrderGoodsReturn:materialCube'),
		t('lgsBuyOrderGoodsReturn:qualityCategory'),
		t('lgsBuyOrderGoodsReturn:batchNo'),
		t('lgsBuyOrderGoodsReturn:note'),
	];

	// table body 的資料名稱
	const bodyTitleList = [
		'displayOrder',
		'buyCategoryCode',
		'buyContent',
		'uomName',
		'quantityReceive',
		'quantityReturn',
		'materialCube',
		'qualityCategory',
		'batchNo',
		'note',
	];

	// 不同 input 的 type
	const inputProperties = [
		{},
		{},
		{},
		{},
		{},
		{ type: 'number' },
		{ type: 'text' },
		{ type: 'text' },
		{ type: 'text' },
		{ type: 'text' },
	];

	// table header 的 style
	const tableHeadStyles = [
		{
			width: '56px',
			textAlign: 'center',
		},
		{
			width: '56px',
			textAlign: 'center',
		},
		{
			width: '240px',
			textAlign: 'center',
		},
		{
			width: '80px',
			textAlign: 'center',
		},
		{
			width: '104px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'center',
		},
		{
			width: '136px',
			textAlign: 'center',
		},
		{
			width: '112px',
			textAlign: 'center',
		},
		{
			width: '144px',
			textAlign: 'center',
		},
		{
			width: '144px',
			textAlign: 'center',
		},
	];

	// table body 的 style
	const tableBodyStyles = [
		{
			width: '56px',
			textAlign: 'center',
		},
		{
			width: '56px',
			textAlign: 'left',
		},
		{
			width: '240px',
			textAlign: 'left',
		},
		{
			width: '80px',
			textAlign: 'left',
		},
		{
			width: '104px',
			textAlign: 'right',
		},
		{
			width: '120px',
			textAlign: 'right',
		},
		{
			width: '136px',
			textAlign: 'left',
		},
		{
			width: '112px',
			textAlign: 'left',
		},
		{
			width: '144px',
			textAlign: 'left',
		},
		{
			width: '144px',
			textAlign: 'left',
		},
	];

	//#endregion

	//#region function

	// 暫存被修改的資料
	const handleTemporaryStore = () => {
		const newParentData = { ...parentData };
		const newPageData = { ...pageData };

		// 在換頁時，1.先新增採購金額欄位(returnPrice,returnForeignPrice)
		// 2.先新增採購總金額欄位

		let totalReturnPrice = 0;
		let totalReturnForeignPrice = 0;

		newPageData.buyOrderGoodsItemList.forEach((el) => {
			if (el.quantityReturn && el.quantityReturn !== '0') {
				let returnForeignPrice = (
					el.foreignNetPrice * el.quantityReturn
				).toFixed(newPageData.backEndData.buyOrderGoods.decimalPlaceTl);

				let returnPrice = (el.netPrice * el.quantityReturn).toFixed(
					newPageData.backEndData.buyOrderGoods.decimalPlaceTl
				);

				el.returnForeignPrice = returnForeignPrice;
				el.returnPrice = returnPrice;

				totalReturnPrice += parseFloat(returnPrice);
				totalReturnForeignPrice += parseFloat(returnForeignPrice);
			}
		});

		newPageData.totalPriceInfo = {
			totalReturnPrice: totalReturnPrice,
			totalReturnForeignPrice: totalReturnForeignPrice,
		};

		console.log(newPageData);

		newParentData.page1 = newPageData;
		// 標記是否曾拿取過資料
		newParentData.isEverEnterPage.page1 = true;

		setParentData(newParentData);
	};

	console.log('parentData', parentData);

	// 回到page0 清空暫存資料
	const handleBackPage0 = () => {
		const newData = {
			btnName: 'goToPage0',
			isOpen: true,
			goTo: 'preStep',
			title: t('lgsBuyOrderGoodsReturn:confirmRechooseOrder') + '?',
		};
		setDialogConfirmData(newData);
	};

	// 往下一頁之前的檢查
	const handleSubmit = () => {
		let falseCount = 0;

		// 1.pageData
		// 1-1.收貨日期必填
		let newPageData = { ...pageData };

		console.log(pageData.goodsItemInfo.returnDate === 'undefined');
		if (
			typeof pageData.goodsItemInfo.returnDate === 'undefined' ||
			pageData.goodsItemInfo.returnDate === ''
		) {
			newPageData.goodsItemInfo.returnDateWarning = t(
				'lgsBuyOrderGoodsReturn:fillData'
			);
			setPageData({ ...newPageData });

			falseCount++;
		}

		// 2.tableBodyData
		if (tableBodyData.length) {
			let newTableBodyData = [...tableBodyData];

			// 2-1.收貨數量至少填一欄 (alert)
			let isQuantityReturnLeastOne = tableBodyData.some((el) => {
				return el['quantityReturn'] && el['quantityReturn'] !== '0';
			});
			!isQuantityReturnLeastOne && falseCount++;

			if (!isQuantityReturnLeastOne) {
				setWarningMessage(
					t(
						'lgsBuyOrderGoodsReturn:atLeastOneReturnQuantityRequired'
					) + '！'
				);
				handleDialogWarning();
			}

			// 2-2.物料倉位&批次編號必填 (錯誤訊息存tableBodyData)
			let isRequiredAllFill = tableBodyData.every((el) => {
				if (el.buyCategoryCode === 'M') {
					return el.batchNo && el.cubeGuid;
				}

				return true; // 不是"M"時
			});
			!isRequiredAllFill && falseCount++;

			newTableBodyData.forEach((el) => {
				if (el.buyCategoryCode === 'M') {
					if (!el.batchNo) {
						el.batchNoWarning = t(
							'lgsBuyOrderGoodsReturn:fillData'
						);
					}

					if (!el.cubeGuid) {
						el.cubeGuidWarning = t(
							'lgsBuyOrderGoodsReturn:fillData'
						);
					}
				}
			});

			setTableBodyData([...newTableBodyData]);
		}

		// 驗證通過
		console.log(falseCount);
		if (falseCount === 0) {
			handleTemporaryStore();
			handleNextPage();
		}
	};

	//#endregion

	//#region DialogWarning

	// 返回btn確認dialog

	const [isWarningOpen, setIsWarningOpen] = useState(false);

	// Modal open
	const handleDialogWarning = () => {
		setIsWarningOpen(true);
	};

	//#endregion

	const handleNewTableBodyData = (backEndData) => {
		const oldGoodsItemList = [...backEndData.buyOrderGoodsItemList];

		// 品質型態對應表
		const qualityCategoryList = [...backEndData.defaultQualityCategoryList];
		let newQualityCategoryList = qualityCategoryList.reduce((obj, item) => {
			obj[item.categoryCode] = item.name;
			return obj;
		}, {});
		console.log(newQualityCategoryList);

		// 需驗證是否有數量的欄位
		const verifyArray = [
			'quantity0',
			'quantityA',
			'quantityB',
			'quantityQ',
			'quantityR',
			'quantityT',
			'quantityX',
		];

		let newGoodsItemList = oldGoodsItemList.flatMap((item) => {
			console.log(item);

			if (item.batchControl === '0') {
				// 1.無批次管理
				return {
					...item,
					qualityCategoryName:
						newQualityCategoryList[item.qualityCategoryCode],
				};
			} else if (item.batchControl === '1') {
				// 2.有批次管理，依七種分類，若有數量，新增一列資料
				return verifyArray
					.filter((field) => item[field] !== 0)
					.map((field) => ({
						...item,
						quantityReceive: item[field],
						quantityReturnLimit: item[field],
						qualityCategoryCode: field.split('quantity')[1],
						qualityCategoryName:
							newQualityCategoryList[field.split('quantity')[1]],
					}));
			}
		});
		console.log(newGoodsItemList);

		// 給予displayOrder
		newGoodsItemList.forEach((el, index) => (el.displayOrder = index + 1));

		return newGoodsItemList;
	};

	//#region useEffect
	useEffect(() => {
		const isEverEnterPage = parentData.isEverEnterPage.page1;

		if (!isEverEnterPage) {
			// axios get BE data
			const url = `api/Logistics/lgsBuyOrderGoodsReturn/getPage2?guid=${parentData.page0.goodsGuid}`;
			const method = 'POST';

			axiosDataPage1({ url, method }, async (res) => {
				console.log(res);

				await setBackEndData(res);

				// 由於品質型態需要展開，得到一個新的tableBodyData
				let newTableBodyData = handleNewTableBodyData(res);
				await setTableBodyData(newTableBodyData);

				const newPageData = {
					...pageData,
					goodsItemInfo: {
						...pageData.goodsItemInfo,
						...res.buyOrderGoods, // 後端來的topInfo不可編輯資料
					},
					backEndData: { ...res },
				};

				newPageData.buyOrderGoodsItemList = res.buyOrderGoodsItemList;
				await setPageData(newPageData);
			});
		} else {
			setBackEndData(parentData.page1.backEndData);
			setTableBodyData(parentData.page1.buyOrderGoodsItemList);
			setPageData(parentData.page1);
		}
	}, []);
	//#endregion

	return (
		<>
			{/* DialogWarning element */}
			<DialogWarning
				isWarningOpen={isWarningOpen}
				setIsWarningOpen={setIsWarningOpen}
				warningMessage={warningMessage}
			/>

			<Container className="">
				<Left className="">
					{page !== 0 && (
						<BtnNormal
							className={`layout-title-searchBtn layout-title-item btn btn-outline-primary justify-content-center align-items-center`}
							key={'PrevBtn'}
							onClick={() => {
								handleBackPage0();
							}}
						>
							{t('common:button.prevStep')}
						</BtnNormal>
					)}
				</Left>
				<Center className="">
					<StepByStepBar steps={processSteps} activePage={page} />
				</Center>
				<Right className="">
					{page < processSteps.length - 1 ? (
						<BtnNormal
							className={`layout-title-searchBtn layout-title-item btn btn-primary justify-content-center align-items-center`}
							key={'PrevBtn'}
							onClick={(e) => {
								e.preventDefault();
								handleSubmit(page);
							}}
						>
							{t('common:button.nextStep')}
						</BtnNormal>
					) : (
						<NextBtnSmall className="d-none" />
					)}
				</Right>
			</Container>

			<div className="row bg-white rounded-xxl px-6 py-4 mx-0 mb-3">
				<TopInfo
					pageData={pageData}
					setPageData={setPageData}
					dialogBtnKeyName={dialogBtnKeyName}
					setDialogBtnKeyName={setDialogBtnKeyName}
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					modalData={modalData}
					setModalData={setModalData}
					backEndData={backEndData}
				/>
			</div>
			<div className="position-relative">
				<IndexTableContainer className="bg-white rounded-xxl d-flex flex-column px-2 py-3 position-relative">
					<GoodsTable
						pageData={pageData}
						setPageData={setPageData}
						headTitleList={headTitleList}
						bodyTitleList={bodyTitleList}
						tableHeadStyles={tableHeadStyles}
						tableBodyStyles={tableBodyStyles}
						tableBodyData={tableBodyData}
						setTableBodyData={setTableBodyData}
						inputProperties={inputProperties}
						backEndData={backEndData}
						dialogBtnKeyName={dialogBtnKeyName}
						setDialogBtnKeyName={setDialogBtnKeyName}
						isOpen={isOpen}
						setIsOpen={setIsOpen}
						modalData={modalData}
						setModalData={setModalData}
					/>
				</IndexTableContainer>
			</div>
		</>
	);
}

export default Page1;
