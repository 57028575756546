import React from 'react';
import styled from 'styled-components';
import { LabelNormal } from './shared/LabelNormal';
import { disabledStyle } from './shared/DisabledStyle';
import { ErrMsg } from './shared/ErrMsg';
import { ReactComponent as Icn_InputYellowCancel } from '../../assets/images/icn_InputYellowCancel.svg';
import { ReactComponent as Icn_InputDialog } from '../../assets/images/icn_inputDialog.svg';

// 使用範例
/* 
<InputDialog
	wrapperClass="mb-3" // 外層class，可用來推下面margin
	labelText="會計人員" // label文字
	id="person" // input id&name ; label htmlFor
	idShow="personShow"
	disabled={false}
	disabledCssType={0} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
	dialogClick={() => {
		alert('dialog click, set value.');
		setValue('person', 'apple');
		console.log(getValues('person'));

		setValueShow('appleShow');
	}} // 開啟dialog function
	cancelClick={() => {
		alert('cancel click');
		setValue('person', '');
		console.log(getValues('person'));

		setValueShow('');
	}} // 取消function
	register={register}
	getValues={getValues}
	validation={{
		required: 'must be required',
	}}
	isErr={errors.person} // 是否顯示錯誤訊息
	errMsg={errors.person?.message} // 錯誤訊息
	value={getValues('person')} // 實際傳後端的value
	valueShow={valueShow} // 使用者看到的value
/>
*/

const DialogWrapper = styled.div`
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	width: 100%;
`;

// input實際資料欄位
const InputDialogElementData = styled.input`
	display: none;
`;

// input顯示欄位
const InputDialogElementView = styled.input`
	flex: 1 1 auto;
	border: 1px solid var(--grey2);
	border-radius: 4px 0 0 4px;
	border-right: 0;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
	pointer-events: none;

	${disabledStyle};
	background-color: transparent;
`;

// 取消按鈕
const CancelInputBtn = styled.span`
	display: inline-block;
	position: absolute;
	height: 100%;
	top: 3px;
	right: 32px;
	width: 24px;
	text-align: center;
	display: ${(props) => (props.isValueExist ? 'inline' : 'none')};
	cursor: pointer;
`;

// dialog按鈕
const DialogBtn = styled.div`
	display: flex;
	align-items: center;
	border: 1px solid var(--grey2);
	border-radius: 0 4px 4px 0;
	border-left: 0;
	padding-right: 8px;
	cursor: pointer;

	${disabledStyle}
`;

function InputDialog({
	labelText,
	elementStyle,
	id,
	value,
	valueShow,
	placeholder,
	isErr,
	errMsg,
	wrapperClass,
	disabled,
	disabledCssType,
	cancelClick,
	dialogClick,
	register,
	validation,
}) {
	return (
		<div className={`wrapper d-flex flex-column ${wrapperClass}`}>
			<LabelNormal htmlFor={id}>{labelText}</LabelNormal>
			<DialogWrapper>
				<InputDialogElementData
					{...register(id, validation)}
					type="text"
					id={id}
					value={value}
					placeholder={placeholder}
					disabled={disabled}
					disabledCssType={disabledCssType}
				/>
				<InputDialogElementView
					style={elementStyle}
					disabled={true}
					disabledCssType={disabledCssType}
					value={valueShow}
				/>

				<CancelInputBtn onClick={cancelClick} isValueExist={valueShow}>
					<Icn_InputYellowCancel />
				</CancelInputBtn>

				<DialogBtn
					disabledCssType={disabledCssType}
					onClick={disabled ? () => {} : dialogClick}
				>
					<Icn_InputDialog />
				</DialogBtn>
			</DialogWrapper>
			<ErrMsg
				className={`text-danger mt-1 ${isErr ? 'd-block' : 'd-none'}`}
			>
				{errMsg}
			</ErrMsg>
		</div>
	);
}

export default InputDialog;
