import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useCookies } from 'react-cookie';
import { ReactComponent as Icn_InputYellowCancel } from '../../../../../assets/images/icn_InputYellowCancel.svg';
import { ReactComponent as Icn_InputDialog } from '../../../../../assets/images/icn_inputDialog.svg';
import ThreeColLayout from '../../../../../components/ThreeColLayout/ThreeColLayout';
import { DialogCol } from '../DialogCol';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { disabledStyle } from '../../../../../components/FormElement/shared/DisabledStyle';

//#region styled-components

const SelectElement = styled.select`
    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 6px 8px;
    height: 32px;
    &:focus-visible {
        outline: 0;
    }

    ${disabledStyle}
`;

const TopTable = styled.table`
    font-size: 12px;
    color: #616e83;
    background-color: #f7f8fc;
    width: 100% !important;
    height: 128px !important;
    border: 1px solid var(--grey1) !important;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 10px;
`;

const TBody = styled.tbody``;

const TR = styled.tr`
    height: 32px;
    border: 2px solid var(--grey1) !important;
`;
const TH1 = styled.th`
    padding: 0px;
    height: 32px;
    width: 23% !important;
    text-align: center !important;
`;
const TH = styled.th`
    padding: 0px;
    height: 32px;
    width: 38.5% !important;
    text-align: center !important;
`;
const TD1 = styled.td`
    padding: 0px;
    height: 32px;
    width: 23% !important;
    border: 1px solid var(--grey1) !important;
    text-align: center !important;
`;
const TD = styled.td`
    padding-right: 8px;
    height: 32px;
    width: 38.5% !important;
    border: 1px solid var(--grey1) !important;

    text-align: right !important;
`;

const LabelNormal = styled.label`
    font-size: 12px;
    color: var(--grey4);
    margin-bottom: 6px;
`;

const InputElement = styled.input`
    /* border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 6px 8px;
    height: 32px;
    &:focus-visible {
        outline: 0;
    }
    &:disabled {
        background-color: #eff2f5;
    } */

    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 6px 8px;
    height: 32px;
    &:focus-visible {
        outline: 0;
    }
    width: 100%;
    ${disabledStyle};
`;

const InputElement2 = styled.input`
    border: 1px solid var(--grey2);
    border-right: none;
    border-radius: 4px;
    padding: 6px 8px;
    height: 32px;
    &:focus-visible {
        outline: 0;
    }
    &:disabled {
        background-color: #eff2f5;
    }
`;

const TextareaElement = styled.textarea`
    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 8px;
    min-height: 88px;
    resize: none;
    &:focus-visible {
        outline: 0;
    }
`;

// -----DialogCol------
const DialogWrapper = styled.div`
    position: relative;
    align-items: stretch;
    width: 100%;
    --bs-gutter-x: none;
`;

const InputDialogElementData = styled.input`
    display: none;
`; // input實際資料欄位

const InputDialogElementView = styled.input`
    flex: 1 1 auto;
    border: 1px solid var(--grey2);
    border-radius: 4px 0 0 4px;
    border-right: 0;
    padding: 6px 8px;
    height: 32px;
    &:focus-visible {
        outline: 0;
    }
    pointer-events: none;
    background-color: transparent;
`; // input顯示欄位

const CancelInputBtn = styled.span`
    display: inline-block;
    position: absolute;
    height: 100%;
    top: 3px;
    right: 32px;
    width: 24px;
    text-align: center;
    display: ${(props) => (props.isValueExist ? 'inline' : 'none')};
    cursor: pointer;
`; // 取消按鈕

const DialogBtn = styled.div`
    width: 40px;
    display: flex;
    align-items: center;
    border: 1px solid var(--grey2);
    border-radius: 0 4px 4px 0;
    border-left: 0;
    padding-left: 16px;
    padding-right: 8px;
    cursor: pointer;
`; // dialog按鈕

//#endregion

const BottomInfo = ({
    pageData,
    setPageData,
    CurrencyCode,
    CurrencyName,
    DecimalPlaceTl,
}) => {
    const { t, i18n } = useTranslation();
    const { employeeList, bankingAccountList, buyOrder } = pageData;

    const handleInput = (inputName) => {
        pageData.goodsItemInfo[inputName] = event.target.value;

        setPageData(pageData);
        console.log('pageData', pageData);
    };

    const LeftCol = () => {
        const handleEmployeeChange = (val) => {
            console.log(val);
            setPageData({ ...pageData, employeeGuid: val });
        };

        const handleBankingAccountChange = (val) => {
            console.log(val);
            setPageData({ ...pageData, bankAccountGuid: val });
        };

        const handlePostingDateChange = (val) => {
            console.log(val);
            setPageData({ ...pageData, postingDate: val });
        };

        return (
            <>
                <div className="wrapper d-flex flex-column mb-3">
                    <LabelNormal>
                        {t('lgsBuyOrderPayment:page1.bottom.postingDate')}
                    </LabelNormal>
                    <InputElement
                        type="text"
                        id="postingDate"
                        value={dayjs(Date.now()).format('YYYY-MM-DD')}
                        onChange={(e) =>
                            handlePostingDateChange(e.target.value)
                        }
                        disabled={true}
                        disabledCssType={1}
                    />
                    <span className="text-danger ms-1 mt-1">
                        {/* {data.vendorNameWarning} */}
                    </span>
                </div>

                <div className="wrapper d-flex flex-column mb-3">
                    <LabelNormal>
                        {t('lgsBuyOrderPayment:page1.bottom.staff')}
                    </LabelNormal>
                    <InputElement
                        id="staff"
                        value={pageData?.payment?.employeeName}
                        onChange={(e) => handleEmployeeChange(e.target.value)}
                        disabled={true}
                        disabledCssType={1}
                    >
                        {/* {employeeList?.map((el) => (
                            <option
                                key={el.employeeGuid}
                                id={el.employeeGuid}
                                value={el.employeeGuid}
                            >
                                {el.fullname}
                            </option>
                        ))} */}
                    </InputElement>
                    <span className="text-danger ms-1 mt-1">
                        {/* {data.orderTypeCodeWarning} */}
                    </span>
                </div>

                <div className="wrapper d-flex flex-column mb-3">
                    <LabelNormal>
                        {t('lgsBuyOrderPayment:page1.bottom.bankingAccount')}
                    </LabelNormal>
                    <InputElement
                        id="bankingAccount"
                        value={pageData?.payment?.bankingAccountName}
                        onChange={(e) =>
                            handleBankingAccountChange(e.target.value)
                        }
                        disabled={true}
                        disabledCssType={1}
                    ></InputElement>
                    <span className="text-danger ms-1 mt-1">
                        {/* {data.orderTypeCodeWarning} */}
                    </span>
                </div>

                <div className="wrapper d-flex flex-column mb-3">
                    <LabelNormal>
                        {t('lgsBuyOrderPayment:page1.bottom.account')}
                    </LabelNormal>
                    <InputElement
                        id="account"
                        value={pageData?.payment?.accountName}
                        onChange={(e) =>
                            handleBankingAccountChange(e.target.value)
                        }
                        disabled={true}
                        disabledCssType={1}
                    >
                        {/* <option>--- Please select---</option>
                        {bankingAccountList?.map((el) => (
                            <option
                                key={el.bankAccountGuid}
                                id={el.bankAccountGuid}
                                value={el.bankAccountGuid}
                            >
                                {el.bankingAccountName}
                            </option>
                        ))} */}
                    </InputElement>
                    <span className="text-danger ms-1 mt-1">
                        {/* {data.orderTypeCodeWarning} */}
                    </span>
                </div>
            </>
        );
    };

    const CenterCol = () => (
        <>
            <div className="row">
                <div className="wrapper d-flex flex-column mb-3 col">
                    <LabelNormal>
                        {t('lgsBuyOrderPayment:page1.bottom.postingCurrency')}
                    </LabelNormal>
                    <div className="input-group">
                        <InputElement
                            id="postingCurrency"
                            value={
                                buyOrder?.currencyCode
                                    ? `${buyOrder?.currencyCode} ${buyOrder?.currencyName}`
                                    : ''
                            }
                            onChange={(e) =>
                                handleBankingAccountChange(e.target.value)
                            }
                            disabled={true}
                            disabledCssType={1}
                        >
                            {/* <option>--- Please select---</option>
                        {bankingAccountList?.map((el) => (
                            <option
                                key={el.bankAccountGuid}
                                id={el.bankAccountGuid}
                                value={el.bankAccountGuid}
                            >
                                {el.bankingAccountName}
                            </option>
                        ))} */}
                        </InputElement>
                    </div>
                </div>
                {buyOrder.foreignCurrency ? (
                    <div className="d-flex flex-column col-5 ps-0">
                        <LabelNormal>
                            {t(
                                'lgsBuyOrderPayment:page1.bottom.paymentExchangeRate'
                            )}
                        </LabelNormal>
                        <div className="input-group">
                            <InputElement
                                type="number"
                                id="exchageRate"
                                className="mb-3"
                                value={pageData?.payment?.exchageRate || ''}
                                min="1"
                                onChange={(e) =>
                                    handleGrossAmountChange(e.target.value)
                                }
                                disabled
                                disabledCssType={1}
                            />
                        </div>
                    </div>
                ) : null}
            </div>

            {buyOrder.foreignCurrency ? (
                <div className="d-flex flex-column">
                    <LabelNormal>
                        {t('lgsBuyOrderPayment:page1.bottom.grossAmount')}
                    </LabelNormal>
                    <InputElement
                        type="number"
                        id="foreignGrossAmount
                                        "
                        className="mb-3"
                        value={
                            (pageData.payment.foreignGrossAmount &&
                                Number(
                                    pageData.payment.foreignGrossAmount
                                ).toFixed(DecimalPlaceTl)) ||
                            ''
                        }
                        min="1"
                        onChange={(e) =>
                            handleForeignGrossAmountChange(e.target.value)
                        }
                        disabled={true}
                        disabledCssType={1}
                    />
                </div>
            ) : (
                <div className="wrapper d-flex flex-column mb-3">
                    <LabelNormal>
                        {t('lgsBuyOrderPayment:page1.bottom.grossAmount')}
                    </LabelNormal>
                    <InputElement
                        id="grossAmount"
                        value={
                            (pageData.payment.grossAmount &&
                                Number(pageData.payment.grossAmount).toFixed(
                                    DecimalPlaceTl
                                )) ||
                            ''
                        }
                        onChange={(e) =>
                            handleBankingAccountChange(e.target.value)
                        }
                        disabled={true}
                        disabledCssType={1}
                    ></InputElement>
                    <span className="text-danger ms-1 mt-1">
                        {/* {data.orderTypeCodeWarning} */}
                    </span>
                </div>
            )}
            {buyOrder.foreignCurrency ? (
                <>
                    <div className="d-flex flex-column">
                        <LabelNormal>
                            {t(
                                'lgsBuyOrderPayment:page1.bottom.localCurrencyPaymentAmount'
                            )}
                        </LabelNormal>
                        <InputElement
                            type="number"
                            id="grossAmountActualPay"
                            className="mb-3"
                            value={
                                (pageData.payment.foreignGrossAmount &&
                                    Number(
                                        pageData.payment.foreignGrossAmount *
                                            (pageData.payment.exchageRate ||
                                                pageData.buyOrder.exchageRate)
                                    ).toFixed(DecimalPlaceTl)) ||
                                ''
                            }
                            min="1"
                            onChange={(e) =>
                                handleGrossAmountChange(e.target.value)
                            }
                            disabled
                            disabledCssType={1}
                        />
                    </div>
                    <div className="d-flex flex-column">
                        <LabelNormal>
                            {t(
                                'lgsBuyOrderPayment:page1.bottom.localCurrencyPayableAmount'
                            )}
                        </LabelNormal>
                        <InputElement
                            type="number"
                            id="grossAmountShouldPay"
                            className="mb-3"
                            value={
                                (pageData.payment.foreignGrossAmount &&
                                    Number(
                                        pageData.payment.foreignGrossAmount *
                                            pageData.buyOrder.exchageRate
                                    ).toFixed(DecimalPlaceTl)) ||
                                ''
                            }
                            min="1"
                            onChange={(e) =>
                                handleGrossAmountChange(e.target.value)
                            }
                            disabled
                            disabledCssType={1}
                        />
                    </div>
                    <div className="d-flex flex-column">
                        <LabelNormal>
                            {t(
                                'lgsBuyOrderPayment:page1.bottom.exchangeRateDifferenceAmount'
                            )}
                        </LabelNormal>
                        <InputElement
                            type="number"
                            id="exchangeDifferenceAmount"
                            className="mb-3"
                            value={
                                (pageData.payment.foreignGrossAmount &&
                                    Number(
                                        (pageData.payment.foreignGrossAmount *
                                            (pageData.payment.exchageRate ||
                                                pageData.buyOrder
                                                    .exchageRate) || '') -
                                            pageData.payment
                                                .foreignGrossAmount *
                                                pageData.buyOrder.exchageRate
                                    ).toFixed(DecimalPlaceTl)) ||
                                ''
                            }
                            min="1"
                            onChange={(e) =>
                                handleGrossAmountChange(e.target.value)
                            }
                            disabled
                            disabledCssType={1}
                        />
                    </div>
                </>
            ) : null}
        </>
    );

    const RightCol = () => (
        <>
            <div className={`wrapper d-flex flex-column mb-3`}>
                <LabelNormal>
                    {t('lgsBuyOrderPayment:page1.bottom.note')}
                </LabelNormal>
                <TextareaElement
                    //style={elementStyle}
                    id="note"
                    rows="4"
                    cols="30"
                    value={pageData?.payment?.note || ''}
                    onChange={() => handleInput('note')}
                    disabled={true}
                    disabledCssType={0}
                />
            </div>
        </>
    );

    return (
        <ThreeColLayout
            LeftCol={<LeftCol />}
            CenterCol={<CenterCol />}
            RightCol={<RightCol />}
        />
    );
};

export default BottomInfo;
