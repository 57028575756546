import React from 'react';
import styled from 'styled-components';
import { ReactComponent as EditIcon } from '../../assets/images/icn_Edit.svg';
import { BtnNormal } from './BtnNormal';
import { useNavigate } from 'react-router-dom';

export const EditBtnNormal = ({ className, to, buyOrderGuid }) => {
    // console.log(className, to);
    const navigate = useNavigate();
    return (
        <BtnNormal
            className={'btn btn-primary'}
            key={'EditBtnNormal'}
            onClick={() => navigate(`../Edit/${buyOrderGuid}`)}
        >
            <EditIcon />
            <span>修改</span>
        </BtnNormal>
    );
};
