import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { ReactComponent as Icn_Search2 } from '../../../../../assets/images/icn_Search2.svg';
import { ReactComponent as Icn_Cancel } from '../../../../../assets/images/icn_Cancel.svg';
import { ReactComponent as Icn_InputYellowCancel } from '../../../../../assets/images/icn_InputYellowCancel.svg';
import { useTranslation } from 'react-i18next';

// #region styled-components
const ModalWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1055;
	width: 100%;
	height: 100%;
`;

const ModalDialog = styled.div`
	max-width: 600px !important;
	min-height: calc(100% - 3.5rem);
`;

const SortableTableUl = styled.ul`
	height: 440px;
	overflow-y: scroll;
	overflow-x: hidden;
	border-radius: 0 !important;
`;

const SortableTableLi = styled.li`
	height: 40px;
	cursor: pointer;
	border: none;
	border-radius: 0 !important;
	background-color: ${({ selected }) => (selected ? 'var(--grey1)' : '#fff')};

	&:hover {
		background-color: ${({ selected }) =>
			selected ? `fff` : 'var(--grey1)'};
	}
`;

// #endregion

export const DialogTwoCol = ({ index, props, isOpen, setIsOpen }) => {
	//#region state initialization
	const { t } = useTranslation();

	// prop
	const { dialogDataFromBE, newBuyOrderData, setNewBuyOrderData } = props;

	// state
	const [isCancelSearchModalBtn, setIsCancelSearchModalBtn] = useState(false); // cancel search btn
	const [selectedLeftButtonIndex, setSelectedLeftButtonIndex] = useState(-1);
	const [selectedRightButtonIndex, setSelectedRightButtonIndex] =
		useState(-1);
	const [UOMList, setUOMList] = useState([]);
	const [UOMRightSelected, setUOMRightSelected] = useState({});

	// ref
	const searchModalInput = useRef('');

	//#endregion

	//#region Modal搜尋

	// 搜尋
	const searchModal = () => {
		//取得搜尋文字
		let searchModalInputText = '';
		if (searchModalInput.current) {
			searchModalInputText = searchModalInput.current.value.toUpperCase();
		}

		let modalRightLiList = document.querySelectorAll('.modalRightLi');

		if (modalRightLiList.length !== 0 && searchModalInputText !== '') {
			//隱藏全部欄位
			modalRightLiList.forEach((item) => {
				item.classList.add('d-none');
			});

			// 搜尋每個rightLi中的每個text
			modalRightLiList.forEach((item) => {
				// console.log(item.textContent);

				if (
					item.textContent
						.toUpperCase()
						.includes(searchModalInputText)
				) {
					item.classList.remove('d-none');
				}
			});

			//顯示取消搜尋btn
			setIsCancelSearchModalBtn(true);
		}
	};

	// 取消搜尋
	const cancelSearchModal = (i) => {
		// 顯示全部欄位
		document.querySelectorAll('.modalRightLi').forEach((item) => {
			item.classList.remove('d-none');
		});

		// 清空搜尋欄位
		searchModalInput.current.value = '';

		// 隱藏取消搜尋btn
		setIsCancelSearchModalBtn(false);
	};

	//#endregion

	//#region Modal儲存

	function modalSave() {
		// console.log(UOMRightSelected);

		// 更新newBuyOrderData
		const newData = [...newBuyOrderData];
		newData[index]['uomCode'] = UOMRightSelected.uomcode;
		newData[index]['uomName01'] = UOMRightSelected.name01;
		newData[index]['uomGuid'] = UOMRightSelected.uomguid;
		setNewBuyOrderData(newData);

		handleClose();
	}

	//#endregion

	//#region Modal取消(關閉)

	const handleClose = () => {
		setSelectedLeftButtonIndex(-1);
		setSelectedRightButtonIndex(-1);
		setUOMList([]);
		setIsOpen(false);
	};

	//#endregion

	//#region handleLeftButtonClick

	const handleLeftButtonClick = (index, categoryCode) => {
		setSelectedLeftButtonIndex(index);

		let selectedUomList = dialogDataFromBE.uomList.filter(
			(el) => el.uomcategoryCode === categoryCode
		);

		setUOMList(selectedUomList);

		// 如果搜尋框有文字則再隱藏不符合的條件
	};

	//#endregion

	//#region handleRightButtonClick

	const handleRightButtonClick = (index, data) => {
		setSelectedRightButtonIndex(index);

		setUOMRightSelected(data);
	};
	// console.log(UOMRightSelected);

	//#endregion

	//#region useEffect

	useEffect(() => {
		searchModal();
	}, [UOMList]);
	// console.log(UOMList);

	//#endregion

	return (
		<>
			{isOpen && (
				<ModalWrapper onClick={handleClose}>
					<ModalDialog
						className="modal-dialog d-flex align-items-center"
						onClick={(e) => e.stopPropagation()}
					>
						<div
							className="modal-content border-0"
							style={{
								boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
								borderRadius: '12px',
							}}
						>
							<div className="modal-header px-4 pt-4 pb-3">
								<h5 className="text-primary fw-bold fontSize125Rem mb-0">
									{t('lgsBuyOrder:dialogCol.unitOfMeasure')}
								</h5>
								<button
									style={{ top: '12px', right: '12px' }}
									type="button"
									className="close position-absolute btn"
									onClick={handleClose}
								>
									<Icn_Cancel />
								</button>
							</div>

							<div className="modal-body text-center px-7 py-0">
								{/* 搜尋 */}
								<div className="row ms-1">
									<div className="position-relative col-12 col-md-6 p-0 pr-3 mt-6 mb-3">
										<input
											type="text"
											id="uomSearchInput"
											name="searchModalInput"
											className="form-control searchModalInput fontSize875Rem"
											ref={searchModalInput}
											placeholder={t(
												'lgsBuyOrder:dialogCol.quickSearch'
											)}
											style={{
												height: '32px',
												textTransform: 'uppercase',
											}}
										/>
										<button
											type="button"
											className="searchModalBtn position-absolute border-0 btn btn-primaryllt px-3 py-0"
											onClick={() => searchModal()}
											style={{
												top: '1px',
												right: '1px',
												height: '30px',
												borderRadius:
													'0 0.25rem 0.25rem 0',
											}}
										>
											<Icn_Search2 />
										</button>
										{isCancelSearchModalBtn && (
											<a
												id="cancelSearchUomModal"
												className={`cancelSearchModalBtn position-absolute ${
													searchModalInput.current
														?.value
														? ''
														: 'd-none'
												}`}
												onClick={() =>
													cancelSearchModal()
												}
												style={{
													top: '3px',
													right: '-24px',
													cursor: 'pointer',
												}}
											>
												<Icn_InputYellowCancel />
											</a>
										)}
									</div>
								</div>

								<div
									className="form-group mb-0 row"
									style={{ height: '500px' }}
								>
									{/* left col */}
									<div className="col-6 pl-0 bg-white leftContainer">
										<div
											className="ModalSubTitleBar fw-bold text-secondary bg-primaryllt  border border-primarylt"
											style={{
												height: '40px',
												lineHeight: '40px',
												borderRadius: '12px 12px 0 0',
											}}
										>
											{t(
												'lgsBuyOrder:dialogCol.unitOfMeasureType'
											)}
										</div>
										<div id="sortable">
											<SortableTableUl
												id="left"
												className="list-group text-left border border-primarylt border-top-0 connectedSortable"
											>
												{dialogDataFromBE.defaultUomcategoryList.map(
													(el, index) => (
														<SortableTableLi
															key={index}
															selected={
																selectedLeftButtonIndex ===
																index
															}
															onClick={() =>
																handleLeftButtonClick(
																	index,
																	el.categoryCode
																)
															}
															className="list-group-item"
														>
															{el.nameZh}
														</SortableTableLi>
													)
												)}
											</SortableTableUl>
										</div>
									</div>

									{/* right col */}
									<div className="col-6 pr-0 bg-white rightContainer">
										<div
											className="ModalSubTitleBar fw-bold text-secondary bg-primaryllt  border border-primarylt"
											style={{
												height: '40px',
												lineHeight: '40px',
												borderRadius: '12px 12px 0 0',
											}}
										>
											{t(
												'lgsBuyOrder:dialogCol.unitOfMeasure'
											)}
										</div>
										<div id="sortable">
											<SortableTableUl
												id="right"
												className="list-group text-left border border-primarylt border-top-0 connectedSortable"
											>
												{Object.keys(UOMList).length ? (
													UOMList.map((el, index) => {
														return (
															<SortableTableLi
																key={el.uomguid}
																className="modalRightLi text-center  border-0 p-2"
																selected={
																	selectedRightButtonIndex ===
																	index
																}
																onClick={() =>
																	handleRightButtonClick(
																		index,
																		el
																	)
																}
															>
																{el.name01}
															</SortableTableLi>
														);
													})
												) : (
													<SortableTableLi className="text-center border-0 p-2">
														{t(
															'lgsBuyOrder:dialogCol.pleaseSelectUnitOfMeasureTypeFirst'
														)}
													</SortableTableLi>
												)}
											</SortableTableUl>
										</div>
									</div>
								</div>
							</div>

							<hr
								className="mx-4 mt-3 mb-0"
								style={{
									border: `1px solid var(--grey2)`,
								}}
							/>

							{/* 確定btn */}
							<div className="modal-footer justify-content-center border-0 pt-4 pb-5">
								<button
									type="button"
									className="btn btn-primary mediumBtn mx-1"
									style={{
										margin: '0 20px',
										width: '120px',
									}}
									onClick={modalSave}
								>
									{t('button.confirmButton')}
								</button>
								<button
									type="button"
									className="btn btn-outline-primarylllt mediumBtn mx-1"
									onClick={handleClose}
								>
									{t('button.cancelButton')}
								</button>
							</div>
						</div>
					</ModalDialog>
				</ModalWrapper>
			)}
		</>
	);
};
