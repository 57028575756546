import { useState, useEffect, useCallback } from 'react';
import useAxios from '../../../../../../../hooks/useAxios';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Table } from '../../../../../../../components/exports';
import PaymentTable from './PaymentTable';
import { useTranslation } from 'react-i18next';

function LgsBuyOrder_PaymentHistory(props) {
	const { t } = useTranslation();

	console.log(props);
	const { buyOrderPayment, foreignCurrency } = props;
	const [sortBy, setSortBy] = useState('postingDate');
	const [displayColumns, setDisplayColumns] = useState([]);
	const [data, setData] = useState({});
	const guid = useLocation().pathname.split('/').pop();
	const { isLoading, error, sendRequest: axiosPayment } = useAxios();

	let headTitleList = [];
	let bodyTitleList = [];
	let tableHeadStyles = [];
	let tableBodyStyles = [];

	useEffect(() => {
		// const url = `api/Logistics/LgsBuyOrder/buyOrderPayment?guid=${guid}`;
		// axiosPayment({ url }, (res) => {
		//     console.log(res.data);
		//     setData(res.data);
		// });

		setData(buyOrderPayment);
	}, []);

	if (error) {
		console.log('error !', error);
	}

	const {
		buyOrderPaymentList,
		totalExchangeDifferenceAmount,
		totalForeignGrossAmount,
		totalGrossAmount,
	} = data;

	if (foreignCurrency) {
		headTitleList = [
			'#',
			t('lgsBuyOrderPaymentClose:page2.paymentHistory.paymentDate'),
			t('lgsBuyOrderPaymentClose:page2.paymentHistory.payer'),
			t(
				'lgsBuyOrderPaymentClose:page2.paymentHistory.accountingVoucherNumber'
			),
			t('lgsBuyOrderPaymentClose:page2.paymentHistory.refund'),
			t('lgsBuyOrderPaymentClose:page2.paymentHistory.currency'),
			t(
				'lgsBuyOrderPaymentClose:page2.paymentHistory.localCurrencyPaymentAmount'
			),
			t(
				'lgsBuyOrderPaymentClose:page2.paymentHistory.foreignCurrencyPaymentAmount'
			),
			t(
				'lgsBuyOrderPaymentClose:page2.paymentHistory.exchangeRateDifferenceAmount'
			),
			t('lgsBuyOrderPaymentClose:page2.paymentHistory.annotation'),
		];

		bodyTitleList = [
			'displayOrder',
			'postingDate',
			'staffGuid',
			'documentNo',
			'isReturn',
			'currencyCode',
			'grossAmount',
			'foreignGrossAmount',
			'exchangeDifferenceAmount',
			'note',
		];

		tableHeadStyles = [
			{
				width: '48px',
				textAlign: 'center',
			},
			{
				width: '112px',
				textAlign: 'center',
			},
			{
				width: '136px',
				textAlign: 'center',
			},
			{
				width: '160px',
				textAlign: 'center',
			},
			{
				width: '80px',
				textAlign: 'center',
			},
			{
				width: '96px',
				textAlign: 'center',
			},
			{
				width: '120px',
				textAlign: 'center',
			},
			{
				width: '120px',
				textAlign: 'center',
			},
			{
				width: '120px',
				textAlign: 'center',
			},
			{
				width: '200px',
				textAlign: 'center',
			},
		];

		tableBodyStyles = [
			{
				width: '48px',
				textAlign: 'center',
			},
			{
				width: '112px',
				textAlign: 'left',
			},
			{
				width: '136px',
				textAlign: 'left',
			},
			{
				width: '160px',
				textAlign: 'left',
			},
			{
				width: '80px',
				textAlign: 'center',
			},
			{
				width: '96px',
				textAlign: 'center',
			},
			{
				width: '120px',
				textAlign: 'right',
			},
			{
				width: '120px',
				textAlign: 'right',
			},
			{
				width: '120px',
				textAlign: 'right',
			},
			{
				width: '200px',
				textAlign: 'left',
			},
		];
	} else {
		headTitleList = [
			'#',
			t('lgsBuyOrderPaymentClose:page2.paymentHistory.paymentDate'),
			t('lgsBuyOrderPaymentClose:page2.paymentHistory.payer'),
			t(
				'lgsBuyOrderPaymentClose:page2.paymentHistory.accountingVoucherNumber'
			),
			t('lgsBuyOrderPaymentClose:page2.paymentHistory.refund'),
			t('lgsBuyOrderPaymentClose:page2.paymentHistory.currency'),
			t('lgsBuyOrderPaymentClose:page2.paymentHistory.paymentAmount'),
			t('lgsBuyOrderPaymentClose:page2.paymentHistory.annotation'),
		];

		bodyTitleList = [
			'displayOrder',
			'postingDate',
			'staffGuid',
			'documentNo',
			'isReturn',
			'currencyCode',
			'grossAmount',
			'note',
		];

		tableHeadStyles = [
			{
				width: '48px',
				textAlign: 'center',
			},
			{
				width: '112px',
				textAlign: 'center',
			},
			{
				width: '136px',
				textAlign: 'center',
			},
			{
				width: '160px',
				textAlign: 'center',
			},
			{
				width: '80px',
				textAlign: 'center',
			},
			{
				width: '96px',
				textAlign: 'center',
			},
			{
				width: '160px',
				textAlign: 'center',
			},
			{
				//     width: '120px',
				textAlign: 'center',
			},
		];

		tableBodyStyles = [
			{
				width: '48px',
				textAlign: 'center',
			},
			{
				width: '112px',
				textAlign: 'left',
			},
			{
				width: '136px',
				textAlign: 'left',
			},
			{
				width: '160px',
				textAlign: 'left',
			},
			{
				width: '80px',
				textAlign: 'center',
			},
			{
				width: '96px',
				textAlign: 'center',
			},
			{
				width: '160px',
				textAlign: 'right',
			},
			{
				// width: '120px',
				textAlign: 'right',
			},
		];
	}

	return (
		<>
			{buyOrderPaymentList && (
				<PaymentTable
					guidName="paymentGuid"
					headTitleList={headTitleList}
					bodyTitleList={bodyTitleList}
					tableHoverEffect={false}
					totalGrossAmount={totalGrossAmount}
					totalForeignGrossAmount={totalForeignGrossAmount}
					totalExchangeDifferenceAmount={
						totalExchangeDifferenceAmount
					}
					indexData={buyOrderPaymentList}
					tableHeadStyles={tableHeadStyles}
					tableBodyStyles={tableBodyStyles}
					foreignCurrency={foreignCurrency}
				/>
			)}
		</>
	);
}

export default LgsBuyOrder_PaymentHistory;
