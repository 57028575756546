import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { BtnNormal2 } from '../../../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import { ReactComponent as Icn_InputYellowCancel } from '../../../../../assets/images/icn_InputYellowCancel.svg';
import { ReactComponent as Icn_InputDialog } from '../../../../../assets/images/icn_inputDialog.svg';
import { ReactComponent as CreateIcon } from '../../../../../assets/images/icn_Create.svg';
import useAxios from '../../../../../hooks/useAxios';
import { Dialog2 } from './Dialog2';
import { Dialog } from './Dialog';
import { DialogCol } from '../DialogCol';
import { useTranslation } from 'react-i18next';

// #region styled-components
const IndexTableContainer = styled.div``;

const IndexTable = styled.table`
	margin-bottom: 0;
	color: var(--grey4);
	background-color: #fff;
`;

const THead = styled.thead`
	height: 40px;
`;

const TR = styled.tr`
	height: 40px;
	&:hover {
		cursor: ${(hoverEffect) =>
			hoverEffect === 'true' ? 'pointer' : 'default'};
		background-color: ${(hoverEffect) =>
			hoverEffect === 'true' ? 'var(--grey0)' : 'var(--bs-table-bg)'};
	}
	.last-td {
		display: none !important;
	}

	&:hover {
		.last-td {
			display: block !important;
		}
	}
`;

const TH = styled.th`
	vertical-align: middle;
	border: 1px solid var(--grey1) !important;
	&:first-of-type {
		border-left: none !important;
	}

	&:last-of-type {
		border-right: none !important;
	}
`;

const TBody = styled.tbody``;

const TD = styled.td`
	cursor: auto;
	border: 1px solid var(--grey1) !important;
	&:first-of-type {
		border-left: none !important;
	}

	&:last-of-type {
		// border-left: none !important;
		border-right: none !important;
	}

	background-color: ${(prop) =>
		prop.active ? '#fbfcfd !important' : '#fff'};

	position: relative;
	padding: 0;
`;

const TFoot = styled.tfoot`
	border: none;
`;

const FootTR = styled.tr`
	border-top: 2px solid var(--grey3) !important;
`;

const FootTD = styled.td`
	border: none !important;
`;

const Container = styled.div`
	display: flex;
	justify-content: start;
	margin: 16px 0;
`;

const Input = styled.input`
	border: ${(prop) => (prop.active ? '1px solid #e3e8ee' : 'none')};
	border-radius: 4px;
`;

const Menu = styled.div`
	position: absolute;
	top: 0px;
	right: -36px;
	will-change: transform;
	padding: 0;
	margin-top: 8px;
	min-width: 64px;
	background-color: #ffffff;
	box-shadow: 2px 4px 12px -1px rgb(61 61 61 / 51%);
	border-radius: 0.25rem;
	color: var(--danger);

	&:last-of-type {
		border-left: none !important;
		border-right: none !important;
	}

	&:hover,
	&:focus {
		color: var(--danger);
	}
`;

const Button = styled.div`
	//active-submit dropdownItem d-flex align-items-center pe-2 tex-left px-3 py-2
	display: flex;
	cursor: pointer;
	align-items: center;
	padding: 8px 16px;
	text-align: left;
	min-width: 64px;
	height: 32px;
	justify-content: center;
	svg {
		margin-right: 8px;
	}
`;

// -----Dialog------
const DialogWrapper = styled.div`
	position: relative;
	align-items: stretch;
	width: 100%;
	height: 25px;
	--bs-gutter-x: none;
`;

const InputDialogElementData = styled.input`
	display: none;
`; // input實際資料欄位

const InputDialogElementView = styled.input`
	flex: 1 1 auto;
	border: 1px solid var(--grey2);
	border-radius: 4px 0 0 4px;
	border-right: 0;
	padding: 6px 8px;
	height: 25px;
	&:focus-visible {
		outline: 0;
	}
	pointer-events: none;
	background-color: transparent;
`; // input顯示欄位

const CancelInputBtn = styled.span`
	display: inline-block;
	position: absolute;
	height: 100%;
	top: -1px;
	right: 24px;
	width: 24px;
	text-align: center;
	display: ${(props) => (props.isValueExist ? 'inline' : 'none')};
	cursor: pointer;
`; // 取消按鈕

const DialogBtn = styled.div`
	width: 40px;
	display: flex;
	align-items: center;
	border: 1px solid var(--grey2);
	border-radius: 0 4px 4px 0;
	border-left: 0;
	padding-left: 16px;
	padding-right: 8px;
	cursor: pointer;
`; // dialog按鈕

//#endregion

function GoodsInspectTable(props) {
	//#region state initiation
	const {
		pageData,
		setPageData,
		headTitleList,
		bodyTitleList,
		backEndData,
		tableHeadStyles,
		tableBodyStyles,
		tableBodyData,
		setTableBodyData,
		inputProperties,
		dialogBtnKeyName,
		setDialogBtnKeyName,
		isOpen,
		setIsOpen,
		modalData,
		setModalData,
		setDeleteTermList,
		deletedTermList,
		decimalPlace,
		toFloatList,
		totals,
		handleSplit,
	} = props;

	const { t } = useTranslation();

	const [totalAmount, setTotalAmount] = useState();
	const [totalPercentage, setTotalPercentage] = useState();

	const [shouldFocus, setShouldFocus] = useState(-1); // 初始值為 -1，表示不應該聚焦任何 input 元素
	const handleInputFocus = (index) => {
		setShouldFocus(index);
	};

	//#endregion

	//#region function

	// make a new row
	const handleAddRow = () => {
		const newData = {};

		bodyTitleList.map((columnName) => {
			tableBodyData.map((el) => {
				//console.log('226', el);
				// newData.displayOrder = el.displayOrder;
				newData.materialName = el.materialName;
				newData.materialGuid = el.materialGuid;
				newData.cubeName = el.cubeName;
				newData.thisQuantityInspect = '';
				newData.qualityCode = '';
				newData.qualityName = '';
				newData.materialCube = '';
				newData.batchNo = el.batchNo;
				newData.tableBodyNote = '';
				newData.serialList = [];
				newData.serialString = '';
			});

			// newData[columnName] = '';

			newData.action = 'insert';
			columnName === 'displayOrder' &&
				(newData[columnName] = tableBodyData.length + 1);
		});

		setTableBodyData([...tableBodyData, newData]);
		console.log(tableBodyData);
	};

	const handleEditTable = (e, index) => {
		const newList = [...tableBodyData];
		newList.forEach((el) => delete el?.active);
		newList[index].active = true;

		setTableBodyData(newList);
	};

	// input改變時
	const handleInputChange = (value, rowIndex, inputIndex, inputName) => {
		const newData = [...tableBodyData];
		newData[rowIndex][bodyTitleList[inputIndex]] = value;
		newData[rowIndex][`${inputName}Warning`] = ''; // 清空錯誤訊息
		setTableBodyData(newData);
	};

	//檢驗數量加總
	const handleCalculateAmount = (value, rowIndex, inputIndex, inputName) => {
		const newData = [...tableBodyData];
		const oldThisInspectQuantity =
			pageData.goodsItemInfo.thisQuantityInspect;

		//計算數量加總
		const totalQuantity = newData
			.map((el) => Number(el.thisQuantityInspect))
			.reduce(
				(accumulator, currentValue) => accumulator + currentValue,
				0
			);
		// console.log('284 totalQuantity', totalQuantity);
		// console.log('284 pageData.goodsItemInfo', pageData.goodsItemInfo);

		// 檢驗數量totalQuantity	 不大於 淨收貨數量(pageData.goodsItemInfo.netReceivedQuantity)

		//console.log(value);
		if (
			Number(totalQuantity) >
			Number(pageData.goodsItemInfo.netReceivedQuantity)
		) {
			alert('不可超過淨收貨數量');

			setWarningMessage('不可超過淨收貨數量' + '！');
			handleDialogWarning();

			const remainingAmount =
				pageData.goodsItemInfo.pendingInspectionQuantity -
				pageData.goodsItemInfo.thisQuantityInspect;
			newData[rowIndex].thisQuantityInspect = '';
			//console.log('296', newData);
		}

		const finalTotal =
			Number(totalQuantity) >
			Number(pageData.goodsItemInfo.netReceivedQuantity)
				? totalQuantity - value
				: totalQuantity;

		setPageData((prevPageData) => {
			const updatedPageData = {
				...prevPageData,
				goodsItemInfo: {
					...prevPageData.goodsItemInfo,
					thisQuantityInspect: finalTotal,
				},
			};

			return updatedPageData;
		});
	};

	const handleDeletePaymentTerm = (e, data, rowIndex) => {
		//console.log('322', e, data, rowIndex);
		e.stopPropagation();
		const oldList = [...tableBodyData];

		// 只有在 EDIT 裡才會有被刪除的付款條件
		if (oldList[data.displayOrder - 1].action === 'update') {
			oldList[data.displayOrder - 1].action = 'delete';
			deletedTermList &&
				setDeleteTermList([
					...deletedTermList,
					oldList[data.displayOrder - 1],
				]);
		}

		// remove the item from the array
		oldList.splice(data.displayOrder - 1, 1);
		// update the displayOrder property of the remaining items
		const newList = oldList.map((item, index) => ({
			...item,
			displayOrder: index + 1,
		}));
		// console.log('343', newList);
		//每次刪除後計算檢驗數量加總

		const newThisQuantityInspect = newList
			.map((el) => Number(el.thisQuantityInspect))
			.reduce(
				(accumulator, currentValue) => accumulator + currentValue,
				0
			);
		pageData.goodsItemInfo.thisQuantityInspect = newThisQuantityInspect;
		console.log(pageData);

		console.log(newThisQuantityInspect);

		setTableBodyData(newList);

		// console.log(newList);
	};

	//#region DialogCol

	const [indexDialog, setIndexDialog] = useState('');

	// DialogCol input 黃色按鈕取消
	const handleCancelClick = (rowIndex) => {
		const newData = { ...pageData };
		newData.buyOrderItemList[rowIndex].cubeGuid = '';
		newData.buyOrderItemList[rowIndex].cubeName = '';
		//console.log(newData);

		setPageData(newData);
	};

	// DialogCol Modal open
	const handleOpenCube = (rowIndex) => {
		setDialogBtnKeyName('cube');
		setModalData(backEndData.materialCubeList);
		setIndexDialog(rowIndex); // 獲取真實的index，傳給DialogCol
		setIsOpen(true);
	};
	const qualityCategoryList = [
		{
			qualityGuid: '111',
			qualityCode: 'A', //資料庫此欄位應存成 categoryCode
			displayOrder: '1',
			name01: '良品過檢件',
		},
		{
			qualityGuid: '112',
			qualityCode: 'B',
			displayOrder: '2',
			name01: '凍結件',
		},
		{
			qualityGuid: '113',
			qualityCode: 'R',
			displayOrder: '3',
			name01: '待修件',
		},
		{
			qualityGuid: '114',
			qualityCode: 'T',
			displayOrder: '4',
			name01: '待退件',
		},
		{
			qualityGuid: '115',
			qualityCode: 'X',
			displayOrder: '5',
			name01: '報廢件',
		},
	];

	const handleOpenQuality = (rowIndex) => {
		setDialogBtnKeyName('quality');
		setModalData(qualityCategoryList);
		setIndexDialog(rowIndex); // 獲取真實的index，傳給DialogCol
		setIsOpen(true);
	};

	//console.log(tableBodyData);

	//#endregion

	// axios
	const { sendRequest: axiosData } = useAxios();

	useEffect(() => {
		const url = `api/Logistics/LgsBuyOrderGoodsInspect/checkSerial`;
		const method = 'POST';
		axiosData((res) => {
			//console.log(res);
		});
	});
	//console.log(pageData);

	// pageData.totalSerialList
	// setPageData({...pageData, XXXXX})

	// [001, 002, 003]
	// [[001, 002], [003]]

	return (
		<div className="position-relative">
			<IndexTableContainer className="firstRowMinHeight bg-white d-flex flex-column position-relative">
				<Container>
					{/* DialogCol element */}
					<DialogCol
						indexDialog={indexDialog}
						isOpen={isOpen}
						setIsOpen={setIsOpen}
						modalData={modalData}
						setModalData={setModalData}
						btnKeyName={dialogBtnKeyName}
						pageData={pageData}
						setPageData={setPageData}
						tableBodyData={tableBodyData}
						setTableBodyData={setTableBodyData}
					/>

					<BtnNormal2
						className="btn-primary smallBtn"
						keyName="advance-search"
						handleClick={handleAddRow}
						word={t('button.createButton')}
						// icon={}
					>
						<CreateIcon className="me-2" />
					</BtnNormal2>
				</Container>
				<IndexTable className="table">
					<THead className="table-head">
						<TR>
							{headTitleList?.map((el) => (
								<TH
									key={`tableHead${headTitleList?.indexOf(
										el
									)}`}
									style={
										tableHeadStyles[
											headTitleList?.indexOf(el)
										]
									}
								>
									{el}
								</TH>
							))}
						</TR>
					</THead>
					<TBody className="">
						{tableBodyData?.map((data, rowIndex) => (
							<TR
								key={`paymentTermTableRow${rowIndex}`}
								onClick={(e) => handleEditTable(e, rowIndex)}
							>
								{bodyTitleList?.map((el, inputIndex) => {
									// 不可編輯欄位(不為input)

									if (
										[
											'displayOrder',
											'materialName',
											'cubeName',
											'batchNo',
										].includes(el)
									)
										return (
											<TD
												style={
													tableBodyStyles[
														bodyTitleList?.indexOf(
															el
														)
													]
												}
												className={`p-2 border`}
												active={data.active}
												key={`${bodyTitleList?.indexOf(
													el
												)}`}
											>
												<div>{data[el]}</div>
											</TD>
										);

									// 檢驗數量
									if (el === 'thisQuantityInspect') {
										return (
											<TD
												style={
													tableBodyStyles[
														bodyTitleList?.indexOf(
															el
														)
													]
												}
												className={`p-2 border`}
												active={data.active}
												key={`${bodyTitleList?.indexOf(
													el
												)}`}
											>
												<Input
													value={data[el] || ''}
													active={data.active}
													key={`${bodyTitleList?.indexOf(
														el
													)}`}
													type={
														inputProperties[
															inputIndex
														]?.type
													}
													min={
														inputProperties[
															inputIndex
														]?.min
													}
													onChange={(e) => {
														handleInputChange(
															e.target.value,
															rowIndex,
															inputIndex,
															el
														);
													}}
													onBlur={(e) =>
														handleCalculateAmount(
															e.target.value,
															rowIndex,
															inputIndex,
															el
														)
													}
													// onBlur={
													// 	inputProperties[
													// 		inputIndex
													// 	]?.onBlur
													// }
													style={{
														minWidth: '24px',
														width: '100%',
														outline: 'none',
													}}
												/>

												{data[
													'thisQuantityInspectWarning'
												] && (
													<div className="mt-xl-1">
														<span className="text-danger ms-1">
															{
																data[
																	'thisQuantityInspectWarning'
																]
															}
														</span>
													</div>
												)}
											</TD>
										);
									}

									// 判定品質型態
									if (el === 'qualityCode') {
										return (
											<TD
												style={
													tableBodyStyles[
														bodyTitleList?.indexOf(
															el
														)
													]
												}
												className={`border`}
												active={data.active}
												key={`${bodyTitleList?.indexOf(
													el
												)}`}
											>
												<DialogWrapper className="row flex-nowrap">
													<InputDialogElementData
														type="text"
														value={
															data.qualityGuid ||
															''
														}
														onChange={() => {}}
													/>
													<InputDialogElementView
														style={{
															minWidth: '85px',
															cursor: 'auto',
														}}
														value={
															`${data.qualityCode} ${data.qualityName}` ||
															''
														}
														// value={
														// 	data.qualityCode ||
														// 	''
														// }
														onChange={(e) => {
															handleInputChange(
																e.target.value,
																rowIndex,
																inputIndex,
																el
															);
														}}

														// onChange={() => {}}
													/>
													<CancelInputBtn
														onClick={() => {
															handleCancelClick(
																rowIndex
															);
														}}
														isValueExist={
															data.qualityGuid
														}
													>
														<Icn_InputYellowCancel />
													</CancelInputBtn>

													<DialogBtn
														style={{
															cursor: 'pointer',
														}}
														onClick={() =>
															handleOpenQuality(
																rowIndex
															)
														}
													>
														<Icn_InputDialog />
													</DialogBtn>
												</DialogWrapper>

												{data['qualityCodeWarning'] && (
													<div className="mt-xl-1">
														<span className="text-danger ms-1">
															{
																data[
																	'qualityCodeWarning'
																]
															}
														</span>
													</div>
												)}
											</TD>
										);
									}

									// 判收物料倉位
									if (el === 'materialCube') {
										return (
											<TD
												style={
													tableBodyStyles[
														bodyTitleList?.indexOf(
															el
														)
													]
												}
												className={`border`}
												active={data.active}
												key={`${bodyTitleList?.indexOf(
													el
												)}`}
											>
												<DialogWrapper className="row flex-nowrap">
													<InputDialogElementData
														type="text"
														value={
															data.cubeGuid || ''
														}
														onChange={() => {}}
													/>
													<InputDialogElementView
														style={{
															minWidth: '85px',
															cursor: 'auto',
														}}
														value={
															data.materialCube ||
															''
														}
														onChange={() => {}}
													/>
													<CancelInputBtn
														onClick={() => {
															handleCancelClick(
																rowIndex
															);
														}}
														isValueExist={
															data.cubeGuid
														}
													>
														<Icn_InputYellowCancel />
													</CancelInputBtn>

													<DialogBtn
														style={{
															cursor: 'pointer',
														}}
														onClick={() =>
															handleOpenCube(
																rowIndex
															)
														}
													>
														<Icn_InputDialog />
													</DialogBtn>
												</DialogWrapper>

												{data[
													'materialCubeWarning'
												] && (
													<div className="mt-xl-1">
														<span className="text-danger ms-1">
															{
																data[
																	'materialCubeWarning'
																]
															}
														</span>
													</div>
												)}
											</TD>
										);
									}

									// 明細註記
									if (el === 'tableBodyNote') {
										return (
											<TD
												style={
													tableBodyStyles[
														bodyTitleList?.indexOf(
															el
														)
													]
												}
												className={`p-2 border`}
												active={data.active}
												key={`${bodyTitleList?.indexOf(
													el
												)}`}
											>
												<Input
													value={data[el] || ''}
													active={data.active}
													type={
														inputProperties[
															inputIndex
														]?.type
													}
													// min={
													// 	inputProperties[
													// 		inputIndex
													// 	]?.min
													// }
													onChange={(e) => {
														handleInputChange(
															e.target.value,
															rowIndex,
															inputIndex
															// toFloatList[
															// 	inputIndex
															// ]
														);
													}}
													//失焦動作
													onBlur={
														inputProperties[
															inputIndex
														]?.onBlur
													}
													style={{
														minWidth: '24px',
														width: '100%',
														outline: 'none',
													}}
												/>
											</TD>
										);
									}
									//console.log('818', rowIndex);
									return (
										<TD
											style={
												tableBodyStyles[
													bodyTitleList?.indexOf(el)
												]
											}
											className={`p-2 border`}
											active={data.active}
											key={`${bodyTitleList?.indexOf(
												el
											)}`}
										>
											<Dialog
												rowIndex={rowIndex}
												goodsItemInfo={
													pageData.goodsItemInfo
												}
												setPageData={setPageData}
												tableBodyData={tableBodyData}
												setTableBodyData={
													setTableBodyData
												}
												btnWord={t(
													'lgsBuyOrderGoodsInspect:page1.serialNumber'
												)}
												btnKeyName="material"
												key={`${bodyTitleList?.indexOf(
													el
												)}`}
												data={data}
											/>
										</TD>
									);
								})}

								{tableBodyData.length > 1 ? (
									<TD
										style={{
											padding: '0',
											border: 'none !important',
										}}
										className="last-td"
									>
										<Menu style={{ zIndex: '2' }}>
											<Button
												onClick={(e) => {
													handleDeletePaymentTerm(
														e,
														data,
														rowIndex
													);
												}}
											>
												<span>
													{t('button.deleteButton')}
												</span>
											</Button>
										</Menu>
									</TD>
								) : (
									<TD
										style={{
											padding: '0',
											border: 'none !important',
										}}
										className="last-td"
									>
										<Menu
											style={{
												zIndex: '2',
												display: 'none',
											}}
										>
											<Button
												onClick={(e) => {
													handleDeletePaymentTerm(
														e,
														data,
														rowIndex
													);
												}}
											>
												<span>刪除</span>
											</Button>
										</Menu>
									</TD>
								)}
							</TR>
						))}
					</TBody>
				</IndexTable>
			</IndexTableContainer>
		</div>
	);
}

export default GoodsInspectTable;
