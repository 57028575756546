import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { StepByStepBar } from '../../../../../components/StepByStepBar/StepByStepBar';
import { SaveSubmitBtn } from '../../../../../components/Buttons/SaveSubmitBtn';
import { CancelSubmitBtn } from '../../../../../components/Buttons/CancelSubmitBtn';
import useAxios from '../../../../../hooks/useAxios';
import GoodsItemTab from './GoodsItemTab/GoodsItemTab';
import { BtnNormal } from '../../../../../components/Buttons/BtnNormal';
import { useTranslation } from 'react-i18next';

// #region styled-components

// 換上下頁元件
const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div`
	display: flex;
`;

const Right = styled.div`
	display: flex;
`;

// 表格
const Wrapper = styled.div``;

//#endregion

function Page2(props) {
	const { parentData, page, processSteps, handlePrevPage, handleNextPage } =
		props;

	console.log('parentData', parentData);

	const { t } = useTranslation();

	// 整理給後端的資料
	const organizeData = () => {
		const oldParentData = { ...parentData };

		console.log(oldParentData);

		// 整理資料
		const inspectItemList = oldParentData.page1.goodsInspectList.map(
			({
				displayOrder,
				materialGuid,
				thisQuantityInspect,
				qualityCode,
				materialCubeGuid,
				tableBodyNote,
				serialList,
			}) => ({
				displayOrder,
				materialGuid,
				quality: thisQuantityInspect,
				qualityCategory: qualityCode,
				materialCubeGuid,
				note: tableBodyNote,
				serialList: serialList || null,
			})
		);
		let newData = {
			GoodsItemGuid: oldParentData.page0.goodsItemGuid,
			PostingDate: oldParentData.page1.goodsItemInfo.inspectDate, //要整理日期格式
			StaffGuid: oldParentData.page1.goodsItemInfo.employeeGuid,
			Note: oldParentData.page1.goodsItemInfo.note,
			MaterialGuid: oldParentData.page1.goodsItemInfo.materialGuid,
			inspectItemList: inspectItemList,
		};
		console.log('newData', newData);
		return newData;
	};

	//儲存
	const { error, sendRequest: axiosSaveData } = useAxios();

	const handleSubmit = () => {
		const newData = organizeData();
		console.log(newData);

		const url = `api/Logistics/LgsBuyOrderGoodsInspect/insert`;
		const method = 'POST';
		const data = newData;

		axiosSaveData({ url, method, data }, (res) => {
			console.log(res);
			res && handleNextPage();
		});
		console.log(error);
	};

	return (
		<>
			<Container>
				<Left>
					{page !== 0 && (
						<BtnNormal
							className={`layout-title-searchBtn layout-title-item btn btn-outline-primary justify-content-center align-items-center`}
							key={'PrevBtn'}
							onClick={() => {
								handlePrevPage();
							}}
						>
							{t('button.prevStep')}
						</BtnNormal>
					)}
				</Left>
				<Center className="ps-14">
					<StepByStepBar steps={processSteps} activePage={page} />
				</Center>
				<Right>
					<SaveSubmitBtn handleSubmit={handleSubmit} />
					<CancelSubmitBtn className={'btn-outline-primary'} />
				</Right>
			</Container>

			<div
				className="row bg-white rounded-xxl pb-0 mx-0"
				style={{ minHeight: '78vh' }}
			>
				<div className="col-12 px-0">
					<GoodsItemTab parentData={parentData} />
				</div>
			</div>
		</>
	);
}

export default Page2;
