import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState, memo } from 'react';

import Modal from '../../../../../../../components/Modals/IndexTableDisplayModal';
import { ReactComponent as Icn_Check } from '../../../../../../../assets/images/icn_Check-r.svg';
import { useTranslation } from 'react-i18next';

// #region styled-components
const IndexTableContainer = styled.div``;

const IndexTable = styled.table`
	margin-bottom: 0;
	color: var(--grey4);
	background-color: #fff;
`;

const THead = styled.thead`
	height: 40px;
`;

const TR = styled.tr`
	height: 40px;
	&:hover {
		cursor: ${(hoverEffect) =>
			hoverEffect === 'true' ? 'pointer' : 'default'};
		background-color: ${(hoverEffect) =>
			hoverEffect === 'true' ? 'var(--grey0)' : 'var(--bs-table-bg)'};
	}
`;

const TH = styled.th`
	vertical-align: middle;
	border: 1px solid var(--grey1) !important;
	&:first-of-type {
		border-left: none !important;
	}

	&:last-of-type {
		border-right: none !important;
	}
`;

const TBody = styled.tbody``;

const TFoot = styled.tfoot`
	border: none;
`;

const TD = styled.td`
	cursor: auto;
	border: 1px solid var(--grey1) !important;
	&:first-of-type {
		border-left: none !important;
	}

	&:last-of-type {
		border-right: none !important;
	}
`;

const FootTR = styled.tr`
	border-top: 2px solid var(--grey3) !important;
`;

const FootTD = styled.td`
	border: none !important;
`;
//#endregion

function LgsBuyOrder_PaymentTable(props) {
	// console.log(props);
	const { t } = useTranslation();

	const {
		guidName,
		sortBy,
		headTitleList,
		bodyTitleList,
		indexData,
		totalGrossAmount,
		totalForeignGrossAmount,
		totalExchangeDifferenceAmount,
		tableHoverEffect,
		tableHeadStyles,
		tableBodyStyles,
		foreignCurrency,
	} = props;

	console.log(props);

	const [orderBy, setOrderBy] = useState('desc');
	const [UISort, setUISort] = useState(sortBy);
	const navigate = useNavigate();
	const location = useLocation();

	return (
		<div className="position-relative">
			<IndexTableContainer className="firstRowMinHeight bg-white d-flex flex-column p-4 position-relative">
				<IndexTable className="table">
					<THead className="table-head">
						<TR>
							{headTitleList?.map((el) => {
								return (
									<TH
										key={`tableHead${headTitleList.indexOf(
											el
										)}`}
										style={
											tableHeadStyles[
												headTitleList.indexOf(el)
											]
										}
										// className={`
										// px-2
										// border ${getClassNamesFor(
										//     bodyTitleList[
										//         headTitleList.indexOf(el)
										//     ]
										// )}`}
										onClick={() => {
											requestSort(
												bodyTitleList[
													headTitleList.indexOf(el)
												]
											);
											handleSortChange(
												bodyTitleList[
													headTitleList.indexOf(el)
												]
											);
										}}
									>
										{el}
										{bodyTitleList[
											headTitleList.indexOf(el)
										] === UISort &&
											(orderBy === 'desc' ? (
												<span className="ml-1">▼</span>
											) : (
												<span className="ml-1">▲</span>
											))}
									</TH>
								);
							})}
						</TR>
					</THead>
					<TBody className="">
						{indexData?.map((data) => {
							return (
								<TR
									id={data[guidName]}
									key={data[guidName]}
									hoverEffect={tableHoverEffect}
								>
									{bodyTitleList.map((el) => {
										if (el === 'isReturn') {
											return data[el] === '1' ? (
												<TD
													style={
														tableBodyStyles[
															bodyTitleList.indexOf(
																el
															)
														]
													}
													className="p-2 border"
													key={`${el}${data[guidName]}`}
												>
													<Icn_Check />
												</TD>
											) : (
												<TD
													style={
														tableBodyStyles[
															bodyTitleList.indexOf(
																el
															)
														]
													}
													className="p-2 border"
													key={`${el}${data[guidName]}`}
												></TD>
											);
										}
										return (
											<TD
												style={
													tableBodyStyles[
														bodyTitleList.indexOf(
															el
														)
													]
												}
												className={`p-2 border 
                                                ${
													data[el] < 0 &&
													'text-danger'
												}`}
												key={`${el}${data[guidName]}`}
											>
												{data[el]}
											</TD>
										);
									})}
								</TR>
							);
						})}
					</TBody>
					{indexData?.length ? (
						<TFoot>
							<FootTR
								id="totalAmount"
								key="totalAmount"
								hoverEffect={tableHoverEffect}
								style={{ fontWeight: 'bold' }}
							>
								<FootTD className="p-2 text-right"></FootTD>
								<FootTD className="p-2 text-right"></FootTD>
								<FootTD className="p-2 text-right">
									{t('lgsBuyOrderPayment:page2.totalAmount')}
								</FootTD>
								<FootTD className="p-2 text-right"></FootTD>
								<FootTD className="p-2 text-right"></FootTD>
								<FootTD className="p-2 text-right"></FootTD>
								<FootTD
									className={`p-2 text-right ${
										totalGrossAmount < 0 && 'text-danger'
									}`}
								>
									{totalGrossAmount}
								</FootTD>
								{foreignCurrency && (
									<FootTD
										className={`p-2 text-right ${
											totalForeignGrossAmount < 0 &&
											'text-danger'
										}`}
									>
										{totalForeignGrossAmount}
									</FootTD>
								)}
								{foreignCurrency && (
									<FootTD
										className={`p-2 text-right ${
											totalExchangeDifferenceAmount < 0 &&
											'text-danger'
										}`}
									>
										{totalExchangeDifferenceAmount}
									</FootTD>
								)}
							</FootTR>
						</TFoot>
					) : (
						<TFoot></TFoot>
					)}
				</IndexTable>
				{/* {!items.length && (
					<>
						<div
							style={{
								textAlign: 'center',
								verticalAlign: 'middle',
								fontWeight: 'bold',
								marginTop: '16px',
							}}
						>
							(無資料)
						</div>
					</>
				)} */}
			</IndexTableContainer>
			<div>{<Modal isHovered={false} />}</div>
		</div>
	);
}

export default LgsBuyOrder_PaymentTable;
