import { useState, useEffect } from 'react';
import styled from 'styled-components';
import useAxios from '../../../../../hooks/useAxios';
import { StepByStepBar } from '../../../../../components/StepByStepBar/StepByStepBar';
import { NextBtnSmall } from '../../../../../components/Buttons/NextBtnSmall';
import TopInfo from './TopInfo';
import { DialogWarning } from '../DialogWarning';
import { BtnNormal } from '../../../../../components/Buttons/BtnNormal';
import BottomInfo from './BottomInfo';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

// #region styled-components

const IndexTableContainer = styled.div``;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
`;

const Left = styled.div`
    display: flex;
`;

const Center = styled.div`
    display: flex;
`;

const Right = styled.div`
    display: flex;
`;

//#endregion

function Page1(props) {
    //#region state initiation
    const { t, i18n } = useTranslation();
    const {
        setDialogConfirmData,
        parentData,
        setParentData,
        buyOrderData,
        page,
        processSteps,
        handleNextPage,
        dialogBtnKeyName,
        setDialogBtnKeyName,
        isOpen,
        setIsOpen,
        modalData,
        setModalData,
        warningMessage,
        setWarningMessage,
        CurrencyCode,
        CurrencyName,
        DecimalPlaceTl,
    } = props;

    const [pageData, setPageData] = useState({
        buyOrder: parentData?.buyOrder,
        employeeList: parentData?.employeeList,
        bankingAccountList: parentData?.bankingAccountList,
    });

    //#endregion

    //#region function

    // 暫存改被修改的資料
    const handleTemporaryStore = (pageData) => {
        const newParentData = { ...parentData, ...pageData };
        // const newPageData = { . };

        setParentData(newParentData);
    };

    // 回到page0 清空暫存資料
    const handleBackPage0 = () => {
        const newData = {
            btnName: 'goToPage0',
            isOpen: true,
            goTo: 'preStep',
            title:
                t('lgsBuyOrderPaymentClose:steps.confirmRechooseOrder') + '?',
        };
        setDialogConfirmData(newData);

        // handlePrevPage();
        // handleTemporaryStore();
    };

    // 往下一頁之前的檢查
    const handleSubmit = async () => {
        const newPayment = { ...pageData.payment };

        await setPageData({ ...pageData, payment: newPayment });

        handleTemporaryStore({ ...pageData, payment: newPayment });
        handleNextPage();
    };

    // state
    // axios
    const { sendRequest: axiosDataPage1 } = useAxios();

    //#endregion

    useEffect(() => {
        const isEverEnterPage = parentData.isEverEnterPage.page1;
        // console.log(isEverEnterPage);

        if (!isEverEnterPage) {
            // 1.axios get BE data
            // const url = `api/Logistics/LgsBuyOrderGoodsInvoice/getPage2?guid=${parentData.page0.buyOrderGuid}`;
            const url = `api/Logistics/LgsBuyOrderPaymentClose/getPage2?guid=${parentData.buyOrderGuid}`;
            const method = 'POST';

            axiosDataPage1({ url, method }, async (res) => {
                console.log(res);

                const localCurrency = res.buyOrder.foreignCurrency
                    ? `${CurrencyCode} ${CurrencyName}`
                    : null;

                setPageData({
                    ...res,
                    buyOrder: { ...res.buyOrder, localCurrency: localCurrency },
                    payment: {
                        postingDate: dayjs().format('YYYY-MM-DD'),
                        employeeGuid: res.employeeList[0].employeeGuid,
                        employeeName: res.employeeList[0].fullname,
                        receivingTransitionBalance:
                            res.buyOrder.receivingTransitionBalance,
                        accountsPayableBalance:
                            res.buyOrder.accountsPayableBalance,
                        varianceBalance: res.buyOrder.varianceBalance,
                        exchageRate: res.buyOrder.exchageRate,
                    },
                });
                setParentData({
                    ...parentData,
                    buyOrder: {
                        ...res?.buyOrder,
                        localCurrency: localCurrency,
                    },
                    payment: {
                        postingDate: dayjs().format('YYYY-MM-DD'),
                        employeeGuid: res.employeeList[0].employeeGuid,
                        employeeName: res.employeeList[0].fullname,
                        receivingTransitionBalance:
                            res.buyOrder.receivingTransitionBalance,
                        accountsPayableBalance:
                            res.buyOrder.accountsPayableBalance,
                        varianceBalance: res.buyOrder.varianceBalance,
                        exchageRate: res.buyOrder.exchageRate,
                    },
                    bankingAccountList: res?.bankingAccountList,
                    employeeList: res?.employeeList,
                    isEverEnterPage: {
                        ...parentData.isEverEnterPage,
                        page1: true,
                    },
                });
            });
        } else {
            setPageData({
                buyOrder: parentData?.buyOrder,
                employeeList: parentData?.employeeList,
                bankingAccountList: parentData?.bankingAccountList,
                payment: parentData?.payment,
            });
        }
    }, []);

    /**
     * DialogWarning 返回btn確認dialog
     **/
    const [isWarningOpen, setIsWarningOpen] = useState(false);

    // DialogWarning Modal open
    const handleDialogWarning = () => {
        setIsWarningOpen(true);
    };

    return (
        <>
            {/* DialogWarning element */}
            <DialogWarning
                isWarningOpen={isWarningOpen}
                setIsWarningOpen={setIsWarningOpen}
                warningMessage={warningMessage}
            />

            <Container className="">
                <Left className="">
                    {page !== 0 && (
                        <BtnNormal
                            className={`layout-title-searchBtn layout-title-item btn btn-outline-primary justify-content-center align-items-center`}
                            key={'PrevBtn'}
                            onClick={() => {
                                handleBackPage0();
                            }}
                        >
                            {t('common:button.prevStep')}
                        </BtnNormal>
                    )}
                </Left>
                <Center className="">
                    <StepByStepBar steps={processSteps} activePage={page} />
                </Center>
                <Right className="">
                    {page < processSteps.length - 1 ? (
                        <BtnNormal
                            className={`layout-title-searchBtn layout-title-item btn btn-primary justify-content-center align-items-center`}
                            key={'PrevBtn'}
                            onClick={(e) => {
                                e.preventDefault();
                                handleSubmit(page);
                            }}
                        >
                            {t('common:button.nextStep')}
                        </BtnNormal>
                    ) : (
                        <NextBtnSmall className="d-none" />
                    )}
                </Right>
            </Container>

            <div className="row bg-white rounded-xxl px-6 py-4 mx-0 mb-3">
                <TopInfo
                    pageData={pageData}
                    CurrencyCode={CurrencyCode}
                    CurrencyName={CurrencyName}
                    DecimalPlaceTl={DecimalPlaceTl}
                />
            </div>
            <div className="row bg-white rounded-xxl px-6 py-4 mx-0 mb-3">
                <BottomInfo
                    pageData={pageData}
                    setPageData={setPageData}
                    topInfoData={buyOrderData?.topInfoData}
                    buyOrderData={buyOrderData}
                    dialogBtnKeyName={dialogBtnKeyName}
                    setDialogBtnKeyName={setDialogBtnKeyName}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    modalData={modalData}
                    setModalData={setModalData}
                    CurrencyCode={CurrencyCode}
                    CurrencyName={CurrencyName}
                    DecimalPlaceTl={DecimalPlaceTl}
                />
            </div>
        </>
    );
}

export default Page1;
