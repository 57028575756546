import { useState, useEffect } from 'react';
import styled from 'styled-components';
import useAxios from '../../../../../hooks/useAxios';
import { StepByStepBar } from '../../../../../components/StepByStepBar/StepByStepBar';
import { NextBtnSmall } from '../../../../../components/Buttons/NextBtnSmall';
import InvoiceSplitTable from './InvoiceSplitTable';
import { DialogWarning } from '../DialogWarning';
import { BtnNormal } from '../../../../../components/Buttons/BtnNormal';
import { useTranslation } from 'react-i18next';

// #region styled-components

const IndexTableContainer = styled.div``;

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div`
	display: flex;
`;

const Right = styled.div`
	display: flex;
`;

//#endregion

function Page2(props) {
	//#region state initiation

	const { t } = useTranslation(['common', 'lgsBuyOrderInvoice']); // i18n

	// props
	const {
		parentData,
		setParentData,
		page,
		processSteps,
		handleNextPage,
		handlePrevPage,
	} = props;
	// console.log(props);

	const decimalPlaceTl =
		parentData.page0.buyOrderSelectedList[0].decimalPlaceTL;

	// state
	const [pageData, setPageData] = useState({
		invoiceSplitList: [],
	});
	const [isMultiSelected, setIsMultiSelected] = useState(false);
	const [tableBodyData, setTableBodyData] = useState([]);
	const [backEndData, setBackEndData] = useState({});
	const [dialogBtnKeyName, setDialogBtnKeyName] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	const [modalData, setModalData] = useState([]);
	const [warningMessage, setWarningMessage] = useState('');

	console.log('tableBodyData', tableBodyData);
	console.log('pageData', pageData);
	console.log('BackEndData', backEndData);

	//#endregion

	//#region variable initiation

	// table header 的名稱
	const headTitleList = [
		'#',
		t('lgsBuyOrderInvoice:vendorCode'),
		t('lgsBuyOrderInvoice:vendor'),
		t('lgsBuyOrderInvoice:orderDate'),
		t('lgsBuyOrderInvoice:orderNo'),
		t('lgsBuyOrderInvoice:certificateNumber'),
		t('lgsBuyOrderInvoice:purchaseTaxableTotal'),
		t('lgsBuyOrderInvoice:totalUncollectedInvoices'),
		t('lgsBuyOrderInvoice:totalCollectedInvoices'),
	];

	// table body 的資料名稱
	const bodyTitleList = [
		'displayOrder',
		'vendorCode',
		'vendor',
		'orderDate',
		'orderNo',
		'invoiceNo',
		'purchaseTaxableTotal',
		'totalUncollectedInvoices',
		'totalCollectedInvoices',
	];

	// 不同 input 的 type
	const inputProperties = [
		{},
		{},
		{},
		{},
		{},
		{},
		{},
		{},
		{ type: 'number' },
	];

	// table header 的 style
	const tableHeadStyles = [
		{
			width: '56px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'center',
		},
		{
			width: '160px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'center',
		},
		{
			width: '160px',
			textAlign: 'center',
		},
		{
			width: '200px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'center',
		},
		{
			width: '136px',
			textAlign: 'center',
		},
	];

	// table body 的 style
	const tableBodyStyles = [
		{
			width: '56px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'left',
		},
		{
			width: '160px',
			textAlign: 'left',
		},
		{
			width: '120px',
			textAlign: 'left',
		},
		{
			width: '160px',
			textAlign: 'left',
		},
		{
			width: '200px',
			textAlign: 'left',
		},
		{
			width: '120px',
			textAlign: 'right',
		},
		{
			width: '120px',
			textAlign: 'right',
		},
		{
			width: '136px',
			textAlign: 'right',
		},
	];

	//#endregion

	//#region function

	// 暫存被修改的資料
	const handleTemporaryStore = () => {
		const newParentData = { ...parentData };
		const newPageData = { ...pageData };

		// 在換頁時，1.先新增採購金額欄位(buyPrice,buyForeignPrice)
		// 2.先新增採購總金額欄位

		// let totalBuyPrice = 0;
		// let totalBuyForeignPrice = 0;

		newPageData.invoiceSplitList = tableBodyData; // newnew

		// newPageData.buyOrderItemList.forEach((el) => {
		// 	let buyForeignPrice = (
		// 		el.foreignNetPrice * el.quantityReceive
		// 	).toFixed(newPageData.backEndData.buyOrder.decimalPlaceTl);

		// 	let buyPrice = (el.netPrice * el.quantityReceive).toFixed(
		// 		newPageData.backEndData.buyOrder.decimalPlaceTl
		// 	);

		// 	el.buyForeignPrice = buyForeignPrice;
		// 	el.buyPrice = buyPrice;

		// 	totalBuyPrice += parseFloat(buyPrice);
		// 	totalBuyForeignPrice += parseFloat(buyForeignPrice);
		// });

		// newPageData.totalPriceInfo = {
		// 	totalBuyPrice: totalBuyPrice,
		// 	totalBuyForeignPrice: totalBuyForeignPrice,
		// };

		newParentData.page2 = newPageData;
		// 標記是否曾拿取過資料
		newParentData.isEverEnterPage.page2 = true;

		setParentData(newParentData);
	};

	console.log('parentData', parentData);

	console.log('isMultiSelected', isMultiSelected);

	// 往下一頁之前的檢查
	const handleSubmit = () => {
		let falseCount = 0;

		if (isMultiSelected) {
			const invoiceTotalAmount =
				parentData.page1.invoiceItemList[0].totalAmount; // 發票明細page1 應稅總額

			const allCollectedInvoices =
				pageData.allAmount.allCollectedInvoices; // 發票拆分page2 應稅總額

			if (invoiceTotalAmount !== allCollectedInvoices) {
				console.log(invoiceTotalAmount, allCollectedInvoices);
				falseCount++;

				setWarningMessage(
					t(
						'lgsBuyOrderInvoice:mustSplitTotalAmountForMultiplePurchaseOrders'
					) + '！'
				);
				handleDialogWarning();
			}
		}

		// 驗證通過
		console.log(falseCount);
		if (falseCount === 0) {
			handleTemporaryStore();
			handleNextPage();
		}
	};

	//#endregion

	//#region DialogWarning

	// 返回btn確認dialog

	const [isWarningOpen, setIsWarningOpen] = useState(false);

	// Modal open
	const handleDialogWarning = () => {
		setIsWarningOpen(true);
	};

	//#endregion

	// 計算總額
	const calAllInvoice = () => {
		let allCollectedInvoices = 0;

		tableBodyData.forEach((el) => {
			allCollectedInvoices += Number(el.totalCollectedInvoices);
		});
		allCollectedInvoices = allCollectedInvoices.toFixed(decimalPlaceTl);

		console.log(allCollectedInvoices);

		setPageData({
			...pageData,
			allAmount: { allCollectedInvoices: allCollectedInvoices },
		});
	};

	//#region useEffect
	useEffect(() => {
		const isEverEnterPage = parentData.isEverEnterPage.page2;

		const { buyOrderSelectedList } = parentData.page0;
		const { invoiceItemList } = parentData.page1;
		buyOrderSelectedList.length > 1
			? setIsMultiSelected(true)
			: setIsMultiSelected(false);

		let invoiceSplitList = buyOrderSelectedList.map((el) => {
			return {
				buyOrderGuid: el.buyOrderGuid,
				displayOrder: el.displayOrder,
				vendorCode: el.vendorCode,
				vendor: el.vendor,
				orderDate: el.orderDate,
				orderNo: el.orderNo,
				purchaseTaxableTotal: el.grossAmount,
				totalUncollectedInvoices: el.foreignCurrency
					? Number(el.displayGrossAmount) -
					  Number(el.foreignGrossAmountInvoice)
					: Number(el.grossAmount) - Number(el.grossAmountInvoice),
			};
		});

		let allCollectedInvoices = 0; // 收發票總額

		// console.log(buyOrderSelectedList);
		// 多筆訂單
		if (buyOrderSelectedList.length > 1) {
			invoiceSplitList.forEach((el) => {
				el.invoiceNo =
					invoiceItemList.length > 0 && invoiceItemList[0].invoiceNo;
				el.totalCollectedInvoices = '';
			});
		} else if (buyOrderSelectedList.length === 1) {
			// 單筆訂單
			// console.log(invoiceSplitList);
			invoiceSplitList = invoiceItemList.map((el, index) => {
				return {
					...invoiceSplitList[0],

					displayOrder: index + 1,
					invoiceNo: el.invoiceNo,
					totalCollectedInvoices: el.totalAmount,
				};
			});

			// 計算總額
			invoiceSplitList.forEach((el) => {
				allCollectedInvoices += Number(el.totalCollectedInvoices);
			});
			allCollectedInvoices = allCollectedInvoices.toFixed(decimalPlaceTl);

			console.log(allCollectedInvoices);
		}
		console.log(invoiceSplitList);

		if (buyOrderSelectedList.length === 1) {
			const updatedInvoiceSplitList = invoiceSplitList.map(
				(el, index) => {
					return {
						...el,
						...invoiceItemList[index], // 將每一筆的發票資訊帶入
					};
				}
			);
			console.log(updatedInvoiceSplitList);

			setTableBodyData(updatedInvoiceSplitList);

			const newPageData = {
				...pageData,
				invoiceSplitList: updatedInvoiceSplitList,
				allAmount: {
					allCollectedInvoices: allCollectedInvoices,
				},
			};

			setPageData(newPageData);
		} else if (!isEverEnterPage && buyOrderSelectedList.length > 1) {
			const updatedInvoiceSplitList = invoiceSplitList.map((el) => {
				return {
					...el,
					...invoiceItemList[0], // 將唯一一張的發票資訊帶入
				};
			});
			console.log(updatedInvoiceSplitList);

			setTableBodyData(updatedInvoiceSplitList);

			const newPageData = {
				...pageData,
				invoiceSplitList: updatedInvoiceSplitList,
				allAmount: {
					allCollectedInvoices: allCollectedInvoices,
				},
			};

			setPageData(newPageData);
		} else if (isEverEnterPage && buyOrderSelectedList.length > 1) {
			// 多筆訂單且曾經編輯過發票拆分，則1.收發票總額 2.收發票總額總計 需使用編輯過的
			const oldPage2Data = { ...parentData.page2 };
			console.log(oldPage2Data);

			const updatedInvoiceSplitList = invoiceSplitList.map(
				(el, index) => {
					return {
						...el,
						totalCollectedInvoices:
							oldPage2Data.invoiceSplitList[index]
								.totalCollectedInvoices,

						...invoiceItemList[0], // 將唯一一張的發票資訊帶入
					};
				}
			);
			console.log(updatedInvoiceSplitList);

			const newPageData = {
				...pageData,
				invoiceSplitList: updatedInvoiceSplitList,
				allAmount: {
					...pageData.allAmount,
					allCollectedInvoices:
						oldPage2Data.allAmount.allCollectedInvoices,
				},
			};
			console.log(newPageData);

			setTableBodyData(updatedInvoiceSplitList);
			setPageData(newPageData);
		}
	}, []);
	//#endregion

	return (
		<>
			{/* DialogWarning element */}
			<DialogWarning
				isWarningOpen={isWarningOpen}
				setIsWarningOpen={setIsWarningOpen}
				warningMessage={warningMessage}
			/>

			<Container className="">
				<Left className="">
					{page !== 0 && (
						<BtnNormal
							className={`layout-title-searchBtn layout-title-item btn btn-outline-primary justify-content-center align-items-center`}
							key={'PrevBtn'}
							onClick={() => {
								handlePrevPage();
								handleTemporaryStore();
							}}
						>
							{t('common:button.prevStep')}
						</BtnNormal>
					)}
				</Left>
				<Center className="">
					<StepByStepBar steps={processSteps} activePage={page} />
				</Center>
				<Right className="">
					{page < processSteps.length - 1 ? (
						<BtnNormal
							className={`layout-title-searchBtn layout-title-item btn btn-primary justify-content-center align-items-center`}
							key={'PrevBtn'}
							onClick={(e) => {
								e.preventDefault();
								handleSubmit(page);
							}}
						>
							{t('common:button.nextStep')}
						</BtnNormal>
					) : (
						<NextBtnSmall className="d-none" />
					)}
				</Right>
			</Container>

			<div className="position-relative">
				<IndexTableContainer className="bg-white rounded-xxl d-flex flex-column px-2 py-3 position-relative">
					<InvoiceSplitTable
						pageData={pageData}
						setPageData={setPageData}
						parentData={parentData}
						headTitleList={headTitleList}
						bodyTitleList={bodyTitleList}
						tableHeadStyles={tableHeadStyles}
						tableBodyStyles={tableBodyStyles}
						tableBodyData={tableBodyData}
						setTableBodyData={setTableBodyData}
						inputProperties={inputProperties}
						isMultiSelected={isMultiSelected}
						calAllInvoice={calAllInvoice}
					/>
				</IndexTableContainer>
			</div>
		</>
	);
}

export default Page2;
