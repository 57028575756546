import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import PaymentTermTable from '../../Details/TabContent/PaymentTerm/PaymentTermTable';
import useAxios from '../../../../../hooks/useAxios';
import { StepByStepBar } from '../../../../../components/StepByStepBar/StepByStepBar';
import { PrevBtnSmall } from '../../../../../components/Buttons/PrevBtnSmall';
import { NextBtnSmall } from '../../../../../components/Buttons/NextBtnSmall';
import TopInfo from './TopInfo';
import { useCookies } from 'react-cookie';
import showAlert from '../../../../../utils/swal';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

// #region styled-components

const IndexTableContainer = styled.div``;

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div`
	display: flex;
`;

const Right = styled.div`
	display: flex;
`;

//#endregion

// 付款條件
// Author: YL
function Page2(props) {
	//#region state initiation
	const { t } = useTranslation();

	const {
		buyOrderData,
		setBuyOrderData,
		page,
		processSteps,
		handlePrevPage,
		handleNextPage,
	} = props;
	// console.log(props);

	const topInfoData = buyOrderData?.topInfoData;
	const vendorGuid = buyOrderData?.page0?.vendorGuid;
	const { foreignCurrency, decimalPlaceTl } = buyOrderData.page0;

	// (修改頁面)來自後端的資料，刪除後必須保留，暫存於此
	const [deletedTermList, setDeleteTermList] = useState([]);

	// 每一筆付款條件
	// todo: 命名改寫
	const [tableBodyData, setTableBodyData] = useState([]);
	// 各付款條件的加總 & 加總百分比
	const [totals, setTotals] = useState({
		totalAmount: 0,
		totalPercentage: 0,
	});

	const [cookies] = useCookies();

	// ?: error 的運用 ?
	const { isLoading, error, sendRequest: axiosPaymentTermList } = useAxios();
	if (error) {
		console.log('error !', error);
	}

	//#endregion

	//#region variable initiation

	// 假資料
	// topInfoData.foreignNetAmount = 10000;
	// topInfoData.netAmount = 10000;
	// topInfoData.foreignTaxAmount = 2000;
	// topInfoData.taxAmount = 2000;
	// topInfoData.foreignGrossAmount = 948787;
	// topInfoData.grossAmount = 8787;
	// buyOrderData.page0.decimalPlaceTl = 3;

	// 本幣/外幣交易 -> 對應資料欄位不同
	const key = foreignCurrency ? 'foreignAmount' : 'amount';
	// 本幣/外幣交易 -> 小數點會不同
	const decimalPlace = foreignCurrency
		? decimalPlaceTl
		: cookies._company_currency_decimalplace_tl;

	// table header 的名稱
	const headTitleList = [
		'#',
		t('lgsBuyOrder:paymentDateBasis'),
		t('lgsBuyOrder:paymentDateDays'),
		t('lgsBuyOrder:paymentDate'),
		t('lgsBuyOrder:paymentAmount'),
		t('lgsBuyOrder:paymentPercentage'),
		t('lgsBuyOrder:annotation'),
	];

	// table body 的資料名稱
	const bodyTitleList = [
		'displayOrder',
		'baseOn',
		'days',
		'payDate',
		key,
		'percentage',
		'note',
	];

	// 不同 input 有沒有小數點的要求
	const toFloatList = ['', '', '', '', 'decimalPlace', 'decimalPlace', ''];

	// 不同 input 的 type
	const inputProperties = [
		{ type: 'number' },
		{ type: 'text' },
		{
			type: 'number',
			min: 1,
		},
		{ type: 'date' },
		{
			type: 'number',
			onBlur: () =>
				handleCalculateAmount('changingAmount', tableBodyData),
		},
		{
			type: 'number',
			onBlur: () =>
				handleCalculateAmount('changingPercentage', tableBodyData),
		},
	];

	// table header 的 style
	const tableHeadStyles = [
		{
			width: '48px',
			textAlign: 'center',
		},
		{
			width: '200px',
			textAlign: 'center',
		},
		{
			width: '200px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'center',
		},
		{
			width: '160px',
			textAlign: 'center',
		},
		{
			textAlign: 'center',
		},
		{
			width: '304px',
			textAlign: 'center',
		},
	];

	// table body 的 style
	const tableBodyStyles = [
		{
			width: '48px',
			textAlign: 'center',
		},
		{
			width: '200px',
			textAlign: 'left',
		},
		{
			width: '200px',
			textAlign: 'left',
		},
		{
			width: '120px',
			textAlign: 'left',
		},
		{
			width: '160px',
			textAlign: 'right',
		},
		{
			textAlign: 'right',
		},
		{
			width: '304px',
			textAlign: 'left',
		},
	];

	//#endregion

	//#region function

	// 轉換浮點數，並四捨五入到小數第decimalPlaces位
	function convertToFloat(inputStr, decimalPlace) {
		let floatNum = parseFloat(inputStr);

		if (!isNaN(floatNum)) {
			floatNum =
				Math.round(floatNum * Math.pow(10, decimalPlace)) /
				Math.pow(10, decimalPlace);
		}

		return floatNum;
	}

	const handleCalculateAmount = (isChanging, list) => {
		const copyList = [];
		let newTotalAmount = 0;
		let newTotalPercentage = 0;

		// isChanging = true -> 使用者完成輸入
		// isChanging = false -> 比例拆分

		list.map((el, index) => {
			let copy;
			const total = foreignCurrency
				? Number(topInfoData.foreignGrossAmount)
				: Number(topInfoData.grossAmount);

			// 使用者完成輸入
			if (isChanging) {
				// console.log(isChanging);
				// 改變百分比
				if (!isChanging === 'changingPercentage') {
					const percentage = Number(el.percentage);
					const payment = (total * percentage) / 100;
					copy = {
						...el,
						[key]: convertToFloat(payment, decimalPlace),
					};

					newTotalAmount += payment;
					newTotalPercentage += percentage;

					// 改變數值
				} else if (isChanging === 'changingAmount') {
					const payment = Number(el[key]);
					// console.log(total / payment, total, payment);
					const percentage = (payment / total) * 100;
					copy = {
						...el,
						percentage: convertToFloat(percentage, 2),
					};

					newTotalAmount += payment;
					newTotalPercentage += percentage;
				} else {
					const percentage = Number(el.percentage);

					const payment = (total * percentage) / 100;
					copy = {
						...el,
						[key]: convertToFloat(payment, decimalPlace),
					};

					newTotalAmount += payment;
					newTotalPercentage += percentage;
				}
				// 比例拆分
			} else {
				const percentage = Number(el.percentage);

				const payment = (total * percentage) / 100;
				copy = {
					...el,
					[key]: convertToFloat(payment, decimalPlace),
				};

				newTotalAmount += payment;
				newTotalPercentage += percentage;
			}

			// console.log(newTotalAmount, newTotalPercentage);
			setTotals({
				totalAmount: convertToFloat(newTotalAmount, decimalPlace),
				totalPercentage: convertToFloat(
					newTotalPercentage,
					decimalPlace
				),
			});

			copyList.push(copy);
			// console.log(copy);
			// console.log(copyList);
		});
		setTableBodyData(copyList);
	};

	// 當刪除條件時, 會檢查被刪除的 element 的 action 是不是 update
	// 如果是 update, 代表是原本的資料, 需要把原資料留下, 以讓後端知道哪些原本的資料被刪
	const handleStoreDeleteTerm = (arr) => {
		// console.log(arr);
		const deleteList = [];
		arr.forEach((el) => {
			if (el.action === 'update') {
				el.action = 'delete';
				deleteList.push(el);
			}
		});

		return deleteList;
	};

	// 比例拆分
	const handleSplit = () => {
		const url = `api/Logistics/LgsBuyOrder/getVendorPaymentTerm?VendorGuid=${vendorGuid}`;
		axiosPaymentTermList({ url }, (res) => {
			// console.log(res);

			// 比例拆分時, 可能會把原本的資料洗掉, 所以要執行 handleStoreDeleteTerm
			const deleteList = handleStoreDeleteTerm(tableBodyData);
			setDeleteTermList([...deletedTermList, ...deleteList]);

			// 把所有資料的預設行為都設定為 insert
			res.vendorPaymentTermList.map((el) => (el.action = 'insert'));

			setTableBodyData(res?.vendorPaymentTermList);
			handleCalculateAmount(false, res?.vendorPaymentTermList);
		});
	};

	// 暫存改被修改的資料
	const handleTemporaryStore = () => {
		const newData = { ...buyOrderData };
		newData.page2.paymentTermList = tableBodyData;
		newData.page2.totals = totals;
		newData.page2.deletedTermList = deletedTermList;
		setBuyOrderData(newData);
		// console.log(newData);
	};

	// 往下一頁之前的檢查
	const handleSubmit = () => {
		let isValid = false;

		// 如果 (沒有totalAmount 或 沒有付款條件) -> 不用檢查
		if (tableBodyData.length && totalAmount) {
			// Step 1:
			// 檢查所有必填欄位是否都有資料
			let allValuesAreFilledIn = tableBodyData.every((el) => {
				/// 必填欄位
				// 付款日期基礎
				// 本幣付款金額 / 外幣付款金額
				// 付款百分比
				// 註記
				const keysToCheck = [
					'baseOn',
					foreignCurrency ? 'foreignAmount' : 'amount',
					'percentage',
					'note',
				];

				// 所有欄位都有資料 -> true, otherwise -> false
				const valueIsFilledIn = keysToCheck.every((key) => {
					return (
						el.hasOwnProperty(key) &&
						el[key] !== null &&
						el[key] !== undefined &&
						el[key] !== ''
					);
				});

				// console.log(valueIsFilledIn);
				return valueIsFilledIn;
			});

			// console.log(allValuesAreFilledIn);

			// Step 2:
			// if (所有必填欄位都有資料)
			//檢查付款金額總額與本幣應稅總額/外幣應稅總額是否相同
			if (allValuesAreFilledIn) {
				const totalAmount = foreignCurrency
					? Number(topInfoData.foreignGrossAmount)
					: Number(topInfoData.grossAmount);

				const alertMessage = foreignCurrency
					? t(
							'lgsBuyOrder:totalPaymentAmountMustEqualForeignCurrencyTaxableAmount'
					  )
					: t(
							'lgsBuyOrder:totalPaymentAmountMustEqualLocalCurrencyTaxableAmount'
					  );

				// 付款金額總額與本幣應稅總額/外幣應稅總額不相同 -> alert提醒
				if (totalAmount !== totals.totalAmount) {
					return showAlert({
						html: `<div class="mt-4 text-danger font-weight-bold">${alertMessage}</div>`,
						htmlSvg: 'danger',
						size: 'medium',
						confirmButtonText: t('button.confirmButton'),
						showCloseButton: true,
						allowOutsideClick: false,
						// title: '我是 title 哦',
						// icon: 'warning',
						// showCancelButton: false,
						// cancelButtonText: '取消的字哦',
					});
				} else {
					isValid = true;
				}
			}

			// 不是所有必填欄位都有資料 -> alert提醒
			if (!allValuesAreFilledIn)
				return showAlert({
					html: `<div class="mt-4 text-danger font-weight-bold">${t(
						'lgsBuyOrder:pleaseFillOutCompleteInformation'
					)}</div>`,
					htmlSvg: 'danger',
					size: 'small',
					confirmButtonText: t('button.confirmButton'),
					showCloseButton: true,
					allowOutsideClick: false,
					// title: '我是 title 哦',
					// icon: 'warning',
					// showCancelButton: false,
					// cancelButtonText: '取消的字哦',
				});
		} else {
			isValid = true;
		}

		if (isValid) {
			handleTemporaryStore();
			return handleNextPage();
		}
	};

	// 取得本訂單「原本的」付款條件
	const getData = useCallback(() => {
		const guid = buyOrderData.page0.buyOrderGuid;
		const url = `api/Logistics/LgsBuyOrder/geteditdatapage2?guid=${guid}`;

		axiosPaymentTermList({ url }, (res) => {
			console.log(res);

			// 把所有資料的預設行為都先設定為 update
			res.buyOrderPaymentTermList.map((el) => {
				el.action = 'update';

				// 把 date 改成 html input 接受的格式
				// todo: 可能之後要改寫 -> 公司的日期格式
				// todo2: 拉出來 -> 共用 function
				const date = dayjs(el.payDate);
				el.payDate = date.format('YYYY-MM-DD');

				if (!el.payDate || el.payDate === 'Invalid Date') {
					el.payDate = '';
				}
			});

			setBuyOrderData({
				...buyOrderData,
				page2: {
					...buyOrderData.page2,
					paymentTermList: res.buyOrderPaymentTermList,
				},
			});
			handleCalculateAmount(false, res?.buyOrderPaymentTermList);
		});
	}, []);

	//#endregion

	useEffect(() => {
		//第一次進來，先從後端拿資料
		const isEverEnterPage = buyOrderData.isEverEnterPage.page2;
		if (!isEverEnterPage) {
			getData();

			// 標記是否曾拿取過資料
			let parentData = { ...buyOrderData };
			parentData.isEverEnterPage.page2 = true;

			setBuyOrderData(parentData);
		}

		setTableBodyData(buyOrderData.page2.paymentTermList);
		setDeleteTermList(buyOrderData.page2.deletedTermList);
		handleCalculateAmount(false, buyOrderData.page2.paymentTermList);
	}, []);

	//#endregion

	return (
		<>
			<Container className="">
				<Left className="">
					{/* page0 */}
					{page !== 0 && (
						<PrevBtnSmall
							handleClick={() => {
								handlePrevPage();
								handleTemporaryStore();
							}}
						/>
					)}
					{/* page0 */}
				</Left>
				<Center className="">
					<StepByStepBar steps={processSteps} activePage={page} />
				</Center>
				<Right className="">
					{page < processSteps.length - 1 ? (
						<NextBtnSmall
							handleClick={() => {
								handleSubmit(page);
							}}
						/>
					) : (
						<NextBtnSmall className="d-none" />
					)}
				</Right>
			</Container>
			<div className="position-relative">
				<div className="bg-white d-flex flex-column p-4 position-relative">
					<TopInfo
						topInfoData={buyOrderData?.topInfoData}
						buyOrderData={buyOrderData}
					/>
				</div>
			</div>
			<div className="position-relative">
				<IndexTableContainer className="bg-white d-flex flex-column p-4 position-relative">
					<PaymentTermTable
						headTitleList={headTitleList}
						bodyTitleList={bodyTitleList}
						tableHeadStyles={tableHeadStyles}
						tableBodyStyles={tableBodyStyles}
						tableBodyData={tableBodyData}
						setTableBodyData={setTableBodyData}
						totals={totals}
						handleSplit={handleSplit}
						inputProperties={inputProperties}
						decimalPlace={decimalPlace}
						toFloatList={toFloatList}
						deletedTermList={deletedTermList}
						setDeleteTermList={setDeleteTermList}
					/>
				</IndexTableContainer>
			</div>
		</>
	);
}

export default Page2;
