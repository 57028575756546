import React, { useState, useRef, useEffect } from 'react';
import useAxios from '../../../../../hooks/useAxios';
import styled from 'styled-components';
import { ReactComponent as AddCard } from '../../../../../assets/images/addCard.svg';
import { ReactComponent as AddCard_plus } from '../../../../../assets/images/addCard_plus.svg';
import { ReactComponent as PictureSmallIcon } from '../../../../../assets/images/pictureSmallIcon.svg';
import Appendix from '../../Details/TabContent/Appendix/Appendix';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { StepByStepBar } from '../../../../../components/StepByStepBar/StepByStepBar';
import { PrevBtnSmall } from '../../../../../components/Buttons/PrevBtnSmall';
import { NextBtnSmall } from '../../../../../components/Buttons/NextBtnSmall';
import TopInfo from './TopInfo';

// #region styled-components
// tab zone
const TabContainer = styled.div``;

const buttonStyles = {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '32px',
    alignItems: 'center',
    border: '1px solid #1278E8',
    marginBottom: 8,
    borderRadius: '10px',
};

const colStyle = {
    margin: 10,
    marginTop: 32,
    marginLeft: 34,
    marginBottom: 48,
    padding: 20,
    border: '1px solid #EFF2F5',
    borderRadius: '10px',
};
const col2Style = {
    margin: 10,
    marginRight: 34,
    marginTop: 32,
    border: '0px solid #EFF2F5',
    display: 'flex',
};

const inner = {
    width: '224px',
    height: '152px',
    backgroundColor: '#EFF2F5',
    backgroundImage: `url("../../../../../assets/1.jpg")`,
    borderTop: '1px solid blue',
    borderLeft: '1px solid blue',
    borderRight: '1px solid blue',
    borderRadius: '10px 10px 0px 0px',
    textAlign: 'center',
};

const inner2 = {
    width: '224px',
    height: '48px',
    borderBottom: '1px solid blue',
    borderLeft: '1px solid blue',
    borderRight: '1px solid blue',
    borderRadius: '0px 0px 10px 10px',
    textAlign: 'center',
};

const inputA = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '12px',
};

const AppendixCard = styled.div`
    height: 200px;
    width: 224px;
    margin-right: 16px;
    margin-bottom: 16px;
    border-radius: 8px;
    border: 1px solid #eff2f5;
    border: ${(props) =>
        props.activeCard ? '1px solid var(--primary)' : '1px solid #eff2f5'};
    overflow: hidden;
    cursor: pointer;
    position: relative;
`;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
`;

const Left = styled.div`
    display: flex;
`;

const Center = styled.div`
    display: flex;
`;

const Right = styled.div`
    display: flex;
`;

//#endregion

// 附件
// Author: YL
function Page3(props) {
    // console.log(props);
    const {
        view,
        page,
        processSteps,
        handlePrevPage,
        handleNextPage,
        buyOrderData,
        setBuyOrderData,
        copyBuyOrderData,
    } = props;

    // 被選中的附件分類
    const [activeTab, setActiveTab] = useState('');
    // 各附件分類 guid
    const [appendixClasses, setAppendixClasses] = useState([]);
    // 記住哪個 file 被按的 ref
    const fileInputRef = useRef(null);
    // 記住哪個 card 被按
    const [activeCard, setActiveCard] = useState('');
    // 檔案其他資訊, 如檔案分類 guid
    const [appendixInfo, setAppendixInfo] = useState([]);
    // 檔案位置
    const [appendixData, setAppendixData] = useState([]);
    // 存放檔案
    const [fileList, setFileList] = useState([]);

    const {
        isLoading,
        error,
        sendRequest: axiosAppendixClassList,
    } = useAxios();

    const {
        // isLoading,
        // error,
        sendRequest: axiosData,
    } = useAxios();

    // 上傳文件
    function handleFileUpload(e, appendixClassGuid) {
        const target = e.target.files;
        if (target.length) {
            // 文件本體
            const file = target[0];
            // 文件路徑
            const blobURL = URL.createObjectURL(file);
            // 文件名稱
            const fileName = file.name ? file.name.split('.')[0] : 'file';
            // 文件類型
            const fileType = file.name ? file.name.split('.').pop() : 'file';

            // handle the uploaded file here
            const newAppendixClass = [...appendixClasses];

            // 把 file 放到所屬的分類當中
            newAppendixClass.forEach((el) => {
                // console.log(el);
                if (el.appendixClassGuid === appendixClassGuid) {
                    // console.log(el);
                    const newAppendixInfo = [...appendixInfo];
                    const newAppendixData = [...appendixData];
                    const newFileList = [...fileList];

                    // 每個 appendixClass 中的附件 都有自己的 displayOrder, 由 1 開始算
                    let displayOrder = 1;
                    newAppendixInfo.map((item) => {
                        if (item.appendixClass === appendixClassGuid) {
                            displayOrder++;
                        }
                    });

                    // 設定 states
                    const infoObject = {
                        action: 'insert',
                        appendixClass: appendixClassGuid,
                        appendixName: fileName,
                        appendixType: fileType,
                    };

                    const dataObject = {
                        appendixData: blobURL,
                    };

                    newAppendixInfo.push(infoObject);
                    newAppendixData.push(dataObject);
                    newFileList.push(file);
                    setAppendixInfo(newAppendixInfo);
                    setAppendixData(newAppendixData);
                    setFileList(newFileList);
                }
            });
        }
    }

    /// ***: 為什麼一定要用 handleSelectTab 要改變 activeTab?
    /// ***: 為什麼不用傳 selectedTab 參數到 handleSelectTab, handleSelectTab 也知道 selectedTab 是什麼?
    /// AI 的解答如下:
    // selectedTab 是由 activeKey 屬性提供的而 onSelect 屬性則是用於在選擇標籤時觸發事件，從而更新 activeKey 狀態。
    // handleSelectTab 函數是作為 onSelect 屬性的回調函數，而 selectedTab 參數則是由 handleSelectTab 函數自動接收的，代表了選擇的標籤的值。
    // 因此，您不能直接在 selectedTab 屬性中使用 selectedTab 變量，因為這個變量是由 handleSelectTab 函數在用戶選擇標籤時接收的，並且是動態的。
    // 如果您需要將 activeKey 狀態更新為用戶選擇的標籤值，請繼續使用 handleSelectTab 函數，並在該函數中調用 setActiveTab 函數更新狀態
    const handleSelectTab = (selectedTab) => {
        setActiveTab(selectedTab);
    };

    // 雙擊下載
    // todo: 沒有彈出選擇儲存位置, 之後再處理
    const handleDoubleClickCard = (e, el, index) => {
        // 防止 double click input 也會下載檔案
        if (e.target.tagName.toLowerCase() !== 'input') {
            const fileName = el.appendixName;
            const file = appendixData[index].appendixData;

            const link = document.createElement('a');
            link.href = file;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    // 顯示卡片
    const handleCardImage = (info, data) => {
        console.log(info, data);
        const { appendixClass, appendixName, appendixType } = info;
        const { appendixData } = data;

        const fileType = appendixType.toUpperCase();
        if (['PNG', 'JPG', 'JPGE', 'GIF'].some((value) => fileType === value)) {
            return <img className="h-100 w-100" src={appendixData} />;
        } else if (['DOC', 'DOCX'].some((value) => fileType === value)) {
            return (
                <h6 className="m-0 px-2 py-2 bg-primary rounded-lg text-white">
                    {fileType}
                </h6>
            );
        } else if (['PDF', 'PPT', 'PPTX'].some((value) => fileType === value)) {
            return (
                <h6 className="m-0 px-2 py-2 bg-danger rounded-lg text-white">
                    {fileType}
                </h6>
            );
        } else if (['XLS', 'XLSX'].some((value) => fileType === value)) {
            return (
                <h6 className="m-0 px-2 py-2 bg-success rounded-lg text-white">
                    {fileType}
                </h6>
            );
        } else {
            return (
                <h6 className="m-0 px-2 py-2 bg-secondary rounded-lg text-white">
                    {fileType}
                </h6>
            );
        }
    };

    const getAppendixClassList = () => {
        axiosAppendixClassList(
            {
                url: `api/logistics/Lgsbuyorder/getcreatedatapage3`,
            },
            (res) => {
                console.log(res);
                setAppendixClasses(res.lgsAppendixClassList);
                setActiveTab(res.lgsAppendixClassList[0].appendixClassGuid);
            }
        );
    };

    // todo: 詳細講講 composition
    // 用於記錄 composition 狀態用
    let isOnComposition = false;

    const handleComposition = (e, index) => {
        const val = e.target.value;

        if (e.type === 'compositionend') {
            //composition結束，代表中文輸入完成
            console.log('compositionend');
            isOnComposition = false;
            handleChangeAppendixName(val, index);
        } else {
            //composition進行中，代表正在輸入中文
            isOnComposition = true;
        }
    };

    // 改名字
    const handleChangeAppendixName = (val, index) => {
        const newAppendixInfo = [...appendixInfo];
        newAppendixInfo[index].appendixName = val;
        setAppendixInfo(newAppendixInfo);
    };

    // 刪卡片
    const handleDeleteAppendix = (index2) => {
        // 將要刪除的 item 把 action 改成 delete, 以供 UI render 和 之後後端處理資料
        const newAppendixInfo = [];
        appendixInfo.map((el, index) => {
            if (index2 === index) {
                // console.log(el);
                if (el.action === 'update') {
                    newAppendixInfo.push({ ...el, action: 'delete' });
                }
            } else {
                newAppendixInfo.push(el);
            }
        });

        // 因為 appendixData 要和 appendixInfo 保持一致性, 所以要改用 appendixInfo 的 action 是不是 delete 來決定要不要 render 此 item
        // 要刪除的 item 不會被放到 newAppendixData 裡
        const newAppendixData = [];
        appendixData.map((el, index) => {
            if (index2 === index) {
                // console.log(el);
                if (appendixInfo[index].action === 'update') {
                    newAppendixData.push(el);
                }
            } else {
                newAppendixData.push(el);
            }
        });

        // 要刪除的 item 不會被放到 newFileList 裡
        const newFileList = [];
        fileList.map((el, index) => {
            if (index2 === index) {
                // console.log(el);
                if (appendixInfo[index].action === 'update') {
                    newFileList.push(el);
                }
            } else {
                newFileList.push(el);
            }
        });

        const targetItem = [...appendixInfo].filter(
            (_, index) => index === index2
        );

        setAppendixInfo(newAppendixInfo);
        setAppendixData(newAppendixData);
        setFileList(newFileList);
    };

    // 暫存改被修改的資料
    const handleTemporaryStore = () => {
        const newData = { ...buyOrderData };

        appendixInfo.map((item, index) => {
            item.displayOrder = index + 1;
        });

        newData.page3.appendixInfo = appendixInfo;
        newData.page3.appendixData = appendixData;
        newData.page3.fileList = fileList;

        setBuyOrderData(newData);
    };

    const handleSubmit = () => {
        handleTemporaryStore();
        handleNextPage();
    };

    // 從 edit 來的資料不太符合 UI 中資料的設計, 所以要轉換
    const convertDataFormat = (data) => {
        // console.log(data);
        const originalAppendixInfo = [];
        const originalAppendixData = [];
        const originalFileList = [];

        data.map((el) => {
            // console.log(el);
            originalAppendixInfo.push({
                action: 'update',
                attachmentGuid: el.attachmentGuid,
                appendixClass: el.appendixClass,
                appendixName: el.appendixName,
                appendixType: el.appendixUrl.split('.').pop(),
                rowStamp: el.rowStamp,
            });

            originalAppendixData.push({
                appendixData: el.appendixUrl,
            });

            // 因為 fileList 要和 appendixInfo 保持一致性, 所以一開始會根據 api 回傳的 data list 把 fileList 加入空字串作為填充
            originalFileList.push('');
        });

        return { originalAppendixInfo, originalAppendixData, originalFileList };
    };

    const getData = () => {
        console.log('getData');
        const guid = buyOrderData.page0.buyOrderGuid;
        axiosData(
            {
                url: `api/logistics/Lgsbuyorder/geteditdatapage3?guid=${guid}`,
            },
            (res) => {
                // console.log(res);
                setAppendixClasses(res.lgsAppendixClassList);
                setActiveTab(res.lgsAppendixClassList[0].appendixClassGuid);

                // 轉換資料格式
                const {
                    originalAppendixInfo,
                    originalAppendixData,
                    originalFileList,
                } = convertDataFormat(res.buyOrderAttachmentList);

                // API 來的都改 action 為 update
                originalAppendixInfo.map((el) => (el.action = 'update'));

                setAppendixInfo(originalAppendixInfo);
                setAppendixData(originalAppendixData);
                setFileList(originalFileList);
            }
        );
    };

    useEffect(() => {
        //第一次進來，先從後端拿資料
        const isEverEnterPage = buyOrderData.isEverEnterPage.page3;
        if (!isEverEnterPage) {
            getData();

            // 標記是否曾拿取過資料
            let parentData = { ...buyOrderData };
            parentData.isEverEnterPage.page3 = true;

            setBuyOrderData(parentData);
        } else {
            setAppendixInfo(buyOrderData.page3.appendixInfo);
            setAppendixData(buyOrderData.page3.appendixData);
            setFileList(buyOrderData.page3.fileList);
            getAppendixClassList();
        }

        return () => {
            console.log('cleanup');
        };
    }, []);

    return (
        <>
            <Container className="">
                <Left className="">
                    {page !== 0 && (
                        <PrevBtnSmall
                            handleClick={() => {
                                handlePrevPage();
                                handleTemporaryStore();
                            }}
                        />
                    )}
                </Left>
                <Center className="">
                    <StepByStepBar steps={processSteps} activePage={page} />
                </Center>
                <Right className="">
                    {page < processSteps.length - 1 ? (
                        <NextBtnSmall
                            handleClick={() => {
                                handleSubmit();
                            }}
                        />
                    ) : (
                        <NextBtnSmall className="d-none" />
                    )}
                </Right>
            </Container>
            <div className="row bg-white rounded-xxl px-6 pt-4 pb-4 mx-0 mb-3">
                <div className="col-12 px-0 ">
                    <TopInfo
                        topInfoData={buyOrderData?.topInfoData}
                        buyOrderData={buyOrderData}
                    />
                </div>
            </div>
            <div className="row bg-white rounded-xxl px-6 pt-4 pb-11 mx-0 mb-3">
                <div className="col-12 px-0 ">
                    <TabContainer activeKey={activeTab}>
                        <Tab.Container
                            id=""
                            activeKey={activeTab}
                            onSelect={handleSelectTab}
                        >
                            <Row>
                                <Col lg={2} style={colStyle}>
                                    <Nav
                                        variant="pills"
                                        className="flex-column"
                                    >
                                        {appendixClasses.map((el) => {
                                            // console.log(el.appendixGuid);
                                            return (
                                                <Nav.Item
                                                    key={el.appendixClassGuid}
                                                >
                                                    <Nav.Link
                                                        eventKey={
                                                            el.appendixClassGuid
                                                        }
                                                        style={buttonStyles}
                                                    >
                                                        {el.name01}
                                                    </Nav.Link>
                                                </Nav.Item>
                                            );
                                        })}
                                    </Nav>
                                </Col>

                                <Col style={col2Style}>
                                    <Tabs activeKey={activeTab}>
                                        {appendixClasses.map((data, index1) => {
                                            // console.log(data);
                                            return (
                                                <Tab.Pane
                                                    key={data.appendixClassGuid}
                                                    eventKey={
                                                        data.appendixClassGuid
                                                    }
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexWrap: 'wrap',
                                                        }}
                                                    >
                                                        <>
                                                            {appendixInfo.map(
                                                                (
                                                                    el,
                                                                    index2
                                                                ) => {
                                                                    // console.log(
                                                                    //     el,
                                                                    //     data
                                                                    // );

                                                                    return (
                                                                        el.action !==
                                                                            'delete' &&
                                                                        el.appendixClass ===
                                                                            data.appendixClassGuid && (
                                                                            <AppendixCard
                                                                                key={
                                                                                    el.appendixName +
                                                                                    index2
                                                                                }
                                                                                id={
                                                                                    el.appendixName +
                                                                                    index2
                                                                                }
                                                                                onClick={() =>
                                                                                    setActiveCard(
                                                                                        el.appendixClass +
                                                                                            index2
                                                                                    )
                                                                                }
                                                                                {...(view !==
                                                                                    'create' && {
                                                                                    onDoubleClick:
                                                                                        (
                                                                                            e
                                                                                        ) => {
                                                                                            // console.log(
                                                                                            //     el,
                                                                                            //     index2
                                                                                            // );
                                                                                            handleDoubleClickCard(
                                                                                                e,
                                                                                                el,
                                                                                                index2
                                                                                            );
                                                                                        },
                                                                                })}
                                                                                activeCard={
                                                                                    el.appendixClass +
                                                                                        index2 ===
                                                                                    activeCard
                                                                                }
                                                                            >
                                                                                {el.appendixClass +
                                                                                    index2 ===
                                                                                    activeCard && (
                                                                                    <span
                                                                                        style={{
                                                                                            position:
                                                                                                'absolute',
                                                                                            top: '12px',
                                                                                            right: '12px',
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            handleDeleteAppendix(
                                                                                                index2
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <svg
                                                                                            id="Group_6556"
                                                                                            dataname="Group 6556"
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                                                                            width="16"
                                                                                            height="16"
                                                                                            viewBox="0 0 16 16"
                                                                                        >
                                                                                            <defs>
                                                                                                <clipPath id="clip-path-times1811DDDDDDD">
                                                                                                    <rect
                                                                                                        id="Rectangle_3429"
                                                                                                        dataname="Rectangle 3429"
                                                                                                        width="16"
                                                                                                        height="16"
                                                                                                        fill="#ea4132"
                                                                                                    ></rect>
                                                                                                </clipPath>
                                                                                            </defs>
                                                                                            <g
                                                                                                id="Group_6556-2"
                                                                                                dataname="Group 6556"
                                                                                                clipPath="url(#clip-pathtimes1811DDDDDDD)"
                                                                                            >
                                                                                                <path
                                                                                                    id="Path_1484"
                                                                                                    dataname="Path 1484"
                                                                                                    d="M6.785,12.011h0a5.226,5.226,0,1,1,5.226-5.226,5.226,5.226,0,0,1-5.226,5.226"
                                                                                                    transform="translate(1.215 1.216)"
                                                                                                    fill="#fff"
                                                                                                ></path>
                                                                                                <path
                                                                                                    id="Path_1485"
                                                                                                    dataname="Path 1485"
                                                                                                    d="M8,16a7.995,7.995,0,1,1,5.657-2.34A8,8,0,0,1,8,16M8,9.126H8L10.868,12,12,10.87,9.124,8,12,5.128,10.868,4,8,6.872,5.127,4,4,5.128,6.869,8,4,10.87,5.127,12Z"
                                                                                                    transform="translate(0.001 0.001)"
                                                                                                    fill="#ea4132"
                                                                                                ></path>
                                                                                            </g>
                                                                                        </svg>
                                                                                    </span>
                                                                                )}
                                                                                <div
                                                                                    className="text-center d-flex align-items-center justify-content-center"
                                                                                    style={{
                                                                                        height: '152px',
                                                                                        width: '224px',
                                                                                    }}
                                                                                >
                                                                                    {handleCardImage(
                                                                                        el,
                                                                                        appendixData[
                                                                                            index2
                                                                                        ]
                                                                                    )}
                                                                                </div>
                                                                                <div
                                                                                    className="text-center bg-white"
                                                                                    style={{
                                                                                        height: '48px',
                                                                                        width: '224px',
                                                                                        padding:
                                                                                            '8px 16px',
                                                                                        display:
                                                                                            'flex',
                                                                                        alignItems:
                                                                                            'center',
                                                                                        position:
                                                                                            'relative',
                                                                                    }}
                                                                                >
                                                                                    <PictureSmallIcon />
                                                                                    <div
                                                                                        style={{
                                                                                            width: '124px',
                                                                                            height: '30px',
                                                                                            margin: '0 auto',
                                                                                            borderRadius:
                                                                                                '4px',
                                                                                            border:
                                                                                                el.appendixClass +
                                                                                                    index2 ===
                                                                                                activeCard
                                                                                                    ? '1px solid var(--primary)'
                                                                                                    : 'none',
                                                                                        }}
                                                                                    >
                                                                                        <input
                                                                                            style={{
                                                                                                width: '120px',
                                                                                                position:
                                                                                                    'absolute',
                                                                                                top: '50%',
                                                                                                transform:
                                                                                                    'translate(-50%, -50%)',
                                                                                                outline:
                                                                                                    'none',
                                                                                                border: 'none',
                                                                                                textAlign:
                                                                                                    'center',
                                                                                            }}
                                                                                            defaultValue={
                                                                                                el.appendixName
                                                                                            }
                                                                                            onCompositionStart={(
                                                                                                e
                                                                                            ) =>
                                                                                                handleComposition(
                                                                                                    e,
                                                                                                    index2
                                                                                                )
                                                                                            }
                                                                                            onCompositionUpdate={(
                                                                                                e
                                                                                            ) =>
                                                                                                handleComposition(
                                                                                                    e,
                                                                                                    index2
                                                                                                )
                                                                                            }
                                                                                            onCompositionEnd={(
                                                                                                e
                                                                                            ) =>
                                                                                                handleComposition(
                                                                                                    e,
                                                                                                    index2
                                                                                                )
                                                                                            }
                                                                                            onChange={(
                                                                                                e
                                                                                            ) => {
                                                                                                if (
                                                                                                    e.target instanceof
                                                                                                        HTMLInputElement &&
                                                                                                    !isOnComposition
                                                                                                ) {
                                                                                                    handleChangeAppendixName(
                                                                                                        e
                                                                                                            .target
                                                                                                            .value,
                                                                                                        index2
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            autoFocus={
                                                                                                el.appendixClass +
                                                                                                    index2 ===
                                                                                                activeCard
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </AppendixCard>
                                                                        )
                                                                    );
                                                                }
                                                            )}
                                                            <div
                                                                style={{
                                                                    background:
                                                                        '#EFF2F5',
                                                                    width: '224px',
                                                                    height: '200px',
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                    justifyContent:
                                                                        'center',
                                                                    borderRadius:
                                                                        '8px',
                                                                    cursor: 'pointer',
                                                                }}
                                                                onClick={() =>
                                                                    fileInputRef.current.click()
                                                                }
                                                            >
                                                                <input
                                                                    key={
                                                                        appendixData.length
                                                                    }
                                                                    type="file"
                                                                    style={{
                                                                        display:
                                                                            'none',
                                                                    }}
                                                                    ref={
                                                                        fileInputRef
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleFileUpload(
                                                                            e,
                                                                            activeTab
                                                                        )
                                                                    }
                                                                />
                                                                <AddCard_plus />
                                                            </div>
                                                        </>
                                                    </div>
                                                </Tab.Pane>
                                            );
                                        })}
                                    </Tabs>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </TabContainer>
                </div>
            </div>
        </>
    );
}

export default Page3;
