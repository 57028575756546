import { useState, useEffect } from 'react';
import { Table } from '../../../../../../components/exports';
import { ReactComponent as Icn_check } from '../../../../../../assets/images/icn_Check-r.svg';
import { useTranslation } from 'react-i18next';

function LgsBuyOrderPage4Tab_PaymentTerm({ buyOrderData }) {
	const { t } = useTranslation();
	const [tableData, setTableData] = useState([]);
	const [tableFooter, setTableFooter] = useState([]);

	const headTitleList = [
		'#',
		t('lgsBuyOrder:paymentDateBasis'),
		t('lgsBuyOrder:paymentDateDays'),
		t('lgsBuyOrder:paymentDate'),
		t('lgsBuyOrder:paymentAmount'),
		t('lgsBuyOrder:paymentPercentage'),
		t('lgsBuyOrder:annotation'),
	];

	const bodyTitleList = [
		'displayOrder',
		'baseOn',
		'days',
		'payDate',
		buyOrderData.page0.foreignCurrency ? 'foreignAmount' : 'amount',
		'percentage',
		'note',
	];

	const tableHeadStyles = [
		{
			width: '56px',
			textAlign: 'center',
		},
		{
			width: '200px',
			textAlign: 'center',
		},
		{
			width: '200px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'center',
		},
		{
			width: '160px',
			textAlign: 'center',
		},
		{
			width: '160px',
			textAlign: 'center',
		},
		{
			width: '304px',
			textAlign: 'center',
		},
	];

	const tableBodyStyles = [
		{
			width: '56px',
			textAlign: 'center',
		},
		{
			width: '200px',
			textAlign: 'left',
		},
		{
			width: '200px',
			textAlign: 'left',
		},
		{
			width: '120px',
			textAlign: 'left',
		},
		{
			width: '160px',
			textAlign: 'right',
		},
		{
			width: '160px',
			textAlign: 'right',
		},
		{
			width: '304px',
			textAlign: 'left',
		},
	];

	// console.log('buyOrderData', buyOrderData);

	let buyOrderDetails = [];
	if (buyOrderData.page0.foreignCurrency) {
		buyOrderDetails = buyOrderData.page2.paymentTermList.map(
			({
				displayOrder,
				baseOn,
				days,
				payDate,
				foreignAmount,
				percentage,
				note,
			}) => ({
				displayOrder,
				baseOn,
				days,
				payDate,
				foreignAmount,
				percentage,
				note,
			})
		);
	} else {
		buyOrderDetails = buyOrderData.page2.paymentTermList.map(
			({
				displayOrder,
				baseOn,
				days,
				payDate,
				amount,
				percentage,
				note,
			}) => ({
				displayOrder,
				baseOn,
				days,
				payDate,
				amount,
				percentage,
				note,
			})
		);
	}

	let totalsCalculate = buyOrderData.page2.totals;

	useEffect(() => {
		setTableData(buyOrderDetails);
		setTableFooter([
			'',
			'',
			'',
			t('lgsBuyOrder:totalAmount'),
			totalsCalculate.totalAmount,
			totalsCalculate.totalPercentage,
			'',
		]);
	}, []);
	console.log('buyOrderDetails', buyOrderDetails);

	return (
		<>
			{tableData && (
				<Table
					guidName="displayOrder"
					headTitleList={headTitleList}
					bodyTitleList={bodyTitleList}
					tableHeadStyles={tableHeadStyles}
					tableBodyStyles={tableBodyStyles}
					tableData={tableData}
					tableFooter={tableFooter}
				></Table>
			)}
		</>
	);
}

export default LgsBuyOrderPage4Tab_PaymentTerm;
