import useSortableData from '../../../../../../hooks/useSortableData';
import styled from 'styled-components';
import React, { useState } from 'react';

import { ReactComponent as Icn_chevronTop } from '../../../../../../assets/images/icn_chevron-top.svg';
import { ReactComponent as Icn_chevronCircleDown } from '../../../../../../assets/images/icn_chevronCircle-down.svg';
import { useTranslation } from 'react-i18next';

// #region styled-components

const IndexTableContainer = styled.div``;

const ToggleBtn = styled.h6`
	font-size: 14px;
	color: var(--primary) !important;
	margin-right: 16px;
	cursor: pointer;
`;

const IndexTable = styled.table`
	margin-bottom: 0;
	color: var(--grey4);
	background-color: #fff;
`;

const THead = styled.thead`
	height: 40px;
	cursor: pointer;

	/* &:hover {
		background-color: var(--primaryllt) !important;
	} */
`;

const TR = styled.tr`
	height: 40px;

	&:hover {
		background-color: transparent !important;
	}
	&:hover:nth-of-type(0) {
		background-color: var(--primaryllt) !important;
	}
`;

const TH = styled.th`
	vertical-align: middle;
	border: 1px solid var(--grey1) !important;
	&:nth-of-type(1) {
		border-left: none !important;
		width: 48px;
	}

	&:nth-of-type(2) {
		width: 144px;
	}

	&:nth-of-type(3) {
		width: 120px;
	}

	&:nth-of-type(4) {
		width: 80px;
	}
	&:nth-of-type(5) {
		width: 112px;
	}
	&:nth-of-type(6) {
		width: 48px;
		border-right: none !important;
	}
	&:nth-of-type(7) {
		width: 240px;
	}
	&:nth-of-type(8) {
		width: 104px;
	}
	&:nth-of-type(9) {
		width: 80px;
	}
	&:nth-of-type(10) {
		width: 144px;
	}

	&:nth-of-type(11) {
		width: 96px;
	}

	&:nth-of-type(12) {
		border-right: none !important;
		width: 48px;
	}
`;

const TBody = styled.tbody``;

const TD = styled.td`
	cursor: auto;
	border: 1px solid var(--grey1) !important;
	&:first-of-type {
		border-left: none !important;
	}

	&:nth-of-type(6),
	&:nth-of-type(7),
	&:nth-of-type(8),
	&:nth-of-type(9),
	&:nth-of-type(10),
	&:nth-of-type(11),
	&:nth-of-type(12) {
		border: none !important;
		border-bottom: 1px solid var(--grey1) !important;
	}
`;

const ChildTR = styled.tr`
	height: 40px;
`;

const ChildTD = styled.td`
	height: 40px;
	border: 1px solid var(--grey1) !important;
	padding: 0.5rem !important;
	text-align: center;

	&:first-of-type {
		border: none !important;
	}

	&:last-of-type {
		border-right: none !important;
	}
`;

//#endregion

function GoodsHistoryTable(props) {
	//#region state initialization
	const { t } = useTranslation();

	// prop
	const { guidName, sortBy, headTitleList, bodyTitleList, indexData } = props;
	console.log(props);

	// state
	const [orderBy, setOrderBy] = useState('desc');
	const [UISort, setUISort] = useState(sortBy);
	const [toggleBtnAllOpen, setToggleBtnAllOpen] = useState(false); // 展開/收合全部 btn

	// component
	const { items, requestSort, sortConfig } = useSortableData(indexData);
	console.log(items);

	//#endregion

	//#region 表格排序

	const preferredOrder = (indexData, bodyTitleList) => {
		let newObject = {};
		for (let i = 0; i < bodyTitleList.length; i++) {
			if (indexData.hasOwnProperty(bodyTitleList[i])) {
				newObject[bodyTitleList[i]] = indexData[bodyTitleList[i]];
			}
		}
		return newObject;
	};

	const getClassNamesFor = (name) => {
		if (!sortConfig) {
			return;
		}
		return sortConfig.key === name ? sortConfig.direction : undefined;
	};

	const handleSortChange = (newTarget) => {
		const oldTarget = UISort;
		setUISort(newTarget);

		if (oldTarget === newTarget)
			return setOrderBy(orderBy === 'desc' ? 'asc' : 'desc');

		return setOrderBy('asc');
	};

	preferredOrder(indexData, bodyTitleList);

	//#endregion

	return (
		<div className="position-relative">
			<IndexTableContainer className="firstRowMinHeight bg-white d-flex flex-column p-4 position-relative">
				{/* 開闔btn */}
				<div className="text-end">
					{toggleBtnAllOpen ? (
						<ToggleBtn
							className="text-primary"
							onClick={() => {
								// 箭頭btn向下
								document
									.querySelectorAll('.iconChevron')
									.forEach(
										(el) =>
											(el.style.transform =
												'rotate(0.5turn)')
									);

								// 隱藏全部子層
								document
									.querySelectorAll('.childInfoTr')
									.forEach((item) =>
										item.classList.add('d-none')
									);
								setToggleBtnAllOpen(false);
							}}
						>
							{t('lgsBuyOrder:foldAll')}
							{/* 箭頭btn向上 */}
							<Icn_chevronCircleDown
								className="ms-2 mb-1"
								style={{ transform: 'rotate(0.5turn)' }}
							/>
						</ToggleBtn>
					) : (
						<ToggleBtn
							className="text-primary"
							onClick={() => {
								// 箭頭btn向上
								document
									.querySelectorAll('.iconChevron')
									.forEach((el) => (el.style.transform = ''));

								// 顯示子層
								document
									.querySelectorAll('.childInfoTr')
									.forEach((item) =>
										item.classList.remove('d-none')
									);
								setToggleBtnAllOpen(true);
							}}
						>
							{t('lgsBuyOrder:expandAll')}
							{/* 箭頭btn向下 */}
							<Icn_chevronCircleDown className="ms-2 mb-1" />
						</ToggleBtn>
					)}
				</div>
				<IndexTable className="table">
					<THead
						className="table-head"
						items={items}
						requestSort={requestSort}
						sortConfig={sortConfig}
					>
						<TR>
							{headTitleList?.map((el) => {
								return (
									<TH
										key={`tableHead${headTitleList.indexOf(
											el
										)}`}
										className={`
                                    text-center
                                        px-2
                                        ${
											bodyTitleList[
												headTitleList.indexOf(el)
											]
										}
                                        ${getClassNamesFor(
											bodyTitleList[
												headTitleList.indexOf(el)
											]
										)}
                                            
										// 批次編號顯示/隱藏 根據後端以最後一筆作為判斷
										${
											bodyTitleList[
												headTitleList.indexOf(el)
											] === 'batchNo' &&
											items.length &&
											items[items.length - 1]
												.batchNoShow === false &&
											'd-none'
										}
										`}
										onClick={() => {
											requestSort(
												bodyTitleList[
													headTitleList.indexOf(el)
												]
											);
											handleSortChange(
												bodyTitleList[
													headTitleList.indexOf(el)
												]
											);
										}}
										// 不可點選排序
										style={{
											pointerEvents:
												bodyTitleList[
													headTitleList.indexOf(el)
												] === ''
													? 'none'
													: 'auto',
										}}
									>
										{el}
										{bodyTitleList[
											headTitleList.indexOf(el)
										] === UISort &&
											(orderBy === 'desc' ? (
												<span className="ml-1">▼</span>
											) : (
												<span className="ml-1">▲</span>
											))}
									</TH>
								);
							})}

							{/* 展開按鈕空間 */}
							<TH
								className={`
                                    text-center
                                        px-2`}
							></TH>
						</TR>
					</THead>
					<TBody>
						{items?.map((data, index) => (
							<React.Fragment key={data[guidName]}>
								{/* 父層 */}
								<TR
									className="list-item table-item"
									id={data[guidName]}
								>
									{bodyTitleList?.map((el, index) => {
										return (
											<TD
												className={`p-2 
													text-center 
	
													// 批次編號顯示/隱藏
											${el === 'batchNo' && items[items.length - 1].batchNoShow === false && 'd-none'}
													
													`}
												key={`${el}${data[guidName]}${index}`}
											>
												{data[el]}
											</TD>
										);
									})}

									{/* 展開按鈕 */}
									<TD
										className={`text-left p-2 
													text-center`}
										style={{ cursor: 'pointer' }}
										onClick={(e) => {
											// icon 箭頭方向
											e.target.closest('svg').style
												.transform === ''
												? (e.target.closest(
														'svg'
												  ).style.transform =
														'rotate(.5turn)')
												: (e.target.closest(
														'svg'
												  ).style.transform = '');

											// 子層顯示
											document
												.querySelectorAll(
													`.childInfoTr${index}`
												)
												.forEach((el) =>
													el.classList.contains(
														'd-none'
													)
														? el.classList.remove(
																'd-none'
														  )
														: el.classList.add(
																'd-none'
														  )
												);

											e.target.closest(
												'svg'
											).style.transform =
												'rotate("0.5turn")';
										}}
									>
										<Icn_chevronTop
											className="iconChevron"
											style={{
												transform:
													index === 0
														? ''
														: 'rotate(.5turn)',
											}}
										/>
									</TD>
								</TR>

								{/* 子層 */}
								{data.goodsItemList?.map((el) => (
									<ChildTR // 第一個預設開啟
										key={el.goodsItemGuid}
										className={`${
											index !== 0 && 'd-none'
										} childInfoTr childInfoTr${index}`}
									>
										<ChildTD
											colSpan="1"
											className="p-0"
										></ChildTD>
										<ChildTD
											colSpan="4"
											className="p-0"
										></ChildTD>
										<ChildTD scope="row">
											{el.displayOrder}
										</ChildTD>
										<ChildTD>
											{el.procurementContent}
										</ChildTD>
										<ChildTD>{el.quantityReceive}</ChildTD>
										<ChildTD>{el.uomcode}</ChildTD>
										{/* 批次編號顯示/隱藏 */}
										{items[items.length - 1]
											.batchNoShow && (
											<ChildTD>{el.batchNo}</ChildTD>
										)}
										<ChildTD>{el.note}</ChildTD>
										<ChildTD></ChildTD>
									</ChildTR>
								))}
							</React.Fragment>
						))}
					</TBody>
				</IndexTable>
				{!indexData.length && (
					<>
						<div
							style={{
								textAlign: 'center',
								verticalAlign: 'middle',
								fontWeight: 'bold',
								marginTop: '16px',
							}}
						>
							({t('lgsBuyOrder:noData')})
						</div>
					</>
				)}
			</IndexTableContainer>
		</div>
	);
}

export default GoodsHistoryTable;
