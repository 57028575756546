import { useState, useEffect } from 'react';
import styled from 'styled-components';
import useAxios from '../../../../../hooks/useAxios';
import { StepByStepBar } from '../../../../../components/StepByStepBar/StepByStepBar';
import { NextBtnSmall } from '../../../../../components/Buttons/NextBtnSmall';
import { BtnNormal } from '../../../../../components/Buttons/BtnNormal';
import { useTranslation } from 'react-i18next';
import { DialogInvoice } from '../DialogInvoice';
import { useCookies } from 'react-cookie';

// #region styled-components

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div`
	display: flex;
`;

const Right = styled.div`
	display: flex;
`;

//#endregion

function Page1(props) {
	//#region state initiation

	const { t } = useTranslation(['common', 'lgsBuyOrderInvoiceReturn']); // i18n

	const {
		setDialogConfirmData,
		parentData,
		setParentData,
		page,
		processSteps,
		handleNextPage,
	} = props;
	// console.log(props);

	// state
	const [backEndData, setBackEndData] = useState({});
	const [pageData, setPageData] = useState({});
	const { sendRequest: axiosDataPage1 } = useAxios();
	const decimalPlaceTl =
		parentData.page0.invoiceSelectedList[0].decimalPlaceTl;
	const [tableBodyData, setTableBodyData] = useState([]);

	// cookies
	const [cookies] = useCookies();
	const CountryCode = cookies._company_country_code;

	//#endregion

	//#region function

	// 回到page0 清空暫存資料
	const handleBackPage0 = () => {
		const newData = {
			btnName: 'goToPage0',
			isOpen: true,
			goTo: 'preStep',
			title: t('lgsBuyOrderInvoiceReturn:confirmRechooseInvoice') + '?',
		};
		setDialogConfirmData(newData);
	};

	// 暫存改被修改的資料
	const handleTemporaryStore = () => {
		const newParentData = { ...parentData };
		newParentData.page1 = pageData;
		// 標記是否曾拿取過資料
		newParentData.isEverEnterPage.page1 = true;

		setParentData(newParentData);
	};

	//#endregion

	// 驗證發票應稅總額是否小於採購訂單應稅總額

	// 往下一頁之前的檢查
	const handleSubmit = () => {
		let falseCount = 0;

		// 驗證通過
		console.log(falseCount);
		if (falseCount === 0) {
			handleTemporaryStore();
			handleNextPage();
		}
	};

	//#endregion

	// state
	const [firstDialogName, setFirstDialogName] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	const [modalData, setModalData] = useState([]);

	const [invoiceDialogData, setInvoiceDialogData] = useState({});

	const createInvoiceDialog = (btnKeyName, countryCode, backendData) => {
		const { accountDocumentInvoice, buyOrderInvoice } = backendData;

		if (
			btnKeyName === 'govInvoiceBtn' &&
			(countryCode === 'TW' || countryCode === 'CN')
		) {
			// 當選擇"+(政府)發票"時，根據公司屬於的國家為TW或CN分別對應不同的發票畫面。如果不屬於TW或CN，則進入"其他憑證"的畫面。
			setFirstDialogName('govInvoiceBtn');
			setInvoiceDialogData({
				governmentInvoice: btnKeyName === 'govInvoiceBtn' ? '1' : '0',
				invoiceReturn: '',
				vatControl: accountDocumentInvoice.vatcontrol,
				invoiceDate: accountDocumentInvoice.invoiceDate.split('T')[0],
				invoiceCode: accountDocumentInvoice.invoiceCode,
				invoiceNo: accountDocumentInvoice.invoiceNo,
				vatInvoiceFormat: accountDocumentInvoice.vatinvoiceFormat,
				vatInvoiceFormatViewName:
					accountDocumentInvoice.vatinvoiceFormatName,
				vatInvoiceTaxType: accountDocumentInvoice.vatinvoiceTaxType,
				vatRate: accountDocumentInvoice.vatrate,
				vatInputTaxable: accountDocumentInvoice.vatinputTaxable,
				netAmount: accountDocumentInvoice.netAmount,
				taxAmount: accountDocumentInvoice.taxAmount,
				totalAmount:
					accountDocumentInvoice.taxAmount +
					accountDocumentInvoice.netAmount,
				buyerCustomerViewName: accountDocumentInvoice.buyerCustomerName,
				buyerCustomerGuid: accountDocumentInvoice.buyerCustomerGuid,
				buyerTaxID:
					countryCode === 'TW'
						? accountDocumentInvoice.buyerTaxIdTw
						: accountDocumentInvoice.buyerTaxIdCn,
				buyerTaxName: accountDocumentInvoice.buyerTaxName,
				buyerAddTel: accountDocumentInvoice.buyerAddTel,
				buyerBankAccount: accountDocumentInvoice.buyerBankAccount,
				salesVendorViewName: accountDocumentInvoice.salesVendorName,
				salesVendorGuid: accountDocumentInvoice.salesVendorGuid,
				salesTaxID:
					countryCode === 'TW'
						? accountDocumentInvoice.salesTaxIdTw
						: accountDocumentInvoice.salesTaxIdCn,
				salesTaxName: accountDocumentInvoice.salesTaxName,
				salesAddTel: accountDocumentInvoice.salesAddTel,
				salesBankAccount: accountDocumentInvoice.salesBankAccount,
				currencyName:
					parentData.page0.invoiceSelectedList[0].currencyName,
			});
		} else {
			setFirstDialogName('otherInvoiceBtn');
			setInvoiceDialogData({
				governmentInvoice: btnKeyName === 'govInvoiceBtn' ? '1' : '0',
				invoiceReturn: '',
				vatControl: '',
				invoiceDate: buyOrderInvoice.invoiceDate.split('T')[0],
				invoiceCode: '',
				invoiceNo: buyOrderInvoice.invoiceDocumentNo,
				vatInvoiceFormat: buyOrderInvoice.invoiceFormat,
				vatInvoiceFormatViewName: '',
				vatInvoiceTaxType: buyOrderInvoice.vatinvoiceTaxType,
				vatRate: buyOrderInvoice.vatrate,
				vatInputTaxable: '',
				netAmount: buyOrderInvoice.netAmount,
				taxAmount: buyOrderInvoice.taxAmount,
				totalAmount:
					buyOrderInvoice.taxAmount + buyOrderInvoice.netAmount,
				buyerCustomerViewName: buyOrderInvoice.buyerCustomerName,
				buyerCustomerGuid: buyOrderInvoice.buyerCustomerGuid,
				buyerTaxID: buyOrderInvoice.buyerTaxId,
				buyerTaxName: buyOrderInvoice.buyerTaxName,
				buyerAddTel: '',
				buyerBankAccount: '',
				salesVendorViewName: buyOrderInvoice.salesVendorName,
				salesVendorGuid: buyOrderInvoice.salesVendorGuid,
				salesTaxID: buyOrderInvoice.salesTaxId,
				salesTaxName: buyOrderInvoice.salesTaxName,
				salesAddTel: '',
				salesBankAccount: '',
				currencyName:
					parentData.page0.invoiceSelectedList[0].currencyName,
			});
		}

		setIsOpen(true);
	};

	//#region useEffect

	useEffect(() => {
		const invoiceGuid = parentData.page0.invoiceGuidList;

		// axios get BE data
		const url = `api/Logistics/LgsBuyOrderInvoiceReturn/getPage2?guid=${invoiceGuid}`;
		const method = 'POST';

		axiosDataPage1({ url, method }, async (res) => {
			console.log(res);

			const btnKeyName =
				res.accountDocumentInvoice === null
					? 'otherInvoiceBtn'
					: 'govInvoiceBtn';

			await setBackEndData(res);

			const newPageData = {
				...pageData,
				backEndData: { ...res },
				invoiceItemList: [],
				allAmount: { totalAmount: 0, taxAmount: 0, netAmount: 0 },
			};

			await setPageData(newPageData);

			await createInvoiceDialog(btnKeyName, CountryCode, {
				...res,
			});
		});
	}, []);

	console.log('parentData', parentData);
	//#endregion

	return (
		<>
			<Container className="">
				<Left className="">
					{page !== 0 && (
						<BtnNormal
							className={`layout-title-searchBtn layout-title-item btn btn-outline-primary justify-content-center align-items-center`}
							key={'PrevBtn'}
							onClick={() => {
								handleBackPage0();
							}}
						>
							{t('common:button.prevStep')}
						</BtnNormal>
					)}
				</Left>
				<Center className="">
					<StepByStepBar steps={processSteps} activePage={page} />
				</Center>
				<Right className="">
					{page < processSteps.length - 1 ? (
						<BtnNormal
							className={`layout-title-searchBtn layout-title-item btn btn-primary justify-content-center align-items-center`}
							key={'PrevBtn'}
							onClick={(e) => {
								e.preventDefault();
								handleSubmit(page);
							}}
						>
							{t('common:button.nextStep')}
						</BtnNormal>
					) : (
						<NextBtnSmall className="d-none" />
					)}
				</Right>
			</Container>

			<div className="position-relative">
				<div
					className="bg-white rounded-xxl d-flex flex-column p-0"
					style={{ minHeight: '78vh' }}
				>
					{Object.keys(invoiceDialogData).length > 0 && (
						<DialogInvoice
							isOpen={isOpen}
							firstDialogName={firstDialogName}
							backEndData={backEndData}
							parentData={parentData}
							invoiceDialogData={invoiceDialogData}
							setInvoiceDialogData={setInvoiceDialogData}
							tableBodyData={tableBodyData}
							decimalPlaceTl={decimalPlaceTl}
							isDetailView={true}
						/>
					)}
				</div>
			</div>
		</>
	);
}

export default Page1;
