import React from 'react';
import styled from 'styled-components';
import ThreeColLayout from '../../../../../../components/ThreeColLayout/ThreeColLayout';
import { useTranslation } from 'react-i18next';

//#region styled-components
const LabelNormal = styled.label`
	font-size: 12px;
	color: var(--grey4);
	margin-bottom: 6px;
`;

const InputElement = styled.input`
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
	&:disabled {
		background-color: transparent;
		border-width: 0 0 1px 0;
		border-bottom: 1px solid var(--grey1);
	}
`;

const TextareaElement = styled.textarea`
	border: 1px solid var(--grey1);
	border-radius: 4px;
	padding: 8px;
	min-height: 88px;
	resize: none;
	&:focus-visible {
		outline: 0;
	}
	&:disabled {
		background-color: transparent;
	}
`;

// -----DialogCol------
const DialogWrapper = styled.div`
	position: relative;
	align-items: stretch;
	width: 100%;
	--bs-gutter-x: none;
`;

const InputDialogElementView = styled.input`
	flex: 1 1 auto;
	border-width: 0 0 1px 0;
	border-bottom: 1px solid var(--grey1);
	border-right: 0;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
	pointer-events: none;
	background-color: transparent;
`; // input顯示欄位

//#endregion

const TopInfo = ({ parentData }) => {
	const { t } = useTranslation(['common', 'lgsBuyOrderGoodsReturn']); // i18n

	function LeftCol1() {
		return (
			<>
				<div className="row">
					<div className="col-12">
						<div className="d-flex flex-column">
							<LabelNormal htmlFor={`returnDate`}>
								{t('lgsBuyOrderGoodsReturn:returnDate')}
							</LabelNormal>
							<InputElement
								type="date"
								id={`returnDate`}
								className="mb-3"
								disabled
								defaultValue={
									parentData.page1.goodsItemInfo.returnDate
								}
								onChange={() => {}}
							/>
						</div>
					</div>
				</div>

				<div className="d-flex flex-column">
					<LabelNormal htmlFor={`staff`}>
						{t('lgsBuyOrderGoodsReturn:returnPersonnel')}
					</LabelNormal>

					<DialogWrapper className="row flex-nowrap">
						<InputDialogElementView
							style={{
								minWidth: '85px',
								cursor: 'auto',
							}}
							disabled
							value={
								parentData.page1.goodsItemInfo.employeeName ||
								''
							}
							onChange={() => {}}
						/>
					</DialogWrapper>
				</div>
			</>
		);
	}

	function CenterCol1() {
		return (
			<>
				<div className={`wrapper d-flex flex-column mb-3`}>
					<LabelNormal htmlFor={'note'}>
						{t('lgsBuyOrderGoodsReturn:note2')}
					</LabelNormal>
					<TextareaElement
						id={'note'}
						rows={'4'}
						cols={'30'}
						defaultValue={parentData.page1.goodsItemInfo.note}
						onChange={() => {}}
						disabled
						disabledCssType={0}
					/>
				</div>
			</>
		);
	}

	function RightCol1() {
		return (
			<>
				<div className="d-flex flex-column">
					<LabelNormal htmlFor={`vendor`}>
						{t('lgsBuyOrderGoodsReturn:vendor2')}
					</LabelNormal>
					<InputElement
						type="text"
						id={`vendor`}
						className="mb-3"
						disabled
						defaultValue={parentData.page1.goodsItemInfo.vendorName}
					/>
				</div>
				<div className="row">
					<div className="col-lg-7">
						<div className="d-flex flex-column">
							<LabelNormal htmlFor={`documentNo`}>
								{t('lgsBuyOrderGoodsReturn:documentNo')}
							</LabelNormal>
							<InputElement
								type="text"
								id={`documentNo`}
								className="mb-3"
								disabled
								defaultValue={
									parentData.page1.goodsItemInfo.documentNo
								}
							/>
						</div>
					</div>

					<div className="col-lg-5 ps-0">
						<div className="d-flex flex-column">
							<LabelNormal htmlFor={`receivedDate`}>
								{t('lgsBuyOrderGoodsReturn:receivedDate')}
							</LabelNormal>
							<InputElement
								type="date"
								id={`receivedDate`}
								className="mb-3"
								disabled
								defaultValue={
									parentData.page1.goodsItemInfo.postingDate?.split(
										'T'
									)[0]
								}
							/>
						</div>
					</div>
				</div>
			</>
		);
	}

	return (
		<ThreeColLayout
			LeftCol={<LeftCol1 />}
			CenterCol={<CenterCol1 />}
			RightCol={<RightCol1 />}
		/>
	);
};

export default TopInfo;
