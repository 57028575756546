//import React from 'react';
import { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { PageTitleBar } from '../../../components/exports';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BtnNormal2 } from '../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import FormControlInput from '../../../components/Inputs/FormControlInput';
import InputWithDialog from '../../../components/Inputs/InputWithDialog';
import InputWithOptions from '../../../components/Inputs/InputWithOptions';
import {
	convertFromNumberingRuleToRuleForUser,
	getRulePreview,
} from './NumberingRule';
import { DialogConfirm } from './DialogConfirm';
import { SubmitConfirm } from './SubmitConfirm';
import { generateEditLog } from '../../../utils';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

// #region styled-components

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div`
	display: flex;
`;

const Right = styled.div`
	display: flex;
`;

const BtnNormal = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 120px;
	height: 32px;
	margin-right: 8px;
	svg {
		margin-right: 8px;
	}
`;

const Wrapper = styled.div`
	min-height: 720px;
`;

const TextareaElement = styled.textarea`
	border: 0px solid var(--grey2);
	border-radius: 4px;
	padding: 8px;
	min-height: 88px;
	resize: none;
	&:focus-visible {
		outline: 0;
	}
`;

const Textarea = styled.div`
	border: 0px solid var(--grey2);
	border-radius: 4px;
	padding: 8px;
	min-height: 88px;
	resize: none;
	&:focus-visible {
		outline: 0;
	}
	background-color: var(--primaryllt);
	/* width: 600px;
    height: 160px; */
`;

const NumberInputElement = styled.input`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}

	-webkit-inner-spin-button,
	-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&:hover::-webkit-inner-spin-button,
	&:hover::-webkit-outer-spin-button {
		-webkit-appearance: none !important;
		opacity: 0;
	}

	&:focus::-webkit-inner-spin-button,
	&:focus::-webkit-outer-spin-button {
		-webkit-appearance: none !important;
		opacity: 0;
	}
`;
const InputElement = styled.input`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 6px 8px;
	height: 32px;

	&:focus-visible {
		outline: 0;
	}
	background-color: transparent;
	border: none;
	border: 1px solid #e3e8ee;
	cursor: auto;
`;

const SelectElement = styled.select`
	font-size: 14px;
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 6px 8px;

	height: 32px;
	&:focus-visible {
		outline: 0;
	}

	/* 下拉箭頭圖案 */
	background-image: linear-gradient(45deg, transparent 50%, var(--grey4) 50%),
		linear-gradient(135deg, var(--grey4) 50%, transparent 50%) !important;
	background-position: calc(100% - 18px) 12px, calc(100% - 12px) 12px;
	background-size: 6px 6px, 6px 6px;
	background-repeat: no-repeat;
	-webkit-appearance: none;
`;

const LabelNormal = styled.label`
	text-align: left;
	font-size: 12px;
	color: var(--grey4);
	margin-bottom: 6px;
`;

const NoInfoRow = styled.div`
	text-align: center;
	vertical-align: middle;
	font-weight: bold;
	margin-top: 16px;
	border-bottom: 1px solid var(--grey2);
	mb-3
`;

const DialogBtn = styled.div`
	display: flex;
	align-items: center;
	border: 1px solid var(--grey2);
	border-radius: 0 4px 4px 0;
	border-left: 0;
	padding-left: 16px;
	padding-right: 12px;
	cursor: pointer;
	height: 32px;
`; // dialog按鈕

const CancelInputBtn = styled.span`
	display: inline-block;
	position: absolute;
	height: 100%;
	top: 3px;
	right: 32px;
	width: 24px;
	text-align: center;
	display: ${(props) => (props.isValueExist ? 'inline' : 'none')};
	cursor: pointer;
`; // 取消按鈕

//#endregion

// 型態 => name轉換
// const categoryControlList = {
// 	0: '不限制購買內容',
// 	A: '禁止購買資產',
// 	M: '禁止購買物料',
// };

const LgsBuyOrderType_Edit = () => {
	const { t, i18n } = useTranslation();
	const pageTitle = t('lgsBuyOrderType:pageTitle');
	const typeGuid = useLocation().pathname.split('/').pop();
	const [apiData, setApiData] = useState({});
	const [modalOpen, setModalOpen] = useState(false);
	const navigate = useNavigate();
	const [buyOrderNoRuleList, setBuyOrderNoRuleList] = useState([]);
	const [data, setData] = useState({
		prefix: '',
		period: '',
		separator: '',
		suffix: '',
		length: '',
		startNo: '',
		incremental: '',
		ruleSample: '',
		status: '',
		noRule: '',
		rowStamp: '',
		typeCode: '',
	});

	const [ruleForUser, setRuleForUser] = useState('');
	const [preview, setPreview] = useState([]);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false);
	const [isInActivateDialogOpen, setIsInActivateDialogOpen] = useState(false);
	const [isSubmitConfirmDialogOpen, setIsSubmitConfirmDialogOpen] =
		useState(false);

	const [err, setErr] = useState(false);
	const [isTypeCodeExisted, setIsTypeCodeExisted] = useState(false);

	const [originalDataForLog, setOriginalDataForLog] = useState({
		// The parameters which were needed for logging page
		//異動紀錄需比對的欄位
		typeCode: '',
		name01: '',
		name02: '',
		name03: '',
		categoryControl: '',
		noRuleName: '',
		status: '',
	});

	//這邊因為是 details 後端使用 root(/) 接，而不是使用 querry(?) 接
	//寫法1

	useEffect(() => {
		axios.get(`api/Logistics/LgsBuyOrderType/getData`).then((res) => {
			console.log('後端資料：', res.data); //要用 ,不可以使用+
			setBuyOrderNoRuleList(res.data.buyOrderNoRuleList);
			//console.log(res.data.buyOrderNoRuleList);
		});
	}, []);

	useEffect(() => {
		axios(`api/Logistics/LgsBuyOrderType/getDetails/${typeGuid}`).then(
			(res) => {
				const newData = res.data;
				console.log('newData:', newData);

				const {
					prefix,
					suffix,
					timeSeparator,
					format,
					content,
					period,
				} = convertFromNumberingRuleToRuleForUser(newData);

				const ruleForUser = `${prefix}${format}${timeSeparator}${content}${suffix}`;
				setRuleForUser(ruleForUser);
				setData({
					...data,
					...newData,
					prefix: prefix.replace(new RegExp('"', 'g'), ''),
					suffix: suffix.replace(new RegExp('"', 'g'), ''),
					timeSeparator: timeSeparator,
					format: format,
					content: content,
					length: newData.startNo.length,
					isPrefix: prefix ? true : false,
					isSuffix: suffix ? true : false,
					isPeriod: format ? true : false,
					isTimeSeparator: timeSeparator ? true : false,
					period: period,
					rowStamp: newData.rowStamp,
					status: newData.status,
				});

				//要先設定 setOriginalDataForLog，否則 setOriginalDataForLog 為空
				const {
					typeCode,
					name01,
					name02,
					name03,
					categoryControl,
					noRuleName,
					status,
				} = newData;

				setOriginalDataForLog({
					typeCode,
					name01,
					name02,
					name03,
					categoryControl,
					noRuleName,
					status,
				});

				const result = getRulePreview({
					...newData,
					prefix: prefix.replace(new RegExp('"', 'g'), ''),
					suffix: suffix.replace(new RegExp('"', 'g'), ''),
					timeSeparator: timeSeparator,
					format: format,
					content: content,
					length: newData.startNo.length,
					isPrefix: prefix ? true : false,
					isSuffix: suffix ? true : false,
					isPeriod: format ? true : false,
					isTimeSeparator: timeSeparator ? true : false,
					period: period,
					rowStamp: newData.rowStamp,
					status: newData.status,
				});
				setPreview(result);

				// console.log('data:', data);
			}
		);
	}, []);
	//console.log('data :', data);

	const handleActivate = () => {
		const updateContent = t('log.activate', {
			value: data.name,
		});

		console.log(updateContent);
		axios
			.put(`api/Logistics/LgsBuyOrderType/activate`, {
				typeGuid: data.typeGuid,
				status: '1',
				rowStamp: data.rowStamp,
				updateContent,
			})
			.then((res) => navigate(-1));
	};

	const handleInActivate = () => {
		const updateContent = t('log.lock', {
			value: data.name,
		});
		axios
			.put(`api/Logistics/LgsBuyOrderType/activate`, {
				typeGuid: data.typeGuid,
				status: '2',
				rowStamp: data.rowStamp,
				updateContent,
			})
			.then((res) => navigate(-1));
	};

	const handleDelete = () => {
		const updateContent = t('log.delete', {
			value: data.name,
		});
		axios
			.put(`api/Logistics/LgsBuyOrderType/activate`, {
				typeGuid: data.typeGuid,
				status: '4',
				rowStamp: data.rowStamp,
				updateContent,
			})
			.then((res) => navigate(-1));
	};

	// const handleDialogConfirm = (
	// 	dialogNoRule,
	// 	dialogData,
	// 	dialogStartNo,
	// 	dialogIncrement
	// ) => {
	// 	setData({
	// 		...data,
	// 		...dialogData,
	// 		startNo: dialogStartNo,
	// 		incremental: dialogIncrement,
	// 	});

	// 	console.log(
	// 		dialogNoRule,
	// 		originalDataForLog,
	// 		dialogData,
	// 		dialogStartNo,
	// 		dialogIncrement
	// 	);

	// 	setNoRule(dialogNoRule);
	// };
	// const checkInputs = () => {
	// 	if (data?.typeCode) {
	// 		setErr(false);
	// 		return setIsSubmitConfirmDialogOpen(true);
	// 	} else {
	// 		setErr(true);
	// 	}
	// };

	const checkInputs = () => {
		setIsTypeCodeExisted(false);

		if (data?.typeCode) {
			setErr(false);
			return data?.status === '1'
				? handleSubmit('1')
				: setIsSubmitConfirmDialogOpen(true);
		} else {
			setErr(true);
		}
	};
	const handleSubmit = (status) => {
		console.log(data);
		console.log(originalDataForLog);

		const newData = { ...data, status: status };
		const updateContent = generateEditLog(
			'buyOrderType',
			t,
			newData,
			originalDataForLog
		);

		const {
			typeGuid,
			typeCode,
			name01,
			name02,
			name03,
			categoryControl,
			noRuleGuid,
			rowStamp,
		} = data;

		if (updateContent) {
			axios
				.put(`api/Logistics/LgsBuyOrderType/update`, {
					typeGuid,
					typeCode,
					name01,
					name02,
					name03,
					categoryControl,
					noRuleGuid,
					status,
					rowStamp,
					updateContent,
				})
				.then((res) => navigate(-1));
		} else {
			navigate(-1);
		}
	};

	console.log(data);

	const checkTypeCode = async (e) => {
		console.log(e.target.value);
		await axios
			.get(
				`api/Logistics/LgsBuyOrderType/verifyCode?TypeGuid=${typeGuid}&TypeCode=${e.target.value}`
			)
			.then((res) => {
				console.log('check代碼是否valid', res.data);
				setIsTypeCodeExisted(false);
			})
			.catch((err) => {
				console.log(err);
				setIsTypeCodeExisted(true);

				setData({
					...data,
					typeCode: '',
				});
			});
	};

	return (
		<>
			<div id="detailsView">
				<PageTitleBar
					pageTitle={pageTitle}
					previousPageButton={true}
					backTo={-1}
				/>

				{/* DialogConfirm element */}
				<DialogConfirm
					isOpen={isActivateDialogOpen}
					setIsOpen={setIsActivateDialogOpen}
					handleClick={() => handleActivate()}
					message={t('dialog.activeConfirm')}
				/>
				<DialogConfirm
					isOpen={isInActivateDialogOpen}
					setIsOpen={setIsInActivateDialogOpen}
					handleClick={() => handleInActivate()}
					message={t('dialog.lockConfirm')}
				/>
				<DialogConfirm
					isOpen={isDeleteDialogOpen}
					setIsOpen={setIsDeleteDialogOpen}
					handleClick={() => handleDelete()}
					message={t('dialog.deleteConfirm')}
				/>
				<SubmitConfirm
					t={t}
					isOpen={isSubmitConfirmDialogOpen}
					setIsOpen={setIsSubmitConfirmDialogOpen}
					handleClickInactive={() => handleSubmit('0')}
					handleClickActive={() => handleSubmit('1')}
					message={t('dialog.saveConfirm')}
				/>
				<Container>
					<Left></Left>
					<Center></Center>
					<Right>
						<BtnNormal2
							className="btn-primary mediumBtn"
							handleClick={() => checkInputs()}
						>
							<span>{t('button.saveButton')}</span>
						</BtnNormal2>
						<BtnNormal2
							className="btn-outline-primary mediumBtn"
							handleClick={() => navigate(-1)}
						>
							<span>{t('button.cancelButton')}</span>
						</BtnNormal2>
					</Right>
				</Container>

				<div className="position-relative">
					<Wrapper className="bg-white rounded-xxl d-flex flex-column p-4 position-relative">
						<div className="col-12">
							<div className="row">
								<div className="col-lg-4 px-4 pr-xl-6">
									<FormControlInput
										id="name"
										labelText={t(
											'lgsBuyOrderType:typeCode'
										)}
										value={data.typeCode.slice(0, 15)}
										onChange={(e) => {
											const value =
												e.target.value.replace(
													/[^a-zA-Z0-9]/g,
													''
												);
											setData({
												...data,
												typeCode: e.target.value
													.slice(0, 15)
													.toUpperCase(),
											});
										}}
										onBlur={checkTypeCode}
										disabled={false}
										disabledCssType={0}
										isErr={err}
										errMsg="此欄位為必填欄位"
										isTypeCodeExisted={isTypeCodeExisted}
									/>

									<div
										className={`text-danger mt-1 ${
											isTypeCodeExisted
												? 'd-block'
												: 'd-none'
										}`}
									>
										此代碼已存在請重新輸入
									</div>
								</div>
								<div className="col-lg-4 px-4 py-1 "></div>

								<div className="col-lg-4 px-4 py-1 "></div>
							</div>

							<div className="row">
								{/* #Col1---------------------  */}
								<div className="col-lg-4 px-4 py-1 ">
									<FormControlInput
										id="name"
										labelText={t('lgsBuyOrderType:name01')}
										value={data.name01}
										onChange={(e) => {
											setData({
												...data,
												name01: e.target.value,
											});
										}}
										disabled={false}
										disabledCssType={0}
										isErr={err}
										errMsg="此欄位為必填欄位"
									/>
								</div>
								{/* #endCol1---------------------  */}
								{/* #Col2---------------------  */}
								<div className="col-lg-4 px-4 py-1 ">
									<FormControlInput
										id="name"
										labelText={t('lgsBuyOrderType:name02')}
										value={data.name02}
										onChange={(e) => {
											setData({
												...data,
												name02: e.target.value,
											});
										}}
										disabled={false}
										disabledCssType={0}
										//isErr={err}
										errMsg="此欄位為必填欄位"
									/>
								</div>

								{/* #endCol2---------------------  */}

								{/* #Col3---------------------  */}
								<div className="col-lg-4 px-4 py-1 ">
									<FormControlInput
										id="name"
										labelText={t('lgsBuyOrderType:name03')}
										value={data.name03}
										onChange={(e) => {
											setData({
												...data,
												name03: e.target.value,
											});
										}}
										disabled={false}
										disabledCssType={0}
										//isErr={err}
										errMsg={t('errMsg.mustFill')}
									/>
								</div>
								{/* #endCol3---------------------  */}
							</div>

							<div className="col-12">
								<div className="row">
									<div className="col-lg-4 px-4 py-1 ">
										<div className="form-check"></div>
									</div>
								</div>
								<div className="col-12 px-xl-3">
									<hr
										style={{
											backgroundColor: 'var(--grey3)',
										}}
									/>
								</div>
							</div>

							<div className="col-12">
								<div className="row">
									<div className="col-lg-4 px-4 py-1 ">
										<div
											className={`wrapper d-flex flex-column mb-3`}
										>
											<LabelNormal
												htmlFor={'orderTypeGuid'}
											>
												{t(
													'lgsBuyOrderType:categoryControl'
												)}
											</LabelNormal>

											<SelectElement
												value={
													data.categoryControl || ''
												}
												onChange={(e) => {
													const options =
														e.target.options;
													console.log(e.target.value);
													setData({
														...data,
														categoryControl:
															e.target.value,
														categoryName:
															options[
																options
																	.selectedIndex
															].dataset.name,
													});
												}}
											>
												{/* <option>
													{data.categoryControl ===
													'0'
														? categoryControlList[
																'0'
														  ]
														: data.categoryControl ===
														  'M'
														? categoryControlList.M
														: categoryControlList.A ||
														  ''}
												</option> */}
												<option
													value="0"
													data-name="不限制購買內容"
												>
													不限制購買內容
												</option>
												<option
													value="A"
													data-name="禁止購買資產"
												>
													禁止購買資產
												</option>
												<option
													value="M"
													data-name="禁止購買物料"
												>
													禁止購買物料
												</option>
											</SelectElement>
										</div>
										<div className="col-lg-4 px-4 py-1 "></div>

										<div className="col-lg-4 px-4 py-1 "></div>
									</div>
								</div>
							</div>

							<div className="col-12">
								<div className="row">
									<div className="col-lg-4 px-4 py-1 ">
										<div
											className={`wrapper d-flex flex-column mb-3`}
										>
											<LabelNormal
												htmlFor={'orderTypeGuid'}
											>
												{t('buyOrderType.noRuleName')}
											</LabelNormal>

											<SelectElement
												id={'noRuleGuid'}
												value={data.noRuleName}
												disabled={
													data.status === '1' ||
													data.status === '2'
														? true
														: false
												} //碰到數字要注意是字串還是數字
												onChange={(e) => {
													const options =
														e.target.options; //(e) event的 target(這邊指的是select)

													const content5 =
														options[
															options
																.selectedIndex
														].text; //這邊指的是選到第幾個的文字

													const content2 =
														options[
															options
																.selectedIndex
														];

													const categoryControl =
														buyOrderNoRuleList[
															options.selectedIndex -
																1
														].categoryControl;

													setData({
														...data,

														noRule: content2.getAttribute(
															'noRule'
														),
														noRuleName:
															e.target.value,

														noRuleGuid:
															content2.getAttribute(
																'noRuleGuid'
															),

														incremental:
															content2.getAttribute(
																'incremental'
															),

														startNo:
															content2.getAttribute(
																'startNo'
															),

														noRuleName:
															content2.getAttribute(
																'noRuleName'
															),
														// categoryControl:
														// 	categoryControl,
													});

													console.log(data);

													const {
														prefix,
														suffix,
														timeSeparator,
														format,
														content,
														period,
													} =
														convertFromNumberingRuleToRuleForUser(
															{
																...data,

																noRule: content2.getAttribute(
																	'noRule'
																),
																noRuleName:
																	e.target
																		.value,

																noRuleGuid:
																	content2.getAttribute(
																		'noRuleGuid'
																	),

																incremental:
																	content2.getAttribute(
																		'incremental'
																	),

																startNo:
																	content2.getAttribute(
																		'startNo'
																	),
																noRuleName:
																	content2.getAttribute(
																		'noRuleName'
																	),
																categoryControl:
																	categoryControl,
															}
														);
													const ruleForUser = `${prefix}${format}${timeSeparator}${content}${suffix}`;
													setRuleForUser(ruleForUser);

													console.log(
														prefix,
														suffix,
														timeSeparator,
														format,
														content,
														period
													);

													const result =
														getRulePreview({
															...data,
															prefix: prefix.replace(
																new RegExp(
																	'"',
																	'g'
																),
																''
															),
															suffix: suffix.replace(
																new RegExp(
																	'"',
																	'g'
																),
																''
															),
															timeSeparator:
																timeSeparator,
															format: format,
															content: content,
															length: data.startNo
																.length,
															isPrefix: prefix
																? true
																: false,
															isSuffix: suffix
																? true
																: false,
															isPeriod: format
																? true
																: false,
															isTimeSeparator:
																timeSeparator
																	? true
																	: false,
															period: period,
															rowStamp:
																data.rowStamp,
															status: data.status,

															startNo:
																content2.getAttribute(
																	'startNo'
																),
															incremental:
																content2.getAttribute(
																	'incremental'
																),
														});
													setPreview(result);
												}}
												style={{
													backgroundColor:
														data.status === '1' ||
														data.status === '2'
															? 'var(--grey1)'
															: 'white',
													borderRadius: '0px',
												}}
											>
												<option>
													{data.noRuleName}
												</option>
												{buyOrderNoRuleList.map(
													(el) => {
														// console.log(el.name);
														return (
															<option
																key={
																	el.noRuleGuid
																}
																id={
																	el.noRuleGuid
																}
																value={
																	el.noRuleName
																}
																onChange={() => {}}
																startno={
																	el.startNo
																}
																incremental={
																	el.incremental
																}
																norule={
																	el.noRule
																}
																noruleguid={
																	el.noRuleGuid
																}
																norulename={
																	el.name
																}
															>
																{el.name}
															</option>
														);
													}
												)}
											</SelectElement>
										</div>
										<div className="col-lg-4 px-4 py-1 "></div>

										<div className="col-lg-4 px-4 py-1 "></div>
									</div>
								</div>
							</div>
							<div className="col-12">
								<div className="row">
									<div className="col-lg-4 px-4 py-1 ">
										<div
											className={`wrapper d-flex flex-column mb-3`}
										>
											<LabelNormal
												htmlFor={'orderTypeGuid'}
											>
												{t('lgsBuyOrderType:preview')}
											</LabelNormal>
											<Textarea disabled>
												<div className="mb-1">
													{preview[0]}
												</div>
												<div className="mb-1">
													{preview[1]}
												</div>
												<div className="mb-1">
													{preview[2]}
												</div>
											</Textarea>
										</div>
										<div className="col-lg-4 px-4 py-1 "></div>

										<div className="col-lg-4 px-4 py-1 "></div>
									</div>
								</div>
							</div>
						</div>
					</Wrapper>
				</div>
			</div>

			{/* <NumberingRuleDialog
				modalOpen={modalOpen}
				handleClose={() => {
					setModalOpen(false);
				}}
				data={data}
				setData={setData}
				preview={preview}
				setPreview={setPreview}
			/> */}
		</>
	);
};

export default LgsBuyOrderType_Edit;
